import palette from 'src/theme/palette';
import { styled } from '@mui/material/styles';
import { AppBar, Toolbar, MenuItem as MuiMenuItem, Button, Box } from '@mui/material';
import { HEADER, NAVBAR } from 'src/config';
import { FONT_ROBOTO } from 'src/theme/typography';
import { LoadingButton } from '@mui/lab';
import cssStyles from '../../utils/cssStyles';

interface IRootStyle {
	isCollapse?: boolean;
	isOffset?: boolean;
	verticalLayout?: boolean;
	children?: JSX.Element | JSX.Element[];
}

export const RootStyle = styled(AppBar, {
	shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})<IRootStyle>(({ isCollapse, isOffset, verticalLayout, theme }) => ({
	...cssStyles(theme).bgBlur(),
	height: 'fit-content',
	boxShadow: 'none',
	zIndex: theme.zIndex.appBar + 1,
	transition: theme.transitions.create(['width', 'height'], {
		duration: theme.transitions.duration.shorter,
	}),
	[theme.breakpoints.up('lg')]: {
		width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
		...(isCollapse && {
			width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
		}),
		...(isOffset && {
			height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
		}),
		...(verticalLayout && {
			width: '100%',
			height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
			backgroundColor: theme.palette.background.default,
		}),
	},
}));

// ----------------------------------------------------------------------

export const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
	height: 'fit-content',
	transition: theme.transitions.create(['height', 'background-color'], {
		easing: theme.transitions.easing.easeInOut,
		duration: theme.transitions.duration.shorter,
	}),
}));

export const ToolbarShadowStyle = styled('div')(({ theme }) => ({
	left: 0,
	right: 0,
	bottom: 0,
	height: 24,
	zIndex: -1,
	margin: 'auto',
	borderRadius: '50%',
	position: 'absolute',
	width: `calc(100% - 48px)`,
	boxShadow: theme.customShadows.z8,
}));

export const MenuItem = styled(MuiMenuItem)`
	border-radius: 10px;
	font-family: ${FONT_ROBOTO};
	color: ${palette.light.custom.bidyText};
`;

export const SelectActionButtonStyled = styled(Button)`
	margin-left: -6px;
	color: ${palette.light.custom.bidyText};
`;

export const BoxStyles = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	color: theme.palette.primary.main,
	gap: '7px',
}));

export const ActionButtons = styled(Box)(() => ({
	display: 'flex',
	alignItems: 'center',
	gap: '30px',
	'> button': { fontSize: '16px' },
}));

export const ActionButtonItem = styled(LoadingButton)(({ theme }) => ({
	display: 'flex',
	cursor: 'pointer',
	gap: '5px',
	alignItems: 'center',
	color: theme.palette.primary.main,
}));
