import { ITransactionParticipants } from 'src/redux/types';

export const movingIn = (participants: ITransactionParticipants) => {
	const buyerNames = participants.buyers.reduce<string[]>((acc, buyer) => {
		if ((!buyer.relationship || buyer.relationship === 'buyer') && buyer.isApproved) {
			acc.push(`${buyer.givenNames} ${buyer.lastNames}`);
		}

		return acc;
	}, []);

	return {
		logoWidth: '100px',
		logoHeight: '100px',
		logoPosition: 'right',
		completedHtml: "<h3>That's it! Thank you for completing the Moving Out card! 🎉</h3>",
		completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
		loadingHtml: '<h3>Loading questions...</h3>',
		pages: [
			{
				name: 'timing_financing',
				elements: [
					{
						type: 'boolean',
						name: 'chain_yn',
						title: 'Do any of the buyers have any related transactions? *',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'paneldynamic',
						name: 'chain',
						visibleIf: '{chain_yn} = true',
						title: 'Related transactions',
						description: 'Please provide details of all related transactions',
						hideNumber: true,
						requiredIf: '{chain_yn} = true',
						templateElements: [
							{
								type: 'tagbox',
								name: 'sellers',
								title: 'Person(s)',
								requiredIf: '{chain_yn} = true',
								choices: buyerNames,
							},
							{
								type: 'multipletext',
								name: 'property',
								title: 'Property details',
								hideNumber: true,
								requiredIf: '{chain_yn} = true',
								items: [
									{
										name: 'street_address',
										isRequired: true,
										placeholder: 'Flat 12, Churchill Lodge',
										title: 'Street address *',
									},
									{
										name: 'postcode',
										isRequired: true,
										placeholder: 'HP5 1AQ',
										title: 'Postcode *',
									},
									{
										name: 'town_city',
										isRequired: true,
										placeholder: 'Chesham',
										title: 'Town/City *',
									},
									{
										name: 'county_province',
										isRequired: true,
										placeholder: 'Buckinghamshire',
										title: 'County/Province *',
									},
									{
										name: 'state_region',
										isRequired: true,
										placeholder: 'England',
										title: 'State/Region *',
									},
									{
										name: 'country',
										isRequired: true,
										placeholder: 'United Kingdom',
										title: 'Country *',
									},
								],
							},
							{
								type: 'multipletext',
								name: 'solicitor',
								title: 'Solicitor details',
								hideNumber: true,
								requiredIf: '{chain_yn} = true',
								items: [
									{
										name: 'given_names',
										isRequired: true,
										title: 'Given name(s) *',
									},
									{
										name: 'last_names',
										isRequired: true,
										title: 'Last name(s) *',
									},
									{
										name: 'company',
										isRequired: true,
										title: 'Company *',
									},
									{
										name: 'address',
										title: 'Address',
									},
									{
										name: 'email',
										isRequired: true,
										title: 'Email *',
									},
									{
										name: 'mobile',
										isRequired: true,
										title: 'Mobile *',
									},
								],
							},
							{
								type: 'comment',
								name: 'desc',
								title: 'Tell us more',
								description: 'e.g. what stage you are at and if there have been any hold-ups',
								hideNumber: true,
								requiredIf: '{chain_yn} = true',
							},
							{
								type: 'text',
								name: 'completion_date',
								title: 'What is the expected completion date?',
								titleLocation: 'left',
								hideNumber: true,
								requiredIf: '{chain_yn} = true',
								inputType: 'date',
							},
						],
						noEntriesText: ' ',
						panelAddText: 'Add new transaction',
					},
					{
						type: 'boolean',
						name: 'moving_requirements_yn',
						title: 'Do you have any special requirements about a move-in date? *',
						hideNumber: true,
						isRequired: true,
						labelTrue: 'Yes',
						labelFalse: 'ASAP',
					},
					{
						type: 'comment',
						name: 'moving_requirements_desc',
						visibleIf: '{moving_requirements_yn} = true',
						title: 'Please give details',
						hideNumber: true,
					},
				],
				title: 'Chain',
			},
		],
		showCompletedPage: false,
		showProgressBar: 'top',
		completeText: 'Submit',
		widthMode: 'static',
	};
};
