import Page from 'src/components/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BrandCard } from 'src/components';
import { dispatch, useSelector } from 'src/redux/store';
import { selectUser, updateUser, updateUserOnboarding } from 'src/redux/slices/auth';
import { AuthPage, ContinueButton, EmailTextInput } from '../styles';
import { OnboardingInfoFormType } from './types';
import { onboardingInfoValidationSchema } from './validation';
import OnboardingFooter from '../OnboardingFooter';

const OnboardingInfo = () => {
	const user = useSelector(selectUser);
	const form = useForm<OnboardingInfoFormType>({
		resolver: yupResolver(onboardingInfoValidationSchema),
		defaultValues: {
			email: user.email ?? null,
			givenNames: user.givenNames,
			lastNames: user.lastNames,
			mobile: user.telephone ?? null,
		},
	});

	const handleSubmit = async (data: OnboardingInfoFormType) => {
		await dispatch(updateUserOnboarding({ info: true }));

		dispatch(
			updateUser({
				telephone: data.mobile,
				workEmail: data.email,
				givenNames: data.givenNames,
				lastNames: data.lastNames,
			}),
		);
	};

	return (
		<FormProvider {...form}>
			<Page title='Login'>
				<AuthPage>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<BrandCard title='My contact details are...'>
							<EmailTextInput name='givenNames' placeholder='Enter your first and middle names ' />
							<EmailTextInput name='lastNames' placeholder='Enter your last name(s)' />
							{!user.isPrincipal && (
								<>
									<EmailTextInput name='email' placeholder='Enter your work email' />
									<EmailTextInput name='mobile' placeholder='Enter your work mobile' />
								</>
							)}
							<ContinueButton variant='contained' fullWidth type='submit' loading={form.formState.isSubmitting}>
								Continue
							</ContinueButton>
							<OnboardingFooter />
						</BrandCard>
					</form>
				</AuthPage>
			</Page>
		</FormProvider>
	);
};

export default OnboardingInfo;
