import { ChangeEvent, FC, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import palette from 'src/theme/palette';
import { dispatch } from 'src/redux/store';
import { uploadDocumentThunk, getDocumentsOverviewThunk, setAreDocumentsLoading } from 'src/redux/slices/documents';
import useCurrentDocumentsLocation from 'src/hooks/useCurrentDocumentsLocation';
import { useSnackbar } from 'notistack';
import { documentsInputAcceptTypes } from 'src/constants/documents';
import { validateUploadingFiles } from 'src/utils/documents';
import { UploadButtonProps } from './types';

const UploadButton: FC<UploadButtonProps> = ({ folderId }) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const currentLocation = useCurrentDocumentsLocation();
	const { enqueueSnackbar } = useSnackbar();

	const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
		try {
			if (!event.target.files) return;

			const filesArray = Array.from(event.target.files);

			const isValid = validateUploadingFiles(filesArray, enqueueSnackbar);

			if (!isValid) return;

			dispatch(setAreDocumentsLoading(true));

			await Promise.all(
				filesArray.map(async (file) => {
					await dispatch(uploadDocumentThunk({ folderId, location: currentLocation, file })).unwrap();
				}),
			);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			// eslint-disable-next-line no-param-reassign
			event.target.value = '';

			dispatch(getDocumentsOverviewThunk({ location: currentLocation, withLoading: true }));
		}
	};

	const handleClick = () => inputRef.current?.click();

	return (
		<Box>
			<IconButton color='info' disableRipple onClick={handleClick} sx={{ padding: '3px' }}>
				<Iconify icon='material-symbols:upload' fontSize={24} color={palette.light.primary.main} />
			</IconButton>
			<input ref={inputRef} onChange={handleChange} type='file' hidden multiple accept={documentsInputAcceptTypes} />
		</Box>
	);
};

export default UploadButton;
