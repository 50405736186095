import { selectProperty } from 'src/redux/slices/property';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { capitalize } from 'lodash';
import { wrapOptionalValue } from 'src/utils/common';
import { convertPriceToPricePerPeriod } from 'src/utils/strings';
import { PropertyGeneralAddressCard, TypographyAddressTitle } from '../../styled';

const UtilitiesSection = () => {
	const property = useSelector(selectProperty);

	const { epc } = property.overview;

	const parts = [
		{
			label: 'Main heating:',
			value: epc?.mainheat_description,
		},
		{
			label: 'Main heating controls:',
			value: epc?.mainheatcont_description,
		},
		{
			label: 'Lighting:',
			value: epc?.lighting_description,
		},
		{
			label: 'Hot water:',
			value: epc?.hotwater_description,
		},
		{
			label: 'Ventilation:',
			value: capitalize(epc?.mechanical_ventilation ?? ''),
		},
		{
			label: 'Hot water cost:',
			value: wrapOptionalValue(epc?.hot_water_cost_current, convertPriceToPricePerPeriod),
		},
		{
			label: 'Heating cost:',
			value: wrapOptionalValue(epc?.heating_cost_current, convertPriceToPricePerPeriod),
		},
		{
			label: 'Lighting cost:',
			value: wrapOptionalValue(epc?.lighting_cost_current, convertPriceToPricePerPeriod),
		},
	];

	return (
		<PropertyGeneralAddressCard sx={{ maxWidth: '650px' }}>
			<TypographyAddressTitle mb='20px'>UTILITIES</TypographyAddressTitle>
			<Box display='grid' gridTemplateColumns='1fr' columnGap='40px' rowGap='10px'>
				{parts.map(({ label, value }) => (
					<Box key={label}>
						<b>{label}</b> {value}
					</Box>
				))}
			</Box>
		</PropertyGeneralAddressCard>
	);
};

export default UtilitiesSection;
