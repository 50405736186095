import { ExtractedPropertyPrice, ExtractedPropertyPrices } from 'src/redux/slices/property';
import { getPropertyListing, getPropertyPrices } from '..';

export const extractPropertyPrices = async (uprn: string): Promise<ExtractedPropertyPrices> => {
	const [listing, propertyPrices] = await Promise.all([
		getPropertyListing(uprn).catch(() => null),
		getPropertyPrices(uprn).catch(() => null),
	]);

	const askPrices = Object.values(listing?.data ?? {})
		.reduce<ExtractedPropertyPrice[]>((acc, item) => {
			if (item.transactionType === 'Buy') acc.push({ date: item.addedOn.toDate(), price: item.price });

			return acc;
		}, [])
		.sort((a, b) => a.date.getTime() - b.date.getTime());

	const paidPrices = Object.values(propertyPrices?.data ?? {})
		.reduce<ExtractedPropertyPrice[]>((acc, item) => {
			acc.push({ date: item.date.toDate(), price: item.price });

			return acc;
		}, [])
		.sort((a, b) => a.date.getTime() - b.date.getTime());

	return { ask: askPrices, paid: paidPrices };
};
