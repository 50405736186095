import SimpleBarReact from 'simplebar-react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';
import { FC } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
	flexGrow: 1,
	height: '100%',
	overflow: 'hidden',
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
	maxHeight: '100%',
	'& .simplebar-scrollbar': {
		'&:before': {
			backgroundColor: alpha(theme.palette.grey[600], 0.48),
		},
		'&.simplebar-visible:before': {
			opacity: 1,
		},
	},
	'& .simplebar-track.simplebar-vertical': {
		width: 10,
	},
	'& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
		height: 6,
	},
	'& .simplebar-mask': {
		zIndex: 'inherit',
	},
}));

// ----------------------------------------------------------------------

const Scrollbar: FC<BoxProps> = ({ children, sx }) => {
	const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

	const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

	if (isMobile) {
		return <Box sx={{ overflowX: 'auto', ...sx }}>{children}</Box>;
	}

	return (
		<RootStyle>
			<SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx}>
				{children}
			</SimpleBarStyle>
		</RootStyle>
	);
};

export default Scrollbar;
