import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import Modal from 'src/components/customModal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import useCurrentDocumentsLocation from 'src/hooks/useCurrentDocumentsLocation';
import useLoading from 'src/hooks/useLoading';
import { deleteDocumentsFolderThunk, deleteDocumentThunk, getDocumentsOverviewThunk } from 'src/redux/slices/documents';
import { selectModal } from 'src/redux/slices/modal';
import { dispatch, useSelector } from 'src/redux/store';
import { Document } from 'src/redux/types/documents';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { getFileNameWithoutExtension } from 'src/utils/strings';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading();
	const { payload } = useSelector(selectModal<Document>);
	const location = useCurrentDocumentsLocation();

	const handleDelete = async () => {
		setIsLoading(true);
		if (payload.type === 'file') {
			await dispatch(deleteDocumentThunk({ folderId: payload.parent.id, location, path: payload.path }));
		} else {
			await dispatch(deleteDocumentsFolderThunk({ folderId: payload.id, location }));
		}
		dispatch(getDocumentsOverviewThunk({ withLoading: true, location }));

		handleCloseModal();
		setIsLoading(false);
	};

	return (
		<Box>
			<Typography sx={styledMainTitle}>DELETE DOCUMENT</Typography>
			<Typography variant='subtitle1' display='inline'>
				Are you sure you want to delete{' '}
				<Typography variant='subtitle1' color='grey.600' display='inline'>
					{getFileNameWithoutExtension(payload.name)}
				</Typography>
				?
			</Typography>
			<Box display='flex' justifyContent='flex-end' gap='15px' alignItems='center' mt='20px'>
				<LoadingButton variant='contained' onClick={handleCloseModal} loading={isLoading}>
					CANCEL
				</LoadingButton>
				<LoadingButton variant='contained' type='submit' loading={isLoading} onClick={handleDelete}>
					DELETE
				</LoadingButton>
			</Box>
		</Box>
	);
};

const DeleteDocumentModal = () => (
	<Modal name={modals.deleteDocument}>
		<Content />
	</Modal>
);

export default DeleteDocumentModal;
