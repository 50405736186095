import type { FC, PropsWithChildren } from 'react';
import { FormProvider as Form } from 'react-hook-form';
import type { IFormProvider } from './types';

// ----------------------------------------------------------------------

const FormProvider: FC<PropsWithChildren<IFormProvider>> = ({ children, onSubmit, methods }) => (
	<Form {...methods}>
		<form onSubmit={onSubmit}>{children}</form>
	</Form>
);

export default FormProvider;
