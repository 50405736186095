// ----------------------------------------------------------------------

export default function Stepper() {
	return {
		MuiStepConnector: {
			styleOverrides: {
				line: {
					borderWidth: 2,
					borderRadius: 10,
				},
				root: {
					left: 'calc(-40% + 20px)',
					right: 'calc(60% + 20px)',
				},
				completed: {
					span: {
						borderColor: '#36B37E',
					},
				},
				active: {
					span: {
						borderColor: 'black',
					},
				},
				alternativeLabel: {
					top: 20,
				},
			},
		},
		MuiStepIcon: {
			styleOverrides: {
				root: {
					height: 40,
					width: 40,
				},
				text: {
					fontSize: 13,
					fontWeight: 900,
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				label: ({ ownerState: state }) => {
					let color = '#212B36';

					if (state.completed) color = '#36B37E';
					if (!state.alternativeLabel && state.inProgress) color = '#FFAB00';
					if (!state.alternativeLabel && state.blocked) color = '#FF5630';

					return {
						fontSize: 16,
						lineHeight: '16px',
						fontWeight: 600,
						color: `${color} !important`,
					};
				},
				iconContainer: ({ ownerState: state }) => {
					let color = '#AFB8BF';

					if (state.completed) color = '#36B37E';
					if (state.inProgress) color = '#FFAB00';
					if (state.blocked) color = '#FF5630';

					return {
						'& > svg': {
							color: `${color} !important`,
						},
					};
				},
			},
		},
	};
}
