import { useState } from 'react';
import { COUNTIES_LIST, STATES_COLLECTION_NAME, PROFESSION_ESTATE_AGENT } from 'src/constants';
import {
	CITIES_COLLECTION_NAME,
	COUNTRIES_COLLECTION_NAME,
	REALTY_HIERARCHIES_COLLECTION_NAME,
	LAW_HIERARCHIES_COLLECTION_NAME,
} from 'src/constants/collections';
import { Branch, Business } from 'src/types';
import { getListOfCollectionDocuments } from 'src/utils/firebase';
import { getBranches } from 'src/utils/firebase/branch';

function firmSorter(firstFirm, secondFirm) {
	const fn = firstFirm.tradingName.toLowerCase();
	const sn = secondFirm.tradingName.toLowerCase();

	if (fn < sn) return -1;
	if (sn > fn) return 1;

	return 0;
}

function branchSorter(firstBranch, secondBranch) {
	const fn = firstBranch.name.toLowerCase();
	const sn = secondBranch.name.toLowerCase();

	if (fn < sn) return -1;
	if (sn > fn) return 1;

	return 0;
}

const useStatic = () => {
	const [cities, setSities] = useState<{ id: string }[]>([]);
	const [countries, setCountries] = useState<{ id: string }[]>([]);
	const [firms, setFirms] = useState<Business[]>([]);
	const [branches, setBranches] = useState<Branch[]>([]);
	const [states, setStates] = useState<{ id: string }[]>([]);
	const [counties, setCounties] = useState<{ name: string }[]>([]);
	const [firmsLoading, setFirmsLoading] = useState(false);
	const [branchesLoading, setBranchesLoading] = useState(false);

	const fetchStates = async () => {
		try {
			const res = await getListOfCollectionDocuments(STATES_COLLECTION_NAME);
			setStates(res);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchCities = async () => {
		try {
			const res = await getListOfCollectionDocuments(CITIES_COLLECTION_NAME);
			setSities(res);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchCountries = async () => {
		try {
			const res = await getListOfCollectionDocuments(COUNTRIES_COLLECTION_NAME);
			setCountries(res);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchCounties = async () => {
		try {
			const res = await new Promise<typeof COUNTIES_LIST>((resolve) => {
				setTimeout(() => resolve(COUNTIES_LIST), 500);
			});
			setCounties(res);
		} catch (err) {
			console.log(err);
		}
	};
	const fetchFirms = async (profession: { id: string }) => {
		try {
			setFirmsLoading(true);
			const isRealtyRole = profession.id === PROFESSION_ESTATE_AGENT.id;
			const collectionName = isRealtyRole ? REALTY_HIERARCHIES_COLLECTION_NAME : LAW_HIERARCHIES_COLLECTION_NAME;
			const firmList = await getListOfCollectionDocuments<Business>(collectionName);
			const firmsWithBranches = firmList.reduce((acc, firm) => {
				const isEqualByTradingName = (savedFirm) => savedFirm.tradingName === firm.tradingName;
				if (isRealtyRole && !firm.hasSalesBranches) return acc;
				if (acc.some(isEqualByTradingName)) {
					const oldFirm = acc.findIndex(isEqualByTradingName);
					acc[oldFirm].branches = [...acc[oldFirm].branches, ...firm.branches];
					return acc;
				}
				return [...acc, { name: firm.tradingName, ...firm }];
			}, [] as Business[]);
			setFirms(firmsWithBranches.sort(firmSorter));
			setFirmsLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	const fetchBranches = async (firm: Business) => {
		try {
			setBranchesLoading(true);
			await getBranches(firm).then((res) => {
				setBranches(res.sort(branchSorter));
			});
			setBranchesLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	return {
		cities,
		countries,
		states,
		counties,
		firms,
		branches,
		fetchStates,
		fetchCountries,
		fetchCities,
		fetchCounties,
		fetchFirms,
		fetchBranches,
		firmsLoading,
		branchesLoading,
	};
};

export default useStatic;
