import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import ActionPermissionsPicker from './components/ActionPermissionsPicker';
import { PermissionsPickerProps } from './types';

const PermissionsPicker: FC<PermissionsPickerProps> = ({ areSeeSettingsDisabled }) => (
	<Box>
		<Typography fontWeight={500}>Manage folder permissions:</Typography>
		<ActionPermissionsPicker name='permissions.upload' label='Upload' />
		<ActionPermissionsPicker name='permissions.delete' label='Delete' />
		<ActionPermissionsPicker name='permissions.download' label='Download' />
		<ActionPermissionsPicker name='permissions.see' label='See' disabled={areSeeSettingsDisabled} />
	</Box>
);

export default PermissionsPicker;
