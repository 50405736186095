import { call } from '../@';

export type Payload = {
	file: File;
	type: string;
	transactionId: string;
	placeholderId: string;
};

export default async function uploadPlaceholderVerificationDocument(payload: Omit<Payload, 'type'>) {
	return await call('transaction-uploadPlaceholderVerificationDocument', {
		...payload,
		type: payload.file.type,
		file: payload.file,
	});
}
