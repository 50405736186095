import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';
import Progress from 'src/components/Progress';
import { useEffect } from 'react';
import { logout as logoutApi } from 'src/api';
import { dispatch } from 'src/redux/store';
import { logout } from 'src/redux/slices/auth';

const Logout = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const setup = async () => {
			dispatch(logout());
			await logoutApi();

			navigate('/auth/login');
		};

		setup();
	});

	return (
		<Page title='Conveyo'>
			<Progress />
		</Page>
	);
};

export default Logout;
