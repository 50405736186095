import { handleCloseModal } from 'src/utils/propertyHelpers';
import modals from 'src/constants/modals';
import { ConfirmationModal } from 'src/components/modals';

const AcceptAskPriceConfirmationModal = () => (
	<ConfirmationModal
		sx={{ minWidth: '600px' }}
		modalName={modals.acceptAskPriceConfirmation}
		title='CONGRATULATIONS!'
		confirmText='Continue'
		handleConfirm={handleCloseModal}
		withCancelButton={false}
		description={
			<>
				Your offer has been submitted to the seller. <br /> You will be notified once your offer is accepted or
				rejected.
			</>
		}
	/>
);

export default AcceptAskPriceConfirmationModal;
