import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { DocumentOverviewProps } from './types';
import { Title } from '../Document/styled';
import Document from '../Document';
import UploadButton from '../UploadButton';
import DocumentMenu from '../DocumentMenu';
import DownloadButton from '../DownloadButton';

const DocumentOverview: FC<DocumentOverviewProps> = ({ document, parent, ...props }) => (
	<Box display='flex' flexDirection='column' gap='15px' height='100%'>
		{!parent && (
			<Box display='flex' gap='3px' alignItems='center'>
				<Title fontSize='20px !important' mr='7px'>
					{document.name}
				</Title>
				{document.settings.upload && <UploadButton folderId={document.id} location={document.location} />}
				{document.settings.download && <DownloadButton {...document} />}
				<DocumentMenu {...document} />
			</Box>
		)}

		{!parent && document.children.length ? (
			<Box display='flex' flexWrap='wrap' gap='15px' {...props}>
				{document.children.map((d) => (
					<Document key={d.id} {...d} parent={document} />
				))}
			</Box>
		) : (
			<Box
				height='75%'
				width='100%'
				display='flex'
				flexDirection='column'
				gap='5px'
				justifyContent='center'
				alignItems='center'
			>
				<img src='/assets/empty-box.jpg' alt='Empty' width='165px' />
				<Typography fontWeight={700} variant='h4'>
					Nothing here yet.
				</Typography>
			</Box>
		)}
	</Box>
);

export default DocumentOverview;
