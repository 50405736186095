import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Box, FormHelperText } from '@mui/material';
import palette from 'src/theme/palette';
import type { IRHFCheckboxGroup } from './types';
import { RHFCheckbox } from './RHFCheckbox';

const RHFCheckboxGroup: FC<IRHFCheckboxGroup> = ({ name, options }) => {
	const { formState } = useFormContext();

	const error = formState.errors[name];

	return (
		<Box display='flex' flexDirection='column'>
			{options.map((option, index) => (
				<RHFCheckbox
					key={option.value}
					label={option.label}
					name={`${name}.${index}.value`}
					disabled={option.disabled}
					labelControlProps={{ labelPlacement: 'end' }}
				/>
			))}
			{error && (
				<FormHelperText sx={{ mt: '-7px', color: palette.light.error.main }}>
					{error.message?.toString()}
				</FormHelperText>
			)}
		</Box>
	);
};

export default RHFCheckboxGroup;
