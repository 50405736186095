import moment from 'moment-timezone';
import { Log, LogTypeEnum, TableColumn } from 'src/types';
import { checkIsLogSpecificType } from 'src/utils/log';
import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { Task } from 'src/redux/types';
import { labelByLogEvent, labelByLogStatus, labelByLogType } from '../TransactionSummary/constants';
import { useTask } from '../GeneralTab/components/TasksSection/components/Task/hooks/useTask';

const Statement: FC<Task> = (task) => {
	const { statement } = useTask(task);

	return <>{statement}</>;
};

const logColorByType: Record<LogTypeEnum, string> = {
	[LogTypeEnum.EMAIL]: 'orange',
	[LogTypeEnum.JOB]: 'grey',
	[LogTypeEnum.TASK]: 'turquoise',
};

export const columns: TableColumn<Log>[] = [
	{
		key: 'timestamp',
		label: 'Timestamp',
		sortable: true,
		width: 175,
		getContent: (log) => moment(log.createdAt).utc().format('YYYY-MM-DD hh:mm A z'),
	},
	{
		key: 'type',
		label: 'Type',
		width: 75,
		sortable: true,
		getContent: (log) => (
			<Box sx={{ background: logColorByType[log.type], color: 'black', fontWeight: 700, textAlign: 'center' }}>
				{labelByLogType[log.type]}
			</Box>
		),
	},
	{
		key: 'event',
		label: 'Event',
		width: 200,
		sortable: true,
		getContent: (log) => labelByLogEvent[log.event] ?? log.event,
	},
	{
		key: 'status',
		label: 'Status',
		width: 75,
		sortable: true,
		getContent: (log) => labelByLogStatus[log.status],
	},
	{
		key: 'eventDescription',
		width: 275,
		sortable: true,
		label: 'Description',
		getContent: (l) =>
			checkIsLogSpecificType(l, LogTypeEnum.EMAIL) ? (
				l.metadata.subject
			) : checkIsLogSpecificType(l, LogTypeEnum.TASK) && l.task ? (
				<Statement {...l.task} />
			) : (
				''
			),
	},
	{
		key: 'to',
		width: 200,
		sortable: true,
		label: 'To',
		getContent: (l) => {
			if (checkIsLogSpecificType(l, LogTypeEnum.EMAIL)) {
				return (
					<Box>
						<Box display='flex' gap='5px' flexWrap='nowrap'>
							<Typography fontWeight={700} whiteSpace='nowrap'>
								To:
							</Typography>
							<Box>{[l.metadata.to].flat().join(',')}</Box>
						</Box>
						{!!l.metadata.cc?.length && (
							<Box display='flex' gap='5px' flexWrap='nowrap'>
								<Typography fontWeight={700} whiteSpace='nowrap'>
									CC:
								</Typography>
								<Box>{l.metadata.cc?.flat().join(',')}</Box>
							</Box>
						)}
					</Box>
				);
			}

			if (checkIsLogSpecificType(l, LogTypeEnum.TASK) && l.users) {
				return (
					<Box>
						{l.users.map((u, i) => {
							const isLast = i + 1 === l.users?.length;

							return (
								<b key={u.id}>
									{u.givenNames} {u.lastNames}
									{!isLast && ', '}
								</b>
							);
						})}
					</Box>
				);
			}

			return '';
		},
		sx: { wordBreak: 'break-all' },
	},
];
