import * as yup from 'yup';

export const verificationAddressIssueValidationSchema = yup.object().shape({
	buildingNumber: yup.string().required('This field is required').min(1, 'This field is required'),
	street: yup.string().required('This field is required').min(1, 'This field is required'),
	city: yup.string().required('This field is required').min(1, 'This field is required'),
	postcode: yup.string().required('This field is required').min(1, 'This field is required'),
	// county: yup.string().required('This field is required').min(1, 'This field is required'),
	state: yup.string().required('This field is required').min(1, 'This field is required'),
	country: yup.string().required('This field is required').min(1, 'This field is required'),
});
