import modals, { TERMS_OF_USE } from 'src/constants/modals';
import { BrandModal } from 'src/components/common';
import { LegalModal } from '../styled';

const AcceptableUsePolicyModal = () => (
	<LegalModal name={modals.acceptableUsePolicy} width='80%'>
		<BrandModal.Title title='ACCEPTABLE USE POLICY' />
		<BrandModal.SubTitle subTitle='PLEASE READ CAREFULLY BEFORE USING CONVEYO' />
		<BrandModal.Note note='Date last revised: January 2023' />
		<p>
			This Policy sets out the terms under which you may use conveyo; it supplements all other documents, policies and
			terms governing your use of our website (listed in our Terms of Use available at{' '}
			<a
				href={`http://www.app.conveyo.io/auth/register#${TERMS_OF_USE}`}
			>{`www.app.conveyo.io/auth/register#${TERMS_OF_USE}`}</a>
			.
		</p>
		<p>
			We may revise or amend this Policy at any time, so you are advised to check back regularly. Changes will be
			legally binding on you, regardless of whether prior notification is given.
		</p>
		<p>
			All capitalised but undefined terms used in this Policy have the meaning(s) given to them in our User Terms and
			Conditions. Your use of conveyo means that you accept, and agree to abide by, all terms in this Policy.
		</p>
		<p>
			Conveyo is operated by CONVEYO LTD (number 14345945). Our registered office is at 6 Bentinck Street, London W1U
			2EQ, England, United Kingdom.
		</p>
		<p>
			For any related queries, please contact us by email in the first instance, at{' '}
			<a href='mailto:hello@conveyo.io'>hello@conveyo.io</a>. Restrictions
		</p>
		<ol>
			<li>
				<b>Restrictions</b>
				<p>
					<ol type='a'>
						<li>
							Put simply, we want you to use conveyo only for lawful purposes. You therefore may not use conveyo:
							<ol type='1'>
								<li>in any way that breaches any applicable laws of the UK; </li>
								<li>in any way that is unlawful or fraudulent (or has that purpose of effect);</li>
								<li>to harm (or attempt to harm) others, or to encourage others to do the same;</li>
								<li>to encourage others to commit criminal acts;</li>
								<li>
									to transmit or send any unauthorised advertising or promotional material (or other form of similar
									solicitation);
								</li>
								<li>
									to collect user content or otherwise search or scrape conveyo using automated means (such as through
									harvesting bots, robots or scrapers) without our prior consent;
								</li>
								<li>
									to knowingly transmit any data, or send or upload any material that contains viruses, Trojan horses,
									worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar
									computer code designed to adversely affect the operation of any of our software or hardware, or to
									circumvent our security or authentication measures.
								</li>
							</ol>
						</li>
						<li>
							You agree not to:
							<ol type='1'>
								<li>
									use meta tags, hidden text, or metadata with our trademark, logo, URL or product name (conveyo)
									without our prior written consent;
								</li>
								<li>
									link or frame any part of conveyo in contravention of this Policy or section 10 of the Terms of Use;
								</li>
								<li>upload large amounts of unwanted or repetitive content;</li>
								<li>post unsolicited messages in comments, captions or tags;</li>
								<li>try to artificially boost views or comments on any content;</li>
								<li>reproduce, duplicate, copy or re-sell any part of conveyo.</li>
							</ol>
						</li>
						<li>
							You agree not to access without permission, or interfere with, damage or disrupt any:
							<ol type='1'>
								<li>part of our website;</li>
								<li>equipment or network on which any part of the website is stored;</li>
								<li>software used in the provision of the website;</li>
								<li>
									equipment, network or software owned or used by any third party in connection with the operation of
									the website.
								</li>
							</ol>
						</li>
						<li>
							You must not use conveyo to pledge or demand money, or payments in kind, from others, or to blackmail
							other users.
						</li>
						<li>
							You are reminded of conveyo’s status as an independent service provider to you (see section 12.1 of the
							Terms of Use). Therefore you must not represent yourself as our agent, partner or affiliate, or make any
							representations, warranties or guarantees in respect of conveyo at any time (whether online or at any
							event).
						</li>
					</ol>
				</p>
			</li>
			<li>
				<b>Who Registration</b>
				<p>
					When registering for an account, you agree to ensure that your account details are accurate and represent that
					the account is associated with you (and not a third party that is not the account holder).
				</p>
			</li>
			<li>
				<b>Privacy</b>
				<ol type='a'>
					<li>
						At conveyo, we recognise that users may wish to have a heightened expectation of privacy. As such, we are
						aware of the importance of keeping user’s personal information confidential at all times.
					</li>
					<li>
						When using conveyo, you must not solicit the account details, or access any account that belongs to another
						user, or undertake any related actions that violate the privacy or publicity rights of another user.
					</li>
				</ol>
			</li>
			<li>
				<b>Content standards</b>
				<ol type='a'>
					<li>
						Our aim is to maintain a safe and secure environment for all users, and to keep conveyo operating as a
						delightful experience for all. We therefore require that any uploaded content must not:
						<ol type='1'>
							<li>defame any person;</li>
							<li>
								discriminate against any person based on race, sex, religion, nationality, disability, sexual
								orientation or age;
							</li>
							<li>infringe the IP Rights of any person;</li>
							<li>copy any other content;</li>
							<li>misrepresent the identity or affiliation of any person with any other person;</li>
							<li>deceive, or be likely to deceive, any person; </li>
							<li>breach any legal duty owed to a third party (such as a contractual duty or a duty of confidence);</li>
							<li>promote illegal activity; </li>
							<li>threaten, abuse or invade another’s privacy;</li>
							<li>cause annoyance, inconvenience or needless anxiety (or that is otherwise cruel and insensitive);</li>
							<li>be sexually explicit, pornographic, or violate modesty and human dignity;</li>
							<li>exploit minors;</li>
							<li>
								give the impression that it emanates from, or is approved or endorsed by, conveyo (where this is not the
								case);
							</li>
							<li>advocate, promote or assist any unlawful act (such as copyright infringement or computer misuse).</li>
						</ol>
					</li>
					<li>
						In accordance with section 7 of the Terms of Use, you undertake not to upload any data, information or
						content to conveyo unless you are the owner of the IP Rights (defined in the Terms of Use) in question, or
						otherwise have permission of the relevant owner to do so.
					</li>
					<li>
						Whilst conveyo offers some form of connectivity for its users, this is not intended to operate as a social
						media platform. You must not therefore post photos or content of third parties (whether other conveyo users
						or members, or not) on conveyo without the prior consent of all those who are identified in, or identifiable
						from, that content.
					</li>
				</ol>
			</li>
			<li>
				<b>Suspension and termination</b>
				<ol type='a'>
					<li>
						You are advised to notify us at any time if you reasonably consider that there has been a breach of this
						Policy.
					</li>
					<li>
						Where a breach of this Policy occurs, we may take such action as we consider appropriate. Ultimately,
						whether a breach of this Policy has occurred is a matter for our sole discretion. Any decision we do make to
						terminate or suspend an account shall be final.
					</li>
					<li>
						Failure to comply with this Policy constitutes a material breach of the Terms of Use and may result in us:
						<ol type='1'>
							<li>immediately withdrawing your access to conveyo (whether permanently or temporarily);</li>
							<li>
								immediately removing any content, postings or material you have uploaded to conveyo (whether permanently
								or temporarily);
							</li>
							<li>issuing a first or second warning to you;</li>
							<li>
								notifying and requiring you to take all necessary steps (at your own cost) to remove any link or frame
								of any part of conveyo contained on any other website, blog, webpage or profile;
							</li>
							<li>
								taking legal proceedings against you for the reimbursement of all of our costs (on an indemnity basis)
								that we incur as a result of the relevant breach, including our reasonable administrative and legal
								fees;{' '}
							</li>
							<li>taking further legal action against you;</li>
							<li>
								disclosing such information to law enforcement authorities, either as required or as we reasonably
								consider necessary.
							</li>
						</ol>
					</li>
					<li>
						We exclude all liability for our actions taken in response to any breach of this Policy. Our rights listed
						in section 5.3 are not limited, and we may take any other action that we reasonably consider appropriate.
					</li>
				</ol>
			</li>
		</ol>
	</LegalModal>
);

export default AcceptableUsePolicyModal;
