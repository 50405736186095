import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ProfilePicWrapper = styled(Box)(({ theme }) => ({
	width: '53px',
	minWidth: '53px',
	height: '53px',
	borderRadius: '50%',
	overflow: 'hidden',
	backgroundColor: theme.palette.primary.main,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginTop: '5px',
}));

export const TypoWithoutPhoto = styled(Typography)(({ theme }) => ({
	textTransform: 'uppercase',
	fontSize: '23px',
	fontWeight: 700,
	color: theme.palette.grey[0],
}));
