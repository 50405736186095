import { styled } from '@mui/material';
import { BrandModal } from 'src/components/common';

export const LegalModal = styled(BrandModal)({
	color: '#697885',
	fontFamily: 'Public Sans',
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '28px',
	letterSpacing: '0em',
	textAlign: 'left',
	padding: '42px 75px',
	ol: {
		marginLeft: '20px',
	},
	ul: {
		marginLeft: '20px',
	},
	b: {
		fontWeight: '700',
		lineHeight: '40px',
		letterSpacing: '0em',
		textAlign: 'left',
	},
});
