import modals from 'src/constants/modals';
import { ConfirmationModal } from 'src/components/modals';
import { noop } from 'lodash';
import useLoading from 'src/hooks/useLoading';
import { dispatch, useSelector } from 'src/redux/store';
import { viewCounterOffer } from 'src/redux/slices/transaction';
import { selectModal } from 'src/redux/slices/modal';
import { IStateTransactionOffer } from 'src/redux/types';
import { handleCloseModal } from 'src/utils/propertyHelpers';

const CounterOfferReceivedModal = () => {
	const { payload } = useSelector(selectModal<{ offer: IStateTransactionOffer }>);
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(viewCounterOffer({ offerId: payload.offer.id }));

		setIsLoading(false);
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			sx={{ minWidth: '600px' }}
			modalName={modals.counterOfferReceived}
			title='COUNTEROFFER RECEIVED'
			withCancelButton={false}
			confirmText='Continue'
			handleClose={noop}
			handleConfirm={handleConfirm}
			isLoading={isLoading}
			description={
				<>
					The seller has reviewed your bid and made a counteroffer. <br />
					<br />
					Check out your participant card to accept their counteroffer or reply with another offer.
				</>
			}
		/>
	);
};

export default CounterOfferReceivedModal;
