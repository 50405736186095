import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Box } from '@mui/system';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CircularLoader } from 'src/components/CircularLoader';
import Page from 'src/components/Page';
import { selectUser } from 'src/redux/slices/auth';
import {
	selectTransactionOverview,
	selectTransactionOverviewLoading,
	selectTransactionParticipantsField,
	selectTransactionSlice,
	setCurrentTransactionTab,
} from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import { clearQuestionnairePermissions, getQuestionnairePermissionsThunk } from 'src/redux/slices/permissions';
import { formatBidValue } from 'src/utils/formatBid';
import { useTheme } from '@mui/material';
import { selectPropertySlice } from 'src/redux/slices/property';
import { QuestionnaireStatus, VerificationSessionStatus } from 'src/types';
import { clearDocuments } from 'src/redux/slices/documents';
import modals from 'src/constants/modals';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import AssignTitleModal from 'src/components/TransactionSummary/components/AssignTitle';
import SearchTitleModal from 'src/components/TransactionSummary/components/SearchTitle';
import TitleAssignmentConfirmationModal from 'src/components/TransactionSummary/components/TitleAssignmentConfirmationModal';
import ForceTitleAssignmentModal from 'src/components/TransactionSummary/components/ForceTitleAssignmentModal';
import { Navigate, Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { getStringAfterSymbols } from 'src/utils/strings';
import UpdateTransactionStateModal from 'src/components/modals/transaction/UpdateTransactionState';
import { OuterStackStyled, TitleStackStyled, TitleStyled } from './style';
import DropPropertyModal from './modals/DropPropertyModal';
import { getTransactionTabs } from './utils/navigation';
import PropertyOnboardingFlow from '../properties/components/PropertyOnboardingFlow';
import { propertyOnboardingSteps } from '../properties/constants';
import TransactionQrCodeModal from './modals/TransactionQrCodeModal';
import TransactionOnboardingFlow from './components/TransactionOnboardingFlow';
import { OfferRejectedModal } from './modals/OfferRejectedModal';
import { OfferAcceptedModal } from './modals/OfferAcceptedModal';
import CounterOfferReceivedModal from './modals/CounterOfferReceivedModal';
import { AcceptCounterofferModal } from './modals/AcceptCounterofferModal';
import CounterOfferAcceptedModal from './modals/CounterOfferAcceptedModal';
import { ViewerOfferRejectedModal } from './modals/ViewerOfferRejectedModal';
import ViewerCounterofferReceivedModal from './modals/ViewerCounterofferReceivedModal';
import CompleteIdChecksModal from './modals/CompleteIdChecksModal';
import CompleteYourPersonalDetailsModal from './modals/CompleteYourPersonalDetailsModal';
import { RequiredAskPriceModal } from './modals/RequiredAskPriceModal';
import { getTransactionInterest } from './utils/common';
import ConfirmDetailsModal from './participants/components/ConfirmDetailsModal';
import GiftorWelcomeModal from './components/GiftorWelcomeModal';
import EnterSignatureModal from './modals/EnterSignatureModal';
import SignDeedModal from './modals/SignDeedModal';
import TransactionStateLabel from './components/TransactionStateLabel';
import DeleteTransactionModal from './modals/DeleteTransactionModal';

export const MyTransaction = () => {
	const location = useLocation();
	const { id: transactionId } = useParams();
	const navigate = useNavigate();
	const theme = useTheme();
	const user = useSelector(selectUser);
	const { property } = useSelector(selectPropertySlice);
	const transaction = useSelector(selectTransactionOverview);
	const { data: participants } = useSelector(selectTransactionParticipantsField);
	const { fullQuestionnaire: transactionQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const { fullQuestionnaire: propertyQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const isLoading = useSelector(selectTransactionOverviewLoading);
	const { currentTab } = useSelector(selectTransactionSlice);
	const currentPathTab = getStringAfterSymbols(location.pathname, `${transactionId}/`);

	useEffect(() => {
		if (currentPathTab) dispatch(setCurrentTransactionTab(currentPathTab));
	}, [currentPathTab]);

	useEffect(() => {
		navigate(`/transactions/${transactionId}/${currentTab}`, { replace: true });
	}, [currentTab]);

	console.log('transaction', transaction);

	useEffect(() => {
		if (!transaction.isGiftorWelcomeEmailSkipped && transaction.isBuyerGiftor) {
			dispatch(openModal({ name: modals.giftorWelcome }));

			return;
		}

		if (transaction.myOffer?.isRejected && transaction.isBuyerViewer) {
			dispatch(openModal({ name: modals.offerRejected, payload: { offer: transaction.myOffer } }));

			return;
		}

		if (transaction.counterofferToMe?.isActive && transaction.isBuyerViewer) {
			const isViewedByUser = transaction.counterofferToMe.viewedBy.includes(user.uid);

			if (isViewedByUser) return;

			dispatch(openModal({ name: modals.counterOfferReceived, payload: { offer: transaction.counterofferToMe } }));

			return;
		}

		if (transaction.askPrice && transaction.isBuyer) {
			const isAgreedByUser = transaction.askPrice.agreedBy.includes(user.uid);

			if (isAgreedByUser) return;

			dispatch(openModal({ name: modals.offerAccepted, payload: { offer: transaction.askPrice } }));

			return;
		}

		const acceptedOffer = transaction.offers.find((o) => o.fromUserId === user.uid && o.isAccepted);

		if (acceptedOffer && participants?.participants) {
			const isViewedByUser = acceptedOffer.viewedBy.includes(user.uid);
			const viewer = participants.participants.viewers.find((v) => v.uid === acceptedOffer.toUserId);

			if (isViewedByUser || !viewer) return;

			dispatch(openModal({ name: modals.counterOfferAccepted, payload: { user: viewer, offer: acceptedOffer } }));
		}

		const receivedOffer = transaction.offers.find((o) => o.toUserId === user.uid && o.isActive);

		if (receivedOffer && participants?.participants) {
			const isViewedByUser = receivedOffer.viewedBy.includes(user.uid);
			const viewer = participants.participants.viewers.find((v) => v.uid === receivedOffer.fromUserId);

			if (isViewedByUser || !viewer) return;

			dispatch(openModal({ name: modals.viewerCounterOfferReceived, payload: { user: viewer, offer: receivedOffer } }));
		}

		const rejectedOffer = transaction.offers.find((o) => o.fromUserId === user.uid && o.isRejected);

		if (rejectedOffer && participants?.participants) {
			const isViewedByUser = rejectedOffer.viewedBy.includes(user.uid);
			const viewer = participants.participants.viewers.find((v) => v.uid === rejectedOffer.toUserId);

			if (isViewedByUser || !viewer) return;

			dispatch(openModal({ name: modals.viewerOfferRejected, payload: { user: viewer, offer: rejectedOffer } }));
		}
	}, [
		transaction.myOffer,
		transaction.agreedOffer,
		transaction.offers,
		transaction.isBuyer,
		transaction.isBuyerViewer,
		participants?.participants,
	]);

	useEffect(() => {
		if (!transaction.askPrice) {
			if (user.isAdmin) return;

			const isVerified = user.verificationStatus === VerificationSessionStatus.VERIFIED;

			if (user.isAncillary && isVerified && !transaction.askPrice) {
				dispatch(openModal({ name: modals.requiredAskPrice }));

				return;
			}

			const personalDetails = transactionQuestionnaire?.personal_details?.[user.uid];

			if (
				user.isPrincipal &&
				isVerified &&
				!transaction.askPrice &&
				personalDetails?.personal?.status === QuestionnaireStatus.DONE
			) {
				dispatch(openModal({ name: modals.requiredAskPrice }));
			}
		}
	}, [participants, transaction.askPrice, user]);

	useEffect(() => {
		dispatch(getQuestionnairePermissionsThunk({ propertyId: transaction.propertyId }));

		return () => {
			dispatch(clearQuestionnairePermissions());
		};
	}, [transaction.propertyId]);

	useEffect(
		() => () => {
			dispatch(clearDocuments());
		},
		[],
	);

	useEffect(() => {
		if (user.verificationStatus === VerificationSessionStatus.FAILED) {
			dispatch(openModal({ name: propertyOnboardingSteps.verificationFail }));
		}
	}, [property?.latestOnboardingStep]);

	const tabs = useMemo(
		() =>
			getTransactionTabs({
				isAdmin: user.isAdmin,
				isBuyerViewer: transaction.isBuyerViewer,
				isPendingParticipant: transaction.isPendingParticipant,
				isSolicitor: transaction.isBuySideSolicitor || transaction.isSellSideSolicitor,
				isVerified: user.verificationStatus === VerificationSessionStatus.VERIFIED,
			}),
		[
			transaction.isPendingParticipant,
			transaction.isBuyerViewer,
			user.isAdmin,
			user.verificationStatus,
			transaction.isBuySideSolicitor,
			transaction.isSellSideSolicitor,
		],
	);

	const isValidTab = tabs.some((t) => t.value === currentTab);

	if (!currentTab || !isValidTab) return <Navigate to={`/transactions/${transactionId}/overview`} replace />;

	const handleTabChange = (_, tab: string) => {
		dispatch(setCurrentTransactionTab(tab));
	};

	const interest = getTransactionInterest(transaction, propertyQuestionnaire);

	return (
		<Page title={transaction.name}>
			<OuterStackStyled>
				<TitleStackStyled>
					<DropPropertyModal />
					<TitleStyled display='flex' alignItems='center' gap='5px'>
						{transaction.name}{' '}
						<TitleStyled color={theme.palette.info.dark} display='flex' alignItems='center'>
							{(!!transaction.askPrice || !!interest) && (
								<>
									(
									{[interest, transaction.askPrice ? `£${formatBidValue(transaction.askPrice.price)}` : null]
										.filter(Boolean)
										.join(', ')}
									)
								</>
							)}{' '}
							{user.isAdmin && <TransactionStateLabel />}
						</TitleStyled>
					</TitleStyled>
				</TitleStackStyled>
				<Tabs
					allowScrollButtonsMobile
					variant='scrollable'
					scrollButtons='auto'
					value={currentTab}
					onChange={handleTabChange}
				>
					{tabs.map((tab) => (
						<Tab
							disableRipple
							key={tab.value}
							label={tab.label}
							icon={tab.icon}
							value={tab.value}
							disabled={transaction.isBuyerViewer && tab.value === 'transaction questions'}
						/>
					))}
				</Tabs>
			</OuterStackStyled>
			<Box sx={{ height: '100%' }}>{isLoading ? <CircularLoader /> : <Outlet />}</Box>
			<PropertyOnboardingFlow />
			<TransactionQrCodeModal />
			<TransactionOnboardingFlow />
			<OfferRejectedModal />
			<OfferAcceptedModal />
			<CounterOfferReceivedModal />
			<CounterOfferAcceptedModal />
			<AcceptCounterofferModal />
			<ViewerCounterofferReceivedModal />
			<ViewerOfferRejectedModal />
			<CompleteIdChecksModal />
			<CompleteYourPersonalDetailsModal />
			<RequiredAskPriceModal />
			<ConfirmDetailsModal />
			<GiftorWelcomeModal />
			<AssignTitleModal />
			<SearchTitleModal />
			<TitleAssignmentConfirmationModal />
			<ForceTitleAssignmentModal />
			<EnterSignatureModal />
			<SignDeedModal />
			<UpdateTransactionStateModal />
			<DeleteTransactionModal />
		</Page>
	);
};
