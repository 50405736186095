import { FC } from 'react';
// @mui
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ProgressBar from './ProgressBar';
import { ILoadingScreen } from './types';
import Progress from './Progress';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	right: 0,
	bottom: 0,
	zIndex: 99999,
	width: '100%',
	height: '100%',
	position: 'fixed',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

const LoadingScreen: FC<ILoadingScreen> = ({ isDashboard, isAbsolute }) => (
	<>
		{isAbsolute ? (
			<Box sx={{ minHeight: '200px', '& ~ div': { position: 'absolute', zIndex: 2 } }}>
				<ProgressBar />
			</Box>
		) : (
			<ProgressBar />
		)}

		{!isDashboard && (
			<RootStyle>
				<Progress />
			</RootStyle>
		)}
	</>
);

export default LoadingScreen;
