import { LocalUser } from 'src/components/InviteCard/types';
import { Business } from 'src/types';

export const emptyTransactionBuyer: LocalUser = {
	uid: '',
	givenNames: 'Buyer',
	lastNames: '',
	knownAs: '',
	email: null,
	role: 'buyer',
	side: 'buy',
	hint: 'When a buyer is found, their details will be displayed here.',
	isActive: false,
};

export const emptyTransactionSeller: LocalUser = {
	uid: '',
	givenNames: 'Seller',
	lastNames: '',
	knownAs: '',
	email: null,
	role: 'seller',
	side: 'sell',
	hint: null,
	isActive: false,
};

export const emptyBusiness: Business = {
	country: '',
	id: '',
	legalName: '',
	registrar: '',
	regNum: '',
	status: '',
	tradingName: '',
	type: '',
	authorisationType: '',
	branches: [],
	hasSalesBranches: false,
};

export const emptyBranch: RBranch = {
	id: '',
	address: '',
	email: [],
	latitude: '',
	longitude: '',
	name: '',
	officeType: '',
	postcode: '',
	services: [],
	sourceId: '',
	status: '',
	streetAddress: '',
	telephone: '',
	website: '',
};
