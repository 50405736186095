import { SolicitorReport } from 'src/redux/types';
import { call } from '../@';

export type GetSolicitorReportsResponse = {
	rows: SolicitorReport[];
	total: SolicitorReport;
};

export default async function getSolicitorsReports(): Promise<GetSolicitorReportsResponse> {
	return await call('reports-solicitors', {});
}
