import { isString } from 'lodash';

const getItemsFromPage = (page) => {
	const pageProps = Object.keys(page ?? {});
	// flat because of possible not array type
	return pageProps.reduce((acc, curr) => [...acc, ...[page[curr]].flat().filter((p) => isString(p))], [] as string[]);
};

export const sellContent = (fittingContents) => ({
	title: 'Sold items',
	logoPosition: 'right',
	completedHtml: "<h3>🏷️ Sold items form done! You're on a roll!</h3>",
	completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
	loadingHtml: '<h3>Loading questions...</h3>',
	pages: [
		{
			name: 'contents_selling',
			elements: [
				{
					type: 'paneldynamic',
					name: 'items_for_sale',
					title: 'Contents for sale',
					description: "Please select any items you'd like to sell with your property and set your ask price.",
					hideNumber: true,
					templateElements: [
						{
							type: 'dropdown',
							name: 'room_type',
							maxWidth: '70%',
							title: 'Room *',
							titleLocation: 'hidden',
							isRequired: true,
							choices: Array.from(
								new Set([
									...getItemsFromPage(fittingContents?.other),
									...getItemsFromPage(fittingContents?.outdoor),
									...getItemsFromPage(fittingContents?.rooms),
								]),
							),
							choicesOrder: 'asc',
							showOtherItem: true,
						},
						{
							type: 'text',
							name: 'price',
							minWidth: '150px',
							maxWidth: '30%',
							startWithNewLine: false,
							title: '£',
							titleLocation: 'left',
							inputType: 'number',
							autocomplete: 'transaction-amount',
							min: 0,
							max: 10000000,
							step: 0.01,
						},
					],
					noEntriesText: ' ',
					confirmDeleteText: 'Do you want to delete the item?',
					panelAddText: 'Add item',
					panelRemoveButtonLocation: 'right',
				},
			],
			title: 'Sold items',
			description:
				"You have the option to try and sell any of the contents to the buyer as part of the sale. However, it's important to remember that adding items for sale can cause delays, overpricing may turn off potential buyers, and offering too many items can be overwhelming. Keep the list reasonable to avoid confusion and ensure a successful sale. Ultimately, it's up to the buyer to decide whether or not to accept your offer.",
		},
	],
	showTitle: false,
	showCompletedPage: false,
	completeText: 'Submit',
});
