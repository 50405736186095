import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum } from 'src/types';
import useLoading from 'src/hooks/useLoading';
import { askTitleSelectionHelp } from 'src/redux/slices/transaction';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
	getPropertyTitles,
	selectProperty,
	selectPropertyTitles,
	updatePropertyOnboarding,
} from 'src/redux/slices/property';
import { Box, Typography } from '@mui/material';
import Progress from 'src/components/Progress';
import { groupPropertyTitles, handleCloseModal } from 'src/utils/propertyHelpers';
import { RHFRadioGroup } from 'src/components/hook-form';
import { LoadingButton } from '@mui/lab';
import { EmailLink } from 'src/components/common';
import { helpEmail } from 'src/constants/common';
import { useSnackbar } from 'notistack';
import { noop } from 'lodash';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { useNavigate } from 'react-router';
import { ConfirmationModal } from '../../common';
import { propertyProprietorshipConfirmationValidationSchema } from './validation';
import { PropertyProprietorshipConfirmationForm } from './types';

const Content = () => {
	const form = useForm<PropertyProprietorshipConfirmationForm>({
		resolver: yupResolver(propertyProprietorshipConfirmationValidationSchema),
	});
	const property = useSelector(selectProperty);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { data: titles, isInitialized, error } = useSelector(selectPropertyTitles);

	useEffect(() => {
		dispatch(getPropertyTitles({ id: property.id }));
	}, [property.id]);

	const handleSubmit = async (data: PropertyProprietorshipConfirmationForm) => {
		const selectedTitle = titles.find((t) => t.number === data.registerId);

		if (!selectedTitle) return;

		setIsLoading(true);

		await dispatch(
			updatePropertyOnboarding({
				moveToNextModal: true,
				payload: {
					selectedTitle,
					titleSelection: OnboardingStepStatusEnum.PASSED,
					titleHelp: OnboardingStepStatusEnum.PASSED,
				},
			}),
		);

		setIsLoading(false);
	};

	const handleGoToDashboard = () => {
		navigate('/dashboard');
		handleCloseModal();
	};

	const handleAskForHelp = async () => {
		try {
			setIsLoading(true);

			await dispatch(askTitleSelectionHelp()).unwrap();
			await dispatch(
				updatePropertyOnboarding({
					moveToNextModal: true,
					payload: { titleSelection: OnboardingStepStatusEnum.PASSED },
				}),
			);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const groupedTitles = groupPropertyTitles(titles);
	const action =
		property.onboarding?.activeSide === 'sell'
			? 'selling'
			: property.onboarding?.activeSide === 'remortgage'
			? 'remortgaging'
			: 'purchasing';

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<Box>
					{!isInitialized ? (
						<Box py='50px'>
							<Progress />
						</Box>
					) : (
						<div>
							<Box display='flex' flexDirection='column' gap='10px'>
								{groupedTitles.length ? (
									<Box pb='15px'>
										<Typography mb='10px'>
											Below are all titles associated with this property. Which one are you {action}?
										</Typography>
										{groupedTitles.map((group) => (
											<Box key={group.address}>
												<Typography fontWeight={600} fontSize='15px' color='black'>
													{group.address}
												</Typography>
												<RHFRadioGroup name='registerId' row={false} sx={{ width: '100%' }} options={group.titles} />
											</Box>
										))}
									</Box>
								) : error ? (
									<Typography mb='15px'>
										Something went wrong while querying the register. An email has been sent to our team. We will get
										back to you as soon as possible.
									</Typography>
								) : (
									<Typography>
										We couldn't find any titles for this address.
										<br /> Please contact us at <EmailLink email={helpEmail} />
									</Typography>
								)}
							</Box>
							<Box display='flex' gap='15px' justifyContent='flex-end'>
								{error ? (
									<LoadingButton
										variant='contained'
										size='large'
										loading={isLoading}
										sx={{ textTransform: 'unset' }}
										onClick={handleGoToDashboard}
									>
										Go to Dashboard
									</LoadingButton>
								) : (
									<>
										<LoadingButton
											onClick={handleAskForHelp}
											variant='outlined'
											size='large'
											loading={isLoading}
											sx={{ textTransform: 'unset' }}
										>
											Ask for help
										</LoadingButton>
										<LoadingButton
											variant='contained'
											size='large'
											type='submit'
											loading={isLoading}
											sx={{ textTransform: 'unset' }}
										>
											Select
										</LoadingButton>
									</>
								)}
							</Box>
						</div>
					)}
				</Box>
			</form>
		</FormProvider>
	);
};

const PropertyTitleSelectionModal = () => (
	<ConfirmationModal
		sx={{ maxWidth: '700px' }}
		modalName={propertyOnboardingSteps.titleSelection}
		title='SELECT TITLE'
		handleClose={noop}
		withButtons={false}
		description={<Content />}
	/>
);

export default PropertyTitleSelectionModal;
