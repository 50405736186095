import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GreetingsBlockWrapper } from 'src/components/GreetingsBlockWrapper';
import Progress from 'src/components/Progress';
import { getBinTransactions, selectBinTransactions } from 'src/redux/slices/tableSlice';
import { dispatch } from 'src/redux/store';
import Transaction from '../dashboard/components/TransactionsOverview/components/Transaction';

const Bin = () => {
	const transactions = useSelector(selectBinTransactions);

	useEffect(() => {
		dispatch(getBinTransactions({ withLoading: true }));
	}, []);

	return (
		<Box>
			<GreetingsBlockWrapper withoutLogo sx={{ mt: '15px', ml: '30px', minWidth: '500px' }}>
				<Typography variant='h5' mb='7px'>
					Your binned transactions
				</Typography>
				{transactions.isLoading ? (
					<Box py='50px'>
						<Progress />
					</Box>
				) : transactions.error ? (
					<Typography color='red'>
						Error occurred: <b>{transactions.error.message}</b>
					</Typography>
				) : (
					<>
						{!!transactions.data.length && (
							<Box display='flex' flexDirection='column' gap='10px' mb='15px'>
								{transactions.data.map((t) => (
									<Transaction key={t.id} {...t} />
								))}
							</Box>
						)}
					</>
				)}
			</GreetingsBlockWrapper>
		</Box>
	);
};

export default Bin;
