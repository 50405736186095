import { ITransactionParticipants } from 'src/redux/types';

export const proprietorFinance = (participants: ITransactionParticipants) => {
	const proprietorNames = participants.sellers.reduce<string[]>((acc, seller) => {
		if (seller.relationship !== 'proprietor' || !seller.isApproved) return acc;

		acc.push(`${seller.givenNames} ${seller.lastNames}`);

		return acc;
	}, []);

	return {
		logoPosition: 'right',
		pages: [
			{
				name: 'banking',
				elements: [
					{
						type: 'paneldynamic',
						name: 'bank_accounts',
						title: 'Bank accounts *',
						description:
							'If money is owed to you or you are required to make a payment, what bank accounts are you planning to use? Add as many as needed.',
						hideNumber: true,
						isRequired: true,
						templateElements: [
							{
								type: 'panel',
								name: 'account',
								elements: [
									{
										type: 'tagbox',
										name: 'account_holders',
										title: 'Account holder(s) *',
										isRequired: true,
										choices: proprietorNames,
									},
									{
										type: 'text',
										name: 'bank',
										title: 'Bank *',
										titleLocation: 'left',
										isRequired: true,
									},
									{
										type: 'text',
										name: 'sort_code',
										title: 'Sort code *',
										titleLocation: 'left',
										isRequired: true,
										maskType: 'pattern',
										maskSettings: {
											pattern: '99-99-99',
										},
										placeholder: '11-22-33',
									},
									{
										type: 'text',
										name: 'account_number',
										title: 'Account number *',
										titleLocation: 'left',
										hideNumber: true,
										isRequired: true,
										maskType: 'pattern',
										maskSettings: {
											pattern: '99999999',
										},
										placeholder: '12345678',
									},
								],
								title: 'Account details *',
								isRequired: true,
							},
						],
						noEntriesText: ' ',
						panelAddText: 'Add bank account',
					},
				],
				title: 'Bank accounts',
			},
			{
				name: 'mortgages_current',
				elements: [
					{
						type: 'paneldynamic',
						name: 'mortgages_current_list',
						title: 'Current mortgages *',
						hideNumber: true,
						isRequired: true,
						templateElements: [
							{
								type: 'panel',
								name: 'mortgage_current',
								elements: [
									{
										type: 'tagbox',
										name: 'current_holders',
										title: 'Mortgage holder(s) *',
										isRequired: true,
										choices: proprietorNames,
									},
									{
										type: 'text',
										name: 'current_lender',
										title: 'Lender *',
										titleLocation: 'left',
										isRequired: true,
									},
									{
										type: 'text',
										name: 'current_account_number',
										title: 'Mortgage account number *',
										titleLocation: 'left',
										hideNumber: true,
										isRequired: true,
									},
									{
										type: 'text',
										name: 'current_amount_borrowed',
										title: 'Amount borrowed *',
										titleLocation: 'left',
										description: 'In Pounds',
										hideNumber: true,
										isRequired: true,
										maskType: 'numeric',
									},
									{
										type: 'text',
										name: 'current_balance',
										title: 'Current mortgage balance *',
										titleLocation: 'left',
										description: 'In Pounds',
										hideNumber: true,
										isRequired: true,
										maskType: 'numeric',
									},
								],
								title: 'Mortgage details *',
								isRequired: true,
							},
						],
						noEntriesText: '',
						panelAddText: 'Add mortgage',
					},
				],
				title: 'Current mortgages',
			},
			{
				name: 'mortgages_new',
				elements: [
					{
						type: 'paneldynamic',
						name: 'mortgages_new_list',
						title: 'New mortgages *',
						hideNumber: true,
						isRequired: true,
						templateElements: [
							{
								type: 'panel',
								name: 'mortgage_new',
								elements: [
									{
										type: 'tagbox',
										name: 'new_holders',
										title: 'Mortgage holder(s) *',
										isRequired: true,
										choices: proprietorNames,
									},
									{
										type: 'text',
										name: 'new_lender',
										title: 'Lender *',
										titleLocation: 'left',
										isRequired: true,
									},
									{
										type: 'text',
										name: 'new_amount_borrowed',
										title: 'Amount borrowed *',
										titleLocation: 'left',
										description: 'In Pounds',
										isRequired: true,
										maskType: 'numeric',
									},
									{
										type: 'text',
										name: 'current_valuation',
										title: 'Property valuation *',
										titleLocation: 'left',
										description: 'In Pounds',
										hideNumber: true,
										isRequired: true,
										maskType: 'numeric',
									},
								],
								title: 'Mortgage details',
								isRequired: true,
							},
						],
						noEntriesText: '',
						panelAddText: 'Add mortgage',
					},
				],
				title: 'New mortgages',
			},
		],
		showTitle: false,
		completeText: 'Submit',
	};
};
