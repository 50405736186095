import { FC, useCallback } from 'react';
import { noop } from 'lodash';
import { useNavigate } from 'react-router';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import issueNotification from 'src/api/yoti/issue-notification';
import ConfirmationModal from '../ConfirmationModal';
import { VerificationInternalIssueModalProps } from './types';

const VerificationInternalIssueModal: FC<VerificationInternalIssueModalProps> = ({ name }) => {
	const navigate = useNavigate();

	const handleGoToDashboard = () => {
		navigate('/dashboard');
		handleCloseModal();
	};

	const setup = useCallback(async () => {
		await issueNotification();
	}, []);

	return (
		<ConfirmationModal
			modalName={name}
			title='ERROR'
			description={
				<>
					Something went wrong while verifying your identity. An email has been sent to our team. We'll get back to you
					as soon as possible.
				</>
			}
			withCancelButton={false}
			confirmText='Go to Dashboard'
			handleClose={noop}
			handleConfirm={handleGoToDashboard}
			setup={setup}
		/>
	);
};

export default VerificationInternalIssueModal;
