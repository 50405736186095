import { styled } from '@mui/material';
import { alpha } from '@mui/system';
import SummaryTable from './components/SummaryTable';
import SummaryField from './components/SummaryField';

export const TransactionSummaryTable = styled(SummaryTable)<{ minWidth?: string; maxWidth?: string }>(
	({ minWidth = '600px', maxWidth = '600px' }) => ({
		maxWidth,
		minWidth,
		height: 'fit-content',
	}),
);

export const ActiveTransactionSummaryField = styled(SummaryField)(({ theme }) => ({
	backgroundColor: alpha(theme.palette.grey[300], 0.5),
}));
