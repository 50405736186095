import { type Firestore, collections, uidFieldName, importFirestore } from './@firestore';

function getUidFromAuth(firestore: Firestore) {
	// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
	const auth = require('firebase/auth').getAuth(firestore.app);
	if (!auth.currentUser) {
		throw new Error('User should be authenticated to use this method');
	}
	return auth.currentUser.uid;
}

export function getMyRef(firestore: Firestore) {
	const { doc } = importFirestore();
	const uid = uidFieldName in firestore ? (firestore[uidFieldName] as string) : getUidFromAuth(firestore);
	return doc(firestore, collections.users, uid);
}
