import { FC } from 'react';
import { m } from 'framer-motion';
import { alpha, Box } from '@mui/material';

import Logo from './Logo';
import { IProgress } from './types';

const Progress: FC<IProgress> = ({ zoom = 1, sx }) => (
	<Box display='flex' alignItems='center' justifyContent='center' height='100%' sx={{ zoom, ...sx }}>
		<m.div
			animate={{
				scale: [1, 0.9, 0.9, 1, 1],
				opacity: [1, 0.48, 0.48, 1, 1],
			}}
			transition={{
				duration: 2,
				ease: 'easeInOut',
				repeatDelay: 1,
				repeat: Infinity,
			}}
		>
			<Logo disabledLink sx={{ width: 64, height: 64 }} />
		</m.div>

		<Box
			component={m.div}
			animate={{
				scale: [1.2, 1, 1, 1.2, 1.2],
				rotate: [270, 0, 0, 270, 270],
				opacity: [0.25, 1, 1, 1, 0.25],
				borderRadius: ['25%', '25%', '50%', '50%', '25%'],
			}}
			transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
			sx={{
				width: 100,
				height: 100,
				borderRadius: '25%',
				position: 'absolute',
				border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
			}}
		/>

		<Box
			component={m.div}
			animate={{
				scale: [1, 1.2, 1.2, 1, 1],
				rotate: [0, 270, 270, 0, 0],
				opacity: [1, 0.25, 0.25, 0.25, 1],
				borderRadius: ['25%', '25%', '50%', '50%', '25%'],
			}}
			transition={{
				ease: 'linear',
				duration: 3.2,
				repeat: Infinity,
			}}
			sx={{
				width: 120,
				height: 120,
				borderRadius: '25%',
				position: 'absolute',
				border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
			}}
		/>
	</Box>
);

export default Progress;
