import { useSelector } from 'react-redux';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { selectProperty, updatePropertyOnboarding } from 'src/redux/slices/property';
import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum } from 'src/types';
import { ConfirmationModal } from '../../common';

const PropertyProprietorshipModal = () => {
	const property = useSelector(selectProperty);

	const handleConfirm = async () => {
		await dispatch(
			updatePropertyOnboarding({
				moveToNextModal: true,
				payload: { titleIntroduction: OnboardingStepStatusEnum.PASSED },
			}),
		);
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '550px' }}
			modalName={propertyOnboardingSteps.titleIntroduction}
			title='PROPRIETORSHIP VERIFICATION'
			handleConfirm={handleConfirm}
			confirmText='Continue'
			withCancelButton={false}
			description={
				<>
					Thank you! We are now going to verify whether your name appears on the property register for{' '}
					<b>{property.name}</b>.
				</>
			}
		/>
	);
};

export default PropertyProprietorshipModal;
