import { ExtractedPropertyListing } from 'src/redux/slices/property';
import { getPropertyListing } from '..';

export const getExtendedPropertyListing = async (id: string) => {
	const listing = await getPropertyListing(id).catch(() => null);

	if (!listing) return null;

	const sortedEntries = Object.entries(listing.data).sort(([keyA], [keyB]) => +keyB - +keyA);
	const [latestPropertyListingKey] = sortedEntries[0];

	return {
		latest: listing.data[latestPropertyListingKey],
		sortedEntries,
	};
};

export const extractLatestPropertyListing = async (uprn: string): Promise<ExtractedPropertyListing | null> => {
	const listing = await getExtendedPropertyListing(uprn);

	if (!listing) return null;

	const latestListingEntryWithFloorPlans = listing.sortedEntries.find(([, value]) => !!value.floorplans?.length);

	return {
		photos: listing.latest.images ?? [],
		floorPlans: latestListingEntryWithFloorPlans?.[1].floorplans ?? [],
	};
};
