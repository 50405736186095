import { RateRange } from 'src/components/RatingCard/types';

export const rangeEnergy = [
	{
		color: '#0A5554',
		name: 'A',
		range: [92, 100],
	},
	{
		color: '#1B806A',
		name: 'B',
		range: [81, 91],
	},
	{
		color: '#36B37E',
		name: 'C',
		range: [69, 80],
	},
	{
		color: '#FFAB00',
		name: 'D',
		range: [55, 68],
	},
	{
		color: '#FFAC82',
		name: 'E',
		range: [39, 54],
	},
	{
		color: '#FF5630',
		name: 'F',
		range: [21, 38],
	},
	{
		color: '#B71D18',
		name: 'G',
		range: [1, 20],
	},
] as RateRange[];

export const rangeEnvironment = [
	{
		color: '#76B0F1',
		name: 'A',
		range: [92, 100],
	},
	{
		color: '#2065D1',
		name: 'B',
		range: [81, 91],
	},
	{
		color: '#103996',
		name: 'C',
		range: [69, 80],
	},
	{
		color: '#091A7A',
		name: 'D',
		range: [55, 68],
	},
	{
		color: '#697885',
		name: 'E',
		range: [39, 54],
	},
	{
		color: '#454F5B',
		name: 'F',
		range: [21, 38],
	},
	{
		color: '#161C24',
		name: 'G',
		range: [1, 20],
	},
] as RateRange[];
