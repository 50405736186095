export const VAT = 1.2;

export const propertyDataExtractionJobsCount = 6;

export const fullVersionEmail = 'hello@conveyo.io';
export const helpEmail = 'help@conveyo.io';

export const bool = [true, false];

export const privacyPolicyLink = 'https://conveyo.io/privacy-policy';
export const termsOfServiceLink = 'https://conveyo.io/terms-of-service';
