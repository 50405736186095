import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { sellProperty } from 'src/api/property';
import { useSelector } from 'react-redux';
import { selectProperty, updatePropertyOnboarding } from 'src/redux/slices/property';
import { RHFRadioGroup } from 'src/components/hook-form';
import { transactionSellerRelationship } from 'src/pages/transactions/constants';
import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum } from 'src/types';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import EmailLink from 'src/components/common/EmailLink';
import { fullVersionEmail } from 'src/constants/common';
import { getTransactionRef } from 'src/utils/firebase';
import { noop } from 'lodash';
import { ConfirmationModal } from '../../common';
import { PropertyProprietorshipVerificationIssueForm } from './types';
import { propertyProprietorshipVerificationIssueValidationSchema } from './validation';

const PropertyProprietorshipVerificationIssueModal = () => {
	const { enqueueSnackbar } = useSnackbar();
	const property = useSelector(selectProperty);
	const form = useForm<PropertyProprietorshipVerificationIssueForm>({
		resolver: yupResolver(propertyProprietorshipVerificationIssueValidationSchema),
	});

	const relationship = form.watch('relationship');
	const isOtherRelationship = relationship === 'other';

	const handleConfirm = async (data: PropertyProprietorshipVerificationIssueForm) => {
		if (data.relationship === 'other') return;

		try {
			const transactionId = await sellProperty({
				propertyId: property.id,
				role: 'seller',
				side: 'sell',
				relationship: data.relationship,
				registerId: property.onboarding?.selectedTitle?.number,
				isInstrument: property.onboarding?.activeSide === 'remortgage',
			});

			await dispatch(
				updatePropertyOnboarding({
					moveToNextModal: true,
					payload: {
						transactionRef: getTransactionRef(transactionId),
						proprietorshipMatchIssue: OnboardingStepStatusEnum.PASSED,
					},
				}),
			);
		} catch (e) {
			if (e instanceof Error) {
				enqueueSnackbar(e.message, { variant: 'error' });
			}
		}
	};

	return (
		<FormProvider {...form}>
			<ConfirmationModal
				sx={{ maxWidth: '650px' }}
				modalName={propertyOnboardingSteps.proprietorshipMatchIssue}
				isLoading={form.formState.isSubmitting}
				title='MATCH NOT FOUND'
				cancelText='Cancel'
				confirmText='Confirm'
				withButtons={!isOtherRelationship}
				description={
					<>
						Your name does not appear on the register for <b>{property.name}</b>.<br />
						Who are you in relation to the Proprietor(s)?
						<br /> <br />
						<RHFRadioGroup
							name='relationship'
							options={[
								{
									key: transactionSellerRelationship.executor.key,
									name: (
										<>
											<b>Executor</b> - you have the legal authority to act on behalf of a <b>deceased</b> proprietor
										</>
									),
								},
								{
									key: transactionSellerRelationship.attorney.key,
									name: (
										<>
											<b>Attorney</b> - you have the legal authority to act on behalf of a <b>living</b> proprietor
										</>
									),
								},
								{
									key: 'other',
									name: <b>Other</b>,
								},
							]}
						/>
						{isOtherRelationship && (
							<>
								<br />
								<Box
									height={29}
									sx={{ fontSize: 18 }}
									display='flex'
									alignItems='center'
									justifyContent='center'
									whiteSpace='pre-wrap'
								>
									Please send us an email at <EmailLink email={fullVersionEmail} />
								</Box>
							</>
						)}
					</>
				}
				handleConfirm={form.handleSubmit(handleConfirm)}
				handleClose={noop}
			/>
		</FormProvider>
	);
};

export default PropertyProprietorshipVerificationIssueModal;
