import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { sellProperty } from 'src/api/property';
import useLoading from 'src/hooks/useLoading';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { selectProperty, updatePropertyOnboarding } from 'src/redux/slices/property';
import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum } from 'src/types';
import { getTransactionRef } from 'src/utils/firebase';
import { ConfirmationModal } from '../../common';

const PropertyProprietorshipVerificationModal = () => {
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const property = useSelector(selectProperty);

	const handleConfirm = async () => {
		setIsLoading(true);

		try {
			const transactionId = await sellProperty({
				propertyId: property.id,
				registerId: property.onboarding?.selectedTitle?.number,
				role: 'seller',
				side: 'sell',
				relationship: 'proprietor',
				isInstrument: property.onboarding?.activeSide === 'remortgage',
			});

			await dispatch(
				updatePropertyOnboarding({
					moveToNextModal: true,
					payload: {
						transactionRef: getTransactionRef(transactionId),
						proprietorshipMatch: OnboardingStepStatusEnum.PASSED,
						proprietorshipMatchIssue: OnboardingStepStatusEnum.PASSED,
					},
				}),
			);
		} catch (e) {
			if (e instanceof Error) {
				enqueueSnackbar(e.message, { variant: 'error' });
			}
		} finally {
			setIsLoading(false);
		}
	};

	const action = property.onboarding?.activeSide === 'sell' ? 'sell' : 'remortgage';

	return (
		<ConfirmationModal
			sx={{ maxWidth: '550px' }}
			modalName={propertyOnboardingSteps.proprietorshipMatch}
			title='MATCH FOUND'
			cancelText='Cancel'
			confirmText='Continue'
			handleConfirm={handleConfirm}
			isLoading={isLoading}
			description={
				<>
					Your name is on the register for <b>{property.name}</b>. You're authorised to {action}.
					<br />
				</>
			}
		/>
	);
};

export default PropertyProprietorshipVerificationModal;
