import { Button, CircularProgress, styled } from '@mui/material';

export const GoBackButton = styled(Button)(({ theme }) => ({
	all: 'unset',
	position: 'relative',
	cursor: 'pointer',
	color: theme.palette.info.dark,
	background: 'transparent !important',
	fontSize: '12px',
}));

export const GoBackButtonProgress = styled(CircularProgress)({
	position: 'absolute',
	top: '4px',
	right: '-15px',
});
