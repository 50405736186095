import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Box } from '@mui/material';
import { useSnackbar } from 'notistack';

import { styledMainTitle } from 'src/components/InviteModal/styles';
import Modal from 'src/components/customModal';
import { RHFAutocomplete, RHFInvitationRoleAutocomplete, RHFTextField } from 'src/components/hook-form';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { addTransactionPlaceholder, transactionInvitation } from 'src/api';
import { dispatch } from 'src/redux/store';
import { parseStringToJson } from 'src/utils/strings';
import Progress from 'src/components/Progress';
import { RESPONSE_CODES } from 'src/constants';
import modals from 'src/constants/modals';
import { selectUser } from 'src/redux/slices/auth';
import PriceField from 'src/components/PriceField';
import { extractBidValue } from 'src/utils/formatBid';
import { VerificationSessionStatus } from 'src/types';
import useError from 'src/hooks/useError';
import {
	getTransactionOverviewThunk,
	getTransactionParticipantsThunk,
	selectTransactionOverview,
	selectTransactionParticipants,
} from 'src/redux/slices/transaction';
import GoogleAddressAutocomplete from 'src/components/GoogleAddressAutoComplete';
import { getUserBusiness } from 'src/components/InviteCard/utils';
import updateTransactionPlaceholder from 'src/api/transaction/update-placeholder';
import { StyledAlert } from 'src/hooks/styled';
import palette from 'src/theme/palette';
import useTransactionInvitation from '../../hooks/useTransactionInvitation';
import { TransactionRole, TransactionSide } from '../../types';
import { IInvitationForm } from '../types';
import { invitationSchema } from '../validationSchemas';
import ExistingUserError from './components/ExistingUserError';
import { transactionQuoteRolesKeys, transactionUserRelationship } from '../../constants';
import { convertTransactionSidesToOptions } from './utils';
import { ActionButton } from './styled';
import AuthorityOnField from './components/AuthorityOnField';

const Content = () => {
	const user = useSelector(selectUser);
	const participants = useSelector(selectTransactionParticipants);
	const transaction = useSelector(selectTransactionOverview);
	const form = useForm<IInvitationForm>({
		resolver: yupResolver(invitationSchema),
		defaultValues: { isExistingUser: false },
	});
	const { error, setError } = useError();
	const [isPlaceholderFromTitle, setIsPlaceholderFromTitle] = useState(false);
	const [role, side, isExistingUser, innerRole, relationship, existingUser] = form.watch([
		'role',
		'side',
		'isExistingUser',
		'innerRole',
		'relationship',
		'existingUser',
	]);
	const { payload } = useSelector(
		selectModal<{ side?: TransactionSide; role?: TransactionRole; placeholderId?: string; isEdit?: boolean }>,
	);
	const { enqueueSnackbar } = useSnackbar();
	const { transactionId, users, fetchUsers, areUsersLoading, metadata, isMetadataInitialized, sides } =
		useTransactionInvitation();

	const showQuoteField = useMemo(() => !!role && transactionQuoteRolesKeys.includes(role as never), [role]);
	const isAncillary = innerRole !== 'buyer' && innerRole !== 'seller';

	const handleSideChange = () => form.setValue('role', null as never);

	useEffect(() => {
		if (payload.placeholderId) {
			const placeholder = participants.placeholders.find(
				({ placeholderId }) => placeholderId === payload.placeholderId,
			);

			if (!placeholder) return;

			const { branch, business } = getUserBusiness(placeholder);

			form.setValue('side', placeholder.side);
			form.setValue('role', placeholder.role);
			form.setValue('innerRole', placeholder.role);
			form.setValue('relationship', placeholder.relationship);
			form.setValue('givenNames', placeholder.givenNames);
			form.setValue('lastNames', placeholder.lastNames);
			form.setValue('email', placeholder.email ?? '');
			form.setValue('phone', placeholder.phone);
			form.setValue('business', business.name);
			form.setValue('branch', branch.name);
			form.setValue('quote', placeholder.quote ?? '');
			form.setValue('address.formattedAddress', (branch.address || placeholder.address) ?? '');
			setIsPlaceholderFromTitle(placeholder.isFromTitle);

			return;
		}
		if (payload.side) form.setValue('side', payload.side);
		if (transaction.isInstrument) form.setValue('side', 'sell');
	}, [payload, transaction.isInstrument]);

	const handleAdd = async ({ address, ...data }: IInvitationForm) => {
		try {
			setError(null);

			await addTransactionPlaceholder({
				...data,
				role: innerRole,
				transactionId,
				quote: data.quote ? extractBidValue(data.quote) : null,
				...(address?.formattedAddress && { address: address.formattedAddress }),
			});

			handleCloseModal();
			enqueueSnackbar(`${data.givenNames} ${data.lastNames} successfully added!`);
			dispatch(getTransactionParticipantsThunk({ id: transactionId, withLoading: true }));
			dispatch(getTransactionOverviewThunk({ id: transactionId, withLoading: false }));
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		}
	};

	const handleUpdate = async ({ address, ...data }: IInvitationForm) => {
		try {
			setError(null);

			await updateTransactionPlaceholder({
				...data,
				transactionId,
				id: payload.placeholderId as string,
				quote: data.quote ? extractBidValue(data.quote) : null,
				...(address?.formattedAddress && { address: address.formattedAddress }),
			});

			handleCloseModal();
			enqueueSnackbar(`${data.givenNames} ${data.lastNames} successfully updated!`);
			dispatch(getTransactionParticipantsThunk({ id: transactionId, withLoading: true }));
			dispatch(getTransactionOverviewThunk({ id: transactionId, withLoading: false }));
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		}
	};

	const handleAddAndInvite = async (data: IInvitationForm) => {
		try {
			const shouldHaveAuthority =
				relationship === transactionUserRelationship.attorney.key ||
				relationship === transactionUserRelationship.executor.key ||
				relationship === transactionUserRelationship.proxy.key;

			if (!data.email) {
				form.setError('email', { message: 'This field is required' });

				return;
			}

			if (shouldHaveAuthority && !data.authorityOn?.length) {
				form.setError('authorityOn', { message: 'This field is required' });

				return;
			}

			setError(null);

			await transactionInvitation({
				...data,
				authorityOn: data.authorityOn?.length ? data.authorityOn : null,
				quote: data.quote ? extractBidValue(data.quote) : null,
				transactionId,
				role: innerRole,
				placeholderId: payload.placeholderId,
			});

			handleCloseModal();
			enqueueSnackbar(`${data.givenNames} ${data.lastNames} successfully invited!`);
			dispatch(getTransactionParticipantsThunk({ id: transactionId, withLoading: true }));
			dispatch(getTransactionOverviewThunk({ id: transactionId, withLoading: false }));
		} catch (e) {
			if (e instanceof Error) {
				const response = parseStringToJson(e.message);

				if (typeof response === 'string') {
					setError(e.message);
				} else if (response?.code) {
					switch (response?.code) {
						case RESPONSE_CODES.INVITATION.USER_EXISTS_UNDER_OTHER_ROLE:
							enqueueSnackbar(<ExistingUserError />, { variant: 'error' });
							break;
						default:
							break;
					}
				}
			}
		}
	};

	const handleEmailChange = (email: string) => {
		const matchingUser = users.find((u) => u.email.toLowerCase() === email.toLowerCase());

		form.setValue('email', email, { shouldValidate: true });
		form.setValue('existingUser', matchingUser);

		if (!matchingUser) {
			form.setValue('isExistingUser', false);

			if (payload.placeholderId) {
				const placeholder = participants.placeholders.find(
					({ placeholderId }) => placeholderId === payload.placeholderId,
				);

				if (!placeholder) return;

				form.setValue('givenNames', placeholder.givenNames);
				form.setValue('lastNames', placeholder.lastNames);
			}

			return;
		}

		form.setValue('isExistingUser', true);
		form.setValue('givenNames', matchingUser.givenNames);
		form.setValue('lastNames', matchingUser.lastNames);
		if (matchingUser.telephone) form.setValue('phone', matchingUser.telephone);
		if (matchingUser.address && !isAncillary) form.setValue('address.formattedAddress', matchingUser.address);
		if (isAncillary && matchingUser.branch?.address) {
			form.setValue('address.formattedAddress', matchingUser.branch.address);
		}
		if (isAncillary && matchingUser.branch?.name) form.setValue('branch', matchingUser.branch.name);
		if (isAncillary && matchingUser.business?.tradingName) form.setValue('business', matchingUser.business.tradingName);
	};

	useEffect(() => {
		if (side && role) fetchUsers(role, side);
	}, [side, role]);

	useEffect(() => {
		const email = form.getValues('email');

		if (!email) return;

		handleEmailChange(email);
	}, [users]);

	const handleAssignTitle = () => dispatch(openModal({ name: modals.assignTitle }));

	const { areButtonsDisabled = false, ...warning } = useMemo(() => {
		if (payload.isEdit || payload.placeholderId || role !== 'seller' || relationship !== 'proprietor') {
			return { content: null };
		}

		const isExistingProprietor = participants.participants.sellers.some(
			(s) => s.role === 'seller' && s.relationship === 'proprietor',
		);

		if (transaction.titleEdition) {
			if (!isExistingProprietor) return { content: null };

			return {
				content: (
					<>
						Placeholders for known proprietors have already been created.
						<br /> Invite them by clicking on <b>⋮</b> and selecting <b>Invite</b>.
					</>
				),
			};
		}

		return {
			content: user.isPrincipal ? (
				<>Proprietors will be automatically extracted once a property title is assigned.</>
			) : (
				<>
					Proprietors are automatically extracted from the property title. <br />
					<Typography
						sx={{ textDecoration: 'underline' }}
						variant='link'
						fontSize='13px'
						color='#DCDCDC'
						onClick={handleAssignTitle}
					>
						Click here
					</Typography>{' '}
					to assign a title to this transaction.
				</>
			),
			severity: 'error',
			areButtonsDisabled: true,
		};
	}, [transaction.titleEdition, role, relationship, payload, participants, user]);

	if (user.verificationStatus !== VerificationSessionStatus.VERIFIED && !user.isAdmin && !transaction.isBuyerViewer) {
		dispatch(openModal({ name: modals.verificationRequired }));

		return null;
	}

	const handleInnerRoleChange = (r: TransactionRole) => form.setValue('innerRole', r);

	return (
		<FormProvider {...form}>
			<Box display={'flex'} flexDirection={'column'} gap='15px'>
				<Typography sx={{ ...styledMainTitle, pb: 0 }}>
					{payload.placeholderId && payload.isEdit
						? 'EDIT PLACEHOLDER'
						: payload.placeholderId
						? 'INVITE PLACEHOLDER'
						: 'ADD PARTICIPANT'}
				</Typography>
				{payload.placeholderId && payload.isEdit ? (
					<StyledAlert severity='info'>
						Placeholders store details of participants who haven't been invited yet. Click <b>Update</b> to save any
						changes, or enter an email and click <b>Invite</b> to turn the placeholder into an active card.
					</StyledAlert>
				) : payload.placeholderId ? (
					<StyledAlert severity='info'>
						Placeholders store details of participants who haven't been invited yet. Enter an email and click{' '}
						<b>Invite</b> to turn the placeholder into an active card.
					</StyledAlert>
				) : (
					<StyledAlert severity='info'>
						Click <b>Add</b> to save the person's details without inviting them.
						<br />
						Click <b>Add & Invite</b> to invite the person to join the transaction.
					</StyledAlert>
				)}
				{!!warning.content && (
					<StyledAlert variant='filled' severity={(warning.severity as never) ?? 'warning'}>
						{warning.content}
					</StyledAlert>
				)}
				{error}
				{!isMetadataInitialized ? (
					<Box height='85px' display='flex' alignItems='center' justifyContent='center'>
						<Progress zoom={0.5} />
					</Box>
				) : (
					<>
						<RHFAutocomplete
							name='side'
							label='Select a side'
							options={convertTransactionSidesToOptions(sides)}
							valueAttribute='key'
							disabled={!!(payload.side || payload.placeholderId || transaction.isInstrument)}
							handleChange={handleSideChange}
						/>
						{!!side && (
							<RHFInvitationRoleAutocomplete
								name='role'
								label='Select a role'
								options={metadata.roles}
								setInnerRole={handleInnerRoleChange}
								valueAttribute='key'
								disabled={!!(payload.role || payload.placeholderId)}
							/>
						)}
						<AuthorityOnField setError={setError} />
						{!!role && (
							<>
								{areUsersLoading ? (
									<Box height='215px' display='flex' alignItems='center' justifyContent='center'>
										<Progress zoom={0.8} />
									</Box>
								) : (
									<>
										<RHFTextField
											name='givenNames'
											label='Given name(s)'
											disabled={
												isExistingUser || (!!payload.placeholderId && !payload.isEdit) || isPlaceholderFromTitle
											}
										/>
										<RHFTextField
											name='lastNames'
											label='Last name(s)'
											disabled={
												isExistingUser || (!!payload.placeholderId && !payload.isEdit) || isPlaceholderFromTitle
											}
										/>
										<RHFTextField
											name='email'
											label={`Email${!payload.placeholderId || payload.isEdit ? ' (optional)' : ' address'}`}
											onChange={(e) => handleEmailChange(e.target.value)}
											{...(isExistingUser &&
												payload.isEdit && {
													focused: true,
													FormHelperTextProps: { sx: { color: palette.light.info.light } },
													helperText: 'This user is already registered. Click "Invite" to assign them to this card.',
												})}
										/>
										<RHFTextField
											name='phone'
											label='Mobile (optional)'
											disabled={isExistingUser && !!existingUser?.telephone}
										/>
										<GoogleAddressAutocomplete
											name='address.formattedAddress'
											label='Address (optional)'
											setValue={form.setValue}
											clearErrors={form.clearErrors}
											disabled={
												(!!payload.placeholderId && !payload.isEdit) ||
												(isExistingUser && (!!existingUser?.address || !!existingUser?.branch?.address))
											}
										/>
										{isAncillary && (
											<>
												<RHFTextField
													name='business'
													label='Company (optional)'
													disabled={
														(!!payload.placeholderId && !payload.isEdit) || (isExistingUser && !!existingUser?.business)
													}
												/>
												<RHFTextField
													name='branch'
													label='Branch (optional)'
													disabled={
														(!!payload.placeholderId && !payload.isEdit) || (isExistingUser && !!existingUser?.branch)
													}
												/>
											</>
										)}
										{user.isAdmin && showQuoteField && (
											<PriceField
												name='quote'
												placeholder='Enter a quote (optional)'
												InputProps={{
													endAdornment: (
														<Typography ml='5px' variant='subtitle2'>
															+VAT
														</Typography>
													),
												}}
											/>
										)}
									</>
								)}
							</>
						)}
					</>
				)}
				<Box display='flex' justifyContent='flex-end' gap='15px'>
					{!payload.isEdit ? (
						<>
							{!isExistingUser && !payload.placeholderId && (
								<ActionButton
									loading={form.formState.isSubmitting}
									disabled={!role || !side || areUsersLoading || areButtonsDisabled}
									onClick={form.handleSubmit(handleAdd)}
								>
									Add
								</ActionButton>
							)}
							<ActionButton
								variant='contained'
								loading={form.formState.isSubmitting}
								disabled={!role || !side || areUsersLoading || areButtonsDisabled}
								onClick={form.handleSubmit(handleAddAndInvite)}
							>
								{payload.placeholderId ? 'Invite' : 'Add & Invite'}
							</ActionButton>
						</>
					) : (
						<>
							<ActionButton
								loading={form.formState.isSubmitting}
								disabled={!role || !side || areUsersLoading || areButtonsDisabled}
								onClick={form.handleSubmit(handleUpdate)}
							>
								Update
							</ActionButton>
							<ActionButton
								variant='contained'
								loading={form.formState.isSubmitting}
								disabled={!role || !side || areUsersLoading || areButtonsDisabled}
								onClick={form.handleSubmit(handleAddAndInvite)}
							>
								Invite
							</ActionButton>
						</>
					)}
				</Box>
			</Box>
		</FormProvider>
	);
};

const InvitationModal = () => (
	<Modal name={modals.invitation} cardSx={{ maxWidth: '500px', padding: '32px 27px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default InvitationModal;
