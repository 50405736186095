import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { SummaryFieldProps } from './types';
import { SummaryFieldWrapper } from './styled';

const SummaryField: FC<SummaryFieldProps> = ({ name, children, withBorder = true, titleSx, childrenSx, ...other }) => (
	<SummaryFieldWrapper display='flex' px='10px' alignItems='center' withBorder={withBorder} {...other}>
		<Typography flex={0.3} variant='h6' sx={titleSx} py='10px'>
			{name}
		</Typography>
		<Box flex={0.7} fontWeight='500' color='grey.800' py='10px' sx={childrenSx}>
			{children}
		</Box>
	</SummaryFieldWrapper>
);

export default SummaryField;
