import { Grid } from '@mui/material';
import { styled } from '@mui/system';

export const StyledP = styled('p')({
	fontFamily: 'Roboto',
	fontStyle: 'normal',
	fontWeight: '400',
	fontSize: '14px',
	color: '#697885',
});

export const StyledGrid = styled(Grid)({
	display: 'flex',
	flexDirection: 'column',
	fontFamily: 'Public Sans',
	fontStyle: 'normal',
	fontWeight: '400',
	fontSize: '14px',
	color: '#697885',

	b: {
		fontFamily: 'Roboto',
		fontWeight: '600',
	},
});
