import { useMemo } from 'react';
import { Button, Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import { dispatch, useSelector } from 'src/redux/store';
import { selectModal } from 'src/redux/slices/modal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { confirmBuyer, selectTransactionOverview, selectTransactionParticipants } from 'src/redux/slices/transaction';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { formatBidValue } from 'src/utils/formatBid';
import { NoBreak } from 'src/styled';
import { getUserName } from 'src/components/InviteCard/utils';
import modals from 'src/constants/modals';

const SaleConfirmationModalContent = () => {
	const transaction = useSelector(selectTransactionOverview);
	const { participants } = useSelector(selectTransactionParticipants);
	const { payload } = useSelector(selectModal<{ userId: string; price: number }>);

	const viewer = useMemo(
		() => participants.viewers.find(({ uid: id }) => id === payload.userId),
		[payload.userId, participants.viewers],
	);

	if (!viewer) return null;

	const handleConfirm = async () => {
		await dispatch(
			confirmBuyer({
				id: transaction.id,
				userId: payload.userId,
				price: payload.price,
			}),
		);

		handleCloseModal();
	};

	return (
		<>
			<Typography sx={styledMainTitle} pt={0}>
				CONFIRM SALE
			</Typography>
			<Typography fontSize='17px' fontWeight={500} mb={'15px'}>
				You are selling <b>{transaction.name}</b> to <b>{getUserName(viewer)}</b> for{' '}
				<NoBreak as='b'>£ {formatBidValue(`${payload.price}`)}</NoBreak>
			</Typography>
			<ActionButtons mt={'20px'}>
				<Button variant='contained' size='large' fullWidth onClick={handleCloseModal}>
					Cancel
				</Button>
				<Button variant='contained' size='large' fullWidth onClick={handleConfirm} disabled={!payload.price}>
					Confirm
				</Button>
			</ActionButtons>
		</>
	);
};

const SaleConfirmationModal = () => (
	<Modal name={modals.saleConfirmation} cardSx={{ maxWidth: '550px', padding: '32px 27px' }}>
		<SaleConfirmationModalContent />
	</Modal>
);

export default SaleConfirmationModal;
