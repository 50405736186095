import { call } from '../@';

export type ProgressStatus = 'done' | 'pending' | 'notStarted';

export type ProgressReport = {
	name: string;
	id: string;
	handler: string | null;
	sellersAdded: ProgressStatus;
	sellSolicitorsAdded: ProgressStatus;
	sellSolicitorsOnboarded: ProgressStatus;
	sellersOnboarded: ProgressStatus;
	buyersAdded: ProgressStatus;
	buySolicitorsAdded: ProgressStatus;
	buySolicitorsOnboarded: ProgressStatus;
	buyersOnboarded: ProgressStatus;
	memorandumOfSaleAvailable: ProgressStatus | string;
	sellerFormsAvailable: ProgressStatus;
	contractPackAvailable: ProgressStatus;
	searchesOrdered: ProgressStatus;
	searchesUploaded: ProgressStatus;
	enquiriesStarted: ProgressStatus;
	enquiriesCompleted: ProgressStatus;
	exchangeDateAgreed: ProgressStatus;
	exchanged: ProgressStatus;
};

export type GetProgressReportsResponse = { progress: ProgressReport[] };

export default async function getProgressReports(): Promise<GetProgressReportsResponse> {
	return await call('reports-progress', {});
}
