import { FC, PropsWithChildren, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'src/redux/store';
import { selectIsAuthenticated, selectIsInitialized, selectUser, logout } from 'src/redux/slices/auth';
import { Navigate, useLocation } from 'react-router-dom';
import { UserStatus } from 'src/types';
import LoadingScreen from '../components/LoadingScreen';

const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
	const user = useSelector(selectUser);
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const isInitialized = useSelector(selectIsInitialized);
	const { pathname } = useLocation();

	const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

	if (!isInitialized) return <LoadingScreen />;
	if (isInitialized && !user) {
		sessionStorage.setItem('redirectTo', pathname);

		return <Navigate to='/auth/login' />;
	}
	if (user.onboarding?.currentPage) return <Navigate to={user.onboarding.currentPage} />;

	if (!isAuthenticated || user?.status === UserStatus.PENDING) {
		if (pathname !== requestedLocation) {
			setRequestedLocation(pathname);
		}
		dispatch(logout());
		return <Navigate to='/auth/login' {...(user?.status === UserStatus.PENDING && { state: { notVerified: true } })} />;
	}

	if (requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
};

export default AuthGuard;
