import { ITransactionOverview } from 'src/redux/types';
import { IExtendedQuestionnaireProperty } from 'src/types';

export const getTransactionInterest = (
	transaction: ITransactionOverview,
	propertyQuestionnaire: IExtendedQuestionnaireProperty | undefined | null,
) => {
	const shareOfFreehold = propertyQuestionnaire?.property_details.dwelling?.share_of_freehold_yn;

	if (!propertyQuestionnaire || shareOfFreehold === null || shareOfFreehold === undefined || !transaction.tenure) {
		return null;
	}
	if (transaction.tenure === 'leasehold' && !shareOfFreehold) return 'Leasehold';
	if (transaction.tenure === 'freehold' && !shareOfFreehold) return 'Freehold';

	return 'Share of freehold';
};
