/* eslint-disable camelcase */
import { ChangeEvent, FC } from 'react';
import { Accordion, AccordionDetails, Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { propertyDocumentAvailabilityCodes } from 'src/components/TransactionSummary/constants';
import { stopPropagation } from 'src/utils/common';
import { DocumentsSubcategoryProps } from './types';
import { getDocumentName } from './utils';
import { DocumentCheckbox, DocumentCheckboxesAccordionSummary } from '../../styled';
import { FormTitleDocumentItem } from '../../types';

const DocumentsSubcategory: FC<DocumentsSubcategoryProps> = ({ documents, title, name, categoryIndex }) => {
	const { watch, setValue } = useFormContext();

	if (!documents.length) return null;

	const subcategoryFieldName = `titlesDocuments.${categoryIndex}.${name}`;
	const subcategoryDocuments: FormTitleDocumentItem[] | undefined = watch(subcategoryFieldName);
	const areAllDocumentsSelected = !!subcategoryDocuments?.every(({ selected }) => selected);
	const hasAvailableDocument = !!subcategoryDocuments?.some(
		({ availability_code }) => availability_code === propertyDocumentAvailabilityCodes.immediate,
	);

	if (!subcategoryDocuments) return null;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(
			subcategoryFieldName,
			subcategoryDocuments.map((document) => ({ ...document, selected: e.target.checked })),
		);
	};

	return (
		<Accordion>
			<DocumentCheckboxesAccordionSummary>
				<DocumentCheckbox
					name=''
					onChange={handleChange}
					checked={areAllDocumentsSelected}
					label={<b>{title}</b>}
					labelControlProps={{ labelPlacement: 'end', onClick: stopPropagation }}
					disableRipple
					disabled={!hasAvailableDocument}
				/>
			</DocumentCheckboxesAccordionSummary>
			<AccordionDetails>
				<Box pl='40px' display='flex' flexDirection='column'>
					{documents.map((document, index) => (
						<DocumentCheckbox
							key={`${document.type}-${document.date}-${document.entry_numbers?.toString()}`}
							label={getDocumentName(document)}
							labelControlProps={{ labelPlacement: 'end' }}
							disabled={document.availability_code !== propertyDocumentAvailabilityCodes.immediate}
							disableRipple
							name={`${subcategoryFieldName}.${index}.selected`}
						/>
					))}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

export default DocumentsSubcategory;
