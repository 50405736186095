import { mimeTypeFromExtension, extensionFromMimeType } from './mime-types';

export function sleep(ms: number) {
	return new Promise<void>((r) => {
		setTimeout(r, ms);
	});
}

export async function until(callback: () => boolean | Promise<boolean>, interval = 10) {
	// eslint-disable-next-line no-await-in-loop
	while (!(await callback())) await sleep(interval);
}

export async function downloadToBlob(link: string) {
	const response = await fetch(link);
	if (!response.ok) throw new Error(`Unknown server response (code ${response.status})`);
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const reader = response.body!.getReader();
	const contentLength = +(response.headers.get('Content-Length') || Infinity);
	const contentType = response.headers.get('Content-Type') || mimeTypeFromExtension('');
	let receivedLength = 0;
	const chunks: Uint8Array[] = [];
	return {
		result: (async () => {
			// eslint-disable-next-line no-constant-condition
			while (true) {
				// eslint-disable-next-line no-await-in-loop
				const { done, value } = await reader.read();
				if (done) break;
				chunks.push(value);
				receivedLength += value.length;
			}
			return new Blob(chunks, { type: contentType });
		})(),
		getCurrentProgress: () => (contentLength !== Infinity ? (receivedLength / contentLength) * 100 : 0),
	};
}

export function fileNameFromLink(link: string) {
	const url = new URL(link);
	const parts = url.pathname.split('/').filter((v) => v);
	return parts.pop() || 'unnamed';
}

export async function saveToDisk(blobOrPromise: Blob | Promise<Blob>, fixExtension: boolean, name: string) {
	const blob = await blobOrPromise;
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.style.display = 'none';
	a.href = url;
	const extension = extensionFromMimeType(blob.type);
	a.download = fixExtension ? (name.endsWith(extension) ? name : name + extension) : name;
	document.body.appendChild(a);
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
}

export async function downloadToDisk(link: string, fixExtension = false, customName: string | undefined = undefined) {
	const { result, getCurrentProgress } = await downloadToBlob(link);
	return {
		result: saveToDisk(result, fixExtension, customName || fileNameFromLink(link)),
		getCurrentProgress,
	};
}
