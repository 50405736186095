import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useLoading from 'src/hooks/useLoading';
import { selectPropertySlice } from 'src/redux/slices/property';
import { Epc } from 'src/types';
import { getEpc } from 'src/utils/firebase';
import moment from 'moment';
import Label from 'src/components/Label';
import { Timestamp } from 'firebase/firestore';
import { UseEPCType } from './types';

export const useEPC = (): UseEPCType => {
	const { isLoading, setIsLoading } = useLoading(true);
	const [epc, setEpc] = useState<Epc | null>(null);
	const { property } = useSelector(selectPropertySlice);

	useEffect(() => {
		const setup = async () => {
			if (!property?.epcReference) return;

			setIsLoading(true);

			const { data } = await getEpc(property.epcReference.id).catch(() => ({ data: null }));

			setEpc(data);
			setIsLoading(false);
		};

		setup();
	}, [property?.epcReference]);

	if (isLoading || !epc) return { data: null, status: null };

	const now = new Date();
	let expirationDate: Date | null = null;

	if (epc.expiry_date instanceof Timestamp) {
		expirationDate = new Date(epc.expiry_date.toDate());
	} else if (epc.inspection_date) {
		const inspectionDate = epc.inspection_date.toDate();
		expirationDate = new Date(epc.inspection_date.toDate());

		expirationDate.setFullYear(inspectionDate.getFullYear() + 10);
	}

	if (!expirationDate) return { data: null, status: null };

	const isExpired = expirationDate <= now;
	const formattedExpirationDate = moment(expirationDate).format('DD/MM/YYYY');

	if (isExpired) {
		return {
			data: epc,
			status: (
				<Label color='error' fontSize={14}>
					Expired on {formattedExpirationDate}
				</Label>
			),
		};
	}

	const monthsDiff = moment(expirationDate).diff(now, 'month');

	if (monthsDiff <= 6) {
		return {
			data: epc,
			status: (
				<Label color='warning' fontSize={14}>
					Valid until {formattedExpirationDate}
				</Label>
			),
		};
	}

	return {
		data: epc,
		status: (
			<Label color='success' fontSize={14}>
				Valid until {formattedExpirationDate}
			</Label>
		),
	};
};
