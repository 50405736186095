import { DocumentLocation, DocumentType, FolderPermissions } from 'src/redux/types/documents';
import { call } from '../@';

export type Payload = {
	folderId: string;
	propertyId: string;
	name: string;
	type: DocumentType;
	location: DocumentLocation;
	permissions: FolderPermissions;
};

export default async function addDocumentsFolder(payload: Payload) {
	return await call('documents-addFolder', payload);
}
