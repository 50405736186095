import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { QRCodeCanvas } from 'qrcode.react';

import { styledMainTitle } from 'src/components/InviteModal/styles';
import Modal from 'src/components/customModal';
import { RHFAutocomplete, RHFInvitationRoleAutocomplete } from 'src/components/hook-form';
import { openModal } from 'src/redux/slices/modal';
import { dispatch } from 'src/redux/store';
import Progress from 'src/components/Progress';
import modals from 'src/constants/modals';
import { selectUser } from 'src/redux/slices/auth';
import { VerificationSessionStatus } from 'src/types';
import useLoading from 'src/hooks/useLoading';
import { downloadFileByUrl } from 'src/api/questionnaire/documents/utils';
import { generateTransactionQrCode } from 'src/api';
import { useSnackbar } from 'notistack';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import useTransactionInvitation from '../../hooks/useTransactionInvitation';
import { TransactionQrCodeFormType } from './types';
import { transactionQrCodeSchema } from './validation';
import { convertTransactionSidesToOptions } from '../InvitationModal/utils';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading();
	const [qrCodeId, setQrCodeId] = useState<string | null>(null);
	const user = useSelector(selectUser);
	const transaction = useSelector(selectTransactionOverview);
	const { enqueueSnackbar } = useSnackbar();
	const form = useForm<TransactionQrCodeFormType>({ resolver: yupResolver(transactionQrCodeSchema) });
	const [role, side, relationship] = form.watch(['role', 'side', 'relationship']);
	const { transactionId, isMetadataInitialized, sides, metadata } = useTransactionInvitation();

	useEffect(() => {
		const setup = async () => {
			if (!role || !side) return;

			setIsLoading(true);

			const { id } = await generateTransactionQrCode({ role, side, id: transactionId, relationship });

			setIsLoading(false);

			setQrCodeId(id);
		};

		if (transaction.isInstrument) form.setValue('side', 'sell');

		setup();
	}, [side, role, transactionId, transaction.isInstrument]);

	const qrCodeUrl = useMemo(() => {
		if (!qrCodeId) return '';

		return `${window.location.origin}/transaction-qr-code/${qrCodeId}`;
	}, [qrCodeId]);

	if (user.verificationStatus !== VerificationSessionStatus.VERIFIED && !user.isAdmin) {
		dispatch(openModal({ name: modals.verificationRequired }));

		return null;
	}

	const handleSubmit = async () => {
		const canvas = document.querySelector('.transaction-qr-code') as HTMLCanvasElement;

		downloadFileByUrl(canvas.toDataURL(), 'qr-code.png');
	};

	const handleCopy = async () => {
		const canvas = document.querySelector('.transaction-qr-code') as HTMLCanvasElement;

		canvas.toBlob((blob) => {
			if (!blob) return;

			navigator.clipboard.write([new ClipboardItem({ [blob.type]: blob })]);
			enqueueSnackbar('QR code copied!');
		});
	};

	const handleSideChange = () => form.setValue('role', null);

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<Box display={'flex'} flexDirection={'column'} gap='15px'>
					<Typography sx={{ ...styledMainTitle, pb: 0 }}>CREATE QR CODE</Typography>
					{!isMetadataInitialized ? (
						<Box height='85px' display='flex' alignItems='center' justifyContent='center'>
							<Progress zoom={0.5} />
						</Box>
					) : (
						<>
							<RHFAutocomplete
								name='side'
								label='Select a side'
								options={convertTransactionSidesToOptions(sides)}
								valueAttribute='key'
								disabled={transaction.isInstrument}
								handleChange={handleSideChange}
							/>
							{!!side && (
								<RHFInvitationRoleAutocomplete
									name='role'
									label='Select a role'
									options={metadata.roles}
									valueAttribute='key'
								/>
							)}
						</>
					)}
					{side && role && (
						<>
							<Typography variant='caption' fontSize='12.5px' mb='15px'>
								Sharing this QR code lets users join the transaction instantly. <br />
								They'll be automatically assigned the role and side you selected above.
							</Typography>
							{isLoading ? (
								<Box height='185px'>
									<Progress zoom={0.7} />
								</Box>
							) : (
								<Box display='flex' justifyContent='center' alignItems='center' mb='5px'>
									<QRCodeCanvas
										value={qrCodeUrl}
										style={{ height: '175px', width: '175px' }}
										className='transaction-qr-code'
									/>
								</Box>
							)}
						</>
					)}
					<Box display='flex' flexDirection='row' gap='15px' mt='15px'>
						<LoadingButton
							variant='contained'
							size='large'
							fullWidth
							type='submit'
							loading={form.formState.isSubmitting}
							disabled={!role || !side || isLoading}
						>
							Download
						</LoadingButton>
						<LoadingButton
							variant='contained'
							size='large'
							fullWidth
							loading={form.formState.isSubmitting}
							disabled={!role || !side || isLoading}
							onClick={handleCopy}
						>
							Copy
						</LoadingButton>
					</Box>
				</Box>
			</form>
		</FormProvider>
	);
};

const TransactionQrCodeModal = () => (
	<Modal
		name={modals.transactionQrCode}
		cardSx={{
			maxWidth: '500px',
			padding: '32px 27px',
			overflow: 'visible',
		}}
	>
		<Content />
	</Modal>
);

export default TransactionQrCodeModal;
