export const formatPropertyWaterProviders = (providers: PropertyWaterProvider[]) => (
	<span>
		{providers.map((p, index) => {
			const isLast = index + 1 === providers.length;

			return (
				<span key={p.name}>
					<a href={p.url} target='_blank' rel='noreferrer'>
						{p.name}
					</a>
					{!isLast && <>, </>}
				</span>
			);
		})}
	</span>
);
