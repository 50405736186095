import { ChangeEvent, FC } from 'react';
import { Accordion } from '@mui/material';
import { capitalize } from 'lodash';
import { transactionRolesBySide } from 'src/pages/transactions/constants';
import { useFormContext } from 'react-hook-form';
import { TransactionSide } from 'src/pages/transactions/types';
import { FolderPermissionsAction } from 'src/redux/types/documents';
import { SidePermissionsPickerProps } from './types';
import { PermissionsAccordionDetails, PermissionsAccordionSummary, PermissionsCheckbox } from '../../styled';
import RolePermissionsPicker from '../RolePermissionsPicker';
import { PermissionsType } from '../../types';

const SidePermissionsPicker: FC<SidePermissionsPickerProps> = ({ side, action, disabled = false }) => {
	const name = `${action}.${side}`;
	const { watch, setValue } = useFormContext();
	const roles = transactionRolesBySide[side];

	const permissions: PermissionsType[FolderPermissionsAction][TransactionSide] = watch(name);
	const areAllRolesSelected = Object.values(permissions).every((r) => r.selected);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(
			name,
			Object.entries(permissions).reduce((acc, [role, value]) => {
				acc[role] = { ...value, selected: e.target.checked };

				return acc;
			}, {}),
		);
	};

	return (
		<Accordion disabled={disabled}>
			<PermissionsAccordionSummary>
				<PermissionsCheckbox
					name=''
					checked={areAllRolesSelected}
					label={`${capitalize(side)} side`}
					onChange={handleChange}
					disabled={disabled}
				/>
			</PermissionsAccordionSummary>
			<PermissionsAccordionDetails>
				{roles.map((r) => (
					<RolePermissionsPicker key={r.key} side={side} role={r.key} action={action} />
				))}
			</PermissionsAccordionDetails>
		</Accordion>
	);
};

export default SidePermissionsPicker;
