import { DocumentSnapshot, getDocs, query, where } from 'firebase/firestore';
import { transactionOnboardingCollection } from 'src/constants/firestore';
import { TransactionOnboarding } from 'src/types';
import { getTransactionRef, getUserRef } from '..';

export const findTransactionOnboarding = async (
	userId: string,
	transactionId: string,
): Promise<DocumentSnapshot<TransactionOnboarding> | null> => {
	const { docs } = await getDocs(
		query(
			transactionOnboardingCollection,
			where('transactionRef', '==', getTransactionRef(transactionId)),
			where('userRef', '==', getUserRef(userId)),
		),
	);

	return docs[0] ?? null;
};

export const findAllTransactionOnboardings = async (
	userId: string,
): Promise<DocumentSnapshot<TransactionOnboarding>[]> => {
	const userRef = getUserRef(userId);

	const { docs } = await getDocs(query(transactionOnboardingCollection, where('userRef', '==', userRef)));

	return docs;
};
