import styled from '@emotion/styled';
import { PropertyGeneralAddressCard } from '../../styled';

export const PricesSectionWrapper = styled(PropertyGeneralAddressCard)({
	gridColumn: 'span 2',

	'@media only screen and (max-width: 1600px)': {
		gridColumn: '1',
	},
});
