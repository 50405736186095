import { Box, styled, Typography } from '@mui/material';

export const Picker = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: '7px',
	height: '55px',
	border: `1px solid ${theme.palette.grey[500_56]}`,
	borderRadius: '8px',
	padding: '0 15px',
}));

export const SelectedPath = styled(Typography)({
	fontWeight: 500,
	position: 'relative',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
