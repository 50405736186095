import { auth } from 'src/firebaseConfig';
import userData from './@user-data';
import onUserDataChange from './user-change';

let currentUser: ReturnType<typeof userData> | Awaited<ReturnType<typeof userData>> | null = auth.currentUser
	? userData(auth.currentUser.uid)
	: null;

onUserDataChange((user) => {
	currentUser = user;
});

// "async" notation here ensures that values are always resolved from promise
export default async function getCurrentUser() {
	return currentUser;
}
