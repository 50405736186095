import { FC } from 'react';
import { Typography, useTheme } from '@mui/material';
import { SummaryTableProps } from './types';
import { SummaryTableContent, SummaryTableWrapper } from './styles';

const SummaryTable: FC<SummaryTableProps> = ({ title, children, withBorder = false, ...other }) => {
	const theme = useTheme();

	return (
		<SummaryTableWrapper {...other}>
			<Typography variant='h4' p='10px' {...(withBorder && { borderBottom: `2px solid ${theme.palette.grey[400]}` })}>
				{title}
			</Typography>
			<SummaryTableContent>{children}</SummaryTableContent>
		</SummaryTableWrapper>
	);
};

export default SummaryTable;
