import { FC, PropsWithChildren, useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
	createTheme,
	ThemeProvider as MUIThemeProvider,
	StyledEngineProvider,
	ThemeOptions,
	Shadows,
} from '@mui/material/styles';
// hooks
import useSettings from '../hooks/useSettings';
//
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
	const { themeMode, themeDirection } = useSettings();

	const isLight = themeMode === 'light';

	const themeOptions = useMemo(
		() => ({
			palette: isLight ? palette.light : palette.dark,
			typography,
			breakpoints,
			shape: { borderRadius: 8 },
			direction: themeDirection,
			shadows: (isLight ? shadows.light : shadows.dark) as Shadows,
			customShadows: isLight ? customShadows.light : customShadows.dark,
		}),
		[isLight, themeDirection],
	) as ThemeOptions;

	const theme = createTheme(themeOptions);

	theme.components = componentsOverride(theme);

	return (
		<StyledEngineProvider injectFirst>
			<MUIThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</MUIThemeProvider>
		</StyledEngineProvider>
	);
};

export default ThemeProvider;
