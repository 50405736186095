import { FC } from 'react';
import { noop } from 'lodash';
import { useNavigate } from 'react-router';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { dispatch } from 'src/redux/store';
import { propertyOnboardingNotification, selectProperty } from 'src/redux/slices/property';
import { useSelector } from 'react-redux';
import { selectTransactionOverview, transactionOnboardingNotification } from 'src/redux/slices/transaction';
import useLoading from 'src/hooks/useLoading';
import { VerificationIssueModalProps } from './types';
import ConfirmationModal from '../ConfirmationModal';

const VerificationIssueModal: FC<VerificationIssueModalProps> = ({ isProperty }) => {
	const { isLoading, setIsLoading } = useLoading();
	const property = useSelector(selectProperty);
	const transaction = useSelector(selectTransactionOverview);
	const navigate = useNavigate();

	const handleGoToDashboard = async () => {
		try {
			setIsLoading(true);

			if (isProperty) {
				await dispatch(propertyOnboardingNotification({ id: property.id, action: 'incorrectInfo' }));
			} else {
				await dispatch(transactionOnboardingNotification({ id: transaction.id, action: 'incorrectInfo' }));
			}

			navigate('/dashboard');
			handleCloseModal();
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<ConfirmationModal
			modalName={isProperty ? propertyOnboardingSteps.verificationIssue : transactionOnboardingSteps.verificationIssue}
			title='AMENDMENT RECEIVED'
			description={
				<>
					Thanks for updating your identity details. Our team is reviewing your submission and will email you once it's
					processed.
				</>
			}
			withCancelButton={false}
			confirmText='Go to Dashboard'
			handleClose={noop}
			handleConfirm={handleGoToDashboard}
			isLoading={isLoading}
		/>
	);
};

export default VerificationIssueModal;
