import Iconify from 'src/components/Iconify';
import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { SupportModalSubtitleStyled, SupportModalTitleStyled } from './style';

export default function SupportModal() {
	return (
		<Modal name={modals.support}>
			<SupportModalTitleStyled>24/7 CUSTOMER SUPPORT</SupportModalTitleStyled>
			<SupportModalSubtitleStyled>
				<Iconify icon={'material-symbols:phone-enabled'} />
				<a href='tel:0333 242 0123'>0333 242 0123</a>
			</SupportModalSubtitleStyled>
			<SupportModalSubtitleStyled>
				<Iconify icon={'ri:whatsapp-fill'} />
				<a href='https://wa.me/message/VXKXCLV7IDRJI1' target='_blank' rel='noreferrer'>
					Chat online
				</a>
				<span>or at</span>
				<a href='tel:0734 158 0901'>0734 158 0901</a>
			</SupportModalSubtitleStyled>
			<SupportModalSubtitleStyled>
				<Iconify icon={'heroicons:envelope-solid'} />
				<a href='mailto:help@conveyo.io'>help@conveyo.io</a>
			</SupportModalSubtitleStyled>
		</Modal>
	);
}
