import { useEffect, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { dispatch } from 'src/redux/store';
import { getPropertyPrices, selectPropertySlice } from 'src/redux/slices/property';
import { useSelector } from 'react-redux';
import { TypographyAddressTitle } from '../../styled';
import { PricesSectionWrapper } from './styled';
import { createApexChartOptions } from './utils';

const PricesSection = () => {
	const { property } = useSelector(selectPropertySlice);

	useEffect(() => {
		dispatch(getPropertyPrices());
	}, []);

	const chartOptions = useMemo(
		() => createApexChartOptions(property?.prices ?? { ask: [], paid: [] }),
		[property?.prices],
	);

	if (!property?.prices?.ask.length && !property?.prices?.paid.length) return null;

	return (
		<PricesSectionWrapper>
			<TypographyAddressTitle mb='20px'>PRICES</TypographyAddressTitle>
			<Chart options={chartOptions} series={chartOptions.series} type='line' width='100%' height='400px' />
		</PricesSectionWrapper>
	);
};

export default PricesSection;
