/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getRooms } from 'src/utils/firebase';
import { RootState } from '../store';
import { IRoomsState, Rooms } from '../types';

export const selectRooms = (state: RootState) => state.rooms.rooms;

const initialState: IRoomsState = {
	rooms: { isInitialized: false, data: null },
};

export const getRoomsThunk = createAsyncThunk<Rooms>('/rooms/get', async () => {
	const rooms = await getRooms();

	const formattedRooms = rooms.reduce<Rooms>((acc, r) => {
		acc[r.id] = r.data();

		return acc;
	}, {} as Rooms);

	return formattedRooms;
});

const roomsSlice = createSlice({
	name: 'transaction',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getRoomsThunk.fulfilled, (state, { payload }) => {
			state.rooms.data = payload;
		});
	},
});

export default roomsSlice.reducer;
