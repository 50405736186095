import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import type { IPage } from './types';

// ----------------------------------------------------------------------

const Page = forwardRef<HTMLDivElement, IPage>(({ children, title = '', meta, ...other }, ref) => {
	const isDev = process.env.REACT_APP_ENV_MODE === 'development';

	return (
		<>
			<Helmet>
				<title>{`${title} | Conveyo`}</title>
				{meta}
				{/* allow mixed content in dev mode */}
				{isDev && <meta httpEquiv='Content-Security-Policy' content='upgrade-insecure-requests' />}
			</Helmet>

			<Box ref={ref} sx={{ width: '100%', height: '100%', ...(other.sx || {}) }} {...other}>
				{children}
			</Box>
		</>
	);
});

export default Page;
