import { noop } from 'lodash';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import modals from 'src/constants/modals';
import { ConfirmationModal } from '../../common';

const PropertyAlreadyOnMarket = () => (
	<ConfirmationModal
		modalName={modals.propertyAlreadyOnMarket}
		sx={{ maxWidth: '600px' }}
		title='PROPERTY ALREADY ON THE MARKET'
		confirmText='Cancel'
		withCancelButton={false}
		handleConfirm={handleCloseModal}
		handleClose={noop}
		description={
			<>
				A transaction for this property already exists. To join, use the link in the Conveyo email invitation or request
				an invite from your introducer.
			</>
		}
	/>
);

export default PropertyAlreadyOnMarket;
