import findAddressByPostcode from 'src/api/epc/findAddressByPostCode';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { IFindAddressByPostcodeResponse } from 'src/api/epc/epc-types';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { noop } from 'lodash';
import { INewProperty } from 'src/pages/properties/types';
import { RHFAutocomplete, RHFTextField } from '../hook-form';
import { UprnFormProps } from './types';

export const hardcodeCountry = 'England';

const UprnForm: FC<UprnFormProps> = ({ setError, isLoading, setIsLoading, setIsSelected = noop }) => {
	const [uprnData, setUprnData] = useState<IFindAddressByPostcodeResponse[]>([]);

	const { watch, setValue, formState } = useFormContext<INewProperty>();

	const handleUprnChange = (data: IFindAddressByPostcodeResponse) => {
		setIsSelected(true);
		setError(null);
		setValue('address', data);
		setValue('uprn', +data.uprn);
		setValue('name', data.display_address);
	};

	const postcodeWatcher = watch('address.postcode');

	useEffect(() => {
		let timeout: NodeJS.Timeout | null = null;

		timeout = setTimeout(() => {
			if (postcodeWatcher.length >= 5 && !formState.errors.address?.postcode) {
				setValue('uprn', 0);
				setIsSelected(false);
				setIsLoading(true);
				setUprnData([]);

				(async () => {
					const uprn = await findAddressByPostcode(postcodeWatcher);
					setIsLoading(false);

					if (uprn.length) {
						setUprnData(
							uprn.map((el) => ({
								...el,
								uprn: +el.uprn,
								id: el.uprn,
								name: el.display_address,
							})),
						);
					}
				})();
			}
		}, 200);

		if (!postcodeWatcher.length || formState.errors.address?.postcode) {
			setUprnData([]);
		}

		return () => {
			if (timeout) clearTimeout(timeout);
		};
	}, [postcodeWatcher, formState.errors.address?.postcode]);

	const handlePostcodeChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue('address.postcode', e.target.value.toUpperCase(), { shouldValidate: true });
	};

	return (
		<Box display='flex' flexDirection='column' gap='20px'>
			<RHFTextField
				name='address.postcode'
				label='Postcode'
				InputLabelProps={{ shrink: true }}
				onChange={handlePostcodeChange}
			/>

			{isLoading ? (
				'Loading...'
			) : uprnData.length ? (
				<RHFAutocomplete
					// eslint-disable-next-line
					onChange={(_, el: any) => handleUprnChange(el)}
					name='uprn'
					label='Choose address...'
					options={uprnData}
					valueAttribute='uprn'
					labelAttribute='display_address'
					autoComplete={false}
					InputLabelProps={{ shrink: true }}
				/>
			) : null}
		</Box>
	);
};

export default UprnForm;
