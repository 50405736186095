import palette from 'src/theme/palette';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';

export const AutocompleteStyled = styled('div')(() => ({
	'.MuiAutocomplete-popper': {
		position: 'static !important',
		transform: 'none !important',
	},

	'.MuiAutocomplete-listbox': {
		maxHeight: '190px',
	},
}));

export const TitleStyled = styled(Typography)(() => ({
	marginBottom: '16px',
	fontWeight: 700,
	fontSize: '18px',
	lineHeight: '20px',
	textTransform: 'uppercase',
}));

export const SubtitleStyled = styled(Typography)(() => ({
	marginBottom: '30px',
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '19px',
	color: palette.light.custom.bidyText,
}));

export const LoadingButtonStyled = styled(LoadingButton)(() => ({
	width: '171px',
}));

export const LoadingButtonStyledOffer = styled(LoadingButton)({
	padding: '15px 64px',
	marginLeft: 'auto',
	marginRight: '0',
	marginTop: '45px',
	display: 'block',
});
