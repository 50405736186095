import { Box, Card, PopoverOrigin, styled, Typography } from '@mui/material';

export const EmptyBox = styled(Box)`
	width: 540px;
	height: 400px;
	background-color: #e7e7e7;
	border-radius: 5px;
`;

export const CardsWrapper = styled(Box)(() => ({
	display: 'grid',
	gap: '30px',
	gridTemplateColumns: 'repeat(auto-fill, 620px)',
	gridAutoRows: '1fr',
}));

export const EmptyCard = styled(Card)`
	height: 500px;
	width: 100%;
	padding: 40px 44px;
	box-shadow: 0px 1px 1px #f1f1f1, 0px 4px 16px #d5d5d5;
`;

export const PropertyGeneralAddressCard = styled(Card)`
	width: 100%;
	padding: 25px 40px;
	box-shadow: 0px 1px 1px #f1f1f1, 0px 4px 16px #d5d5d5;
`;

export const TypographyAddressTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.grey[800],
	fontSize: '16px',
	fontWeight: 700,
	lineHeight: '16px',
}));

export const HeadAddressWrapper = styled(Box)<{ isPropertyPage: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${(props) => (props.isPropertyPage ? '10px' : '20px')};
`;

export const PropertySummaryCard = styled(PropertyGeneralAddressCard)`
	padding-top: 40px;
`;

export const PropertySummaryContent = styled('div')`
	margin-top: 22px;
	display: flex;
	flex-direction: column;
	gap: 26px;
`;

export const PropertySummaryGroup = styled('div')`
	display: flex;
	justify-content: space-between;
`;

export const PropertySummaryItem = styled('div')`
	flex-direction: column;
	gap: 8px;
	min-width: 100px;
	max-width: calc(540px / 3);
`;

export const PropertySummaryItemLabel = styled(Typography)`
	color: #697885;
	font-size: 16px;
	line-height: 21px;
	font-weight: 600;
`;

export const PropertySummaryNotes = styled('div')`
	min-width: 540px;
	font-size: 16px;
	line-height: 24px;
	font-family: Roboto;
	font-weight: 400;
	color: #697885;
	margin-top: 7px;

	li {
		list-style-position: inside;
	}
`;

export const MenuPopoverAddressTransformOrigin = {
	vertical: 'top',
	horizontal: 'left',
} as PopoverOrigin;

export const MenuPopoverAddressAnchorOrigin = {
	vertical: 'bottom',
	horizontal: 'left',
} as PopoverOrigin;

export const MapContainerStyles = {
	width: '100%',
	height: '400px',
	borderRadius: '5px',
};
