import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import Modal from 'src/components/customModal';
import { FormProvider } from 'src/components/hook-form';
import { extractBidValue, formatBidValue } from 'src/utils/formatBid';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { dispatch, useSelector } from 'src/redux/store';
import { makeAnOffer } from 'src/redux/slices/transaction';
import { selectModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import PriceField from 'src/components/PriceField';
import { IStateTransactionOffer } from 'src/redux/types';
import { LoadingButtonStyledOffer, SubtitleStyled, TitleStyled } from './style';
import { validationMakeAnOffer } from './validationSchemas';

const OfferModalContent = () => {
	const { id = '' } = useParams();
	const { payload } = useSelector(selectModal<{ price?: number; offer?: IStateTransactionOffer }>);
	const { enqueueSnackbar } = useSnackbar();

	const methods = useForm({
		resolver: yupResolver(validationMakeAnOffer),
		values: { offer: payload.price ? formatBidValue(`${payload.price}`) : '' },
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = async (data) => {
		try {
			await dispatch(makeAnOffer({ id, price: extractBidValue(data.offer), offerId: payload.offer?.id }));

			enqueueSnackbar('Your bid has been submitted', { variant: 'success' });
			handleCloseModal();
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Something went wrong while submitting your bid', { variant: 'error' });
		}
	};

	return (
		<FormProvider methods={methods} onSubmit={onSubmit}>
			<TitleStyled>MAKE AN OFFER</TitleStyled>
			<SubtitleStyled>Please enter an amount in Pound Sterling</SubtitleStyled>
			<PriceField name='offer' />
			<LoadingButtonStyledOffer
				loading={isSubmitting}
				onClick={handleSubmit(onSubmit)}
				variant='contained'
				sx={{ padding: '10px 65px', fontWeight: 600 }}
			>
				Submit
			</LoadingButtonStyledOffer>
		</FormProvider>
	);
};

export const OfferModal = () => (
	<Modal name={modals.offerModal}>
		<OfferModalContent />
	</Modal>
);
