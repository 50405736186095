import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate, useParams } from 'react-router';

import LoadingScreen from 'src/components/LoadingScreen';
import Page from 'src/components/Page';
import { clearProperty, getProperty, selectPropertySlice, setCurrentTab } from 'src/redux/slices/property';
import { dispatch } from 'src/redux/store';
import { ROOTS_DASHBOARD } from 'src/routes/paths';
import { clearQuestionnairePermissions, getQuestionnairePermissionsThunk } from 'src/redux/slices/permissions';
import { selectUser } from 'src/redux/slices/auth';
import { openModal } from 'src/redux/slices/modal';
import { clearDocuments } from 'src/redux/slices/documents';
import { getRoomsThunk } from 'src/redux/slices/rooms';
import { StackPropertyViewStyle, TypographyPropertyViewStyle } from './style';
import { getPropertyTabs } from './utils';
import DropPropertyModal from '../transactions/modals/DropPropertyModal';
import PropertyOnboardingFlow from './components/PropertyOnboardingFlow';

export default function PropertiesViewPage() {
	const { id = '' } = useParams();
	const navigate = useNavigate();
	const user = useSelector(selectUser);

	const { property, isLoading, currentTab } = useSelector(selectPropertySlice);

	useEffect(() => {
		dispatch(getProperty({ id, withLoading: true }));
		dispatch(getQuestionnairePermissionsThunk({ propertyId: id }));
		dispatch(getRoomsThunk());

		return () => {
			dispatch(clearQuestionnairePermissions());
			dispatch(clearProperty());
			dispatch(setCurrentTab('general'));
			dispatch(clearDocuments());
		};
	}, [id]);

	useEffect(() => {
		if (!property && !isLoading) navigate(ROOTS_DASHBOARD);
	}, [property, isLoading]);

	const tabs = useMemo(() => getPropertyTabs(user, property), [property]);

	useEffect(() => {
		if (!property?.latestOnboardingStepModalName) return;

		if (property.latestOnboardingStep === 'payment' || property.latestOnboardingStep === 'verificationFail') {
			dispatch(openModal({ name: property.latestOnboardingStepModalName }));
		}
	}, [property?.latestOnboardingStep]);

	const handleTabChange = (_, tab: string) => dispatch(setCurrentTab(tab));

	return (
		<Page title={property?.name}>
			{isLoading ? (
				<LoadingScreen isAbsolute />
			) : (
				<>
					<StackPropertyViewStyle>
						<DropPropertyModal />
						<TypographyPropertyViewStyle>{property?.name}</TypographyPropertyViewStyle>
						<Tabs
							allowScrollButtonsMobile
							variant='scrollable'
							scrollButtons='auto'
							value={currentTab}
							onChange={handleTabChange}
						>
							{tabs.map(({ icon, value, label }) => (
								<Tab disableRipple key={value} label={label} icon={icon} value={value} />
							))}
						</Tabs>
						{tabs.map(
							({ value, component }) =>
								value === currentTab && (
									<Box key={value} height='100%'>
										{component}
									</Box>
								),
						)}
					</StackPropertyViewStyle>

					<Outlet />
					<PropertyOnboardingFlow />
				</>
			)}
		</Page>
	);
}
