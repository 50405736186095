import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Radio, RadioGroup, FormHelperText, FormControlLabel, Box } from '@mui/material';
import type { IRHFRadioGroup } from './types';

// ----------------------------------------------------------------------

const RHFRadioGroup: FC<IRHFRadioGroup> = ({ name, options, row = true, align = 'center', ...other }) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<Box width='100%' display='flex' flexDirection='column' alignItems={align}>
					<RadioGroup {...field} value={field.value ?? null} row={row} {...other}>
						{(options ?? []).map((option) => (
							<FormControlLabel
								sx={{ ml: 0 }}
								key={option.key}
								value={option.key}
								control={<Radio />}
								label={option.name}
							/>
						))}
					</RadioGroup>

					{!!error && (
						<FormHelperText error sx={{ px: 2 }}>
							{error.message}
						</FormHelperText>
					)}
				</Box>
			)}
		/>
	);
};

export default RHFRadioGroup;
