import styled from '@emotion/styled';
import { Box, Card, Snackbar, Stack, Typography } from '@mui/material';

export const OuterStackStyled = styled(Stack)`
	position: relative;
	margin-left: 25px;
`;

export const TitleStackStyled = styled(Stack)({
	flexDirection: 'row',
	alignItems: 'center',
	gap: '16px',
});

export const TitleStyled = styled(Typography)(() => ({
	fontWeight: 600,
	fontSize: '18px',
	lineHeight: '21px',
}));

export const TitleProspectiveBuyerLabel = styled(TitleStyled)(() => ({
	marginTop: '3px',
	lineHeight: '17px',
	color: '#2065D1',
	marginBottom: 0,
}));

export const SnackbarStyled = styled(Snackbar)`
	background-color: white;
`;

export const TransactionGeneralWrapper = styled(Box)`
	display: grid;
	column-gap: 40px;
	row-gap: 40px;
	@media screen and (min-width: 768px) {
		grid-template-columns: minmax(max-content, 620px);
	}
	@media screen and (min-width: 1600px) {
		grid-template-columns: minmax(max-content, 620px) minmax(max-content, 620px);
	}
`;

export const EmptyCard = styled(Card)`
	height: 500px;
	width: 100%;
	box-shadow: 0px 1px 1px #f1f1f1, 0px 4px 16px #d5d5d5;
`;
