import Tracker from 'src/sections/@dashboard/greetings/Tracker';
import { useSelector } from 'react-redux';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { TrackerSectionWrapper, TrackerWrapper } from './styled';

const TrackerSection = () => {
	const transaction = useSelector(selectTransactionOverview);

	return (
		<TrackerSectionWrapper withTracker={!transaction.isInstrument}>
			{!transaction.isInstrument && (
				<TrackerWrapper>
					<Tracker />
				</TrackerWrapper>
			)}
		</TrackerSectionWrapper>
	);
};

export default TrackerSection;
