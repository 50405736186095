import type { PropsWithChildren } from 'react';
import { useSelector } from 'src/redux/store';
import { Modal, Typography } from '@mui/material';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { selectModal } from 'src/redux/slices/modal';
import type { BrandModalNoteProps, BrandModalProps, BrandModalSubTitleProps, BrandModalTitleProps } from './types';
import { brandModalSlotProps } from './constants';
import { Content } from './styled';

const BrandModal = ({
	name,
	className,
	children,
	handleClose = handleCloseModal,
	height = 'fit-content',
	width = '500px',
}: PropsWithChildren<BrandModalProps>) => {
	const { isOpened, modalName } = useSelector(selectModal);

	const isOpen = isOpened && modalName === name;

	if (!isOpen) return null;

	return (
		<Modal open slotProps={brandModalSlotProps} onClose={handleClose}>
			<Content withLogo={false} width={width} height={height} className={className}>
				{children}
			</Content>
		</Modal>
	);
};

BrandModal.Title = ({ title }: BrandModalTitleProps) => (
	<Typography color='black' variant='h6' width='100%' textAlign='left'>
		{title}
	</Typography>
);

BrandModal.SubTitle = ({ subTitle }: BrandModalSubTitleProps) => (
	<Typography variant='caption' width='100%' textAlign='left' fontSize='12px' color='black'>
		{subTitle}
	</Typography>
);

BrandModal.Note = ({ note }: BrandModalNoteProps) => (
	<Typography variant='caption' width='100%' textAlign='left' fontSize='11px'>
		{note}
	</Typography>
);

export default BrandModal;
