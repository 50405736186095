import { QuestionnaireStatus } from 'src/types';

export const QUESTIONNAIRE_TRANSACTION = {
	transaction: null,
	buyer_questions: {
		status: 'To Do',
		ownership: {
			residence_country: null,
			other_property_past_yn: null,
			other_property_future_yn: null,
			partnered_yn: null,
			partner_other_property_yn: null,
			main_dwelling: null,
			main_dwelling_solicitor: {
				full_name: null,
				company: null,
				country: null,
				email: null,
				mobile: null,
			},
			main_dwelling_address: {
				street_address: null,
				postcode: null,
				town_city: null,
				county_province: null,
				state_region: null,
				country: null,
			},
			main_dwelling_sale_past_yn: null,
			main_dwelling_new_yn: null,
		},
		financing: {
			attributes: {
				first_time_buyer: null,
				htb_isa: null,
				htb_loan: null,
				lifetime_isa: null,
			},
			giftors: [
				{
					giftor: {
						given_names: null,
						last_names: null,
						relationship: null,
					},
					giftor_residence: {
						street_address: null,
						town_city: null,
						postcode: null,
						country_province: null,
						state_region: null,
						country: null,
					},
					giftor_bank_details: {
						account_holder: null,
						bank_name: null,
						sort_code: null,
						account_number: null,
						iban: null,
					},
					sources: {
						funds_origin: null,
						question1: `You'll be asked to upload evidence of the funds (such as a bank statement,
                 financial accounts, a solicitor's letter, etc) in the Documents tab.`,
					},
				},
			],
		},
		property_survey_page: {
			property_survey: null,
			buyer_signature: null,
		},
	},
	moving_out: {
		status: 'To Do',
		timing_financing: {
			moving_requirements: null,
			moving_requirements_desc: null,
			mortgage_sufficient: null,
		},
		occupiers: {
			occupiers_seller_yn: null,
			occupiers_others_yn: null,
			occupiers_others_names: [
				{
					occupier: {
						last_names: null,
						given_names: null,
						surname: null,
					},
				},
			],
			occupiers_tenants_yn: null,
			vacant_possession: null,
			vacant_possession_occupiers: {
				leave: null,
				sign: null,
			},
			vacant_possession_occupiers_note: `After the questionnaire,
         we'll ask you to upload evidence that the property will be vacant on completion.`,
		},
		moving_out: {
			moving_out_clean: null,
			moving_out_lights: null,
			moving_out_care: null,
			moving_out_keys: null,
		},
	},
	fitting_contents: {
		status: QuestionnaireStatus.NOT_STARTED,
		rooms: {
			room: null,
			kitchen: null,
			bathrooms: null,
		},
		outdoor: {
			outdoors: null,
		},
		other: {
			stock_of_fuel: null,
		},
		items_for_sale: {
			items_for_sale_yn: null,
		},
	},
	sell_content: {
		status: QuestionnaireStatus.NOT_STARTED,
		contents_selling: {
			items_for_sale: [
				{
					room_type: null,
					price: null,
				},
			],
		},
	},
	liabilities: {
		status: 'To Do',
		enfranchisement_page: {
			enfranchisement_two_years_yn: null,
			enfranchisement_landlord_notice_yn: null,
			enfranchisement_landlord_notice_note: `
        After the questionnaire, we'll ask you to upload a copy of your notice to the landlord.`,
			enfranchisement_tenants_notice_yn: null,
			enfranchisement_tenants_notice_note: `
        After the questionnaire, we'll ask you to upload a copy of the collective purchase notice.`,
			enfranchisement_response_yn: null,
			enfranchisement_response_note: `
        After the questionnaire, we'll ask you to upload a copy of the response.`,
		},
		service_charge_page: {
			service_charge_extra: null,
			service_charge_extra_desc: null,
			service_charge_issues: null,
			service_charge_issues_desc: null,
			service_charge_challenge: null,
			service_charge_challenge_desc: null,
			service_charge_collectionissues: null,
			service_charge_collectionissues_desc: null,
			service_charge_owed: null,
			service_charge_owed_desc: null,
			notice_sale_yn: null,
			notice_sale_note: `
          After the questionnaire, we'll ask you to upload a copy of the notice.`,
			notice_building_yn: null,
			notice_building_note: `
          After the questionnaire, we'll ask you to upload a copy of the notice.`,
			consent_lease_yn: null,
			consent_written_yn: null,
			consent_lease_desc: null,
			consent_lease_note: `
          After the questionnaire, we'll ask you to upload a copy of the terms changes / consents.`,
			complaint_received_yn: null,
			complaint_received_desc: null,
			complaint_made_yn: null,
			complaint_made_desc: null,
		},
		potential_liabilities: {
			liabilities: {
				planning_breaches: null,
				consent_breaches: null,
				unfinished_work: null,
				no_consent_work: null,
				planning_issues: null,
			},
			planning_breaches_desc: null,
			planning_breaches_date: null,
			consent_breaches_desc: null,
			consent_breaches_date: null,
			unfinished_work_desc: null,
			unfinished_work_date: null,
			no_consent_work_desc: null,
			no_consent_work_date: null,
			planning_issues_desc: null,
			planning_issues_date: null,
		},
		disputes: {
			disputes_complaints_yn: null,
			disputes_complaints_desc: null,
			disputes_complaints_future_yn: null,
			disputes_complaints_future_desc: null,
		},
		notices_proposals: {
			notices_proposals_yn: null,
			notices_proposals_desc: null,
			planned_development_yn: null,
			planned_development_desc: null,
			party_wall_notice_yn: null,
			party_wall_notice_desc: null,
			alterations_use_consent_note: null,
		},
	},
};
