import { QuestionnaireStatus } from 'src/types';

export const statusColors = {
	[QuestionnaireStatus.DONE]: {
		color: '#20BC39',
		bgColor: '#CDF3D5',
	},
	[QuestionnaireStatus.IN_PROGRESS]: {
		color: '#C6992F',
		bgColor: '#FFF5D7',
	},
	[QuestionnaireStatus.NOT_STARTED]: {
		color: '#697885',
		bgColor: '#EDEDED',
	},
};
