export const environment = {
	logoWidth: '100px',
	logoHeight: '100px',
	logoPosition: 'right',
	pages: [
		{
			name: 'flooding',
			elements: [
				{
					type: 'boolean',
					name: 'flooded',
					title: 'Has any part of your property (buildings, surrounding garden or land) ever been flooded? *',
					description: 'If you own a flat, this question and the following apply to the building your flat is in.',
					hideNumber: true,
					isRequired: true,
				},
				{
					type: 'paneldynamic',
					name: 'floods_history',
					visibleIf: '{flooded} = true',
					title: 'Previous floods',
					hideNumber: true,
					requiredIf: '{flooded} = true',
					templateElements: [
						{
							type: 'text',
							name: 'flooded_date',
							title: 'When did the flooding take place?',
							titleLocation: 'left',
							inputType: 'month',
						},
						{
							type: 'text',
							name: 'flooded_desc',
							title: 'Which parts flooded? ',
						},
					],
					panelCount: 1,
					minPanelCount: 1,
					maxPanelCount: 20,
					panelAddText: 'Add other flood',
				},
				{
					type: 'matrix',
					name: 'flood_type',
					visible: false,
					visibleIf: '{flooded} = true',
					title: 'What type of flooding took place? *',
					hideNumber: true,
					isRequired: true,
					requiredIf: '{flooded} = true',
					columns: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
					],
					rows: [
						{
							value: 'ground',
							text: 'Ground water',
						},
						{
							value: 'sewer',
							text: 'Sewer flooding',
						},
						{
							value: 'surface',
							text: 'Surface water',
						},
						{
							value: 'coastal',
							text: 'Coastal flooding',
						},
						{
							value: 'river',
							text: 'River flooding',
						},
						{
							value: 'other',
							text: 'Other',
						},
					],
				},
				{
					type: 'comment',
					name: 'flood_type_other_desc',
					visibleIf: "{flood_type.other} = 'yes'",
					title: 'Tell us more.',
					hideNumber: true,
					requiredIf: "{flood_type.other} = 'yes'",
				},
				{
					type: 'radiogroup',
					name: 'flood_report',
					title: 'Has a flood risk report been prepared for the property? *',
					hideNumber: true,
					isRequired: true,
					choices: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
						{
							value: 'not_known',
							text: 'Not known',
						},
					],
					colCount: 3,
				},
			],
			title: 'Flooding',
			description:
				'Flooding may take a variety of forms: it may be seasonal, irregular or simply a one-off event. Your property does not need to be near the sea or to a river for flooding to happen. ',
		},
		{
			name: 'radon',
			elements: [
				{
					type: 'radiogroup',
					name: 'radon_test',
					title: 'Has a radon test been carried out on the property? *',
					hideNumber: true,
					isRequired: true,
					choices: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
						{
							value: 'not_known',
							text: 'Not known',
						},
					],
					colCount: 3,
				},
				{
					type: 'panel',
					name: 'radon_test_yes',
					elements: [
						{
							type: 'boolean',
							name: 'radon_test_safe',
							title: 'Was the test result below the recommended action level?',
							hideNumber: true,
							requiredIf: "{radon_test} = 'yes'",
						},
						{
							type: 'boolean',
							name: 'radon_test_remedies',
							visibleIf: '{radon_test_safe} = false',
							title: 'Were any remedial measures undertaken to reduce radon gas levels in the property?',
							hideNumber: true,
							requiredIf: '{radon_test_safe} = false',
						},
					],
					visibleIf: "{radon_test} = 'yes'",
				},
				{
					type: 'radiogroup',
					name: 'radon_remedies',
					title: 'Were any remedial measures undertaken on construction to reduce radon gas levels in the property? *',
					hideNumber: true,
					isRequired: true,
					choices: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
						{
							value: 'not_known',
							text: 'Not known',
						},
					],
					colCount: 3,
				},
			],
			title: 'Radon',
			description:
				"Radon is a naturally-occurring inert radioactive gas found in the ground. Some parts of England and Wales are more adversely affected by it than others. Remedial action is advised for properties with a test result above the 'recommended action level'.",
		},
		{
			name: 'japanese_knotweed',
			elements: [
				{
					type: 'radiogroup',
					name: 'knotweed',
					title: 'Is the property affected by Japanese knotweed? *',
					hideNumber: true,
					isRequired: true,
					choices: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
						{
							value: 'not_known',
							text: 'Not known',
						},
					],
					colCount: 3,
				},
				{
					type: 'radiogroup',
					name: 'knotweed_plan',
					visibleIf: "{knotweed} = 'yes'",
					title: 'Is there a Japanese knotweed management and treatment plan in place?',
					hideNumber: true,
					requiredIf: "{knotweed} = 'yes'",
					choices: [
						{
							value: 'yes',
							text: 'Yes',
						},
						{
							value: 'no',
							text: 'No',
						},
						{
							value: 'not_known',
							text: 'Not known',
						},
					],
					colCount: 3,
				},
				{
					type: 'html',
					name: 'knotweed_plan_note',
					visibleIf: "{knotweed_plan} = 'yes'",
					html: "We'll ask you to upload a copy of the <b>Japanese Knotweed Insurance Cover</b> linked to the plan in the <b>Documents</b> tab.\n",
				},
			],
			title: 'Japanese Knotweed',
		},
	],
	showCompletedPage: false,
	showProgressBar: 'top',
	completeText: 'Submit',
	widthMode: 'static',
};
