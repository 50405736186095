import { Accordion, AccordionDetails, Box } from '@mui/material';
import { FC, MouseEvent } from 'react';
import { FoldersAccordionSummary, FolderTitle } from './styled';
import { FoldersAccordionProps } from './types';
import { checkIsDocumentTreeExpanded } from './utils';

const FoldersAccordion: FC<FoldersAccordionProps> = ({ tree, folderId, handleSelect }) => {
	const isSelected = tree.id === folderId;
	const isExpanded = checkIsDocumentTreeExpanded(tree, folderId);

	const handleClick = (e: MouseEvent) => {
		e.stopPropagation();
		handleSelect(tree);
	};

	if (!tree.children.length) {
		return (
			<FolderTitle isSelected={isSelected} onClick={handleClick} ml='27px' width='fit-content'>
				{tree.name}
			</FolderTitle>
		);
	}

	return (
		<div>
			<Accordion defaultExpanded={isExpanded}>
				<FoldersAccordionSummary>
					<FolderTitle isSelected={isSelected} onClick={handleClick}>
						{tree.name}
					</FolderTitle>
				</FoldersAccordionSummary>
				<AccordionDetails>
					<Box display='flex' flexDirection='column' gap='5px' pl='20px'>
						{tree.children.map((c) => (
							<FoldersAccordion key={c.id} tree={c} folderId={folderId} handleSelect={handleSelect} />
						))}
					</Box>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default FoldersAccordion;
