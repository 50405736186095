/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { QuestionnairePropertyEnum, QuestionnaireTransactionEnum } from 'src/utils/types';
import { getQuestionnairePermissions } from 'src/api';
import { RootState } from '../store';
import { InitializedStateField } from '../types/common';

export type QuestionnaireRestriction = 'view' | 'edit' | 'card-preview';

export interface QuestionnairePermissions {
	transaction: Partial<Record<QuestionnaireTransactionEnum, QuestionnaireRestriction>>;
	property: Partial<Record<QuestionnairePropertyEnum, QuestionnaireRestriction>>;
}

interface PermissionsState {
	questionnaire: InitializedStateField<QuestionnairePermissions>;
}

const initialState: PermissionsState = {
	questionnaire: {
		data: {
			transaction: {
				buyer_questions: 'card-preview',
				fitting_contents: 'card-preview',
				liabilities: 'card-preview',
				moving_out: 'card-preview',
				moving_in: 'card-preview',
				sell_content: 'card-preview',
				seller_finance: 'card-preview',
				proprietor_finance: 'card-preview',
			},
			property: {
				building_works: 'card-preview',
				environment: 'card-preview',
				guarantees_insurance: 'card-preview',
				ownership: 'card-preview',
				property_details: 'card-preview',
				rights_arrangements: 'card-preview',
				services: 'card-preview',
			},
		},
		isInitialized: false,
	},
};

export const getQuestionnairePermissionsThunk = createAsyncThunk<QuestionnairePermissions, { propertyId: string }>(
	'propertyPermissions/get',
	async ({ propertyId }, { rejectWithValue }) => {
		try {
			return await getQuestionnairePermissions(propertyId);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

const permissionsSlice = createSlice({
	name: 'permissions',
	initialState,
	reducers: {
		clearQuestionnairePermissions: (state) => {
			state.questionnaire = initialState.questionnaire;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getQuestionnairePermissionsThunk.pending, (state) => {
			state.questionnaire.isInitialized = false;
		});
		builder.addCase(getQuestionnairePermissionsThunk.fulfilled, (state, { payload }) => {
			state.questionnaire.isInitialized = true;
			state.questionnaire.data = payload;
		});
		builder.addCase(getQuestionnairePermissionsThunk.rejected, (state, { error }) => {
			state.questionnaire.isInitialized = true;
			state.questionnaire.error = error;
		});
	},
});

export const selectPermissions = (state: RootState) => state.permissions;
export const selectQuestionnairePermissions = (state: RootState) => state.permissions.questionnaire.data;

export const { clearQuestionnairePermissions } = permissionsSlice.actions;

export default permissionsSlice.reducer;
