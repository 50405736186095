import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { useState } from 'react';
import { ModalErrorAlert } from 'src/sections/property/components/styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { InitialAdress } from 'src/components/GoogleAddressAutoComplete/GooglePlaceAutocomlete';
import UprnForm from 'src/components/UprnForm';
import { FormProvider } from 'src/components/hook-form';
import { Stack } from '@mui/material';
import { LoadingButtonStyled } from 'src/pages/properties/style';
import { addTransaction, getNav, handleCloseModal } from 'src/utils/propertyHelpers';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
import { newPropertyDataSchema } from 'src/sections/property/components/validationSchema';
import { PATH_TRANSACTIONS } from 'src/routes/paths';
import { SubtitleStyled, TitleStyled } from './style';

const Content = () => {
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const user = useSelector(selectUser);

	const navigate = useNavigate();

	const methods = useForm({
		resolver: yupResolver(newPropertyDataSchema),
		defaultValues: InitialAdress,
		mode: 'onChange',
	});

	const {
		handleSubmit,
		watch,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = async (data) => {
		setError(null);

		try {
			const id = await addTransaction({
				...data,
				uid: user.uid,
				isInstrument: true,
				...(!user.isAdmin && { role: user.transactionRole, side: 'sell' }),
			});

			handleCloseModal();
			await getNav();

			navigate(PATH_TRANSACTIONS.view(id));
		} catch (e) {
			if (e instanceof Error) {
				setError(e.message);
			}
		}
	};

	const [postcodeWatcher, uprn] = watch(['address.postcode', 'uprn']);

	return (
		<>
			<TitleStyled>Add remortgage</TitleStyled>
			<SubtitleStyled>Enter the property postcode and choose an address.</SubtitleStyled>
			{!!error && (
				<ModalErrorAlert variant='filled' severity='error'>
					{error}
				</ModalErrorAlert>
			)}
			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<UprnForm setError={setError} isLoading={isLoading} setIsLoading={setIsLoading} />
				<Stack>
					<LoadingButtonStyled
						type='submit'
						variant='contained'
						loading={isSubmitting || isLoading}
						size='large'
						disabled={!uprn || !postcodeWatcher || !!error}
					>
						Add remortgage
					</LoadingButtonStyled>
				</Stack>
			</FormProvider>
		</>
	);
};

const AddRemortgageModal = () => (
	<Modal name={modals.addRemortgage} cardSx={{ maxWidth: '630px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default AddRemortgageModal;
