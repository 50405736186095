import { AccordionSummary, AccordionSummaryProps, styled, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';

export const FolderTitle = styled(Typography)<{ isSelected?: boolean }>(({ isSelected, theme }) => ({
	padding: '2px 5px',
	cursor: 'pointer',
	fontWeight: 500,
	borderRadius: '4px',
	background: isSelected ? theme.palette.primary.lighter : 'none',
	transition: 'all .1s',
}));

export const FoldersAccordionSummary = styled((props: AccordionSummaryProps) => (
	<AccordionSummary expandIcon={<Iconify icon='material-symbols-light:play-arrow' fontSize={18} />} {...props} />
))({
	background: 'transparent',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotate(90deg)' },
});
