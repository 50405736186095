import { IconButton, MenuItem } from '@mui/material';
import { GoogleMap, InfoWindow, Marker, useLoadScript, StreetViewPanorama } from '@react-google-maps/api';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import modals from 'src/constants/modals';
import { handleOpenModal } from 'src/utils/propertyHelpers';
import { selectProperty } from 'src/redux/slices/property';
import {
	HeadAddressWrapper,
	MapContainerStyles,
	MenuPopoverAddressAnchorOrigin,
	MenuPopoverAddressTransformOrigin,
	PropertyGeneralAddressCard,
	TypographyAddressTitle,
} from './styled';

// const center = { lat: -3.745, lng: -38.523 };

export const GeneralAddress = () => {
	const { pathname } = useLocation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState(false);
	const toogleOpen = () => setOpen(!open);
	const property = useSelector(selectProperty);

	const isPropertyPage = pathname?.startsWith('/properties');
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '',
		libraries: ['places'],
		language: 'en',
	});
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => setAnchorEl(null);
	const handleEdit = () => {
		handleOpenModal(modals.addProperty);
		handleClose();
	};

	const coordinates: google.maps.LatLngLiteral = {
		lat: property.address.coordinates.latitude,
		lng: property.address.coordinates.longitude,
	};

	return (
		<PropertyGeneralAddressCard>
			<HeadAddressWrapper isPropertyPage={isPropertyPage}>
				<TypographyAddressTitle>STREET VIEW</TypographyAddressTitle>
				{isPropertyPage ? (
					<>
						<IconButton
							id='settings'
							aria-controls={anchorEl ? 'fade-menu' : undefined}
							aria-haspopup='true'
							aria-expanded={anchorEl ? 'true' : undefined}
							onClick={handleClick}
						>
							<Iconify icon='bx:dots-vertical-rounded' />
						</IconButton>
						<MenuPopover
							open={!!anchorEl}
							anchorEl={anchorEl}
							onClose={handleClose}
							transformOrigin={MenuPopoverAddressTransformOrigin}
							anchorOrigin={MenuPopoverAddressAnchorOrigin}
							disabledArrow
						>
							<MenuItem onClick={handleEdit}>Edit</MenuItem>
						</MenuPopover>
					</>
				) : null}
			</HeadAddressWrapper>
			{isLoaded && (
				<GoogleMap mapContainerStyle={MapContainerStyles} center={coordinates} zoom={20}>
					<StreetViewPanorama options={{ position: coordinates, visible: true }} />
					<Marker onClick={toogleOpen} position={coordinates} visible />
					{open && (
						<InfoWindow onCloseClick={toogleOpen} position={coordinates}>
							<div>{property.name}</div>
						</InfoWindow>
					)}
				</GoogleMap>
			)}
		</PropertyGeneralAddressCard>
	);
};
