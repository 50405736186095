import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, CircularProgress } from '@mui/material';
import { IRHFSelect } from './types';

// ----------------------------------------------------------------------

const RHFSelect: FC<IRHFSelect> = ({
	name,
	children,
	controlProps,
	loading,
	noNeedEmptyOption,
	emptyOptionLabel,
	...other
}) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={controlProps || control}
			render={({ field, fieldState: { error } }) => (
				<TextField
					{...field}
					value={field.value || ''}
					select
					fullWidth
					SelectProps={{ native: true }}
					error={!!error}
					helperText={error?.message}
					InputLabelProps={{ shrink: Boolean(field.value) || noNeedEmptyOption }}
					InputProps={{
						endAdornment: (
							<>
								{loading && (
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<CircularProgress sx={{ marginRight: '10px' }} color='inherit' size={20} />
									</div>
								)}
							</>
						),
					}}
					{...other}
				>
					{!noNeedEmptyOption && <option value=''>{emptyOptionLabel}</option>}
					{children}
				</TextField>
			)}
		/>
	);
};

export default RHFSelect;
