import { DocumentLocation, FolderPermissions } from 'src/redux/types/documents';
import { call } from '../@';

export type Payload = {
	folderId: string;
	location: DocumentLocation;
	propertyId: string;
	name: string;
	permissions: FolderPermissions;
};

export default async function updateDocumentsFolder(payload: Payload) {
	return await call('documents-updateFolder', payload);
}
