import { LocalUser } from 'src/components/InviteModal/types';
import { ITransactionOverview } from 'src/redux/types';
import {
	extendedTransactionRoles,
	extendedTransactionRolesOptions,
	transactionPartieSides,
	transactionRolesOptions,
} from '../../constants';
import { TransactionRole, TransactionSide } from '../../types';

export const getUserCompanyName = (user: LocalUser) => {
	if (!user.business && !user.branch) return '';

	const business = user.business
		? `${typeof user.business === 'string' ? user.business : user.business.tradingName}`.trim()
		: '';
	const branch = user.branch ? `${typeof user.branch === 'string' ? user.branch : user.branch.name}`.trim() : '';

	if (!business && !branch) return '';

	return [business, branch].filter(Boolean).join(', ');
};

export const getInvitationRolesOptions = (
	transaction: ITransactionOverview,
): ReadonlyArray<{ key: string; name: string }> => {
	if (transaction.isBuyerViewer) return [extendedTransactionRoles.viewer];

	return transaction.isBuyer ? extendedTransactionRolesOptions : transactionRolesOptions;
};

export const convertTransactionRolesToOptions = (roles: TransactionRole[]) =>
	roles.map((r) => ({
		name: extendedTransactionRoles[r]?.name ?? r,
		key: extendedTransactionRoles[r]?.key ?? r,
	}));

export const convertTransactionSidesToOptions = (sides: TransactionSide[]) =>
	sides.reduce<Array<{ name: string; key: string }>>((acc, s) => {
		acc.push({ name: transactionPartieSides[s]?.name, key: transactionPartieSides[s]?.key });

		return acc;
	}, []);
