import { dispatch, useSelector } from 'src/redux/store';
import { makeAnOffer, selectTransactionOverview } from 'src/redux/slices/transaction';
import modals from 'src/constants/modals';
import { ConfirmationModal } from 'src/components/modals';
import useLoading from 'src/hooks/useLoading';
import { NoBreak } from 'src/styled';
import { formatBidValue } from 'src/utils/formatBid';
import { openModal } from 'src/redux/slices/modal';

const AcceptAskPriceModal = () => {
	const { isLoading, setIsLoading } = useLoading();
	const transaction = useSelector(selectTransactionOverview);

	if (!transaction.askPrice) return null;

	const handleConfirm = async () => {
		if (!transaction.askPrice) return;

		setIsLoading(true);

		await dispatch(makeAnOffer({ id: transaction.id, price: transaction.askPrice.price }));

		if (transaction.hasSellSideAgent || transaction.hasSeller) {
			dispatch(openModal({ name: modals.acceptAskPriceConfirmation }));
		} else {
			dispatch(openModal({ name: modals.offerAccepted, payload: {} }));
		}

		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			sx={{ minWidth: '650px' }}
			modalName={modals.acceptAskPrice}
			title='ACCEPT ASK PRICE'
			confirmText='Confirm'
			handleConfirm={handleConfirm}
			cancelText='Cancel'
			description={
				<>
					You're purchasing <b>{transaction.name}</b> for{' '}
					<NoBreak as='b'>£{formatBidValue(`${transaction.askPrice.price}`)}</NoBreak>.
				</>
			}
			isLoading={isLoading}
		/>
	);
};

export default AcceptAskPriceModal;
