import { call } from '../@';

export type Payload = {
	role: string;
	side: string;
	id: string;
	relationship?: string;
};

export default async function generateTransactionQrCode(payload: Payload) {
	return await call('transaction-generateQrCode', payload);
}
