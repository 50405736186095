import { doc, collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { firestoreImportsField, type importFirestore } from '../@db/@firestore';

const imports: ReturnType<typeof importFirestore> = {
	doc,
	collection,
	getDocs,
	query,
	where,
	orderBy,
};

globalThis[firestoreImportsField] = imports;
