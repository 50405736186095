import { FC } from 'react';
import Carousel from 'react-material-ui-carousel';
import { PhotosCarouselProps } from './types';
import { Photo } from './styled';

const PhotosCarousel: FC<PhotosCarouselProps> = ({ photos, onPhotoClick }) => {
	const showControls = photos.length > 1;

	return (
		<Carousel
			height='365px'
			autoPlay={false}
			animation='slide'
			navButtonsAlwaysVisible={showControls}
			navButtonsAlwaysInvisible={!showControls}
			indicators={showControls}
		>
			{photos.map((photo) => (
				<Photo key={photo.url} src={photo.url} alt={photo.caption ?? ''} onClick={onPhotoClick} />
			))}
		</Carousel>
	);
};

export default PhotosCarousel;
