import { getDocs, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RHFAutocomplete } from 'src/components/hook-form';
import { ROLES_ADMIN } from 'src/constants';
import { usersCollection } from 'src/constants/firestore';
import useLoading from 'src/hooks/useLoading';
import { selectTransactionSummary } from 'src/redux/slices/transaction';
import { getRoleRef } from 'src/utils/firebase';

const CaseHandlerField = () => {
	const { isLoading, setIsLoading } = useLoading();
	const [users, setUsers] = useState<Array<{ id: string; name: string }>>([]);
	const summary = useSelector(selectTransactionSummary);

	useEffect(() => {
		if (!summary.data) return;

		const setup = async () => {
			setIsLoading(true);

			const { docs } = await getDocs(query(usersCollection, where('role', '==', getRoleRef(ROLES_ADMIN.id))));

			setUsers(
				docs.map((d) => {
					const data = d.data();

					return { id: d.id, name: `${data.givenNames} ${data.lastNames}` };
				}),
			);

			setIsLoading(false);
		};

		setup();
	}, []);

	if (!summary.data) return null;

	return (
		<RHFAutocomplete
			name='general.caseHandlerId'
			label='Select a case handler'
			options={users}
			valueAttribute='id'
			loading={isLoading}
		/>
	);
};

export default CaseHandlerField;
