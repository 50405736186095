import { useState, type FC, type PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { CircularLoader } from '../CircularLoader';
import { CloseWrapper, GreetingsWrapper, LogoWrapper } from './styles';
import { IGreetingsBlockWrapper } from './types';
import Iconify from '../Iconify';

export const GreetingsBlockWrapper: FC<PropsWithChildren<IGreetingsBlockWrapper>> = ({
	children,
	isLoading = false,
	isClosed,
	withoutLogo = false,
	onClose,
	sx,
}) => {
	const [close, setClose] = useState(true);
	const toogleClose = () => {
		if (onClose) onClose();
		setClose(!close);
	};
	return close ? (
		<GreetingsWrapper sx={sx}>
			{isLoading ? (
				<CircularLoader />
			) : (
				<>
					{children}
					{!withoutLogo && (
						<LogoWrapper>
							<Box component='img' src='/logo/logo_opacity_2.svg' />
						</LogoWrapper>
					)}
					{isClosed && (
						<CloseWrapper onClick={toogleClose}>
							<Iconify icon='jam:close-circle' color='#2065D1' width={20} height={20} />
						</CloseWrapper>
					)}
				</>
			)}
		</GreetingsWrapper>
	) : null;
};
