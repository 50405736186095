import Iconify from 'src/components/Iconify';
import { Box, Menu, MenuItem } from '@mui/material';
import { FC, ReactNode, useMemo, useState } from 'react';
import { dispatch } from 'src/redux/store';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { Document } from 'src/redux/types/documents';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
import { selectDocuments } from 'src/redux/slices/documents';
import { stopPropagation } from 'src/utils/common';
import { OptionsButton } from './styled';

const DocumentMenu: FC<Omit<Document, 'nestedElementsCount' | 'elements'>> = (document) => {
	const user = useSelector(selectUser);
	const documents = useSelector(selectDocuments);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = !!anchorEl;

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		stopPropagation(event);
	};
	const handleClose = (e) => {
		setAnchorEl(null);
		stopPropagation(e);
	};

	const handleEdit = () => dispatch(openModal({ name: modals.updateDocumentsFolder, payload: document }));
	const handleDelete = () => dispatch(openModal({ name: modals.deleteDocumentsFolder, payload: document }));

	const handleOptionClick = (cb: () => void) => (e) => {
		handleClose(e);
		cb();
	};

	const menuOptions = useMemo(() => {
		const options: ReactNode[] = [];

		if (user.isAdmin) options.push(<MenuItem onClick={handleOptionClick(handleEdit)}>Edit</MenuItem>);
		if (user.isAdmin && document.id !== documents.structure.id) {
			options.push(<MenuItem onClick={handleOptionClick(handleDelete)}>Delete</MenuItem>);
		}

		return options;
	}, [user.isAdmin, handleOptionClick, handleEdit, handleDelete, document.id, documents.structure]);

	if (!menuOptions.length) return null;

	return (
		<Box>
			<OptionsButton onClick={handleClick} disableRipple>
				<Iconify icon='tabler:settings' fontSize={22} />
			</OptionsButton>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				{menuOptions}
			</Menu>
		</Box>
	);
};

export default DocumentMenu;
