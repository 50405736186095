import { FC, useState } from 'react';
import { Typography } from '@mui/material';

import Iconify from 'src/components/Iconify';
import { dispatch } from 'src/redux/store';
import { removeTask } from 'src/redux/slices/transaction';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
import { LinkButtonProps } from './types';

const LinkButton: FC<LinkButtonProps> = ({
	handleClick,
	children,
	task,
	disabled = false,
	shouldRemoveTask = true,
}) => {
	const user = useSelector(selectUser);
	const [isLoading, setIsLoading] = useState(false);

	const handleClickWithLoading = async () => {
		setIsLoading(true);

		await handleClick?.();

		if (shouldRemoveTask) dispatch(removeTask({ taskId: task.id, userId: user.uid }));

		setIsLoading(false);
	};

	return (
		<Typography
			variant='link'
			onClick={handleClickWithLoading}
			sx={{
				pointerEvents: disabled || isLoading ? 'none' : 'auto',
				display: 'inline-flex',
				alignItems: 'center',
				gap: '3px',
			}}
			{...((disabled || isLoading) && { color: 'gray' })}
		>
			{children}
			{isLoading && <Iconify icon='line-md:loading-loop' />}
		</Typography>
	);
};

export default LinkButton;
