import { useSelector } from 'react-redux';
import { selectPropertySlice } from 'src/redux/slices/property';
import { Typography } from '@mui/material';
import { OwnershipStatus } from 'src/types';
import { selectTransactionOverviewField } from 'src/redux/slices/transaction';
import { labelByPropertyTitleTenure } from 'src/constants/title';
import {
	PropertySummaryCard,
	PropertySummaryContent,
	PropertySummaryGroup,
	PropertySummaryItem,
	PropertySummaryItemLabel,
	PropertySummaryNotes,
	TypographyAddressTitle,
} from './styled';
import { TypographyStatus } from '../cards/QuestionnaireCard/styles';
import { titleSummaryStatusBadgeStyle, titleSummaryStatusText } from './constants';
import { getTitleEditionRestrictiveCovenants, getTitleEditionTerm } from './utils';

const Summary = () => {
	const { property } = useSelector(selectPropertySlice);
	const { data: transaction } = useSelector(selectTransactionOverviewField);

	const ownershipStatus = property?.title?.status ?? OwnershipStatus.PENDING;

	const notes = (property?.title?.notes || transaction?.titleNotes) ?? 'Under review';
	const term = property?.title?.term || getTitleEditionTerm(transaction?.titleEdition);
	const tenure =
		property?.title?.tenure || (transaction?.tenure ? labelByPropertyTitleTenure[transaction?.tenure] : null);
	const restrictiveCovenants =
		property?.title?.restrictive_covenants || getTitleEditionRestrictiveCovenants(transaction?.titleEdition);

	return (
		<PropertySummaryCard sx={{ paddingTop: '40px' }}>
			<PropertySummaryGroup sx={{ alignItems: 'center' }}>
				<TypographyAddressTitle>TITLE SUMMARY</TypographyAddressTitle>
				<TypographyStatus sx={titleSummaryStatusBadgeStyle[ownershipStatus]}>
					{titleSummaryStatusText[ownershipStatus]}
				</TypographyStatus>
			</PropertySummaryGroup>
			<PropertySummaryContent>
				<PropertySummaryGroup>
					{!!tenure && (
						<PropertySummaryItem>
							<PropertySummaryItemLabel>Tenure:</PropertySummaryItemLabel>
							<Typography fontFamily={'Roboto'} fontSize={16} variant='body2' color={'text.secondary'}>
								{tenure}
							</Typography>
						</PropertySummaryItem>
					)}
					{!!restrictiveCovenants && (
						<PropertySummaryItem>
							<PropertySummaryItemLabel>Restrictive Covenants:</PropertySummaryItemLabel>
							<Typography fontFamily={'Roboto'} fontSize={16} variant='body2' color={'text.secondary'}>
								{restrictiveCovenants === 'true' ? 'Yes' : 'No'}
							</Typography>
						</PropertySummaryItem>
					)}
					{!!term && (
						<PropertySummaryItem>
							<PropertySummaryItemLabel>Term:</PropertySummaryItemLabel>
							<Typography fontFamily={'Roboto'} fontSize={16} variant='body2' color={'text.secondary'}>
								{term}
							</Typography>
						</PropertySummaryItem>
					)}
				</PropertySummaryGroup>
				{!!notes && (
					<PropertySummaryGroup>
						<PropertySummaryItem>
							<PropertySummaryItemLabel>Notes:</PropertySummaryItemLabel>
							<PropertySummaryNotes dangerouslySetInnerHTML={{ __html: notes }} />
						</PropertySummaryItem>
					</PropertySummaryGroup>
				)}
			</PropertySummaryContent>
		</PropertySummaryCard>
	);
};

export default Summary;
