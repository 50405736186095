/* eslint-disable camelcase */
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { ownership } from 'src/constants/surveyJson/ownership';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnairePropertyEnum } from 'src/utils/types';
import { selectTransactionOverviewField } from 'src/redux/slices/transaction';
import { getTransactionInterest } from 'src/pages/transactions/utils/common';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { DescriptionBlock } from '../styles';
import { useQuestionnaire } from '../useQuestionnaire';
import { StyledGrid, StyledP } from './styles';

const QuestionnaireOwnershipCard = () => {
	const { fullQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const { data: transaction } = useSelector(selectTransactionOverviewField);
	const { onPageChanged, onSubmit, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnairePropertyEnum.ownership,
		questionnaireType: QuestionnaireTypeEnum.PROPERTY,
	});

	if (!transaction || isHidden) return null;

	const isShareOfFreehold = fullQuestionnaire?.property_details.dwelling?.share_of_freehold_yn === true;
	const interest = getTransactionInterest(transaction, fullQuestionnaire);

	const completedPropertyOwnerships = fillQuestionnaireJsonWithCompletedData(
		ownership(transaction?.tenure, fullQuestionnaire),
		fullQuestionnaire?.ownership,
	);

	if (transaction.tenure !== 'leasehold' && interest !== 'Share of freehold') return null;

	return (
		<>
			<QuestionnarieModal
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				initialCurrentPage={fullQuestionnaire?.ownership.currentPage}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				questionnaireKey={QuestionnairePropertyEnum.ownership}
				surveyJson={completedPropertyOwnerships}
				status={fullQuestionnaire?.ownership.status}
				title='Management'
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				title='Management'
				type={QuestionnairePropertyEnum.ownership}
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			>
				{({ details }) => {
					if (!details) return null;

					const { ownership_page, leasehold_page, management_page, finance } = details;

					return (
						<>
							{/* {ownership_page?.tenure_type && (
								<StyledP sx={{ b: { display: 'inline-block', '&::first-letter': { textTransform: 'capitalize' } } }}>
									Tenure:{' '}
									<b>{ownership_page?.share_of_freehold_yn ? 'Share of freehold' : ownership_page?.tenure_type}</b>
								</StyledP>
							)} */}
							{finance?.ownership_type ? (
								<StyledP>
									Ownership: <b>{finance.ownership_type}</b>
								</StyledP>
							) : null}
							{ownership_page?.tenure_type ? (
								<>
									<Grid container spacing={0} rowSpacing={2} sx={{ marginTop: '8px' }}>
										{leasehold_page?.rent_yn ? (
											<StyledGrid xs={5}>
												Ground rent:
												<b>
													£{leasehold_page.rent_amount}/{leasehold_page.rent_frequency}
												</b>
											</StyledGrid>
										) : null}
										{leasehold_page?.service_charge_frequency ? (
											<StyledGrid xs={5}>
												Service charge:
												<b>
													£{leasehold_page.service_charge_amount}/{leasehold_page.service_charge_frequency}
												</b>
											</StyledGrid>
										) : null}
									</Grid>
									{isShareOfFreehold ? (
										<>
											{management_page?.freeholders_company_details ? (
												<DescriptionBlock style={{ marginTop: '8px' }}>
													Freeholder: <b>{management_page.freeholders_company_details.freeholders_company_name}</b>
												</DescriptionBlock>
											) : null}
											{management_page?.freeholders_managing_agent_details ? (
												<DescriptionBlock style={{ marginTop: '8px' }}>
													Managed by: <b>{management_page.freeholders_managing_agent_details.freeholders_agent_name}</b>{' '}
												</DescriptionBlock>
											) : null}
										</>
									) : (
										<>
											{management_page?.landlord_details ? (
												<DescriptionBlock style={{ marginTop: '8px' }}>
													Landlord: <b>{management_page.landlord_details.landlords_name}</b>
												</DescriptionBlock>
											) : null}
											{management_page?.landlords_managing_agent_details?.landlords_agent_name ||
											management_page?.tenants_managing_agent_details?.tenants_agent_name ? (
												<DescriptionBlock style={{ marginTop: '0px' }}>
													Managed by:{' '}
													<b>
														{[
															management_page.landlords_managing_agent_details?.landlords_agent_name,
															management_page.tenants_managing_agent_details?.tenants_agent_name,
														]
															.filter(Boolean)
															.join(', ')}
													</b>
												</DescriptionBlock>
											) : null}
										</>
									)}
								</>
							) : null}
						</>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default QuestionnaireOwnershipCard;
