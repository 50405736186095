import * as yup from 'yup';
import { emailString, nonEmptyString, requiredString } from 'src/utils/schemas';
import { newPropertyDataSchema } from 'src/sections/property/components/validationSchema';
import { extendedTransactionRolesKeys, transactionPartieSides, transactionPartieSidesKeys } from '../constants';

export const validationMakeAnOffer = yup.object().shape({
	offer: requiredString.min(1).not(['0'], 'Amount must be bigger than 0'),
});

export const validationMakeBuyer = yup.object().shape({
	price: yup.string().min(1, 'This field is required'),
});

export const invitationSchema = yup.object().shape({
	role: requiredString.oneOf(extendedTransactionRolesKeys),
	side: yup.string().oneOf(transactionPartieSidesKeys),
	relationship: yup.string().optional().nullable(),
	givenNames: nonEmptyString,
	lastNames: nonEmptyString,
	quote: yup.string(),
	isExistingUser: yup.bool(),
	hasConflictingActiveUser: yup.bool(),
	email: emailString
		.optional()
		.test(
			'hasConflictingActiveUser',
			'User under provided email already exists',
			(_, context) => !context.parent.hasConflictingActiveUser,
		),
	innerRole: requiredString.oneOf(extendedTransactionRolesKeys),
});

export const confirmPriceSchema = yup.object().shape({ price: requiredString });
export const counterOfferSchema = yup.object().shape({ price: requiredString });
export const newTransactionSchema = newPropertyDataSchema.shape({
	side: requiredString.oneOf([transactionPartieSides.buy.key, transactionPartieSides.sell.key]),
});
