import { styled } from '@mui/material';

export const Iframe = styled('iframe')({
	border: 0,
	position: 'relative',
	zIndex: 0,

	// hide cookies box
	marginTop: '-70px',
	// hide scroll bar
	marginLeft: '-15px',
	width: 'calc(100% + 35px)',
});
