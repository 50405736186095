import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { useNavigate } from 'react-router';
import { noop } from 'lodash';
import { ConfirmationModal } from '../../common';

const TransactionVerificationFailModal = () => {
	const navigate = useNavigate();

	const handleConfirm = () => navigate('/dashboard');

	return (
		<ConfirmationModal
			modalName={transactionOnboardingSteps.verificationFail}
			title='IDENTITY VERIFICATION FAILED'
			description={
				<>
					We have failed to verify your identity. <br /> <br /> A team member is reviewing your submission and will be
					in touch shortly.
				</>
			}
			withCancelButton={false}
			confirmText='Go to Dashboard'
			handleConfirm={handleConfirm}
			handleClose={noop}
		/>
	);
};

export default TransactionVerificationFailModal;
