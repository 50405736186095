import { useSelector } from 'react-redux';
import { selectProperty } from 'src/redux/slices/property';
import { Box } from '@mui/material';
import { PropertyGeneralAddressCard, TypographyAddressTitle } from '../../styled';

const MaterialsSection = () => {
	const property = useSelector(selectProperty);

	const { epc } = property.overview;

	const parts = [
		{
			label: 'Floor:',
			value: epc?.floor_description,
		},
		{
			label: 'Walls:',
			value: epc?.walls_description,
		},
		{
			label: 'Roof:',
			value: epc?.roof_description,
		},
		{
			label: 'Windows:',
			value: epc?.windows_description,
		},
	];

	return (
		<PropertyGeneralAddressCard sx={{ maxWidth: '630px' }}>
			<TypographyAddressTitle mb='20px'>MATERIALS</TypographyAddressTitle>
			<Box display='grid' gridTemplateColumns='1fr' columnGap='40px' rowGap='10px'>
				{parts.map(({ label, value }) => (
					<Box key={label}>
						<b>{label}</b> {value}
					</Box>
				))}
			</Box>
		</PropertyGeneralAddressCard>
	);
};

export default MaterialsSection;
