import { TransactionRole, TransactionSide } from 'src/pages/transactions/types';
import { call } from '../@';

type DefaultPayload = { userId: string; transactionId: string };

export type Payload =
	| (DefaultPayload & { transactionInvitationId: string })
	| (DefaultPayload & { role: TransactionRole; side: TransactionSide });

export default async function rejectTransactionInvitation(payload: Payload) {
	return await call('transactionInvitation-reject', payload);
}
