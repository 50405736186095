import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { downloadFileByUrl, fileDownloader } from 'src/api/questionnaire/documents/utils';
import { Document } from 'src/redux/types/documents';
import JSZip from 'jszip';

const storage = getStorage();

export const downloadFile = async (bucket: string, path: string, name: string) => {
	const fileRef = ref(storage, `gs://${bucket}/${path}`);

	await fileDownloader(fileRef)(name);
};

const convertDocumentToZipFile = async (document: Document, zip: JSZip = new JSZip()) => {
	const currentFolder = zip.folder(document.name) as JSZip;

	await Promise.all(
		document.children.map(async (child) => {
			if (child.type === 'file') {
				if (!document.settings.download) return;

				const fileRef = ref(storage, `gs://${child.bucket}/${child.path}`);
				const downloadURL = await getDownloadURL(fileRef);

				const response = await fetch(downloadURL);
				const blob = await response.blob();

				currentFolder.file(child.name, new File([blob], ''));

				return;
			}

			await convertDocumentToZipFile(child, currentFolder);
		}),
	);

	return currentFolder;
};

export const downloadMultipleFiles = async (document: Document) => {
	const archive = await convertDocumentToZipFile(document);

	const zipFile = await archive.generateAsync({ type: 'blob' });

	downloadFileByUrl(URL.createObjectURL(zipFile), `${document.fullName}.zip`);
};
