import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MotionLazyContainer } from 'src/components/animate';
import NotistackProvider from 'src/components/NotistackProvider';
import { store } from 'src/redux/store';
import ThemeProvider from 'src/theme';

const Providers: FC<PropsWithChildren> = ({ children }) => (
	<Provider store={store}>
		<MotionLazyContainer>
			<ThemeProvider>
				<NotistackProvider>
					<BrowserRouter>
						<>{children}</>
					</BrowserRouter>
				</NotistackProvider>
			</ThemeProvider>
		</MotionLazyContainer>
	</Provider>
);

export default Providers;
