import { useEffect, useState, FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';

// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Stack, Drawer, Typography, Box } from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';

// utils
import cssStyles from 'src/utils/cssStyles';
// config
import { NAVBAR } from 'src/config';

// components
import Logo from 'src/components/Logo';
import Scrollbar from 'src/components/Scrollbar';
import { NavSectionVertical } from 'src/components/nav-section';
import { INavConfig } from 'src/components/nav-section/vertical/types';
import { CircularLoader } from 'src/components/CircularLoader';
//
import { handleOpenModal, getNav } from 'src/utils/propertyHelpers';
import modals from 'src/constants/modals';
import { getNavConfig } from './NavConfig';
import CollapseButton from './CollapseButton';
import NavbarAccount from './NavbarAccount';
import SupportModal from './SupportModal';
import { INavbarVertical } from './types';
import { ScrollBarStyle, ScrollbarStackStyle, IconifyStyled, NavbarOption } from './style';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('lg')]: {
		flexShrink: 0,
		transition: theme.transitions.create('width', {
			duration: theme.transitions.duration.shorter,
		}),
	},
}));

const NavbarVertical: FC<INavbarVertical> = ({ isOpenSidebar, onCloseSidebar }) => {
	const userData = useSelector(selectUser);
	const { pathname } = useLocation();
	const { navbar, loadingNavbar } = useSelector(
		(state: { navbar: { navbar: INavConfig[]; loadingNavbar: boolean } }) => state.navbar,
	);

	const [nav, setNav] = useState<INavConfig[]>(getNavConfig(userData ?? {}));

	useEffect(() => {
		getNav();
	}, [userData?.uid]);

	useEffect(() => {
		if (navbar.length) setNav(navbar);
	}, [navbar]);

	const theme = useTheme();

	const isDesktop = useResponsive('up', 'lg');

	const {
		isCollapse,
		collapseClick,
		collapseHover,
		hideNavbar,
		onToggleCollapse,
		onHoverEnter,
		onHoverLeave,
		onToggleHideNavar,
	} = useCollapseDrawer();

	useEffect(() => {
		if (isOpenSidebar) onCloseSidebar();
		if (hideNavbar) onToggleHideNavar();
	}, [pathname]);

	const renderContent = (
		<Scrollbar sx={ScrollBarStyle}>
			<Stack spacing={3} sx={ScrollbarStackStyle({ isCollapse })}>
				<Stack direction='row' alignItems='center' justifyContent='space-between'>
					<Stack direction='row'>{!isCollapse ? <Logo full /> : <Logo />}</Stack>
					{isDesktop && !isCollapse ? null : !isDesktop && hideNavbar ? (
						<CollapseButton
							onToggleCollapse={isDesktop ? onToggleCollapse : onToggleHideNavar}
							collapseClick={isDesktop ? collapseClick : !hideNavbar}
						/>
					) : null}
				</Stack>

				<NavbarAccount isCollapse={isCollapse} />
			</Stack>
			<NavSectionVertical navConfig={nav} isCollapse={isCollapse} />
			<Box
				position='sticky'
				bottom={0}
				bgcolor='white'
				mt='auto'
				zIndex={9999}
				pb='30px'
				pt='15px'
				display='flex'
				flexDirection='column'
				gap='15px'
			>
				{!userData.isAdmin && (
					<>
						<NavbarOption mt='auto'>
							<IconifyStyled icon='mdi:archive-outline' />
							<Typography component={Link} to='/archive'>
								<Typography component='span'>Archive</Typography>
							</Typography>
						</NavbarOption>
						<NavbarOption>
							<IconifyStyled icon='flowbite:trash-bin-outline' />
							<Typography component={Link} to='/bin'>
								<Typography component='span'>Bin</Typography>
							</Typography>
						</NavbarOption>
					</>
				)}
				<NavbarOption onClick={() => handleOpenModal(modals.support)} sx={{ cursor: 'pointer' }}>
					<IconifyStyled icon='mdi:question-mark-circle-outline' />
					Get Support
				</NavbarOption>
			</Box>
			<SupportModal />
		</Scrollbar>
	);

	return (
		<RootStyle
			sx={{
				width: {
					lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
				},
				...(collapseClick && { position: 'absolute' }),
			}}
		>
			{!isDesktop && (
				<Drawer
					open={!isCollapse}
					variant='permanent'
					onClose={onCloseSidebar}
					PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH, left: !hideNavbar ? -NAVBAR.DASHBOARD_WIDTH : 0 } }}
				>
					{userData?.roleName && !loadingNavbar ? renderContent : <CircularLoader />}
				</Drawer>
			)}

			{isDesktop && (
				<Drawer
					open
					variant='persistent'
					onMouseEnter={onHoverEnter}
					onMouseLeave={onHoverLeave}
					PaperProps={{
						sx: {
							width: NAVBAR.DASHBOARD_WIDTH,
							borderRightStyle: 'dashed',
							bgcolor: 'background.default',
							transition: (styledTheme) =>
								styledTheme.transitions.create('width', {
									duration: styledTheme.transitions.duration.standard,
								}),
							...(isCollapse && {
								width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
							}),
							...(collapseHover && {
								...cssStyles(theme).bgBlur(),
								boxShadow: (styledTheme) => styledTheme.customShadows.z24,
							}),
						},
					}}
				>
					{userData?.roleName && !loadingNavbar ? renderContent : <CircularLoader />}
				</Drawer>
			)}
		</RootStyle>
	);
};

export default NavbarVertical;
