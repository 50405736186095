import { FC } from 'react';
import { Link } from '@mui/material';
import { EmailLinkProps } from './types';

const EmailLink: FC<EmailLinkProps> = ({ email, ...other }) => (
	<Link href={`mailto:${email}`} {...other}>
		{email}
	</Link>
);

export default EmailLink;
