import { DocumentReference, DocumentSnapshot, getDoc } from 'firebase/firestore';
import UserProto from 'src/api/auth/@user-proto';
import { LocalUser } from 'src/components/InviteModal/types';
import {
	PROFESSION_ESTATE_AGENT,
	PROFESSION_MORTGAGE_BROKER,
	PROFESSION_PRINCIPAL,
	PROFESSION_SOLICITOR,
	PROFESSION_SURVEYOR,
	PROFESSION_TRADES_PERSON,
	ROLES_PRINCIPAL,
} from 'src/constants';
import { emptyBranch, emptyBusiness } from 'src/constants/mock';
import { userOnboardingSteps } from 'src/pages/auth/constants';
import { TransactionRole } from 'src/pages/transactions/types';
import { ExtendedUserOnboarding } from 'src/redux/slices/auth';
import { Branch, Business, UserOnboarding, UserOnboardingStep } from 'src/types';

export const getUserBusinessAndBranch = async (
	business: UserProto['business'],
	branch: UserProto['branch'],
): Promise<Pick<LocalUser, 'branch' | 'business'>> => {
	let businessData: LocalUser['business'] = structuredClone(emptyBusiness);
	let branchData: LocalUser['branch'] = structuredClone(emptyBranch);

	if (business instanceof DocumentReference) {
		const snapshot = (await getDoc(business)) as DocumentSnapshot<Business>;

		if (!snapshot.exists()) throw new Error('Business not found');

		businessData = snapshot.data();
	} else if (typeof business === 'string') {
		businessData.tradingName = business;
	}

	if (branch instanceof DocumentReference) {
		const snapshot = (await getDoc(branch)) as DocumentSnapshot<Branch>;

		if (!snapshot.exists()) throw new Error('Branch not found');

		branchData = snapshot.data();
	} else if (typeof branch === 'string') {
		branchData.name = branch;
	}

	return { business: businessData, branch: branchData };
};

export const convertProfessionIdToTransactionRole = (professionId: string): TransactionRole => {
	switch (professionId) {
		case PROFESSION_ESTATE_AGENT.id:
			return 'agent';
		case PROFESSION_SOLICITOR.id:
			return 'solicitor';
		case PROFESSION_MORTGAGE_BROKER.id:
			return 'mortgageBroker';
		case PROFESSION_TRADES_PERSON.id:
			return 'tradesPerson';
		case PROFESSION_SURVEYOR.id:
			return 'surveyor';
		case PROFESSION_PRINCIPAL.id:
			// need to change in the future, because principal can be seller or buyer
			return 'seller';
		default:
			throw new Error('Profession not found');
	}
};

export const extractKnownAsFromGivenNames = (givenNames: string) => givenNames.split(' ')[0];

export const sortUserOnboardingSteps = (steps: UserOnboardingStep[]) =>
	steps.sort((a, b) => userOnboardingSteps.indexOf(a) - userOnboardingSteps.indexOf(b as never));

export const getCurrentUserOnboardingStep = (onboarding: UserOnboarding) => {
	const validUserOnboardingSteps = Object.keys(onboarding).filter(
		(field) => userOnboardingSteps.includes(field as never) && onboarding[field] === false,
	) as UserOnboardingStep[];

	return sortUserOnboardingSteps(validUserOnboardingSteps)[0] ?? null;
};

export const getPreviousUserOnboardingStep = (
	onboarding: ExtendedUserOnboarding,
	roleId?: string,
): UserOnboardingStep | null => {
	if (roleId === ROLES_PRINCIPAL.id && onboarding.currentStep === 'info') return 'role';

	const currentStepIndex = userOnboardingSteps.indexOf(onboarding.currentStep);
	const previousStep = userOnboardingSteps[currentStepIndex - 1];

	return previousStep ?? null;
};
