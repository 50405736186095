import { styled } from '@mui/material';
import { GlassMagnifier } from 'react-image-magnifiers';

export const StyledGlassMagnifier = styled(GlassMagnifier)<{ largeWidth: number; largeHeight: number }>(
	({ largeHeight, largeWidth }) => ({
		height: '100%',
		'> img': {
			height: '100%',
			maxHeight: '100%',
			maxWidth: 'unset !important',
		},
		img: {
			maxWidth: 'unset !important',
			objectFit: 'contain',
		},
		'> div > img': {
			width: largeWidth,
			height: largeHeight,
		},
	}),
);
