import { SolicitorReport, SortableRow } from 'src/redux/types';
import { formatPrice } from 'src/utils/transaction/summary';

export const getSolicitorReportSortableRow = (row: SolicitorReport): SortableRow<SolicitorReport> => ({
	id: { value: row.id, label: row.id },
	name: { value: row.name, label: row.name },
	firm: { value: row.firm, label: row.firm },
	livePendingTransactions: { value: row.livePendingTransactions, label: row.livePendingTransactions },
	liveApprovedTransactions: { value: row.liveApprovedTransactions, label: row.liveApprovedTransactions },
	totalPendingTransactions: { value: row.totalPendingTransactions, label: row.totalPendingTransactions },
	totalApprovedTransactions: { value: row.totalApprovedTransactions, label: row.totalApprovedTransactions },
	totalRevenue: { value: row.totalRevenue, label: formatPrice(row.totalRevenue) },
	totalPaidOut: { value: row.totalPaidOut, label: formatPrice(row.totalPaidOut) },
	averageSellSideTransactionTime: {
		value: row.averageSellSideTransactionTime,
		label: row.averageSellSideTransactionTime ? `${row.averageSellSideTransactionTime} days` : 'NaN',
	},
	averageBuySideTransactionTime: {
		value: row.averageBuySideTransactionTime,
		label: row.averageBuySideTransactionTime ? `${row.averageBuySideTransactionTime} days` : 'NaN',
	},
});
