import { Children, FC } from 'react';
import { Stack } from '@mui/material';
import { renderToString } from 'react-dom/server';
import Providers from 'src/providers';
import { CategoryProps } from './types';
import { ParticipantsCategory, ParticipantsCategoryList, ParticipantsCategoryTitle } from '../../style';

const Category: FC<CategoryProps> = ({ title, children, sx, isVisible = true, withHeader = true }) => {
	if (!children || !isVisible) return null;

	const areValidChildren = Children.map(children, (child) => !!renderToString(<Providers>{child}</Providers>))?.some(
		Boolean,
	);

	if (!areValidChildren) return null;

	return (
		<ParticipantsCategory>
			{withHeader && (
				<Stack gap='5.5px'>
					<ParticipantsCategoryTitle>{title}</ParticipantsCategoryTitle>
					<hr color='#F5F8FF' />
				</Stack>
			)}
			<ParticipantsCategoryList sx={sx}>{children}</ParticipantsCategoryList>
		</ParticipantsCategory>
	);
};

export default Category;
