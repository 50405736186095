import { Alert, Box, Card, PopoverOrigin, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// PropertyGeneral

export const PropertyGeneralAddressCard = styled(Card)`
	margin-top: 36px;
	width: 100%;
	padding: 25px 40px;
	box-shadow: 0px 1px 1px #f1f1f1, 0px 4px 16px #d5d5d5;
`;

export const ModalErrorAlert = styled(Alert)`
	margin-bottom: 20px;
`;

export const TypographyAddressTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.grey[800],
	fontSize: '16px',
	fontWeight: 700,
	lineHeight: '16px',
}));

export const HeadAddressWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;

export const MenuPopoverAddressTransformOrigin = {
	vertical: 'top',
	horizontal: 'left',
} as PopoverOrigin;

export const MenuPopoverAddressAnchorOrigin = {
	vertical: 'bottom',
	horizontal: 'left',
} as PopoverOrigin;

export const MapContainerStyles = {
	width: '100%',
	height: '400px',
	borderRadius: '5px',
};
