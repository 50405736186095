import { OwnershipStatus } from 'src/types';

export const titleSummaryStatusBadgeStyle = {
	[OwnershipStatus.PENDING]: {
		background: '#FFF5D7',
		color: '#C6992F',
	},
	[OwnershipStatus.FAILED]: {
		background: '#FFE6E0',
		color: '#D02B2B',
	},
	[OwnershipStatus.VERIFIED]: {
		background: '#CDF3D5',
		color: '#20BC39',
	},
};

export const titleSummaryStatusText = {
	[OwnershipStatus.PENDING]: 'Ownership verification pending',
	[OwnershipStatus.FAILED]: 'Ownership verification failed',
	[OwnershipStatus.VERIFIED]: 'Ownership verified',
};
