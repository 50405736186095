import { DocumentSnapshot, getDocs, query, where } from 'firebase/firestore';
import { propertyOnboardingCollection } from 'src/constants/firestore';
import { PropertyOnboarding } from 'src/types';
import { getPropertyRef, getUserRef } from '..';

export const findPropertyOnboarding = async (
	userId: string,
	propertyId: string,
): Promise<DocumentSnapshot<PropertyOnboarding> | null> => {
	const userRef = getUserRef(userId);
	const propertyRef = getPropertyRef(propertyId);

	const { docs } = await getDocs(
		query(propertyOnboardingCollection, where('propertyRef', '==', propertyRef), where('userRef', '==', userRef)),
	);

	return docs[0] ?? null;
};

export const findAllPropertyOnboardings = async (userId: string): Promise<DocumentSnapshot<PropertyOnboarding>[]> => {
	const userRef = getUserRef(userId);

	const { docs } = await getDocs(query(propertyOnboardingCollection, where('userRef', '==', userRef)));

	return docs;
};
