import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { sellContent } from 'src/constants/surveyJson/sellContent';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { useQuestionnaire } from '../useQuestionnaire';

export const QuestionnaireSellContent = () => {
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const { onPageChanged, onSubmit, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnaireTransactionEnum.sell_content,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	const completedSellContent = fillQuestionnaireJsonWithCompletedData(
		sellContent(fullQuestionnaire?.fitting_contents),
		fullQuestionnaire?.sell_content,
	);

	if (isHidden) return null;

	return (
		<>
			<QuestionnarieModal
				initialCurrentPage={fullQuestionnaire?.sell_content?.currentPage}
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				questionnaireKey={QuestionnaireTransactionEnum.sell_content}
				onSubmit={onSubmit}
				onPageChanged={onPageChanged}
				onComplete={onComplete}
				surveyJson={completedSellContent}
				status={fullQuestionnaire?.sell_content?.status}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title='Sale Contents'
				type={QuestionnaireTransactionEnum.sell_content}
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			/>
		</>
	);
};
