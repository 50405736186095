import { ITransactionParticipants } from 'src/redux/types';

export const giftorQuestions = (participants: ITransactionParticipants) => {
	const giftorNames = participants.buyers.reduce<string[]>((acc, buyer) => {
		if (buyer.relationship === 'giftor' && buyer.isApproved) {
			acc.push(`${buyer.givenNames} ${buyer.lastNames}`);
		}

		return acc;
	}, []);

	return {
		logoPosition: 'right',
		completedHtml: "<h3>That's it! Thank you for completing the finance questionnaire! 🎉</h3>",
		completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
		loadingHtml: '<h3>Loading questions...</h3>',
		pages: [
			{
				name: 'funds',
				elements: [
					{
						type: 'panel',
						name: 'source_of_funds',
						elements: [
							{
								type: 'checkbox',
								name: 'sources',
								title: 'What is the source of your funds? *',
								description: 'Select all that apply.',
								hideNumber: true,
								isRequired: true,
								choices: [
									{
										value: 'savings',
										text: 'Savings',
									},
									{
										value: 'inheritance',
										text: 'Inheritance',
									},
									{
										value: 'property_sale',
										text: 'Sale of a property',
									},
									{
										value: 'pension',
										text: 'Pension',
									},
									{
										value: 'loan',
										text: 'Loan',
									},
									// 									{
									// 										value: 'gift',
									// 										text: 'Gift',
									// 									},
									{
										value: 'gambling',
										text: 'Gambling winnings',
									},
									{
										value: 'compensation',
										text: 'Compensation',
									},
									{
										value: 'remortgage',
										text: 'Remortgage',
									},
									{
										value: 'equity_release',
										text: 'Equity release',
									},
								],
							},
							{
								type: 'text',
								name: 'loan_amount',
								visibleIf: "{sources} contains 'loan'",
								title: 'Amount from loan',
								description: 'Please enter the total loan amount (in Pounds) you intend to use for this gift.\n',
								hideNumber: true,
								requiredIf: "{sources} contains 'loan'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'loan_note',
								visibleIf: "{sources} contains 'loan'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload your <b>loan agreement</b>.",
							},
							{
								type: 'text',
								name: 'savings_amount',
								visibleIf: "{sources} contains 'savings'",
								title: 'Amount from savings',
								description:
									'Please enter the total amount from your savings (in Pounds) that you intend to use for this gift.',
								hideNumber: true,
								requiredIf: "{sources} contains 'savings'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'savings_note',
								visibleIf: "{sources} contains 'savings'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload <b>bank statements</b> and <b>pay slips</b> for the last 3 months.",
							},
							{
								type: 'text',
								name: 'inheritance_amount',
								visibleIf: "{sources} contains 'inheritance'",
								title: 'Amount from inheritance',
								description:
									'Please enter the total amount from your inheritance (in Pounds) that you intend to use for this gift.',
								hideNumber: true,
								requiredIf: "{sources} contains 'inheritance'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'inheritance_note',
								visibleIf: "{sources} contains 'inheritance'",
								html: "You'll be asked to upload evidence from the executors of the estate in the <b>Documents</b> tab.",
							},
							{
								type: 'text',
								name: 'property_sale_amount',
								visibleIf: "{sources} contains 'property_sale'",
								title: 'Amount from property sale',
								description:
									'Please enter the total amount (in Pounds) from the sale of a property that you intend to use for this gift.',
								hideNumber: true,
								requiredIf: "{sources} contains 'property_sale'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'property_sale_note',
								visibleIf: "{sources} contains 'property_sale'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload a copy of the <b>completion statement</b> from your solicitor.",
							},
							{
								type: 'text',
								name: 'pension_amount',
								visibleIf: "{sources} contains 'pension'",
								title: 'Amount from pension',
								description:
									'Please enter the total amount (in Pounds) from your pension payments that you intend to use for this gift.',
								hideNumber: true,
								requiredIf: "{sources} contains 'pension'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'pension_note',
								visibleIf: "{sources} contains 'pension'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload a copy of your <b>pension statement</b> and a copy of your <b>bank statement</b> showing the money coming in from a pension firm.",
							},
							{
								type: 'text',
								name: 'gambling_amount',
								visibleIf: "{sources} contains 'gambling'",
								title: 'Amount from gambling',
								description:
									'Please enter the total amount (in Pounds) from gambling winnings that you intend to use for this gift.',
								hideNumber: true,
								requiredIf: "{sources} contains 'gambling'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'gambling_note',
								visibleIf: "{sources} contains 'gambling'",
								html: "You'll be asked to upload a <b>receipt</b> proving your winnings in the <b>Documents</b> tab.",
							},
							{
								type: 'text',
								name: 'compensation_amount',
								visibleIf: "{sources} contains 'compensation'",
								title: 'Amount from compensation',
								description:
									'Please enter the total amount (in Pounds) from compensation settlements that you plan to use for this gift.',
								hideNumber: true,
								requiredIf: "{sources} contains 'compensation'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'compensation_note',
								visibleIf: "{sources} contains 'compensation'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload a copy of the letter you received from a solicitor or court confirming your compensation settlement.",
							},
							{
								type: 'text',
								name: 'remortgage_amount',
								visibleIf: "{sources} contains 'remortgage'",
								title: 'Amount from remortgage',
								description:
									'Please enter the total amount (in Pounds) from remortgage that you plan to use for this gift.',
								hideNumber: true,
								requiredIf: "{sources} contains 'remortgage'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'remortgage_note',
								visibleIf: "{sources} contains 'remortgage'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload evidence of your remortgage.",
							},
							{
								type: 'text',
								name: 'equity_release_amount',
								visibleIf: "{sources} contains 'equity_release'",
								title: 'Amount from equity release',
								description:
									'Please enter the total amount (in Pounds) from equity release that you plan to use for this gift.',
								hideNumber: true,
								requiredIf: "{sources} contains 'equity_release'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'equity_release_notes',
								visibleIf: "{sources} contains 'equity_release'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload evidence of your equity release.",
							},
							{
								type: 'html',
								name: 'gift_notice',
								visibleIf: "{sources} contains 'gift'",
								html: '<center><font size="8">⚠️</font></center><br> Please head to <b>Participants</b> tab, click on <b>Add Participant</b>, and invite all your giftors before proceeding. You can then resume this questionnaire - any answers provided so far will be saved.<br>',
							},
							{
								type: 'paneldynamic',
								name: 'gift',
								visibleIf: "{sources} contains 'gift'",
								title: 'Gifted amounts *',
								description: 'Please enter all individual gifts that count toward your total gifted amount.',
								hideNumber: true,
								isRequired: true,
								requiredIf: "{sources} contains 'gift'",
								templateElements: [
									{
										type: 'text',
										name: 'amount',
										title: 'Amount *',
										titleLocation: 'left',
										hideNumber: true,
										isRequired: true,
										maskType: 'numeric',
										min: 1,
										step: 1,
									},
									{
										type: 'dropdown',
										name: 'currency',
										title: 'Currency *',
										titleLocation: 'left',
										hideNumber: true,
										isRequired: true,
										choices: [
											'AED - UAE Dirham',
											'AFN - Afghani',
											'ALL - Lek',
											'AMD - Armenian Dram',
											'ANG - Netherlands Antillean Guilder',
											'AOA - Kwanza',
											'ARS - Argentine Peso',
											'AUD - Australian Dollar',
											'AWG - Aruban Florin',
											'AZN - Azerbaijanian Manat',
											'BAM - Convertible Mark',
											'BBD - Barbados Dollar',
											'BDT - Taka',
											'BGN - Bulgarian Lev',
											'BHD - Bahraini Dinar',
											'BIF - Burundi Franc',
											'BMD - Bermudian Dollar',
											'BND - Brunei Dollar',
											'BOB - Boliviano',
											'BOV - Mvdol',
											'BRL - Brazilian Real',
											'BSD - Bahamian Dollar',
											'BTN - Ngultrum',
											'BWP - Pula',
											'BYN - Belarussian Ruble',
											'BZD - Belize Dollar',
											'CAD - Canadian Dollar',
											'CDF - Congolese Franc',
											'CHE - WIR Euro',
											'CHF - Swiss Franc',
											'CHW - WIR Franc',
											'CLF - Unidad de Fomento',
											'CLP - Chilean Peso',
											'CNY - Yuan Renminbi',
											'COP - Colombian Peso',
											'COU - Unidad de Valor Real',
											'CRC - Costa Rican Colon',
											'CUC - Peso Convertible',
											'CUP - Cuban Peso',
											'CVE - Cabo Verde Escudo',
											'CZK - Czech Koruna',
											'DJF - Djibouti Franc',
											'DKK - Danish Krone',
											'DOP - Dominican Peso',
											'DZD - Algerian Dinar',
											'EGP - Egyptian Pound',
											'ERN - Nakfa',
											'ETB - Ethiopian Birr',
											'EUR - Euro',
											'FJD - Fiji Dollar',
											'FKP - Falkland Islands Pound',
											'GBP - Pound Sterling',
											'GEL - Lari',
											'GHS - Ghana Cedi',
											'GIP - Gibraltar Pound',
											'GMD - Dalasi',
											'GNF - Guinea Franc',
											'GTQ - Quetzal',
											'GYD - Guyana Dollar',
											'HKD - Hong Kong Dollar',
											'HNL - Lempira',
											'HTG - Gourde',
											'HUF - Forint',
											'IDR - Rupiah',
											'ILS - New Israeli Sheqel',
											'INR - Indian Rupee',
											'IQD - Iraqi Dinar',
											'IRR - Iranian Rial',
											'ISK - Iceland Krona',
											'JMD - Jamaican Dollar',
											'JOD - Jordanian Dinar',
											'JPY - Yen',
											'KES - Kenyan Shilling',
											'KGS - Som',
											'KHR - Riel',
											'KMF - Comoro Franc',
											'KPW - North Korean Won',
											'KRW - Won',
											'KWD - Kuwaiti Dinar',
											'KYD - Cayman Islands Dollar',
											'KZT - Tenge',
											'LAK - Kip',
											'LBP - Lebanese Pound',
											'LKR - Sri Lanka Rupee',
											'LRD - Liberian Dollar',
											'LSL - Loti',
											'LYD - Libyan Dinar',
											'MAD - Moroccan Dirham',
											'MDL - Moldovan Leu',
											'MGA - Malagasy Ariary',
											'MKD - Denar',
											'MMK - Kyat',
											'MNT - Tugrik',
											'MOP - Pataca',
											'MRU - Ouguiya',
											'MUR - Mauritius Rupee',
											'MVR - Rufiyaa',
											'MWK - Kwacha',
											'MXN - Mexican Peso',
											'MYR - Malaysian Ringgit',
											'MZN - Mozambique Metical',
											'NAD - Namibia Dollar',
											'NGN - Naira',
											'NIO - Cordoba Oro',
											'NOK - Norwegian Krone',
											'NPR - Nepalese Rupee',
											'NZD - New Zealand Dollar',
											'OMR - Rial Omani',
											'PAB - Balboa',
											'PEN - Nuevo Sol',
											'PGK - Kina',
											'PHP - Philippine Peso',
											'PKR - Pakistan Rupee',
											'PLN - Zloty',
											'PYG - Guarani',
											'QAR - Qatari Rial',
											'RON - Romanian Leu',
											'RSD - Serbian Dinar',
											'RUB - Russian Ruble',
											'RWF - Rwanda Franc',
											'SAR - Saudi Riyal',
											'SBD - Solomon Islands Dollar',
											'SCR - Seychelles Rupee',
											'SDG - Sudanese Pound',
											'SEK - Swedish Krona',
											'SGD - Singapore Dollar',
											'SHP - Saint Helena Pound',
											'SLE - Leone',
											'SOS - Somali Shilling',
											'SRD - Surinam Dollar',
											'SSP - South Sudanese Pound',
											'STN - Dobra',
											'SVC - El Salvador Colon',
											'SYP - Syrian Pound',
											'SZL - Lilangeni',
											'THB - Baht',
											'TJS - Somoni',
											'TMT - Turkmenistan New Manat',
											'TND - Tunisian Dinar',
											'TOP - Pa’anga',
											'TRY - Turkish Lira',
											'TTD - Trinidad and Tobago Dollar',
											'TWD - New Taiwan Dollar',
											'TZS - Tanzanian Shilling',
											'UAH - Hryvnia',
											'UGX - Uganda Shilling',
											'USD - US Dollar',
											'UYU - Peso Uruguayo',
											'UZS - Uzbekistan Sum',
											'VED - Bolivar',
											'VEF - Bolivar',
											'VND - Dong',
											'VUV - Vatu',
											'WST - Tala',
											'XAF - CFA Franc BEAC',
											'XCD - East Caribbean Dollar',
											'XOF - CFA Franc BCEAO',
											'XPF - CFP Franc',
											'XSU - Sucre',
											'YER - Yemeni Rial',
											'ZAR - Rand',
											'ZMW - Zambian Kwacha',
											'ZWL - Zimbabwe Dollar',
										],
									},
									{
										type: 'dropdown',
										name: 'giftors',
										title: 'Gifted by *',
										description: 'Select at least one giftor',
										isRequired: true,
										choices: giftorNames,
									},
								],
								noEntriesText: ' ',
								panelAddText: 'Add gift',
							},
							{
								type: 'html',
								name: 'gift_note',
								visibleIf: "{sources} contains 'gift'",
								html: '<b>IMPORTANT</b>: Your giftor will be asked to provide evidence of how the funds came into their possession.',
							},
						],
					},
				],
				title: 'Source of funds',
			},
			{
				name: 'finance',
				elements: [
					{
						type: 'paneldynamic',
						name: 'bank_accounts',
						title: 'Bank accounts *',
						description:
							'Please list all bank accounts involved with the gifted money, including where it was received, sent to, or transferred from.',
						hideNumber: true,
						isRequired: true,
						templateElements: [
							{
								type: 'panel',
								name: 'account',
								elements: [
									{
										type: 'text',
										name: 'account_holders',
										title: 'Account holder(s) *',
										isRequired: true,
									},
									{
										type: 'text',
										name: 'bank',
										title: 'Bank *',
										titleLocation: 'left',
										isRequired: true,
									},
									{
										type: 'text',
										name: 'sort_code',
										title: 'Sort code *',
										titleLocation: 'left',
										isRequired: true,
										maskType: 'pattern',
										maskSettings: {
											pattern: '99-99-99',
										},
										placeholder: '11-22-33',
									},
									{
										type: 'text',
										name: 'account_number',
										title: 'Account number *',
										titleLocation: 'left',
										hideNumber: true,
										isRequired: true,
										maskType: 'pattern',
										maskSettings: {
											pattern: '99999999',
										},
										placeholder: '12345678',
									},
								],
								title: 'Account details *',
								isRequired: true,
							},
						],
						noEntriesText: ' ',
						panelAddText: 'Add bank account',
					},
					{
						type: 'html',
						name: 'bank_accounts_note',
						html: "To meet compliance requirements, we must ensure that bank accounts belong to the giftor, have been active for at least one year, and that their activity aligns with expected financial norms for these individuals. <br></br>In the <b>Documents</b> tab, you'll be required to provide <b>bank statements</b> for each of the accounts above: one statement for each of the <b>last 3 months</b> and one statement <b>going back at least 12 months</b>",
					},
				],
				title: 'Bank accounts',
			},
		],
		showTitle: false,
		showCompletedPage: false,
		completeText: 'Submit',
		widthMode: 'static',
	};
};
