import { reauthenticateWithCredential, updatePassword, EmailAuthProvider } from 'firebase/auth';
import { auth } from 'src/firebaseConfig';
import { AuthErrorMessages } from '../@';

export default async function changeUserPassword(oldPassword: string, newPassword: string) {
	if (!auth.currentUser) throw new Error(AuthErrorMessages.noLogin);
	const credential = EmailAuthProvider.credential(auth.currentUser.email || '', oldPassword);
	await reauthenticateWithCredential(auth.currentUser, credential);
	await updatePassword(auth.currentUser, newPassword);
}
