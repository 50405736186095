import { FC, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from 'src/firebaseConfig';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import useResponsive from 'src/hooks/useResponsive';
import { logout, selectUser } from 'src/redux/slices/auth';

// @mui
import { Container, Fade, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// components
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';
import modals from 'src/constants/modals';
import { selectMenuLoading, setAnchorEl } from 'src/redux/slices/headerSlice';
import { dispatch } from 'src/redux/store';
import { FONT_ROBOTO } from 'src/theme/typography';
import { handleOpenModal } from 'src/utils/propertyHelpers';

// hooks
import useOffSetTop from 'src/hooks/useOffSetTop';

// utils
import cssStyles from 'src/utils/cssStyles';

// config
import { HEADER } from 'src/config';
import palette from 'src/theme/palette';
import { changeProperty, selectPropertySlice } from 'src/redux/slices/property';
import { onSnapshot } from 'firebase/firestore';
import { CircularLoader } from 'src/components/CircularLoader';
import { EpcLoadingStatus } from 'src/types';
import { getPropertyRef } from 'src/utils/firebase';
import { BoxStyles, MenuItem, RootStyle, SelectActionButtonStyled, ToolbarShadowStyle, ToolbarStyle } from './styles';
import CollapseButton from './navbar/CollapseButton';

interface IItemMenus {
	title: string;
	path: null | string;
	onClick: () => void;
}

interface IMenus {
	id: string;
	items: IItemMenus[];
}

const SELECT_ACTION = 'selectAction';

const MainHeader: FC<{ isCollapse?: boolean; verticalLayout?: boolean }> = ({
	isCollapse = false,
	verticalLayout = false,
}) => {
	const userData = useSelector(selectUser);
	const { property } = useSelector(selectPropertySlice);
	const popoverLoading = useSelector(selectMenuLoading);
	const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
	const path = useLocation();

	const isDesktop = useResponsive('up', 'lg');
	const isPropertyPage = path.pathname.includes('/properties');

	const { hideNavbar, onToggleHideNavar } = useCollapseDrawer();
	const [isAddProperty, setIsAddProperty] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(false);

	const [headerMenu, setHeaderMenu] = useState<IItemMenus[] | null>(null);
	const [menuHPosition, setMenuHPosition] = useState<number | 'left' | 'right' | 'center'>('left');

	const { actionMenu, anchorEl } = useSelector(
		(state: { actionMenu: { actionMenu: IItemMenus[]; anchorEl: null | HTMLButtonElement } }) => state.actionMenu,
	);

	const navigate = useNavigate();

	const open = Boolean(anchorEl);

	const handleClose = (redirectPath?: string) => {
		if (redirectPath) {
			navigate(redirectPath);
		}
		dispatch(setAnchorEl(null));
	};

	const noRenderSelectPaths = () =>
		userData?.isPrincipal
			? ['documents', 'account-settings'].some((route) => path.pathname.includes(route))
			: ['dashboard', 'documents', 'account-settings'].some((route) => path.pathname.includes(route));

	const handleLogout = async () => {
		dispatch(logout());
		await dispatch(setAnchorEl(null));
		auth.signOut();
		await navigate('/auth/login');
	};

	const menus: IMenus[] = [
		{
			id: SELECT_ACTION,
			items: [],
		},
		{
			id: 'settings',
			items: [
				{
					title: 'Edit profile',
					path: null,
					onClick: () => handleClose('/account-settings'),
				},
				{
					title: 'Log out',
					path: null,
					onClick: () => handleLogout(),
				},
			],
		},
	] as IMenus[];

	useEffect(() => {
		const isDashboard = path.pathname.includes('/dashboard') || path.pathname === '/';

		setIsAddProperty(isDashboard && userData?.isPrincipal);
	}, [userData, path]);

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		dispatch(setAnchorEl(event.currentTarget));

		const selectAction = menus.find((menu) => menu.id === SELECT_ACTION);

		if (selectAction) selectAction.items = actionMenu;

		const curMenu = menus.find((menu) => menu.id === event.currentTarget.id)?.items;

		setHeaderMenu(curMenu?.length ? curMenu : []);

		setMenuHPosition(event.currentTarget.id === 'settings' ? 'right' : 'left');
	};

	const theme = useTheme();

	useEffect(() => {
		let unsub;
		if (isPropertyPage && property?.id && property?.title?.epcLoadingStatus !== EpcLoadingStatus.COMPLETED) {
			if (!isDataLoading) setIsDataLoading(true);
			unsub = onSnapshot(getPropertyRef(property.id), (snap) => {
				const newData = snap.data();
				if (newData?.title?.epcLoadingStatus === EpcLoadingStatus.COMPLETED) {
					dispatch(changeProperty({ ...newData, id: property.id }));
					setIsDataLoading(false);
				}
			});
		}
		return () => {
			if (unsub) unsub();
			setIsDataLoading(false);
		};
	}, [isPropertyPage, property]);

	return (
		<RootStyle
			isCollapse={isCollapse}
			isOffset={isOffset}
			verticalLayout={verticalLayout}
			sx={{ boxShadow: 0, bgcolor: 'transparent', color: palette.light.custom.bidyText }}
		>
			<ToolbarStyle
				disableGutters
				sx={{
					...(isOffset && {
						...cssStyles(theme).bgBlur(),
						height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
					}),
				}}
			>
				<Container
					sx={{
						maxWidth: '100% !important',
						display: 'flex',

						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<div>
						{!isDesktop && <CollapseButton onToggleCollapse={onToggleHideNavar} collapseClick={!hideNavbar} />}
						{isAddProperty ? (
							<BoxStyles onClick={() => handleOpenModal(modals.addProperty)}>
								<Iconify icon='material-symbols:add-circle-outline-rounded' color='#2065D1' width={20} height={20} />
								Add Property
							</BoxStyles>
						) : (
							!noRenderSelectPaths() && (
								<SelectActionButtonStyled
									id='selectAction'
									aria-controls={open ? 'fade-menu' : undefined}
									aria-haspopup='true'
									aria-expanded={open ? 'true' : undefined}
									onClick={handleClick}
									endIcon={<Iconify icon='akar-icons:chevron-down' />}
								>
									Select Action
								</SelectActionButtonStyled>
							)
						)}
					</div>
					<Stack flexDirection='row' display='inline-flex' alignItems='center' gap='30px'>
						{isDataLoading && (
							<Stack flexDirection='row' gap='15px'>
								<Typography component='span'>Unravelling the data puzzle…</Typography>
								<CircularLoader size={22} />
							</Stack>
						)}
						<IconButton
							id='settings'
							aria-controls={open ? 'fade-menu' : undefined}
							aria-haspopup='true'
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClick}
						>
							<Iconify
								icon='carbon:settings'
								width={22}
								height={22}
								sx={{ color: anchorEl?.id === 'settings' ? 'primary.main' : 'custom.bidyText' }}
							/>
						</IconButton>
					</Stack>

					<MenuPopover
						id='fade-menu'
						anchorEl={anchorEl}
						transformOrigin={{
							vertical: 'top',
							horizontal: menuHPosition,
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: menuHPosition,
						}}
						open={open}
						onClose={() => dispatch(setAnchorEl(null))}
						TransitionComponent={Fade}
						loading={popoverLoading}
						sx={{ overflow: 'hidden' }}
					>
						{headerMenu &&
							headerMenu.map((menu) => (
								<MenuItem
									onClick={menu.onClick}
									key={menu.title}
									sx={{ borderRadius: '10px', fontFamily: FONT_ROBOTO, color: 'custom.bidyText' }}
								>
									{menu.path ? <Link to={menu.path}>{menu.title}</Link> : menu.title}
								</MenuItem>
							))}
					</MenuPopover>
				</Container>
			</ToolbarStyle>
			{isOffset ? <ToolbarShadowStyle /> : <></>}
		</RootStyle>
	);
};

export default MainHeader;
