import { Box, Stack } from '@mui/material';
import { FC } from 'react';
import { calculatePropertyRatingPixels } from 'src/utils/propertyHelpers';
import { RatingLabel } from './RatingLabel';
import {
	GraphBox,
	GraphContainer,
	GraphName,
	MaxValueTitleTypo,
	MinValueTitleTypo,
	RangesInfoWrapper,
	RateRangesItem,
	RateRangesName,
	RateRangesTypo,
	RatingCardWrapper,
	RatingLabelWrapper,
	TypographyTitle,
} from './styles';
import { IRatingCard } from './types';

const RatingCard: FC<IRatingCard> = ({
	rateRanges,
	title,
	maxValueTitle,
	minValueTitle,
	current,
	potential,
	status,
}) => {
	const currentColor = current && rateRanges.find(({ range }) => current >= range[0] && current <= range[1])?.color;
	const potentialColor =
		potential && rateRanges.find(({ range }) => potential >= range[0] && potential <= range[1])?.color;
	const isRatingExist = current && potential;

	if (!isRatingExist) return null;

	return (
		<RatingCardWrapper>
			<Stack justifyContent={'space-between'} flexDirection='row' gap='10px'>
				<TypographyTitle>{title}</TypographyTitle>
				{status}
			</Stack>
			<RangesInfoWrapper>
				<div>
					<MaxValueTitleTypo>{maxValueTitle}</MaxValueTitleTypo>
					{rateRanges.map(({ name, color, range }, i) => (
						<RateRangesItem key={name} index={i} color={color}>
							<RateRangesTypo>{`(${range[0]}-${range[1]})`}</RateRangesTypo>
							<RateRangesName>{name}</RateRangesName>
						</RateRangesItem>
					))}
					<MinValueTitleTypo>{minValueTitle}</MinValueTitleTypo>
				</div>
				<GraphBox>
					<Box sx={{ width: '64px' }}>
						<GraphName>Current</GraphName>
						<GraphContainer>
							<RatingLabelWrapper val={calculatePropertyRatingPixels(current)}>
								<RatingLabel color={currentColor || ''} value={current} />
							</RatingLabelWrapper>
						</GraphContainer>
					</Box>
					<Box sx={{ width: '64px' }}>
						<GraphName>Potential</GraphName>
						<GraphContainer>
							<RatingLabelWrapper val={calculatePropertyRatingPixels(potential)}>
								<RatingLabel color={potentialColor || ''} value={potential} />
							</RatingLabelWrapper>
						</GraphContainer>
					</Box>
				</GraphBox>
			</RangesInfoWrapper>
		</RatingCardWrapper>
	);
};

export default RatingCard;
