import { signOut } from 'firebase/auth';
import { auth } from 'src/firebaseConfig';
import { endSession } from '../user-session';

export default async function logout() {
	const currentSessionId = sessionStorage.getItem('sessionId');

	if (currentSessionId) await endSession(currentSessionId).catch(() => null);

	await signOut(auth);

	sessionStorage.removeItem('sessionId');
}
