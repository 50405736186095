import { SxProps } from '@mui/material';
import palette from 'src/theme/palette';

export const modalSx: SxProps = {
	'.sd-title.sd-element__title.sd-panel__title': {
		'> .sv-string-viewer.sv-string-viewer--multiline': {
			fontSize: '21px',
			fontWeight: 600,
			color: palette.light.info.dark,
		},
	},
};
