import { FC } from 'react';
import Logo from 'src/components/Logo';
import { Typography } from '@mui/material';
import { BrandCardProps } from './types';
import { Wrapper } from './styled';

const BrandCard: FC<BrandCardProps> = ({
	withLogo = true,
	title,
	className,
	width = '350px',
	height = 'fit-content',
	children,
}) => (
	<Wrapper
		className={className}
		display='flex'
		flexDirection='column'
		gap='10px'
		alignItems='center'
		justifyContent='center'
		p='20px 30px 25px'
		borderRadius='2px'
		height={height}
		width={width}
	>
		{withLogo && <Logo full disabledLink zoom={1.3} />}
		{title && (
			<Typography variant='subtitle2' color='grey.700'>
				{title}
			</Typography>
		)}
		{children}
	</Wrapper>
);

export default BrandCard;
