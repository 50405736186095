import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { logout } from 'src/api';

import LoadingScreen from 'src/components/LoadingScreen';
import { confirmChangeEmailRequestThunk } from 'src/redux/slices/auth';
import { dispatch } from 'src/redux/store';
import { PATH_AUTH } from 'src/routes/paths';

export default function ConfirmEmailChange() {
	const [params] = useSearchParams();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();

	const requestId = params.get('requestId');
	const userId = params.get('userId');

	useEffect(() => {
		(async () => {
			try {
				if (!requestId || !userId) {
					await logout();

					navigate(PATH_AUTH.login);

					return;
				}

				await dispatch(confirmChangeEmailRequestThunk({ requestId, userId }));

				enqueueSnackbar('Your account email successfully changed!');

				await logout();

				navigate(PATH_AUTH.login);
			} catch (e) {
				console.error(e);

				navigate(PATH_AUTH.login);
			}
		})();
	}, [requestId, userId]);

	return <LoadingScreen />;
}
