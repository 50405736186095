import { getBlob, StorageReference } from 'firebase/storage';

export const downloadFileByUrl = (url: string, name: string) => {
	const link = document.createElement('a');

	link.setAttribute('href', url);
	link.setAttribute('download', name);

	document.body.appendChild(link);

	link.click();
	link.remove();
};

export const fileDownloader =
	(ref: StorageReference) =>
	async (name = ref.name) => {
		const blob = await getBlob(ref);
		const blobUrl = URL.createObjectURL(blob);

		downloadFileByUrl(blobUrl, name);
	};
