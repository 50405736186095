import { updateDoc } from 'firebase/firestore';
import {
	IQuestionnaireTransaction,
	OnboardingStepStatusEnum,
	Transaction,
	TransactionOfferStatusEnum,
	TransactionOnboarding,
} from 'src/types';
import { TransactionOnboardingStep, TransactionRole, TransactionSide } from 'src/pages/transactions/types';
import { VerificationSessionSide } from 'src/hooks/types';
import { transactionOnboardingSteps, transactionOnboardingStepsKeys } from 'src/pages/transactions/constants';
import { ITransactionParticipants, ITransactionPlaceholder } from 'src/redux/types';
import { transactionState } from 'src/components/modals/transaction/UpdateTransactionState/constants';
import { getTransaction } from '../firebase';

export const makeTransactionAndOffersFrozen = async (id: string) => {
	const transaction = await getTransaction(id);

	await Promise.all([
		updateDoc<unknown>(transaction.ref, { state: transactionState.standBy }),
		Promise.all(
			transaction.data.offers.map((offer) => updateDoc(offer, { status: TransactionOfferStatusEnum.FROZEN })),
		),
	]);
};

export const getVerificationSessionSideByTransactionRole = (role: TransactionRole): VerificationSessionSide => {
	switch (role) {
		case 'buyer':
			return 'buy';
		case 'seller':
			return 'sell';
		default:
			return 'ancillary';
	}
};

export const getTransactionOnboardingModalName = (step: TransactionOnboardingStep) => transactionOnboardingSteps[step];
export const notAdminTransactionsFilter = (data) =>
	data.state !== transactionState.standBy && data.state !== transactionState.archived;
export const adminTransactionsFilter = (data) =>
	data.state !== transactionState.standBy &&
	data.state !== transactionState.archived &&
	data.state !== transactionState.defunct;
export const archiveTransactionsFilter = (data) => data.state === transactionState.archived;

export const sortTransactionOnboardingSteps = (steps: TransactionOnboardingStep[]) =>
	steps.sort((a, b) => transactionOnboardingStepsKeys.indexOf(a) - transactionOnboardingStepsKeys.indexOf(b));

export const getTransactionOnboardingLatestStep = (
	onboarding: TransactionOnboarding,
): TransactionOnboardingStep | undefined => {
	const onboardingSideKeys = Object.keys(onboarding) as TransactionOnboardingStep[];

	return sortTransactionOnboardingSteps(onboardingSideKeys).find(
		(step) => onboarding[step] === OnboardingStepStatusEnum.PENDING,
	);
};

export const getTransactionSellersHolders = (
	participants: ITransactionParticipants,
	placeholders: ITransactionPlaceholder[],
	transactionQuestionnaire: IQuestionnaireTransaction,
) => {
	const proprietorNames = participants.sellers.reduce<string[]>((acc, seller) => {
		if (seller.relationship !== 'proprietor' || !seller.isApproved) return acc;

		const personalQuestionnaire = transactionQuestionnaire.personal_details?.[seller.uid];
		const companyName = personalQuestionnaire?.personal?.tax?.company_details?.name;

		if (companyName) acc.push(companyName);

		acc.push(`${seller.givenNames} ${seller.lastNames}`);

		return acc;
	}, []);

	placeholders.forEach((p) => {
		if (p.side === 'sell' && p.role === 'seller' && p.relationship === 'proprietor') {
			proprietorNames.push(`${p.givenNames} ${p.lastNames}`);
		}
	});

	return proprietorNames;
};

export const getTransactionBuyersHolders = (
	participants: ITransactionParticipants,
	placeholders: ITransactionPlaceholder[],
	transactionQuestionnaire?: IQuestionnaireTransaction,
	withCompanies = false,
) => {
	const proprietorNames = participants.buyers.reduce<string[]>((acc, buyer) => {
		if ((!buyer.relationship || buyer.relationship === 'buyer') && buyer.isApproved) {
			acc.push(`${buyer.givenNames} ${buyer.lastNames}`);
		}

		if (withCompanies && transactionQuestionnaire) {
			const personalQuestionnaire = transactionQuestionnaire.personal_details?.[buyer.uid];
			const companyName = personalQuestionnaire?.personal?.tax?.company_details?.name;

			if (companyName) acc.push(companyName);
		}

		return acc;
	}, []);

	placeholders.forEach((p) => {
		if (p.side === 'buy' && p.role === 'buyer' && p.relationship === 'buyer') {
			proprietorNames.push(`${p.givenNames} ${p.lastNames}`);
		}
	});

	return proprietorNames;
};

export const getUserTransactionSide = (transaction: Transaction, userId: string): TransactionSide | null => {
	const sides = Object.keys(transaction.parties[userId]).filter(
		(s) => !!Object.values(transaction.parties[userId]?.[s]).length,
	) as TransactionSide[];

	return sides[0] ?? null;
};
