import { Link } from '@mui/material';
import modals from 'src/constants/modals';
import { handleOpenModal } from 'src/utils/propertyHelpers';

const ExistingUserError = () => {
	const handleContactUs = () => handleOpenModal(modals.support);

	return (
		<div>
			This email address is already in use, but under a different role. <br /> Please try a different email address or{' '}
			<Link sx={{ cursor: 'pointer' }} onClick={handleContactUs}>
				contact us
			</Link>
		</div>
	);
};

export default ExistingUserError;
