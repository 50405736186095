export type AllowedDocumentFileFormat =
	| '.pdf'
	| '.jpg'
	| '.jpeg'
	| '.png'
	| '.bmp'
	| '.doc'
	| '.docx'
	| '.odt'
	| '.xls'
	| '.xlsx'
	| '.csv'
	| '.txt';

export const allowedDocumentsFilesExtensions = [
	'.pdf',
	'.jpg',
	'.jpeg',
	'.png',
	'.bmp',
	'.doc',
	'.docx',
	'.odt',
	'.xls',
	'.xlsx',
	'.csv',
	'.txt',
];

export const documentsInputAcceptTypes = allowedDocumentsFilesExtensions.join(',');

export const oneMbInBytes = 1048576;

// size in bytes
export const documentsFilesSettings: Record<
	AllowedDocumentFileFormat,
	{ size: number; label: string; sizeLabel: string }
> = {
	'.pdf': { size: 10 * oneMbInBytes, label: 'PDF', sizeLabel: '10 MB' },
	'.bmp': { size: 5 * oneMbInBytes, label: 'BMP', sizeLabel: '5 MB' },
	'.jpeg': { size: 5 * oneMbInBytes, label: 'JPEG', sizeLabel: '5 MB' },
	'.jpg': { size: 5 * oneMbInBytes, label: 'JPG', sizeLabel: '5 MB' },
	'.png': { size: 5 * oneMbInBytes, label: 'PNG', sizeLabel: '5 MB' },
	'.doc': { size: 3 * oneMbInBytes, label: 'DOC', sizeLabel: '3 MB' },
	'.docx': { size: 3 * oneMbInBytes, label: 'DOCX', sizeLabel: '3 MB' },
	'.odt': { size: 3 * oneMbInBytes, label: 'ODT', sizeLabel: '3 MB' },
	'.xls': { size: 3 * oneMbInBytes, label: 'XLS', sizeLabel: '3 MB' },
	'.xlsx': { size: 3 * oneMbInBytes, label: 'XLSX', sizeLabel: '3 MB' },
	'.txt': { size: 0.5 & oneMbInBytes, label: 'TXT', sizeLabel: '500 KB' },
	'.csv': { size: 0.5 & oneMbInBytes, label: 'CSV', sizeLabel: '500 KB' },
};
