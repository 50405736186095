import { ITransactionParticipants, ITransactionPlaceholder } from 'src/redux/types';
import { IQuestionnaireTransaction } from 'src/types';
import { getTransactionBuyersHolders } from 'src/utils/transaction';

export const buyerQuestions = (
	participants: ITransactionParticipants,
	placeholders: ITransactionPlaceholder[],
	transactionQuestionnaire: IQuestionnaireTransaction,
) => {
	const giftorNames = participants.buyers.reduce<string[]>((acc, buyer) => {
		if (buyer.relationship === 'giftor') {
			acc.push(`${buyer.givenNames} ${buyer.lastNames}`);
		}

		return acc;
	}, []);

	const giftBankAccounts =
		transactionQuestionnaire.buyer_questions?.finance?.bank_accounts.reduce((acc, account) => {
			if (!account || !account.bank || !account.account_number) return acc;

			acc.push(`${account.bank} ${account.account_number}`);

			return acc;
		}, []) ?? [];

	return {
		logoPosition: 'right',
		completedHtml: "<h3>That's it! Thank you for completing the finance questionnaire! 🎉</h3>",
		completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
		loadingHtml: '<h3>Loading questions...</h3>',
		pages: [
			{
				name: 'finance',
				elements: [
					{
						type: 'paneldynamic',
						name: 'bank_accounts',
						title: 'Bank accounts *',
						description: 'What bank accounts are you planning to use for this purchase? Add as many as needed.',
						hideNumber: true,
						isRequired: true,
						templateElements: [
							{
								type: 'panel',
								name: 'account',
								elements: [
									{
										type: 'tagbox',
										name: 'account_holders',
										title: 'Account holder(s) *',
										isRequired: true,
										choices: getTransactionBuyersHolders(participants, placeholders, transactionQuestionnaire, true),
									},
									{
										type: 'text',
										name: 'bank',
										title: 'Bank *',
										titleLocation: 'left',
										isRequired: true,
									},
									{
										type: 'text',
										name: 'sort_code',
										title: 'Sort code *',
										titleLocation: 'left',
										isRequired: true,
										maskType: 'pattern',
										maskSettings: {
											pattern: '99-99-99',
										},
										placeholder: '11-22-33',
									},
									{
										type: 'text',
										name: 'account_number',
										title: 'Account number *',
										titleLocation: 'left',
										hideNumber: true,
										isRequired: true,
										maskType: 'pattern',
										maskSettings: {
											pattern: '99999999',
										},
										placeholder: '12345678',
									},
								],
								title: 'Account details *',
								isRequired: true,
							},
						],
						noEntriesText: ' ',
						panelAddText: 'Add bank account',
					},
					{
						type: 'html',
						name: 'bank_accounts_note',
						html: "To meet compliance requirements, we must ensure that bank accounts belong to the buyers, have been active for at least one year, and that their activity aligns with expected financial norms for these individuals. <br></br>In the <b>Documents</b> tab, you'll be required to provide <b>bank statements</b> for each of the accounts above: one statement for each of the <b>last 3 months</b> and one statement <b>going back at least 12 months</b>",
					},
				],
				title: 'Bank accounts',
			},
			{
				name: 'funds',
				elements: [
					{
						type: 'panel',
						name: 'source_of_funds',
						elements: [
							{
								type: 'checkbox',
								name: 'sources',
								title: 'What is the source of your funds? *',
								description: 'Select all that apply.',
								hideNumber: true,
								isRequired: true,
								choices: [
									{
										value: 'mortgage',
										text: 'Mortgage',
									},
									{
										value: 'savings',
										text: 'Savings',
									},
									{
										value: 'gift',
										text: 'Gift',
									},
									{
										value: 'inheritance',
										text: 'Inheritance',
									},
									{
										value: 'property_sale',
										text: 'Sale of a property',
									},
									{
										value: 'pension',
										text: 'Pension',
									},
									{
										value: 'gambling',
										text: 'Gambling winnings',
									},
									{
										value: 'compensation',
										text: 'Compensation',
									},
									{
										value: 'remortgage',
										text: 'Remortgage',
									},
									{
										value: 'equity_release',
										text: 'Equity release',
									},
								],
							},
							{
								type: 'text',
								name: 'mortgage_amount',
								visibleIf: "{sources} contains 'mortgage'",
								title: 'Amount from mortgage',
								description:
									'Please enter the total mortgage amount (in Pounds) you intend to use for this purchase.\n',
								hideNumber: true,
								requiredIf: "{sources} contains 'mortgage'",
								maskType: 'numeric',
							},
							{
								type: 'boolean',
								name: 'mortgage_broker_yn',
								visibleIf: "{sources} contains 'mortgage'",
								title: 'Do you have a mortgage broker?',
								hideNumber: true,
								requiredIf: "{sources} contains 'mortgage'",
							},
							{
								type: 'html',
								name: 'mortgage_broker_note',
								visibleIf: '{mortgage_broker_yn} = true',
								html: '<center><font size="6">⚠️</font></center><br> Please head to the <b>Participants</b> tab, click on <b>Add Participant</b>, and add your mortgage broker\'s details. You can then resume this questionnaire. Any answers provided so far will be saved.',
							},
							{
								type: 'boolean',
								name: 'mortgage_offer_yn',
								visibleIf: "{sources} contains 'mortgage'",
								title: 'Do you have a mortgage offer /offer in principle?',
								hideNumber: true,
								requiredIf: "{sources} contains 'mortgage'",
							},
							{
								type: 'html',
								name: 'mortgage_oip_note',
								visibleIf: '{mortgage_offer_yn} = true',
								html: "In the <b>Documents</b> tab, you'll be asked to upload your <b>mortgage offer</b>.",
							},
							{
								type: 'text',
								name: 'savings_amount',
								visibleIf: "{sources} contains 'savings'",
								title: 'Amount from savings',
								description:
									'Please enter the total amount from your savings (in Pounds) that you intend to use for this purchase.',
								hideNumber: true,
								requiredIf: "{sources} contains 'savings'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'savings_note',
								visibleIf: "{sources} contains 'savings'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload <b>bank statements</b> and <b>pay slips</b> for the last 3 months.",
							},
							{
								type: 'text',
								name: 'inheritance_amount',
								visibleIf: "{sources} contains 'inheritance'",
								title: 'Amount from inheritance',
								description:
									'Please enter the total amount from your inheritance (in Pounds) that you intend to use for this purchase.',
								hideNumber: true,
								requiredIf: "{sources} contains 'inheritance'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'inheritance_note',
								visibleIf: "{sources} contains 'inheritance'",
								html: "You'll be asked to upload evidence from the executors of the estate in the <b>Documents</b> tab.",
							},
							{
								type: 'text',
								name: 'property_sale_amount',
								visibleIf: "{sources} contains 'property_sale'",
								title: 'Amount from property sale',
								description:
									'Please enter the total amount (in Pounds) from the sale of a property that you intend to use for this purchase.',
								hideNumber: true,
								requiredIf: "{sources} contains 'property_sale'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'property_sale_note',
								visibleIf: "{sources} contains 'property_sale'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload a copy of the <b>completion statement</b> from your solicitor.",
							},
							{
								type: 'text',
								name: 'pension_amount',
								visibleIf: "{sources} contains 'pension'",
								title: 'Amount from pension',
								description:
									'Please enter the total amount (in Pounds) from your pension payments that you intend to use for this purchase.',
								hideNumber: true,
								requiredIf: "{sources} contains 'pension'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'pension_note',
								visibleIf: "{sources} contains 'pension'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload a copy of your <b>pension statement</b> and a copy of your <b>bank statement</b> showing the money coming in from a pension firm.",
							},
							{
								type: 'text',
								name: 'gambling_amount',
								visibleIf: "{sources} contains 'gambling'",
								title: 'Amount from gambling',
								description:
									'Please enter the total amount (in Pounds) from gambling winnings that you intend to use for this purchase.',
								hideNumber: true,
								requiredIf: "{sources} contains 'gambling'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'gambling_note',
								visibleIf: "{sources} contains 'gambling'",
								html: "You'll be asked to upload a <b>receipt</b> proving your winnings in the <b>Documents</b> tab.",
							},
							{
								type: 'text',
								name: 'compensation_amount',
								visibleIf: "{sources} contains 'compensation'",
								title: 'Amount from compensation',
								description:
									'Please enter the total amount (in Pounds) from compensation settlements that you plan to use for this purchase.',
								hideNumber: true,
								requiredIf: "{sources} contains 'compensation'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'compensation_note',
								visibleIf: "{sources} contains 'compensation'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload a copy of the letter you received from a solicitor or court confirming your compensation settlement.",
							},
							{
								type: 'text',
								name: 'remortgage_amount',
								visibleIf: "{sources} contains 'remortgage'",
								title: 'Amount from remortgage',
								description:
									'Please enter the total amount (in Pounds) from remortgage that you plan to use for this purchase.',
								hideNumber: true,
								requiredIf: "{sources} contains 'remortgage'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'remortgage_note',
								visibleIf: "{sources} contains 'remortgage'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload evidence of your remortgage.",
							},
							{
								type: 'text',
								name: 'equity_release_amount',
								visibleIf: "{sources} contains 'equity_release'",
								title: 'Amount from equity release',
								description:
									'Please enter the total amount (in Pounds) from equity release that you plan to use for this purchase.',
								hideNumber: true,
								requiredIf: "{sources} contains 'equity_release'",
								maskType: 'numeric',
							},
							{
								type: 'html',
								name: 'equity_release_notes',
								visibleIf: "{sources} contains 'equity_release'",
								html: "In the <b>Documents</b> tab, you'll be asked to upload evidence of your equity release.",
							},
							{
								type: 'html',
								name: 'gift_notice',
								visibleIf: "{sources} contains 'gift'",
								html: '<center><font size="8">⚠️</font></center><br> Please head to <b>Participants</b> tab, click on <b>Add Participant</b>, and invite all your giftors before proceeding. You can then resume this questionnaire - any answers provided so far will be saved.<br>',
							},
							...(giftorNames.length
								? [
										{
											type: 'paneldynamic',
											name: 'gift',
											visibleIf: "{sources} contains 'gift'",
											title: 'Gifted amounts *',
											description: 'Please enter all gifts towards the purchase of this property.',
											hideNumber: true,
											isRequired: true,
											requiredIf: "{sources} contains 'gift'",
											templateElements: [
												{
													type: 'text',
													name: 'amount',
													title: 'Amount *',
													titleLocation: 'left',
													hideNumber: true,
													isRequired: true,
													maskType: 'numeric',
													min: 1,
													step: 1,
												},
												{
													type: 'dropdown',
													name: 'currency',
													title: 'Currency *',
													titleLocation: 'left',
													hideNumber: true,
													isRequired: true,
													readOnly: true,
													defaultValue: 'GBP - Pound Sterling',
													choices: [
														'AED - UAE Dirham',
														'AFN - Afghani',
														'ALL - Lek',
														'AMD - Armenian Dram',
														'ANG - Netherlands Antillean Guilder',
														'AOA - Kwanza',
														'ARS - Argentine Peso',
														'AUD - Australian Dollar',
														'AWG - Aruban Florin',
														'AZN - Azerbaijanian Manat',
														'BAM - Convertible Mark',
														'BBD - Barbados Dollar',
														'BDT - Taka',
														'BGN - Bulgarian Lev',
														'BHD - Bahraini Dinar',
														'BIF - Burundi Franc',
														'BMD - Bermudian Dollar',
														'BND - Brunei Dollar',
														'BOB - Boliviano',
														'BOV - Mvdol',
														'BRL - Brazilian Real',
														'BSD - Bahamian Dollar',
														'BTN - Ngultrum',
														'BWP - Pula',
														'BYN - Belarussian Ruble',
														'BZD - Belize Dollar',
														'CAD - Canadian Dollar',
														'CDF - Congolese Franc',
														'CHE - WIR Euro',
														'CHF - Swiss Franc',
														'CHW - WIR Franc',
														'CLF - Unidad de Fomento',
														'CLP - Chilean Peso',
														'CNY - Yuan Renminbi',
														'COP - Colombian Peso',
														'COU - Unidad de Valor Real',
														'CRC - Costa Rican Colon',
														'CUC - Peso Convertible',
														'CUP - Cuban Peso',
														'CVE - Cabo Verde Escudo',
														'CZK - Czech Koruna',
														'DJF - Djibouti Franc',
														'DKK - Danish Krone',
														'DOP - Dominican Peso',
														'DZD - Algerian Dinar',
														'EGP - Egyptian Pound',
														'ERN - Nakfa',
														'ETB - Ethiopian Birr',
														'EUR - Euro',
														'FJD - Fiji Dollar',
														'FKP - Falkland Islands Pound',
														'GBP - Pound Sterling',
														'GEL - Lari',
														'GHS - Ghana Cedi',
														'GIP - Gibraltar Pound',
														'GMD - Dalasi',
														'GNF - Guinea Franc',
														'GTQ - Quetzal',
														'GYD - Guyana Dollar',
														'HKD - Hong Kong Dollar',
														'HNL - Lempira',
														'HTG - Gourde',
														'HUF - Forint',
														'IDR - Rupiah',
														'ILS - New Israeli Sheqel',
														'INR - Indian Rupee',
														'IQD - Iraqi Dinar',
														'IRR - Iranian Rial',
														'ISK - Iceland Krona',
														'JMD - Jamaican Dollar',
														'JOD - Jordanian Dinar',
														'JPY - Yen',
														'KES - Kenyan Shilling',
														'KGS - Som',
														'KHR - Riel',
														'KMF - Comoro Franc',
														'KPW - North Korean Won',
														'KRW - Won',
														'KWD - Kuwaiti Dinar',
														'KYD - Cayman Islands Dollar',
														'KZT - Tenge',
														'LAK - Kip',
														'LBP - Lebanese Pound',
														'LKR - Sri Lanka Rupee',
														'LRD - Liberian Dollar',
														'LSL - Loti',
														'LYD - Libyan Dinar',
														'MAD - Moroccan Dirham',
														'MDL - Moldovan Leu',
														'MGA - Malagasy Ariary',
														'MKD - Denar',
														'MMK - Kyat',
														'MNT - Tugrik',
														'MOP - Pataca',
														'MRU - Ouguiya',
														'MUR - Mauritius Rupee',
														'MVR - Rufiyaa',
														'MWK - Kwacha',
														'MXN - Mexican Peso',
														'MYR - Malaysian Ringgit',
														'MZN - Mozambique Metical',
														'NAD - Namibia Dollar',
														'NGN - Naira',
														'NIO - Cordoba Oro',
														'NOK - Norwegian Krone',
														'NPR - Nepalese Rupee',
														'NZD - New Zealand Dollar',
														'OMR - Rial Omani',
														'PAB - Balboa',
														'PEN - Nuevo Sol',
														'PGK - Kina',
														'PHP - Philippine Peso',
														'PKR - Pakistan Rupee',
														'PLN - Zloty',
														'PYG - Guarani',
														'QAR - Qatari Rial',
														'RON - Romanian Leu',
														'RSD - Serbian Dinar',
														'RUB - Russian Ruble',
														'RWF - Rwanda Franc',
														'SAR - Saudi Riyal',
														'SBD - Solomon Islands Dollar',
														'SCR - Seychelles Rupee',
														'SDG - Sudanese Pound',
														'SEK - Swedish Krona',
														'SGD - Singapore Dollar',
														'SHP - Saint Helena Pound',
														'SLE - Leone',
														'SOS - Somali Shilling',
														'SRD - Surinam Dollar',
														'SSP - South Sudanese Pound',
														'STN - Dobra',
														'SVC - El Salvador Colon',
														'SYP - Syrian Pound',
														'SZL - Lilangeni',
														'THB - Baht',
														'TJS - Somoni',
														'TMT - Turkmenistan New Manat',
														'TND - Tunisian Dinar',
														'TOP - Pa’anga',
														'TRY - Turkish Lira',
														'TTD - Trinidad and Tobago Dollar',
														'TWD - New Taiwan Dollar',
														'TZS - Tanzanian Shilling',
														'UAH - Hryvnia',
														'UGX - Uganda Shilling',
														'USD - US Dollar',
														'UYU - Peso Uruguayo',
														'UZS - Uzbekistan Sum',
														'VED - Bolivar',
														'VEF - Bolivar',
														'VND - Dong',
														'VUV - Vatu',
														'WST - Tala',
														'XAF - CFA Franc BEAC',
														'XCD - East Caribbean Dollar',
														'XOF - CFA Franc BCEAO',
														'XPF - CFP Franc',
														'XSU - Sucre',
														'YER - Yemeni Rial',
														'ZAR - Rand',
														'ZMW - Zambian Kwacha',
														'ZWL - Zimbabwe Dollar',
													],
												},
												{
													type: 'dropdown',
													name: 'giftees',
													title: 'Gifted to *',
													description: 'Select at least one purchaser',
													isRequired: true,
													choices: getTransactionBuyersHolders(participants, placeholders),
												},
												{
													type: 'dropdown',
													name: 'giftors',
													title: 'Gifted by *',
													description: 'Select at least one giftor',
													isRequired: true,
													choices: giftorNames,
												},
												{
													type: 'text',
													name: 'giftor_relationship',
													title: 'Relationship *',
													description:
														'What is the relationship between the giftor and the giftee? e.g. father, aunt, etc.',
													isRequired: true,
												},

												{
													type: 'dropdown',
													name: 'bank_account',
													title: 'Received on *',
													description: 'Select a bank account',
													isRequired: true,
													choices: giftBankAccounts,
												},
											],
											noEntriesText: ' ',
											panelAddText: 'Add gift',
										},
										{
											type: 'html',
											name: 'gift_note',
											visibleIf: "{sources} contains 'gift'",
											html: '<b>IMPORTANT</b>: Your giftor will be asked to provide evidence of how the funds came into their possession.',
										},
								  ]
								: []),
						],
					},
				],
				title: 'Source of funds',
			},
			{
				name: 'modifiers',
				elements: [
					{
						type: 'matrix',
						name: 'attributes',
						title: 'Do any of the following apply to your purchase? *',
						hideNumber: true,
						isRequired: true,
						columns: ['Yes', 'No'],
						rows: [
							{
								value: 'htb_isa',
								text: 'Help-to-Buy ISA',
							},
							{
								value: 'htb_loan',
								text: 'Help-to-Buy Equity Loan',
							},
							{
								value: 'lifetime_isa',
								text: 'Lifetime ISA',
							},
							{
								value: 'buy_to_let',
								text: 'Buy-to-Let',
							},
							{
								value: 'shared_ownership',
								text: 'Shared ownership',
							},
						],
					},
				],
				title: 'Special conditions',
			},
			// 			{
			//                 name: 'charges',
			//                 elements: [
			//                     {
			//                         type: 'paneldynamic',
			//                         name: 'mortgages',
			//                         title: 'Mortgages *',
			//                         description: 'List all mortgages that you are taking on this property',
			//                         hideNumber: true,
			//                         isRequired: true,
			//                         templateElements: [
			//                             {
			//                                 type: 'panel',
			//                                 name: 'mortgage',
			//                                 elements: [
			//                                     {
			//                                         type: 'tagbox',
			//                                         name: 'holders',
			//                                         title: 'Mortgage holder(s) *',
			//                                         isRequired: true,
			//                                         choices: buyerNames,
			//                                     },
			//                                     {
			//                                         type: 'text',
			//                                         name: 'lender',
			//                                         title: 'Lender *',
			//                                         titleLocation: 'left',
			//                                         isRequired: true,
			//                                     },
			//                                     {
			//                                         type: 'text',
			//                                         name: 'mortgage_account_number',
			//                                         title: 'Mortgage account number *',
			//                                         titleLocation: 'left',
			//                                         hideNumber: true,
			//                                         isRequired: true,
			//                                     },
			//                                     {
			//                                         type: 'text',
			//                                         name: 'amount_borrowed',
			//                                         title: 'Amount borrowed *',
			//                                         titleLocation: 'left',
			//                                         description: 'In Pounds',
			//                                         hideNumber: true,
			//                                         isRequired: true,
			//                                         maskType: 'numeric',
			//                                         maskSettings: { allowNegativeValues: false },
			//                                     }
			//                                 ],
			//                                 title: 'Mortgage details',
			//                                 isRequired: true,
			//                             },
			//                         ],
			//                         noEntriesText: '',
			//                         panelAddText: 'Add mortgage',
			//                     },
			//                 ],
			//                 title: 'Mortgages',
			//                 visibleIf: '{mortgage_offer_yn} = true',
			//             },
			{
				name: 'surveys',
				elements: [
					{
						type: 'radiogroup',
						name: 'property_survey',
						title: 'What property survey would you like to conduct?  ',
						description:
							'The RICS Home Survey Level 1 is the most basic – and cheapest – survey. It is suitable if you’re buying a conventional property built from common building materials and in reasonable condition. It was previously called a Condition Report.\n\nThe RICS Home Survey Level 2 is a popular choice for most people buying a conventional property in reasonable condition. It covers everything you’d get in a RICS Home Survey Level 1, plus they check roof spaces and cellars.\n\nThe RICS Home Survey Level 3, also known as a full structural survey is the most thorough survey offered by RICS. It is a good option if you’re buying a property over 50 years old, of unusual design, is a listed building or in poor condition; if you’re planning to undertake renovations or have any concerns about the property.\n\nFor more information on RICS property surveys click here https://hoa.org.uk/advice/guides-for-homeowners/i-am-buying/what-sort-of-survey-should-i-have/#inpage-2\n',
						hideNumber: true,
						choices: [
							{
								value: 'Level 1',
								text: 'RICS Home Survey – Level 1 (250 GBP)',
							},
							{
								value: 'Level 2',
								text: 'RICS Home Survey – Level 2 (PRICE TBD)',
							},
							{
								value: 'Level 3',
								text: 'RICS Home Survey – Level 3 (PRICE TBD)',
							},
						],
						showNoneItem: true,
					},
					{
						type: 'signaturepad',
						name: 'buyer_signature',
						visible: false,
						title:
							'I hereby instruct conveyo to instruct for and on my behalf and at my cost a surveyor related to the property XXX for a XXX survey. I warrant to fully reimburse conveyo for costs incurred in connection therewith.',
						hideNumber: true,
					},
				],
				visible: false,
				title: 'Property survey',
			},
		],
		showTitle: false,
		showCompletedPage: false,
		completeText: 'Submit',
		widthMode: 'static',
	};
};
