import type { FC } from 'react';
import type { IRatingLabel } from './types';
import { RatingLabelBefore, RatingLabelContainer, RatingLabelValue } from './styles';

export const RatingLabel: FC<IRatingLabel> = ({ color, value }) => (
	<RatingLabelContainer>
		<RatingLabelBefore color={color} />
		<RatingLabelValue color={color}>{value}</RatingLabelValue>
	</RatingLabelContainer>
);
