import { useMemo, type FC } from 'react';
import { createFilterOptions } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { noop } from 'lodash';
import type { IRHFInvitationAutocomplete } from './types';
import { RHFAutocomplete } from '.';

const filterOptions = createFilterOptions({
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	stringify: ({ givenNames, lastNames, email, business, branch }: any) =>
		`${givenNames} ${lastNames} ${email} ${business?.name || ''} ${branch?.name || ''}`,
});

const RHFInvitationAutocomplete: FC<IRHFInvitationAutocomplete> = ({
	options: users,
	name,
	setShowNameFields = noop,
	...other
}) => {
	const { watch, setValue, clearErrors } = useFormContext();
	const email = watch('email');
	const filteredUsers = useMemo(() => users.filter((user) => user.email === email), [users, email]);

	const handleEmailChange = (e) => {
		const hasUserWithPreviousEmail = users.some((user) => user.email === email);

		setShowNameFields(true);
		setValue('email', e);

		if (hasUserWithPreviousEmail) {
			setValue('givenNames', '');
			setValue('lastNames', '');
		}
	};
	const handleUserClick = (user) => {
		clearErrors('email');
		setShowNameFields(false);
		setValue('email', user.email);
		setValue('givenNames', user.givenNames);
		setValue('lastNames', user.lastNames);
	};

	return (
		<RHFAutocomplete
			onInputChange={(_, value) => handleEmailChange(value)}
			onChange={(_, value) => handleUserClick(value)}
			filterOptions={filterOptions}
			options={filteredUsers}
			name={name}
			value={filteredUsers.find((user) => user.email === email)}
			getOptionLabel={(user) => user.email}
			{...other}
		/>
	);
};

export default RHFInvitationAutocomplete;
