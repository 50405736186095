import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
// hooks
import { usePhotoRef } from 'src/hooks/usePhotoRef';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
	const userData = useSelector(selectUser);
	const link = usePhotoRef(userData?.profilePhoto);

	return (
		<Avatar
			src={link || ''}
			alt={userData.givenNames}
			color={
				link
					? 'default'
					: (createAvatar(userData.givenNames).color as
							| 'default'
							| 'primary'
							| 'secondary'
							| 'error'
							| 'warning'
							| 'info'
							| 'success')
			}
			{...other}
		>
			{createAvatar(userData.givenNames).name}
		</Avatar>
	);
}
