import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { noop } from 'lodash';
import { dispatch } from 'src/redux/store';
import {
	selectTransactionOverview,
	setCurrentTransactionTab,
	updateTransactionOnboarding,
} from 'src/redux/slices/transaction';
import { OnboardingStepStatusEnum } from 'src/types';
import { useSelector } from 'react-redux';
import { ConfirmationModal } from '../../common';

const TransactionCompletionModal = () => {
	const transaction = useSelector(selectTransactionOverview);

	const handleConfirm = async () => {
		dispatch(setCurrentTransactionTab('participants'));
		await dispatch(updateTransactionOnboarding({ completion: OnboardingStepStatusEnum.PASSED }));
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '650px' }}
			modalName={transactionOnboardingSteps.completion}
			title={`ADD ${transaction.isInstrument ? 'REMORTGAGE' : 'TRANSACTION'}`}
			withCancelButton={false}
			confirmText='Continue'
			handleConfirm={handleConfirm}
			handleClose={noop}
			description={
				<>
					{transaction.isInstrument ? (
						<>
							Thank you! You've successfully created a new mortgage request.
							<br />
							Next, you'll be taken to "Add Participants" to invite any additional parties.
						</>
					) : (
						<>
							Thank you! The transaction creation process is now complete.
							<br />
							Next, you'll be taken to <b>“Add Participants”</b> to invite the transaction parties.
						</>
					)}
				</>
			}
		/>
	);
};

export default TransactionCompletionModal;
