import { QuestionnaireStatus } from 'src/types';
import { QuestionnairePropertyEnum, QuestionnaireTransactionEnum } from 'src/utils/types';

export const defaultQuestionnaireData = {
	[QuestionnairePropertyEnum.property_details]: {
		common: {
			construction_age: 'England and Wales: 1930-1949',
			floor_height: '2.35',
			tenure: 'Rented (social)',
		},
		property_details: {
			built_form: 'End-Terrace',
			property_rooms: 0,
			property_type: 'House',
		},
		status: QuestionnaireStatus.NOT_STARTED,
	},
	[QuestionnaireTransactionEnum.buyer_questions]: {
		status: QuestionnaireStatus.NOT_STARTED,
	},
	[QuestionnairePropertyEnum.ownership]: {
		status: QuestionnaireStatus.NOT_STARTED,
	},
};
