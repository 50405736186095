import { collections, importFirestore, invitationStatuses, type Firestore } from './@firestore';
import { getMyRef } from './get-my-ref';

export async function getEstateAgentOffers(firestore: Firestore, uid?: string, isAccept = true) {
	const { doc, collection, getDocs, query, where } = importFirestore();
	const { docs: transactions } = await getDocs(
		query(
			collection(firestore, collections.transactions),
			where(
				'parties.estate_agent.userRef',
				'==',
				uid === undefined ? getMyRef(firestore) : doc(firestore, collections.users, uid),
			),
			where('parties.estate_agent.approved', '==', true),
		),
	);

	// condition to avoid firebase empty query 'in' value error
	if (!transactions.length) return [];

	const { docs } = await getDocs(
		query(
			collection(firestore, collections.transactionInvitations),
			where('status', 'in', [invitationStatuses.request, ...(isAccept ? [invitationStatuses.accepted] : [])]),
			where('isVisible', '==', true),
			where('isProspective', '==', true),
			where('invitationRole', '==', 'Buyer'),
			where(
				'transaction',
				'in',
				transactions.map((v) => v.ref),
			),
		),
	);
	return docs;
}
