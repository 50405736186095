import { isArray, mergeWith } from 'lodash';
import { QuestionnaireRestriction } from 'src/redux/slices/permissions';

// eslint-disable-next-line consistent-return
const customizer = (objValue, srcValue) => {
	if (isArray(objValue)) return srcValue;
};

export const mergeQuestionnaireUpdateData = (obj1, obj2) => mergeWith({}, obj1, obj2, customizer);

export const isQuestionnaireOpenable = (restriction: QuestionnaireRestriction) =>
	restriction === 'edit' || restriction === 'view';

export const isQuestionnaireEditable = (restriction: QuestionnaireRestriction) => restriction === 'edit';
