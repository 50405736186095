import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { buildingsWork } from 'src/constants/surveyJson/buildingsWork';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnairePropertyEnum } from 'src/utils/types';
import { selectTransactionOverviewField } from 'src/redux/slices/transaction';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { DescriptionBlock } from '../styles';
import { useQuestionnaire } from '../useQuestionnaire';
import { BuildingsWorkEnum } from './types';

const QuestionnaireBuildingWorksCard = () => {
	const { fullQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const { data: transaction } = useSelector(selectTransactionOverviewField);
	const { onSubmit, onPageChanged, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnairePropertyEnum.building_works,
		questionnaireType: QuestionnaireTypeEnum.PROPERTY,
	});

	const completedPropertyBuildingWorks = fillQuestionnaireJsonWithCompletedData(
		buildingsWork(transaction?.tenure, fullQuestionnaire),
		fullQuestionnaire?.building_works,
	);

	if (isHidden) return null;

	return (
		<>
			<QuestionnarieModal
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				initialCurrentPage={fullQuestionnaire?.building_works.currentPage}
				questionnaireKey={QuestionnairePropertyEnum.building_works}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				surveyJson={completedPropertyBuildingWorks}
				status={fullQuestionnaire?.building_works.status}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				title='Building Works'
				type={QuestionnairePropertyEnum.building_works}
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			>
				{({ details }) => {
					const alterationWorks = details?.alterations_works || { alterations: Object.keys(details.common || {}) };

					return (
						<DescriptionBlock>
							{alterationWorks.alterations
								?.map((val, index) =>
									index === 0 ? capitalize(BuildingsWorkEnum[val] || val) : BuildingsWorkEnum[val] || val,
								)
								.join(', ')}
						</DescriptionBlock>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default QuestionnaireBuildingWorksCard;
