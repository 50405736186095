export const FONT_FAMILY = ['Arial', 'Tahoma', 'Georgia', 'Impact', 'Verdana'];

export const FONT_SIZE = [
	'8px',
	'9px',
	'10px',
	'12px',
	'14px',
	'16px',
	'20px',
	'24px',
	'32px',
	'42px',
	'54px',
	'68px',
	'84px',
	'98px',
];

export const HEADINGS = ['Heading 1', 'Heading 2', 'Heading 3', 'Heading 4', 'Heading 5', 'Heading 6'];

export const formats = [
	'align',
	'background',
	'blockquote',
	'bold',
	'bullet',
	'code',
	'code-block',
	'color',
	'direction',
	'font',
	'formula',
	'header',
	'image',
	'indent',
	'italic',
	'link',
	'list',
	'script',
	'size',
	'strike',
	'table',
	'underline',
	'video',
];
