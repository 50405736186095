import { handleCloseModal } from 'src/utils/propertyHelpers';
import { dispatch, useSelector } from 'src/redux/store';
import { confirmRejectedOffer } from 'src/redux/slices/transaction';
import { selectModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { ConfirmationModal } from 'src/components/modals';
import { IStateTransactionOffer } from 'src/redux/types';
import { noop } from 'lodash';
import useLoading from 'src/hooks/useLoading';

export const OfferRejectedModal = () => {
	const { payload } = useSelector(selectModal<{ offer: IStateTransactionOffer }>);
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(confirmRejectedOffer({ offerId: payload.offer.id }));

		setIsLoading(false);
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			handleClose={noop}
			sx={{ minWidth: '550px' }}
			modalName={modals.offerRejected}
			title='OFFER REJECTED'
			description={
				<>
					Unfortunately, your recent offer was not accepted. <br />
					<br />
					Don't be discouraged - try submitting a higher bid! Good luck!
				</>
			}
			withCancelButton={false}
			confirmText='Continue'
			handleConfirm={handleConfirm}
			isLoading={isLoading}
		/>
	);
};
