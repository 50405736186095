import { Theme } from '@mui/material/styles';

export default function Accordion(theme: Theme) {
	return {
		MuiAccordion: {
			styleOverrides: {
				root: {
					'&.Mui-expanded': {
						borderRadius: theme.shape.borderRadius,
					},
					'&.Mui-disabled': {
						backgroundColor: 'transparent',
					},

					'&:before': {
						display: 'none',
					},

					'&&': {
						margin: 0,
					},
				},
			},
		},
		MuiAccordionDetails: {
			styleOverrides: {
				root: {
					padding: '0',
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					paddingRight: theme.spacing(1),
					paddingLeft: 0,
					'&.Mui-disabled': {
						opacity: 1,
						color: theme.palette.action.disabled,
						'& .MuiTypography-root': {
							color: 'inherit',
						},
					},

					'&&': {
						minHeight: 'fit-content',
					},
				},
				expandIconWrapper: {
					color: 'inherit',
				},
				content: {
					'&&': {
						margin: 0,
						marginLeft: '8px',
					},
				},
			},
		},
	};
}
