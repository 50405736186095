import { FC } from 'react';
import moment from 'moment';
import { formatBidValue } from 'src/utils/formatBid';
import { PriceTooltipProps } from './types';

const PriceTooltip: FC<PriceTooltipProps> = ({ date, type, price }) => (
	<div>
		<div>{moment(date).format('YYYY/MM/DD')}</div>
		<div>
			{type === 'ask' ? 'Ask' : 'Paid'} Price: <b>£ {formatBidValue(price * 1000)}</b>
		</div>
	</div>
);

export default PriceTooltip;
