import { useLocation } from 'react-router';
import { DocumentLocation } from 'src/redux/types/documents';

const useCurrentDocumentsLocation = (): DocumentLocation => {
	const location = useLocation();

	return location.pathname.startsWith('/transactions') ? 'transaction' : 'property';
};

export default useCurrentDocumentsLocation;
