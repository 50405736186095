import { useEffect, useState } from 'react';
import { getPhotoUrlFromBucket } from 'src/utils/getPhotoUrlFromBucket';

export const usePhotoRef = (photo?: IProfilePhoto) => {
	const [link, setLink] = useState<null | string>(null);

	useEffect(() => {
		let url;
		(async () => {
			if (photo) {
				url = await getPhotoUrlFromBucket(photo);
				setLink(url);
			}
		})();

		return () => {
			if (url) URL.revokeObjectURL(url);
		};
	}, [photo]);

	return link;
};
