import { DocumentSnapshot, getDocs, query, where } from 'firebase/firestore';
import { userChangeEmailRequestsCollection } from 'src/constants/firestore';
import { UserChangeEmailRequest } from 'src/types';
import { getUserRef } from '..';

export const findUserChangeEmailRequest = async (
	userId: string,
): Promise<DocumentSnapshot<UserChangeEmailRequest> | null> => {
	const { docs } = await getDocs(query(userChangeEmailRequestsCollection, where('userRef', '==', getUserRef(userId))));

	return docs[0] ?? null;
};
