/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ModalState } from '../types/modal';
import { RootState } from '../store';

// ----------------------------------------------------------------------

const initialState: ModalState = {
	isOpened: false,
	modalName: '',
	payload: {},
};

const slice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		openModal: (state, action: PayloadAction<{ name: string; payload?: Record<string, any> }>) => {
			state.modalName = action.payload.name;
			if (action.payload.payload) state.payload = action.payload.payload;
			state.isOpened = true;
		},
		closeModal: (state) => {
			state.modalName = '';
			state.isOpened = false;
			state.payload = {};
		},
	},
});

export const selectModal = <T extends Record<string, any> = Record<string, any>>(state: RootState) =>
	state.modal as ModalState<T>;

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;
