import { dispatch } from 'src/redux/store';
import { updatePropertyOnboarding } from 'src/redux/slices/property';
import useLoading from 'src/hooks/useLoading';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
import { OnboardingStepStatusEnum, VerificationSessionStatus } from 'src/types';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { ConfirmationModal } from '../../common';

const PropertyIntroductionModal = () => {
	const user = useSelector(selectUser);
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(
			updatePropertyOnboarding({
				activeSide: 'buy',
				moveToNextModal: true,
				payload: { introduction: OnboardingStepStatusEnum.PASSED },
			}),
		);

		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			modalName={propertyOnboardingSteps.introduction}
			isLoading={isLoading}
			title='BUY PROPERTY'
			withCancelButton={false}
			confirmText='Continue'
			handleConfirm={handleConfirm}
			description={
				<>
					To help you buy this property, Conveyo needs to{' '}
					{user.verificationStatus !== VerificationSessionStatus.VERIFIED && 'verify your identity and'} obtain the
					property title from Land Registry.
					<br />
					<br /> Once these steps are complete, Conveyo will guide you through the property questionnaires, ensuring all
					necessary information is provided.
					<br />
					<br /> This service comes with a one-time fee of <b>£9.99</b>. Our team is always here to assist you.
					<br />
					<br /> If you'd like to work on your transaction with one of our partner solicitors,{' '}
					<a href='https://www.conveyo.io/quotes' target='_blank' rel='noreferrer'>
						click here to get a quote
					</a>
					.
				</>
			}
		/>
	);
};

export default PropertyIntroductionModal;
