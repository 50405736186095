import { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import { List, Collapse, Link } from '@mui/material';
import NavItem from './NavItem';
import { getActive, isExternalLink } from '..';
import { INavList } from './types';

// ----------------------------------------------------------------------

const NavList: FC<INavList> = ({ data, depth, hasChildren, isCollapse = false }) => {
	const navigate = useNavigate();

	const { pathname } = useLocation();

	const active = getActive(data.path as string, pathname);

	const [open, setOpen] = useState(active);

	const toItemPage = () => navigate(data.path || '');

	const onClickOpen = () => {
		if (hasChildren) setOpen(!open);
	};
	return (
		<>
			{isExternalLink(data.path || '') ? (
				<Link href={data.path} target='_blank' rel='noopener' underline='none'>
					<NavItem item={data} depth={depth} open={open} active={active} isCollapse={isCollapse} />
				</Link>
			) : (
				<NavItem
					item={data}
					depth={depth}
					open={open}
					active={active}
					isCollapse={isCollapse}
					onClick={onClickOpen}
					toItemPage={toItemPage}
				/>
			)}

			{!isCollapse && hasChildren && (
				<Collapse in={open} unmountOnExit>
					<List component='div' disablePadding>
						{(data.children || []).map((list) => (
							<NavList
								key={list.title + list.path}
								data={list}
								depth={list.depth || depth + 1}
								hasChildren={!!list.children}
							/>
						))}
					</List>
				</Collapse>
			)}
		</>
	);
};

export default NavList;
