import { ReactNode, useState } from 'react';
import { StyledAlert } from './styled';

export default function useError() {
	const [error, setError] = useState<ReactNode | null>(null);

	return {
		error: error ? (
			<StyledAlert variant='filled' severity='error'>
				{error}
			</StyledAlert>
		) : null,
		setError,
	};
}
