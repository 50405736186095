import { ModalProps } from '@mui/material';

export const brandModalSlotProps: ModalProps['slotProps'] = {
	backdrop: { style: { background: 'rgba(0, 0, 0, 0.55)', backdropFilter: 'blur(1.5px)' } },
	root: {
		style: {
			display: 'flex',
			overflow: 'auto',
			padding: '50px 0',
			width: '100%',
			height: '100%',
			scrollbarWidth: 'none',
		},
	},
};
