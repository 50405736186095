import { FC } from 'react';
import { Box } from '@mui/material';
import { transactionRoles } from 'src/pages/transactions/constants';
import { RolePermissionsPickerProps } from './types';
import { PermissionsCheckbox } from '../../styled';

const RolePermissionsPicker: FC<RolePermissionsPickerProps> = ({ side, role, action }) => (
	<Box pl='20px'>
		<PermissionsCheckbox name={`${action}.${side}.${role}.selected`} label={transactionRoles[role].name} />
	</Box>
);

export default RolePermissionsPicker;
