import { Theme } from '@mui/material/styles';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from './CustomIcons';

// ----------------------------------------------------------------------

export default function Alert(theme: Theme) {
	const isLight = theme.palette.mode === 'light';

	return {
		MuiAlert: {
			defaultProps: {
				iconMapping: {
					info: <InfoIcon />,
					success: <SuccessIcon />,
					warning: <WarningIcon />,
					error: <ErrorIcon />,
				},
			},

			styleOverrides: {
				message: {
					'& .MuiAlertTitle-root': {
						marginBottom: theme.spacing(0.5),
					},
				},
				action: {
					'& button:not(:first-of-type)': {
						marginLeft: theme.spacing(1),
					},
				},

				standardInfo: {
					color: theme.palette.info[isLight ? 'darker' : 'lighter'],
					backgroundColor: theme.palette.info[isLight ? 'lighter' : 'darker'],
					'& .MuiAlert-icon': {
						color: theme.palette.info[isLight ? 'main' : 'light'],
					},
				},
				standardSuccess: {
					color: theme.palette.success[isLight ? 'darker' : 'lighter'],
					backgroundColor: theme.palette.success[isLight ? 'lighter' : 'darker'],
					'& .MuiAlert-icon': {
						color: theme.palette.success[isLight ? 'main' : 'light'],
					},
				},
				standardWarning: {
					color: theme.palette.warning[isLight ? 'darker' : 'lighter'],
					backgroundColor: theme.palette.warning[isLight ? 'lighter' : 'darker'],
					'& .MuiAlert-icon': {
						color: theme.palette.warning[isLight ? 'main' : 'light'],
					},
				},
				standardError: {
					color: theme.palette.error[isLight ? 'darker' : 'lighter'],
					backgroundColor: theme.palette.error[isLight ? 'lighter' : 'darker'],
					'& .MuiAlert-icon': {
						color: theme.palette.error[isLight ? 'main' : 'light'],
					},
				},

				filledInfo: { color: theme.palette.info.contrastText },
				filledSuccess: { color: theme.palette.success.contrastText },
				filledWarning: { color: theme.palette.warning.contrastText },
				filledError: { color: theme.palette.error.contrastText },

				outlinedInfo: {
					color: theme.palette.info[isLight ? 'darker' : 'lighter'],
					border: `solid 1px ${theme.palette.info[isLight ? 'light' : 'dark']}`,
					backgroundColor: theme.palette.info[isLight ? 'lighter' : 'darker'],
					'& .MuiAlert-icon': {
						color: theme.palette.info[isLight ? 'main' : 'light'],
					},
				},
				outlinedSuccess: {
					color: theme.palette.success[isLight ? 'darker' : 'lighter'],
					border: `solid 1px ${theme.palette.success[isLight ? 'light' : 'dark']}`,
					backgroundColor: theme.palette.success[isLight ? 'lighter' : 'darker'],
					'& .MuiAlert-icon': {
						color: theme.palette.success[isLight ? 'main' : 'light'],
					},
				},
				outlinedWarning: {
					color: theme.palette.warning[isLight ? 'darker' : 'lighter'],
					border: `solid 1px ${theme.palette.warning[isLight ? 'light' : 'dark']}`,
					backgroundColor: theme.palette.warning[isLight ? 'lighter' : 'darker'],
					'& .MuiAlert-icon': {
						color: theme.palette.warning[isLight ? 'main' : 'light'],
					},
				},
				outlinedError: {
					color: theme.palette.error[isLight ? 'darker' : 'lighter'],
					border: `solid 1px ${theme.palette.error[isLight ? 'light' : 'dark']}`,
					backgroundColor: theme.palette.error[isLight ? 'lighter' : 'darker'],
					'& .MuiAlert-icon': {
						color: theme.palette.error[isLight ? 'main' : 'light'],
					},
				},
			},
		},
	};
}
