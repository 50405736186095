import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { transactionState } from 'src/components/modals/transaction/UpdateTransactionState/constants';
import {
	transactionStatus,
	transactionStatusIcon,
	valueByTransactionStatus,
} from 'src/components/TransactionSummary/constants';
import { TransactionSummaryByColumns } from 'src/pages/dashboard/types/summary';
import { ITransactionSummary } from 'src/redux/types';
import { PATH_TRANSACTIONS } from 'src/routes/paths';
import { formatDate, formatDaysDuration, formatPercent, formatPrice, formatUsers } from 'src/utils/transaction/summary';

const getTransactionIconColorByDaysCount = ({ status, days }: ITransactionSummary['status']) => {
	if (typeof days !== 'number') return 'green';

	if (status === transactionStatus.inProgress) {
		if (days >= 30) return '#e9d01a';
		if (days >= 15) return '#9eeb33';

		return 'green';
	}

	if (status === transactionStatus.pending) {
		if (days >= 125) return 'red';
		if (days >= 75) return '#ea5239';

		return '#e77534';
	}

	return 'green';
};

const calculateSummaryStatusValue = (summary: ITransactionSummary) => {
	const statusValue = valueByTransactionStatus[summary.status.status];

	if (summary.general.state === transactionState.standBy)
		return (summary.frozenDays ? statusValue + summary.frozenDays : statusValue) / 100_000_000;
	if (typeof summary.status.days === 'number') return statusValue + summary.status.days / 100_000_000;

	return statusValue;
};

export const convertSummaryToObjectByColumns = (summary: ITransactionSummary): TransactionSummaryByColumns => {
	const { general, sellSide, buySide, timeFromInstructionToSaleAgreed, timeFromSaleAgreedToExchange, duration } =
		summary;

	const statusIcon = transactionStatusIcon[summary.status.status];

	const statusLabel =
		summary.general.state === 'WITHDRAWAL' ? (
			<Typography textAlign='center' fontSize='25px'>
				💀
			</Typography>
		) : summary.general.state === 'FALL_THROUGH' ? (
			<Typography textAlign='center' fontSize='25px'>
				💔
			</Typography>
		) : summary.general.state === 'DEFUNCT' ? (
			<Typography textAlign='center' fontSize='25px'>
				💸
			</Typography>
		) : summary.general.state === 'DEMO' ? (
			<Typography textAlign='center' fontSize='25px'>
				🎠
			</Typography>
		) : summary.general.state === 'STANDBY' ? (
			<Typography fontWeight={900} borderRadius='50%' textAlign='center' fontSize='20px'>
				{summary.frozenDays ?? '-'}
			</Typography>
		) : typeof summary.status.days === 'number' ? (
			<Typography
				sx={{ color: getTransactionIconColorByDaysCount(summary.status) }}
				fontWeight={900}
				borderRadius='50%'
				textAlign='center'
				fontSize='20px'
			>
				{summary.status.days}
			</Typography>
		) : (
			<Typography textAlign='center' fontSize='25px'>
				{statusIcon}
			</Typography>
		);

	return {
		isPinned: summary.isPinned,
		transactionId: summary.transactionId,
		status: {
			value: calculateSummaryStatusValue(summary),
			label: statusLabel,
		},
		name: {
			value: general.displayName,
			label: (
				<span>
					<Link to={PATH_TRANSACTIONS.view(summary.transactionId)}>{general.displayName}</Link>
				</span>
			),
		},
		postcode: {
			value: general.postcode,
			label: general.postcode,
		},
		uprn: {
			value: general.uprn,
			label: general.uprn,
		},
		// trackerStatus: {
		// 	value: general.trackerStep,
		// 	label: labelByTrackerStepName[general.trackerStep],
		// },
		timeFromInstructionToSaleAgreed: {
			value: timeFromInstructionToSaleAgreed,
			label: formatDaysDuration(timeFromInstructionToSaleAgreed),
		},
		timeFromSaleAgreedToExchange: {
			value: timeFromSaleAgreedToExchange,
			label: formatDaysDuration(timeFromSaleAgreedToExchange),
		},
		duration: {
			value: duration,
			label: formatDaysDuration(duration),
		},
		createdDate: {
			value: general.createdDate,
			label: formatDate(general.createdDate),
		},
		instructionDate: {
			value: general.instructionDate,
			label: formatDate(general.instructionDate),
		},
		sellerDate: {
			value: general.sellerDate,
			label: formatDate(general.sellerDate),
		},
		propertyDate: {
			value: general.propertyDate,
			label: formatDate(general.propertyDate),
		},
		buyerDate: {
			value: general.buyerDate,
			label: formatDate(general.buyerDate),
		},
		legalsDate: {
			value: general.legalsDate,
			label: formatDate(general.legalsDate),
		},
		askPrice: {
			value: general.askOffer?.price,
			label: formatPrice(general.askOffer?.price),
		},
		ourMargin: {
			value: general.margin,
			label: formatPrice(general.margin.withVat),
		},
		sellers: {
			value: sellSide.sellers,
			label: formatUsers(sellSide.sellers),
		},
		sellerQuote: {
			value: sellSide.quote?.withVat,
			label: formatPrice(sellSide.quote?.withVat),
		},
		sellSideAgents: {
			value: sellSide.agents,
			label: formatUsers(sellSide.agents),
		},
		sellSideAgencyRate: {
			value: sellSide.agencyRate?.withVat,
			label: formatPercent(sellSide.agencyRate?.withVat),
		},
		sellSideAgencyFee: {
			value: sellSide.agencyFee?.withVat,
			label: formatPrice(sellSide.agencyFee?.withVat),
		},
		sellSideReferralFee: {
			value: sellSide.referralFee?.withVat,
			label: formatPrice(sellSide.referralFee?.withVat),
		},
		sellSideSolicitors: {
			value: sellSide.solicitors,
			label: formatUsers(sellSide.solicitors),
		},
		sellSideLegalFee: {
			value: sellSide.legalFee?.withVat,
			label: formatPrice(sellSide.legalFee?.withVat),
		},
		buyers: {
			value: buySide.buyers,
			label: formatUsers(buySide.buyers),
		},
		buyerQuote: {
			value: buySide.quote?.withVat,
			label: formatPrice(buySide.quote?.withVat),
		},
		buySideAgents: {
			value: buySide.agents,
			label: formatUsers(buySide.agents),
		},
		buySideAgencyRate: {
			value: buySide.agencyRate?.withVat,
			label: formatPercent(buySide.agencyRate?.withVat),
		},
		buySideAgencyFee: {
			value: buySide.agencyFee?.withVat,
			label: formatPrice(buySide.agencyFee?.withVat),
		},
		buySideReferralFee: {
			value: buySide.referralFee?.withVat,
			label: formatPrice(buySide.referralFee?.withVat),
		},
		buySideSolicitors: {
			value: buySide.solicitors,
			label: formatUsers(buySide.solicitors),
		},
		buySideLegalFee: {
			value: buySide.legalFee?.withVat,
			label: formatPrice(buySide.legalFee?.withVat),
		},
		latestActionDate: {
			value: general.latestActionDate,
			label: formatDate(general.latestActionDate, 'YYYY-MM-DD hh:mm A'),
		},
	};
};
