import Iconify from 'src/components/Iconify';
import { useDispatch } from 'src/redux/store';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { pinProperty, selectPropertySlice } from 'src/redux/slices/property';
import { getNav } from 'src/utils/propertyHelpers';
import { selectMenuLoading, setLoading, unsetLoading } from 'src/redux/slices/headerSlice';
import IconWithLoading from 'src/components/IconWithLoading';
import { sellProperty } from 'src/api/property';
import { PATH_TRANSACTIONS } from 'src/routes/paths';
import { useMemo } from 'react';
import { selectUserSettings } from 'src/redux/slices/auth';
import { selectDocumentsField } from 'src/redux/slices/documents';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { ActionButtonItem, ActionButtons } from '../styles';

const AdminPropertyActionButtons = () => {
	const { id = '' } = useParams();
	const isLoading = useSelector(selectMenuLoading);
	const settings = useSelector(selectUserSettings);
	const { isInitialized: areDocumentsInitialized } = useSelector(selectDocumentsField);
	const { currentTab } = useSelector(selectPropertySlice);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isPinned = useMemo(() => settings.pinnedProperties.some((p) => p.id === id), [settings.pinnedProperties, id]);

	const isDocuments = currentTab === 'documents';

	const handlePin = async () => {
		await dispatch(pinProperty({ id, isPinned }));
		getNav();
	};

	const handlePutPropertyOnSale = async () => {
		dispatch(setLoading());

		const transactionId = await sellProperty({ propertyId: id });

		navigate(PATH_TRANSACTIONS.view(transactionId));
		getNav();

		dispatch(unsetLoading());
	};

	const handleAddFolder = () => dispatch(openModal({ name: modals.addDocumentsFolder }));
	// const handleCreateDocumentsStructure = async () => {
	// 	setIsDocumentsStructureLoading(true);

	// 	await dispatch(createInitialDocumentsStructureThunk({ location }));

	// 	setIsDocumentsStructureLoading(false);
	// };

	return (
		<ActionButtons>
			<ActionButtonItem onClick={handlePin} disabled={isLoading}>
				<IconWithLoading isLoading={isLoading}>
					<Iconify icon={isPinned ? 'lucide:pin-off' : 'lucide:pin'} fontSize={25} />
				</IconWithLoading>
				{isPinned ? 'Unpin' : 'Pin'}
			</ActionButtonItem>
			<ActionButtonItem onClick={handlePutPropertyOnSale} disabled={isLoading}>
				<IconWithLoading isLoading={isLoading}>
					<Iconify icon='material-symbols:sell' fontSize={18} />
				</IconWithLoading>
				Put on Sale
			</ActionButtonItem>
			{isDocuments && areDocumentsInitialized && (
				<ActionButtonItem onClick={handleAddFolder} disabled={isLoading}>
					<IconWithLoading isLoading={isLoading}>
						<Iconify icon='material-symbols:add-circle-outline-rounded' fontSize={18} />
					</IconWithLoading>
					Add Folder
				</ActionButtonItem>
			)}
			{/* {isDocuments && user.isAdmin && (
				<ActionButtonItem onClick={handleCreateDocumentsStructure} disabled={isDocumentsStructureLoading}>
					<IconWithLoading isLoading={isDocumentsStructureLoading}>
						<Iconify icon='solar:document-bold' fontSize={18} />
					</IconWithLoading>
					Create Documents Structure
				</ActionButtonItem>
			)} */}
		</ActionButtons>
	);
};

export default AdminPropertyActionButtons;
