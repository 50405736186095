import moment from 'moment';
import { MonthlyReport, SortableRow } from 'src/redux/types';
import { formatPrice } from 'src/utils/transaction/summary';

export const getMonthlyReportSortableRow = (row: MonthlyReport): SortableRow<MonthlyReport> => ({
	date: { label: moment(row.date).format('YYYY MM'), value: new Date(row.date).getTime() },
	newTransactions: { label: row.newTransactions, value: row.newTransactions },
	withdrawals: { label: row.withdrawals, value: row.withdrawals },
	completions: { label: row.completions, value: row.completions },
	liveTransactions: { label: row.liveTransactions, value: row.liveTransactions },
	newInstructions: { label: row.newInstructions, value: row.newInstructions },
	defunct: { label: row.defunct, value: row.defunct },
	openCash: { label: formatPrice(row.openCash), value: row.openCash },
	closedCash: { label: formatPrice(row.closedCash), value: row.closedCash },
	oldCash: { label: formatPrice(row.oldCash), value: row.oldCash },
});
