import { call } from '../@';

type Payload = {
	transactionId: string;
	relationship?: string;
	role: string;
	side: string;
	givenNames: string;
	lastNames: string;
	email?: string;
	phone?: string;
	business?: string;
	branch?: string;
	quote?: number | null;
	address?: string;
};

export default async function addTransactionPlaceholder(payload: Payload) {
	return await call('transaction-addPlaceholder', payload);
}
