import { handleCloseModal } from 'src/utils/propertyHelpers';
import { dispatch, useSelector } from 'src/redux/store';
import { acceptCounteroffer } from 'src/redux/slices/transaction';
import { selectModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { ConfirmationModal } from 'src/components/modals';
import { IStateTransactionOffer } from 'src/redux/types';
import { noop } from 'lodash';
import useLoading from 'src/hooks/useLoading';
import { NoBreak } from 'src/styled';
import { formatBidValue } from 'src/utils/formatBid';
import { useSnackbar } from 'notistack';

const Content = () => {
	const { payload } = useSelector(selectModal<{ offer: IStateTransactionOffer }>);

	return (
		<>
			By clicking "Confirm", you agree to this transaction for an agreed price of{' '}
			<NoBreak as='b'>£{formatBidValue(`${payload.offer.price}`)}</NoBreak>.
		</>
	);
};

export const AcceptCounterofferModal = () => {
	const { payload } = useSelector(selectModal<{ offer: IStateTransactionOffer }>);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(acceptCounteroffer({ offerId: payload.offer.id }));

		enqueueSnackbar('Counteroffer accepted!');
		setIsLoading(false);
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			handleClose={noop}
			sx={{ minWidth: '550px' }}
			modalName={modals.acceptCounteroffer}
			title='ACCEPT COUNTER OFFER'
			description={<Content />}
			confirmText='Continue'
			handleConfirm={handleConfirm}
			cancelText='Cancel'
			isLoading={isLoading}
		/>
	);
};
