import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
// components
import MyAvatar from '../../../components/MyAvatar';
import type { INavbarAccount } from './types';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(2, 2.5),
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	// backgroundColor: theme.palette.info.lighter,
	backgroundColor: '#F5F8FF',
	transition: theme.transitions.create('opacity', {
		duration: theme.transitions.duration.shorter,
	}),
}));

// ----------------------------------------------------------------------

const NavbarAccount: FC<INavbarAccount> = ({ isCollapse }) => {
	const userData = useSelector(selectUser);
	const user = {
		displayName: `${userData.givenNames} ${userData.lastNames}`,
		profession: userData?.professionName,
	};

	return (
		<>
			<RootStyle
				sx={{
					maxWidth: '100%',
					...(isCollapse && {
						bgcolor: 'transparent',
					}),
				}}
			>
				<MyAvatar />

				<Box
					sx={{
						ml: 2,
						maxWidth: '70%',
						transition: (theme) =>
							theme.transitions.create(['opacity', 'width'], {
								duration: theme.transitions.duration.shorter,
							}),
						...(isCollapse && {
							ml: 0,
							width: 0,
							opacity: 0,
						}),
					}}
				>
					<Typography variant='subtitle2'>{user.displayName}</Typography>
					{!userData.isPrincipal && (
						<Typography variant='body2' sx={{ color: 'text.secondary' }}>
							{user.profession}
						</Typography>
					)}
				</Box>
			</RootStyle>
		</>
	);
};

export default NavbarAccount;
