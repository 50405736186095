import { selectUser, updateUser } from 'src/redux/slices/auth';
import { dispatch, useSelector } from 'src/redux/store';
import { OnboardingStepStatusEnum, VerificationSessionStatus } from 'src/types';
import { useSnackbar } from 'notistack';
import useLoading from 'src/hooks/useLoading';
import { extractFullAddressFromVerificationDocument, getFullVerificationAddress } from 'src/utils/verification-session';
import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { updateTransactionOnboarding } from 'src/redux/slices/transaction';
import { noop } from 'lodash';
import { getAddressVerificationDocument } from 'src/utils/verification';
import { FC } from 'react';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { updatePropertyOnboarding } from 'src/redux/slices/property';
import { ConfirmationModal } from '..';
import { VerificationAddressConfirmationModalProps } from './types';
import VerificationInternalIssueModal from '../VerificationInternalIssue';

const VerificationAddressConfirmationModal: FC<VerificationAddressConfirmationModalProps> = ({ isProperty }) => {
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const user = useSelector(selectUser);

	if (!user.verificationSession) return null;

	const document = user.verificationSession ? getAddressVerificationDocument(user.verificationSession) : null;

	const modalName = isProperty
		? propertyOnboardingSteps.addressConfirmation
		: transactionOnboardingSteps.addressConfirmation;

	if (!document) return <VerificationInternalIssueModal name={modalName} />;

	const handleConfirm = async () => {
		if (!document.structured_postal_address) return;

		setIsLoading(true);

		const fullAddress = await getFullVerificationAddress(document.structured_postal_address);

		if (!fullAddress) {
			enqueueSnackbar('Google Maps Address not found. Contact us please');
			setIsLoading(false);

			return;
		}

		await Promise.all([
			isProperty
				? dispatch(
						updatePropertyOnboarding({
							payload: {
								addressConfirmation: OnboardingStepStatusEnum.PASSED,
								addressIssue: OnboardingStepStatusEnum.PASSED,
							},
							moveToNextModal: true,
						}),
				  )
				: dispatch(
						updateTransactionOnboarding({
							addressConfirmation: OnboardingStepStatusEnum.PASSED,
							addressIssue: OnboardingStepStatusEnum.PASSED,
						}),
				  ),
			dispatch(updateUser({ isAddressLocked: true, address: fullAddress })),
		]);

		setIsLoading(false);
	};
	const handleCancel = async () => {
		setIsLoading(true);

		await Promise.all([
			isProperty
				? dispatch(
						updatePropertyOnboarding({
							payload: {
								addressConfirmation: OnboardingStepStatusEnum.PASSED,
								addressIssue: OnboardingStepStatusEnum.PENDING,
								verificationIssue: OnboardingStepStatusEnum.PENDING,
							},
							moveToNextModal: true,
						}),
				  )
				: dispatch(
						updateTransactionOnboarding({
							addressConfirmation: OnboardingStepStatusEnum.PASSED,
							addressIssue: OnboardingStepStatusEnum.PENDING,
							verificationIssue: OnboardingStepStatusEnum.PENDING,
						}),
				  ),
			dispatch(
				updateUser({
					verificationStatus:
						user.verificationStatus === VerificationSessionStatus.NAME_MISMATCH
							? VerificationSessionStatus.NAME_ADDRESS_MISMATCH
							: VerificationSessionStatus.ADDRESS_MISMATCH,
				}),
			),
		]);

		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '600px' }}
			modalName={modalName}
			isLoading={isLoading}
			title='CONFIRM ADDRESS'
			description={
				<>
					Your permanent address, as it appears on the provided ID is: <br />
					<b>{extractFullAddressFromVerificationDocument(document)}</b>
					<br /> <br />
					Does this look correct?
				</>
			}
			confirmText='Accept'
			cancelText='Reject'
			confirmDisabled={!document.structured_postal_address?.street}
			handleConfirm={handleConfirm}
			handleCancel={handleCancel}
			handleClose={noop}
		/>
	);
};

export default VerificationAddressConfirmationModal;
