import { useParams } from 'react-router';

import { dispatch, useSelector } from 'src/redux/store';
import {
	getTransactionInvitationMetadataThunk,
	getTransactionInvitationUsers,
	selectTransactionInvitationMetadata,
	selectTransactionInvitationUsers,
} from 'src/redux/slices/transaction';
import { useEffect } from 'react';
import { TransactionRole, TransactionSide } from '../types';

const useTransactionInvitation = () => {
	const { id = '' } = useParams();

	const { data: metadata, isInitialized: isMetadataInitialized } = useSelector(selectTransactionInvitationMetadata);
	const { data: users, isLoading: areUsersLoading } = useSelector(selectTransactionInvitationUsers);

	const fetchUsers = (role: TransactionRole, side: TransactionSide) =>
		dispatch(getTransactionInvitationUsers({ role, side, transactionId: id }));

	useEffect(() => {
		dispatch(getTransactionInvitationMetadataThunk({ transactionId: id }));
	}, []);

	const sides = metadata.roles.reduce<TransactionSide[]>((acc, r) => {
		if (!acc.includes(r.side)) acc.push(r.side);

		return acc;
	}, []);

	return {
		transactionId: id,
		users,
		fetchUsers,
		areUsersLoading,
		isMetadataInitialized,
		metadata,
		sides,
	};
};

export default useTransactionInvitation;
