import { Box, Table, TableBody, TableRow, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Progress from 'src/components/Progress';
import {
	clearTransactionTrail,
	getTransactionTrailThunk,
	selectTransactionOverview,
	selectTransactionTrail,
} from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { TableCell } from 'src/pages/dashboard/styles';
import { LogTypeEnum } from 'src/types';
import { columns } from './constants';
import Head from './components/head';

const TransactionJobs = () => {
	const transaction = useSelector(selectTransactionOverview);
	const trail = useSelector(selectTransactionTrail);

	useEffect(() => {
		dispatch(getTransactionTrailThunk());

		return () => {
			dispatch(clearTransactionTrail());
		};
	}, [transaction.id]);

	if (!trail.isInitialized) {
		return (
			<Box width='100%' height='80%'>
				<Progress sx={{ height: '100%' }} />
			</Box>
		);
	}

	return (
		<Box p='15px' height='100%'>
			<Table>
				<Head />
				<TableBody>
					{trail.data.list
						.filter((l) => l.type === LogTypeEnum.JOB)
						.map((log) => (
							<TableRow key={log.id}>
								{columns.map((column) => (
									<TableCell minWidth={100} maxWidth={100} key={column.key}>
										{column.getContent(log as never)}
									</TableCell>
								))}
							</TableRow>
						))}
				</TableBody>
			</Table>
			{!trail.data.list.length && (
				<Box
					height='75%'
					width='100%'
					display='flex'
					flexDirection='column'
					gap='5px'
					justifyContent='center'
					alignItems='center'
				>
					<img src='/assets/empty-box.jpg' alt='Empty' width='165px' />
					<Typography fontWeight={700} variant='h4'>
						Nothing here yet.
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default TransactionJobs;
