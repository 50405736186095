import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { services } from 'src/constants/surveyJson/services';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnairePropertyEnum } from 'src/utils/types';
import { capitalize } from 'lodash';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { DescriptionBlock } from '../styles';
import { useQuestionnaire } from '../useQuestionnaire';
import { PropertyServicesParkingEnum } from './types';

const snakeCaseToUsual = (arr) => arr.map((val) => val.split('_').join(' ')).join(', ');

const QuestionnaireServicesCard = () => {
	const { fullQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const { onSubmit, onPageChanged, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnairePropertyEnum.services,
		questionnaireType: QuestionnaireTypeEnum.PROPERTY,
	});

	if (isHidden) return null;

	const completedPropertyServices = fillQuestionnaireJsonWithCompletedData(services, fullQuestionnaire?.services);

	return (
		<>
			<QuestionnarieModal
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				initialCurrentPage={fullQuestionnaire?.services.currentPage}
				surveyJson={completedPropertyServices}
				questionnaireKey={QuestionnairePropertyEnum.services}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				status={fullQuestionnaire?.services.status}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				title='Services'
				type={QuestionnairePropertyEnum.services}
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			>
				{({ details }) => {
					const heatingTypes = [
						...(details.heating_page?.central_heating_type || details.heating_page?.common?.central_heating_type || []),
						...(details.heating_page?.direct_heating_type || details.heating_page?.common?.direct_heating_type || []),
					];

					const heatingMeans = [
						...(details.heating_page?.central_heating_means ||
							details.heating_page?.common?.central_heating_means ||
							[]),
					];
					const parkingArrangements = (details?.parking?.parking_arrangements || []).reduce((acc, item, index) => {
						const formattedItem = PropertyServicesParkingEnum[item] || item;

						acc.push(index === 0 ? capitalize(formattedItem) : formattedItem.toLowerCase());

						return acc;
					}, []);

					const heatingFuels = [
						details.heating_page?.boiler_fuel || details.heating_page?.common?.boiler_fuel,
						details.heating_page?.furnace_fuel || details.heating_page?.common?.furnace_fuel,
						details.heating_page?.othercentralheating_fuel || details.heating_page?.common?.othercentralheating_fuel,
					]
						.filter(Boolean)
						.flat();

					return (
						<>
							<DescriptionBlock>
								{heatingTypes.length ? (
									<>
										Heating: <b>{snakeCaseToUsual(heatingTypes)}</b>
									</>
								) : null}
							</DescriptionBlock>
							<DescriptionBlock>
								{heatingFuels.length ? (
									<>
										Source: <b>{snakeCaseToUsual(heatingFuels)}</b>
									</>
								) : null}
							</DescriptionBlock>
							<DescriptionBlock>
								{heatingMeans.length ? (
									<>
										Delivery: <b>{snakeCaseToUsual(heatingMeans)}</b>
									</>
								) : null}
							</DescriptionBlock>
							{details?.services_page?.services ? (
								<>
									<DescriptionBlock style={{ marginTop: '16px' }}>
										Solar panels: <b>{details.services_page.services.includes('solarpanels') ? 'Yes' : 'No'}</b>
									</DescriptionBlock>
									<DescriptionBlock style={{ marginTop: '8px' }}>
										Air conditioning: <b>{details.services_page.services.includes('heatpumps') ? 'Yes' : 'No'}</b>
									</DescriptionBlock>
								</>
							) : null}
							{parkingArrangements.length ? (
								<DescriptionBlock style={{ marginTop: '16px' }}>
									Parking: <b>{parkingArrangements.map(capitalize).join(', ')}</b>
								</DescriptionBlock>
							) : null}
						</>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default QuestionnaireServicesCard;
