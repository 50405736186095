import { call } from '../@';

const cleanUpSessionStorage = () => {
	sessionStorage.removeItem('transactionQrCodeId');
};

export default async function scanTransactionQrCode() {
	const id = sessionStorage.getItem('transactionQrCodeId');

	if (!id) {
		cleanUpSessionStorage();

		return;
	}

	try {
		await call('transaction-scanQrCode', { id });
	} catch (e) {
		console.error(e);
	} finally {
		cleanUpSessionStorage();
	}
}
