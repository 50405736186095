import { FC, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDocuments } from 'src/redux/slices/documents';
import { useFormContext } from 'react-hook-form';
import { Button, Popover, Typography } from '@mui/material';
import { DocumentTree } from 'src/redux/types/documents';
import { FolderLocationPickerProps } from './types';
import { Picker, SelectedPath } from './styled';
import FoldersAccordion from './components/FoldersAccordion';

const FolderLocationPicker: FC<FolderLocationPickerProps> = ({ name }) => {
	const { watch, setValue } = useFormContext();
	const folderId: string = watch(name);
	const { tree } = useSelector(selectDocuments);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [selectedDocument, setSelectedDocument] = useState<DocumentTree | null>(tree);

	const handleOpen = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	const open = !!anchorEl;
	const handleSelect = (document: DocumentTree) => {
		setSelectedDocument(document);
		setValue(name, document.id);
		handleClose();
	};

	return (
		<Picker>
			<SelectedPath>{selectedDocument?.fullName ?? 'Unknown folder'}</SelectedPath>
			<Button onClick={handleOpen}>Select</Button>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				PaperProps={{ sx: { p: '10px 13px' } }}
			>
				<Typography fontWeight={600} fontSize='17px' mb='5px'>
					Select new folder location
				</Typography>
				<FoldersAccordion tree={tree} folderId={folderId} handleSelect={handleSelect} />
			</Popover>
		</Picker>
	);
};

export default FolderLocationPicker;
