import { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { CustomTooltip } from './styles';
import { IInviteCountTooltip } from './types';
import Iconify from '../Iconify';

export const InviteCountTooltip: FC<IInviteCountTooltip> = ({ user }) => {
	const [isOpen, setIsOpen] = useState(false);

	if (!user.invitation) return null;

	return (
		<CustomTooltip
			open={isOpen}
			onMouseEnter={() => setIsOpen(true)}
			onClose={() => setIsOpen(false)}
			title={
				<Box p='10px'>
					<Typography>
						Last invite: <b>{moment(user.invitation.modifiedAt).utc().format('YYYY-MM-DD hh:mmA z')}</b>
					</Typography>
				</Box>
			}
		>
			<Box display='flex' alignItems='center' gap='7px' mt='3px' width='fit-content'>
				<Iconify icon='ri:mail-send-line' fontSize='20px' color='grey' />
				<Typography fontSize='17px' fontWeight={700}>
					{user.invitation.inviteCount}
				</Typography>
			</Box>
		</CustomTooltip>
	);
};
