import { onAuthStateChanged } from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';
import { auth } from 'src/firebaseConfig';
import { getUserRef, getUserSnapshot } from 'src/utils/firebase';
import { dispatch } from 'src/redux/store';
import { setIsAuthenticationLoading } from 'src/redux/slices/auth';
import userData from './@user-data';
import { call } from '../@';
import { startSession } from '../user-session';
import { scanTransactionQrCode } from '../transaction';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionArgs<F> = F extends (...args: infer T) => any ? T : never;

let isSubscribedToUser = false;
const previousAuthState = {
	isInitialized: false,
	isAuthenticated: false,
};
const listeners = new Set<FunctionArgs<typeof onUserDataChange>[0]>();

export default function onUserDataChange(callback: (user: Awaited<ReturnType<typeof userData>> | null) => void) {
	listeners.add(callback);
}

interface ObjectWithUid {
	uid: string;
}

async function userDataChangeCallback(user: ObjectWithUid | null) {
	try {
		const data = user && (await userData(user.uid));

		listeners.forEach(async (cb) => {
			try {
				await cb(data);
			} catch (e) {
				console.error(e);
			}
		});
	} catch (e) {
		console.error(e);
	}
}

onAuthStateChanged(auth, async (user) => {
	try {
		const currentSessionId = sessionStorage.getItem('sessionId');

		if (user && !previousAuthState.isInitialized && !currentSessionId) {
			startSession();
		}

		if (user && previousAuthState.isInitialized && !previousAuthState.isAuthenticated && !currentSessionId) {
			const userSnapshot = await getUserSnapshot(user.uid);

			startSession(true);
			if (userSnapshot.exists()) await scanTransactionQrCode();
		}

		previousAuthState.isAuthenticated = !!user;
		previousAuthState.isInitialized = true;

		if (!user) {
			userDataChangeCallback(null);

			return;
		}

		const userSnapshot = await getUserSnapshot(user.uid);

		if (!userSnapshot.exists()) {
			dispatch(setIsAuthenticationLoading(true));

			await call('auth-registration', {
				uid: user.uid,
				email: user.email as string,
				displayName: user.displayName,
			});
			scanTransactionQrCode();

			dispatch(setIsAuthenticationLoading(false));
		}

		if (user && !isSubscribedToUser) {
			isSubscribedToUser = true;
			onSnapshot(getUserRef(user.uid), () => userDataChangeCallback(user));
		}

		userDataChangeCallback(user);
	} catch (e) {
		userDataChangeCallback(null);
		dispatch(setIsAuthenticationLoading(false));

		throw e;
	}
});

export async function forceUpdateUserData() {
	await userDataChangeCallback(auth.currentUser);
}
