import { call } from '../@';

export type Payload = {
	id: string;
	transactionOnboardingId?: string;
	propertyOnboardingId?: string;
};

const getPropertyTitles = (payload: Payload) => call('hmlr-getPropertyTitles', payload);

export default getPropertyTitles;
