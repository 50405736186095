export const ROLES_ESTATE = {
	name: 'Estate Agent',
	id: 'D47WvSVm8i9qyyACIJIj',
};

export const ROLES_SOLICITOR = {
	name: 'Solicitor/Conveyancer',
	id: 'KXAQMXhPHf1qT1imLFlp',
};

export const ROLES_ADMIN = {
	name: 'Admin',
	id: 'M7VUzD1elvxMShD3zmaV',
};

export const ROLES_PRINCIPAL = {
	name: 'Principal',
	key: 'principal',
	id: '6Hk8Aa907GXBtgxWjki6',
};

export const ROLES_ANCILLARY = {
	name: 'Ancillary',
	key: 'ancillary',
	id: '8EmYwPywqxdV3n0ShzTW',
};

export const ROLES_SELLER = { name: 'Seller' };

export const PROFESSION_PRINCIPAL = {
	name: 'Principal',
	key: 'principal',
	id: 'LQK7UJfc2RVAT7G95NVE',
};

export const PROFESSION_ESTATE_AGENT = {
	name: 'Agent',
	key: 'estateAgent',
	id: '1TmZotnvPWObWdRZSL3',
};

export const PROFESSION_SOLICITOR = {
	name: 'Solicitor/Conveyancer',
	key: 'solicitor',
	id: '20Gdk67fy7HuBWVmwHAj',
};

export const PROFESSION_TRADES_PERSON = {
	name: 'Tradesperson',
	key: 'tradesperson',
	id: '5s8KYAuVPyJU1FvodzGy',
};

export const PROFESSION_MORTGAGE_BROKER = {
	name: 'Mortgage Broker',
	key: 'mortgageBroker',
	id: '3F81eW1tslxlZh7GvE5',
};

export const PROFESSION_SURVEYOR = {
	name: 'Surveyor',
	key: 'surveyor',
	id: '4DQkhkOOHld30n7LGcxN',
};
