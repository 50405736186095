import { ApexOptions } from 'apexcharts';
import { renderToString } from 'react-dom/server';
import { ExtractedPropertyPrices } from 'src/redux/slices/property';
import { formatBidValue } from 'src/utils/formatBid';
import { getDateYearsBetweenDates } from 'src/utils/dates';
import PriceTooltip from './components/PriceTooltip';

export const createApexChartOptions = (prices: ExtractedPropertyPrices): ApexOptions => {
	const pricesTimes = [...prices.ask, ...prices.paid].map((p) => new Date(p.date).getTime());
	const latestDate = new Date(Math.max(...pricesTimes));
	const oldestDate = new Date(Math.min(...pricesTimes));

	latestDate.setFullYear(latestDate.getFullYear() + 1);

	const graphicDates = getDateYearsBetweenDates(oldestDate, latestDate);

	return {
		markers: { size: 4 },
		stroke: { width: 2 },
		chart: { toolbar: { tools: { download: false } } },
		series: [
			{
				name: 'Series name',
				data: [
					...prices.ask.map(({ date, price }) => ({
						fillColor: 'red',
						x: date,
						y: price / 1000,
						meta: { type: 'ask' },
					})),
					...prices.paid.map(({ date, price }) => ({
						fillColor: 'blue',
						x: date,
						y: price / 1000,
						meta: { type: 'paid' },
					})),
				].sort((a, b) => a.x.getTime() - b.x.getTime()),
			},
		],
		tooltip: {
			shared: false,
			custom: ({ seriesIndex, dataPointIndex, w }) => {
				const data = w.config.series[seriesIndex].data[dataPointIndex];
				return renderToString(<PriceTooltip date={data.x} price={data.y} type={data.meta.type} />);
			},
		},
		yaxis: {
			labels: {
				show: true,
				align: 'center',
				formatter: (val) => `£${formatBidValue(val)}K`,
			},
		},
		xaxis: {
			type: 'datetime',
			categories: graphicDates.map((date) => date.getTime()),
			min: graphicDates[0]?.getTime(),
			max: graphicDates.at(-1)?.getTime(),
		},
	};
};
