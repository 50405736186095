import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { noop } from 'lodash';
import { useNavigate } from 'react-router';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { ConfirmationModal } from '../../common';

const PropertyVerificationFailModal = () => {
	const navigate = useNavigate();

	const handleConfirm = () => {
		navigate('/dashboard');
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			modalName={propertyOnboardingSteps.verificationFail}
			title='IDENTITY VERIFICATION FAILED'
			description={
				<>
					We have not been able to verify your identity. <br /> <br /> A team member is reviewing your submission and
					will be in touch shortly.
				</>
			}
			withCancelButton={false}
			confirmText='Go to Dashboard'
			handleConfirm={handleConfirm}
			handleClose={noop}
		/>
	);
};

export default PropertyVerificationFailModal;
