import { getDoc, updateDoc, type DocumentSnapshot } from 'firebase/firestore';

const updateQuestionnaire = async (
	// eslint-disable-next-line
	questionnaireRef: any,
	type: string,
	data: object,
	// eslint-disable-next-line
) => {
	// eslint-disable-next-line
	const questionnaire = (await getDoc(questionnaireRef)) as DocumentSnapshot<any>;

	if (questionnaire.exists()) {
		return updateDoc(questionnaireRef, { [type]: { ...questionnaire.data()[type], ...data } });
	}
};

export default updateQuestionnaire;
