import { dispatch } from 'src/redux/store';
import { selectProperty, updatePropertyOnboarding } from 'src/redux/slices/property';
import useLoading from 'src/hooks/useLoading';
import modals from 'src/constants/modals';
import { useSelector } from 'react-redux';
import { syncPropertyOnboarding } from 'src/api/property-onboarding';
import { ConfirmationModal } from '../../common';

const PropertyRemortgageConfirmationModal = () => {
	const property = useSelector(selectProperty);
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		setIsLoading(true);

		await syncPropertyOnboarding(property.id, 'remortgage');
		await dispatch(updatePropertyOnboarding({ moveToNextModal: true, activeSide: 'remortgage' }));

		setIsLoading(false);
	};

	const currentAction = property.onboarding?.activeSide === 'sell' ? 'sale' : 'purchase';

	return (
		<ConfirmationModal
			modalName={modals.onboardingRemortgageConfirmation}
			isLoading={isLoading}
			title={`Cancel ${currentAction}`}
			cancelText='No'
			confirmText='Yes'
			handleConfirm={handleConfirm}
			description={
				<>
					Are you sure you want to cancel the {currentAction} and proceed with remortgaging <b>{property.name}</b>?
				</>
			}
		/>
	);
};

export default PropertyRemortgageConfirmationModal;
