import { Box } from '@mui/material';
import { selectProperty } from 'src/redux/slices/property';
import { useSelector } from 'react-redux';
import { wrapOptionalValue } from 'src/utils/common';
import { convertMetersToFeetAndInches, convertSquareMetersToSquareFeet } from 'src/utils/numbers';
import { PropertyServicesParkingEnum } from 'src/components/cards/QuestionnaireServicesCard/types';
import { capitalize } from 'lodash';
import { convertConstructionAgeToYears } from 'src/utils/strings';
import { selectRooms } from 'src/redux/slices/rooms';
import { PropertyGeneralAddressCard, TypographyAddressTitle } from '../../styled';
import { calculateTotalRooms } from './utils';

const BuiltFormSection = () => {
	const property = useSelector(selectProperty);
	const rooms = useSelector(selectRooms);

	const { latestListing, epc, propertyQuestionnaire } = property.overview;

	if (!propertyQuestionnaire || !rooms.data) return null;

	const isFlat = epc?.property_type === 'Flat';
	const bathrooms =
		calculateTotalRooms(propertyQuestionnaire, rooms.data, 'bathroom') ?? latestListing?.bathrooms ?? '0';
	const bedrooms = calculateTotalRooms(propertyQuestionnaire, rooms.data, 'bedroom') ?? latestListing?.bedrooms ?? '0';
	const totalRooms = epc?.number_habitable_rooms ?? calculateTotalRooms(propertyQuestionnaire, rooms.data) ?? '0';
	const outdoorsRooms = propertyQuestionnaire.property_details.rooms?.outdoors ?? [];
	const propertyType = epc?.property_type ?? propertyQuestionnaire.property_details?.dwelling?.property_type;
	const builtForm = epc?.built_form ?? propertyQuestionnaire.property_details?.building?.built_form;
	const yearBuilt = wrapOptionalValue(epc?.construction_age_band, convertConstructionAgeToYears);
	const floorLevel = epc?.floor_level ?? propertyQuestionnaire.property_details?.building?.building_floor;
	const floorArea =
		wrapOptionalValue(epc?.total_floor_area, (meters) => `${convertSquareMetersToSquareFeet(meters)} sqft`) ??
		propertyQuestionnaire.property_details?.dwelling?.total_floor_area;
	const ceilingHeight =
		wrapOptionalValue(epc?.floor_height, (meters) => `${convertMetersToFeetAndInches(meters)}`) ??
		propertyQuestionnaire.property_details?.dwelling?.ceiling_height;
	const levelCount = epc?.flat_storey_count ?? propertyQuestionnaire.property_details?.dwelling?.level_count;

	const hasGarden = outdoorsRooms.some(
		(r) => r?.outdoors_rooms === 'back_garden' || r?.outdoors_rooms === 'front_garden',
	);
	const hasTerrace = outdoorsRooms.some((r) => r?.outdoors_rooms === 'terrace');
	const hasBalcony = outdoorsRooms.some((r) => r?.outdoors_rooms === 'balcony');

	const parts = [
		// { label: 'Type:', value: latestListing?.type },
		// { label: 'Subtype:', value: latestListing?.subtype },
		{ label: 'Bathrooms:', value: bathrooms },
		{ label: 'Bedrooms:', value: bedrooms },
		// { label: 'Features:', value: latestListing?.keyFeatures?.join(', ') },
		{ label: 'Property type:', value: propertyType },
		{ label: 'Built form:', value: builtForm },
		{ label: 'Year built:', value: yearBuilt },
		...(isFlat ? [{ label: 'Floor:', value: floorLevel }] : []),
		{ label: 'Floor area:', value: floorArea },
		{ label: 'Ceiling height:', value: ceilingHeight },
		{ label: 'Rooms:', value: totalRooms },
		{ label: 'Extensions:', value: epc?.extension_count },
		...(isFlat ? [{ label: 'Top storey?', value: epc?.flat_top_storey }] : []),
		{ label: 'Levels:', value: levelCount },
		{ label: 'Fireplaces:', value: epc?.number_open_fireplaces },
		{
			label: 'Parking:',
			value: wrapOptionalValue(propertyQuestionnaire.services.parking?.parking_arrangements, (arrangements) =>
				arrangements.map((a) => PropertyServicesParkingEnum[a] ?? capitalize(a)).join(', '),
			),
		},
		{ label: 'Terrace/Balcony:', value: hasTerrace || hasBalcony ? 'Y' : 'N' },
		{ label: 'Garden:', value: hasGarden ? 'Y' : 'N' },
	];

	return (
		<PropertyGeneralAddressCard sx={{ maxWidth: '650px' }}>
			<TypographyAddressTitle mb='20px'>BUILT FORM</TypographyAddressTitle>
			<Box display='grid' gridTemplateColumns='1fr 1fr' columnGap='40px' rowGap='10px'>
				{parts.map(({ label, value }) => (
					<Box key={label}>
						<b>{label}</b> {value}
					</Box>
				))}
			</Box>
		</PropertyGeneralAddressCard>
	);
};

export default BuiltFormSection;
