import { FC } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { Box, Typography } from '@mui/material';
import { DocumentProps } from './types';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

const Document: FC<DocumentProps> = ({ publicUrl, title, index, onDocumentFinishLoading }) => {
	const zoom = zoomPlugin();

	const { ZoomInButton, ZoomOutButton } = zoom;

	return (
		<Box>
			<Typography variant='subtitle1'>{title}</Typography>
			<hr />
			<Box py='15px'>
				<Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
					<Box display='flex' gap='5px' justifyContent='flex-end' sx={{ zoom: 1.5 }}>
						<ZoomInButton />
						<ZoomOutButton />
					</Box>
					<Box flex={1} overflow='hidden'>
						<Viewer
							defaultScale={1.25}
							plugins={[zoom]}
							fileUrl={publicUrl}
							onDocumentLoad={() => onDocumentFinishLoading(index)}
						/>
					</Box>
				</Worker>
			</Box>
		</Box>
	);
};

export default Document;
