import { noop } from 'lodash';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { selectProperty } from 'src/redux/slices/property';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { useNavigate } from 'react-router';
import { setCurrentTransactionTab } from 'src/redux/slices/transaction';
import { PATH_INSTRUMENTS, PATH_TRANSACTIONS } from 'src/routes/paths';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { ConfirmationModal } from '../../common';

const PropertyCompletionModal = () => {
	const property = useSelector(selectProperty);
	const navigate = useNavigate();

	const isRemortgage = property.onboarding?.activeSide === 'remortgage';

	const handleConfirm = async () => {
		if (!property.onboarding?.transactionRef) return;

		dispatch(setCurrentTransactionTab('participants'));
		navigate(
			isRemortgage
				? PATH_INSTRUMENTS.view(property.onboarding.transactionRef.id)
				: PATH_TRANSACTIONS.view(property.onboarding.transactionRef.id),
		);
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '650px' }}
			modalName={propertyOnboardingSteps.completion}
			title={`ADD ${isRemortgage ? 'REMORTGAGE' : 'TRANSACTION'}`}
			withCancelButton={false}
			confirmText='Continue'
			handleConfirm={handleConfirm}
			handleClose={noop}
			description={
				<>
					{isRemortgage ? (
						<>
							Thank you! You've successfully created a new mortgage request.
							<br />
							Next, you'll be taken to "Add Participants" to invite any additional parties.
						</>
					) : (
						<>
							Thank you! The transaction creation process is now complete.
							<br />
							Next, you'll be taken to <b>“Add Participants”</b> to invite the transaction parties.
						</>
					)}
				</>
			}
		/>
	);
};

export default PropertyCompletionModal;
