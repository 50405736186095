import type { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import type { IRHFTextField } from './types';
import { makeTextInputStyles } from './styles';

// ----------------------------------------------------------------------

const RHFTextField: FC<IRHFTextField> = ({ name, shrink, ...other }) => {
	const { control } = useFormContext();
	const classes = makeTextInputStyles({ disabled: other.disabled ?? false });

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { ref, ...field }, fieldState: { error } }) => (
				<TextField
					{...field}
					inputRef={ref}
					fullWidth
					classes={classes}
					value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
					InputLabelProps={{ shrink: Boolean(field.value) || shrink }}
					error={!!error}
					helperText={error?.message}
					{...other}
				/>
			)}
		/>
	);
};

export default RHFTextField;
