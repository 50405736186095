import { useSnackbar } from 'notistack';
import Modal from 'src/components/customModal';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { dispatch, useSelector } from 'src/redux/store';
import { getTransactionOverviewThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import modals from 'src/constants/modals';
import orderSalePack from 'src/api/transaction/order-sale-pack';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import useLoading from 'src/hooks/useLoading';
import { SubtitleStyled, TitleStyled } from './style';

const Content = () => {
	const transaction = useSelector(selectTransactionOverview);
	const { enqueueSnackbar } = useSnackbar();
	const { isLoading, setIsLoading } = useLoading();

	const onSubmit = async () => {
		try {
			setIsLoading(true);

			await orderSalePack(transaction.id);

			await dispatch(getTransactionOverviewThunk({ id: transaction.id })).unwrap();
			enqueueSnackbar('Sale pack successfully ordered!', { variant: 'success' });
			handleCloseModal();
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Something went wrong while ordering your sale pack', { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<TitleStyled>ORDER SALE PACK</TitleStyled>
			<SubtitleStyled>
				Click “Continue” to request a Sale Pack for £500 (VAT inclusive). An invoice and a payment link will be produced
				and sent to your email.
			</SubtitleStyled>
			<Box display='flex' flexDirection='row' gap='15px' mt='15px'>
				<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton variant='contained' size='large' fullWidth onClick={onSubmit} loading={isLoading}>
					Order
				</LoadingButton>
			</Box>
		</>
	);
};

export const OrderSalePackModal = () => (
	<Modal name={modals.orderSalePack} cardSx={{ maxWidth: '550px' }}>
		<Content />
	</Modal>
);
