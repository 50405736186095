import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import Modal from 'src/components/customModal';
import { FormProvider } from 'src/components/hook-form';
import { extractBidValue, formatBidValue } from 'src/utils/formatBid';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { dispatch, useSelector } from 'src/redux/store';
import { selectTransactionOverview, setAskPrice } from 'src/redux/slices/transaction';
import { selectModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import PriceField from 'src/components/PriceField';
import { noop } from 'lodash';
import { LoadingButtonStyledOffer, SubtitleStyled, TitleStyled } from './style';
import { validationMakeAnOffer } from './validationSchemas';

const Content = () => {
	const { payload } = useSelector(selectModal<{ price?: number }>);
	const { enqueueSnackbar } = useSnackbar();
	const transaction = useSelector(selectTransactionOverview);

	const methods = useForm({
		resolver: yupResolver(validationMakeAnOffer),
		values: { offer: payload.price ? formatBidValue(`${payload.price}`) : '' },
	});

	const {
		handleSubmit,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = async (data) => {
		try {
			await dispatch(setAskPrice({ price: extractBidValue(data.offer) }));

			enqueueSnackbar('Your ask price has been submitted', { variant: 'success' });
			handleCloseModal();
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Something went wrong while submitting your ask price', { variant: 'error' });
		}
	};

	return (
		<FormProvider methods={methods} onSubmit={onSubmit}>
			<TitleStyled>{transaction.isInstrument ? 'SET PROPERTY VALUE' : 'SET ASK PRICE'}</TitleStyled>
			{transaction.isInstrument ? (
				<>
					<SubtitleStyled>To continue, please enter a valuation for your property.</SubtitleStyled>
					<SubtitleStyled>
						If you haven't obtained an official valuation yet, just add a ballpark estimate.
					</SubtitleStyled>
				</>
			) : (
				<>
					<SubtitleStyled>To proceed with this transaction, please set an ask price.</SubtitleStyled>
					<SubtitleStyled>
						If you haven't obtained an official valuation or agreed an ask price yet, just add a ballpark estimate.
					</SubtitleStyled>
				</>
			)}
			<SubtitleStyled>Don't worry, you can update it later!</SubtitleStyled>
			<PriceField name='offer' />
			<LoadingButtonStyledOffer loading={isSubmitting} onClick={handleSubmit(onSubmit)} variant='contained'>
				Submit
			</LoadingButtonStyledOffer>
		</FormProvider>
	);
};

export const RequiredAskPriceModal = () => (
	<Modal name={modals.requiredAskPrice} cardSx={{ maxWidth: '600px' }} onClose={noop}>
		<Content />
	</Modal>
);
