import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { useNavigate } from 'react-router';
import { noop } from 'lodash';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { ConfirmationModal } from '../../common';

const PropertyVerificationInProgressModal = () => {
	const navigate = useNavigate();

	const handleGoToDashboard = () => {
		navigate('/dashboard');
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			modalName={propertyOnboardingSteps.verificationInProgress}
			title='IDENTITY VERIFICATION IN PROGRESS'
			description={
				<>
					Verifying your identity can take a few minutes.
					<br /> <br /> As soon as your checks are complete, this message will disappear and you will receive an email
					notification.
				</>
			}
			withCancelButton={false}
			confirmText='Go to Dashboard'
			handleConfirm={handleGoToDashboard}
			handleClose={noop}
		/>
	);
};
export default PropertyVerificationInProgressModal;
