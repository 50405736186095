import type { FC } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';
import type { IIconify } from './types';

// ----------------------------------------------------------------------

const Iconify: FC<IIconify> = ({ icon, ...other }) => <Box component={Icon} icon={icon} {...other} />;

export default Iconify;
