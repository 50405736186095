import { transactionRolesBySide } from 'src/pages/transactions/constants';
import { FolderPermissionsAction, FolderPermissions } from 'src/redux/types/documents';

export const getDefaultActionPermissions = (
	permissions: FolderPermissions[FolderPermissionsAction],
	selected = false,
) =>
	Object.entries(transactionRolesBySide).reduce((acc, [side, roles]) => {
		acc[side] = roles.reduce((nestedAcc, role) => {
			// eslint-disable-next-line no-param-reassign
			nestedAcc[role.key] = {
				...role,
				selected: selected || permissions.includes(`${side}-${role.key}`) || permissions.includes('*'),
			};

			return nestedAcc;
		}, {});

		return acc;
	}, {});
