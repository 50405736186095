import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack } from '@mui/material';
import palette from 'src/theme/palette';

export const BoxDocumentsStyle = styled(Box)(() => ({
	height: '100%',
	marginTop: '20px',
	display: 'block',
}));

export const TypographyPropertyViewStyle = styled(Typography)(() => ({
	marginBottom: '13px',
	fontWeight: 600,
	fontSize: '18px',
	lineHeight: '21px',
}));

export const StackPropertyViewStyle = styled(Stack)(() => ({
	paddingLeft: '20px',
	height: '100%',
}));

export const StackPropertyCreateStyle = styled(Stack)(() => ({
	marginBottom: '22px',
	'.MuiOutlinedInput-notchedOutline': {
		borderColor: palette.light.custom.bidyText,
	},
}));

export const LoadingButtonStyled = styled(LoadingButton)(() => ({
	width: '180px',
	fontSize: '16px',
	margin: '34px 0 0 auto',
}));
