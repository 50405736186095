const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.substring(1);

export const transformName = (name: string) => {
	const capitalizedName = capitalize(name.toLowerCase());
	const separators = ['-', ' ', 'Mc', "'", '#'];

	return separators.reduce((acc, s) => {
		// eslint-disable-next-line no-param-reassign
		acc = acc.split(s).map(capitalize).join(s);

		return acc;
	}, capitalizedName);
};
