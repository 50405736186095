import { DocumentSnapshot, getDocs, query, where } from 'firebase/firestore';
import { verificationSessionsCollection } from 'src/constants/firestore';
import { VerificationSession } from 'src/types';
import { getUserRef } from '..';

export const findUserVerificationSession = async (
	userId: string,
): Promise<DocumentSnapshot<VerificationSession> | null> => {
	const { docs } = await getDocs(query(verificationSessionsCollection, where('user', '==', getUserRef(userId))));

	return docs[0] ?? null;
};
