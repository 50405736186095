import { Typography } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { IDashboardTransaction } from 'src/redux/types';

const Transaction: FC<IDashboardTransaction> = ({ side, name, displayAddress, id, isInstrument }) => {
	const to = `/${isInstrument ? 'instruments' : 'transactions'}/${id}`;

	return (
		<Typography variant='link' component={Link} fontWeight={600} sx={{ textDecoration: 'underline' }} to={to}>
			{side === 'sell' ? 'Sale' : 'Purchase'} of {displayAddress ?? name}
		</Typography>
	);
};

export default Transaction;
