import { forwardRef } from 'react';
// @mui
import { IconButton, IconButtonProps } from '@mui/material';
import AnimateWrap from './AnimateWrap';

const varSmall = {
	hover: { scale: 1.1 },
	tap: { scale: 0.95 },
};

const varMedium = {
	hover: { scale: 1.09 },
	tap: { scale: 0.97 },
};

const varLarge = {
	hover: { scale: 1.08 },
	tap: { scale: 0.99 },
};

// ----------------------------------------------------------------------

const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonProps>(
	({ children, size = 'medium', ...other }, ref) => (
		<AnimateWrap size={size} varLarge={varLarge} varSmall={varSmall} varMedium={varMedium}>
			<IconButton size={size} ref={ref} {...other}>
				{children}
			</IconButton>
		</AnimateWrap>
	),
);

// ----------------------------------------------------------------------

export default IconButtonAnimate;
