import { useNavigate } from 'react-router';
import { noop } from 'lodash';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { ConfirmationModal } from '../../common';

const PropertyVerificationNameIssueModal = () => {
	const navigate = useNavigate();

	const handleConfirm = () => {
		navigate('/dashboard');
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '650px' }}
			modalName={propertyOnboardingSteps.titleHelp}
			title='HELP ME CHOOSE A TITLE'
			withCancelButton={false}
			confirmText='Go to Dashboard'
			handleConfirm={handleConfirm}
			handleClose={noop}
			description={
				<>
					Thank you for submitting your request. A member of our team will contact you shortly to help you select the
					correct title. This notification will disappear once a title has been assigned.
				</>
			}
		/>
	);
};

export default PropertyVerificationNameIssueModal;
