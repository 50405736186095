import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { rights } from 'src/constants/surveyJson/rights';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnairePropertyEnum } from 'src/utils/types';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { fromObjToString } from '../localConsts';
import { DescriptionBlock, SecondTitle } from '../styles';
import { useQuestionnaire } from '../useQuestionnaire';
import { ArrangementsEnum, RightsEnum } from './types';

const QuestionnaireRightsCard = () => {
	const { onSubmit, onPageChanged, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnairePropertyEnum.rights_arrangements,
		questionnaireType: QuestionnaireTypeEnum.PROPERTY,
	});
	const { fullQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);

	if (isHidden) return null;

	const completedProperyDetails = fillQuestionnaireJsonWithCompletedData(
		rights,
		fullQuestionnaire?.rights_arrangements,
	);

	return (
		<>
			<QuestionnarieModal
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				initialCurrentPage={fullQuestionnaire?.rights_arrangements.currentPage}
				surveyJson={completedProperyDetails}
				questionnaireKey={QuestionnairePropertyEnum.rights_arrangements}
				onSubmit={onSubmit}
				onPageChanged={onPageChanged}
				onComplete={onComplete}
				status={fullQuestionnaire?.rights_arrangements.status}
				title='RIGHTS AND ARRANGEMENTS'
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				title='Rights'
				type={QuestionnairePropertyEnum.rights_arrangements}
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			>
				{({ details }) => {
					const arrangements = fromObjToString(
						{ ...details.rights_arrangements, ...details.rights_arrangements.arrangements },
						ArrangementsEnum,
					);

					return (
						<>
							<DescriptionBlock>
								{fromObjToString({ ...details.rights_arrangements, ...details.rights_arrangements.rights }, RightsEnum)}
							</DescriptionBlock>
							{arrangements ? (
								<>
									<SecondTitle>Arrangements</SecondTitle>
									<DescriptionBlock>
										{fromObjToString(
											{ ...details.rights_arrangements, ...details.rights_arrangements.arrangements },
											ArrangementsEnum,
										)}
									</DescriptionBlock>
								</>
							) : null}
						</>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default QuestionnaireRightsCard;
