import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { verificationSession } from 'src/api';
import { IVerificationSessionResponse } from 'src/api/verification/types';
import { selectProperty } from 'src/redux/slices/property';
import { VerificationSessionSide } from './types';

export const useYotiVerificationSession = (side: VerificationSessionSide) => {
	const { id } = useSelector(selectProperty);
	const [session, setSession] = useState<IVerificationSessionResponse | null>(null);

	useEffect(() => {
		const setup = async () => {
			setSession(null);

			const sessionRes = await verificationSession({
				side,
				propertyId: id,
			});

			setSession(sessionRes);
		};

		setup();
	}, [side]);

	return { session };
};
