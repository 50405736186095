import type { FC, PropsWithChildren } from 'react';
import { useSelector } from 'src/redux/store';
import { Modal as MuiModal } from '@mui/material';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { selectModal } from 'src/redux/slices/modal';
import { ICustomModal } from './types';
import { ModalCard } from './styled';
import { modalSlotProps } from './constants';

const Modal: FC<PropsWithChildren<ICustomModal>> = ({
	name,
	children,
	onClose = handleCloseModal,
	cardSx,
	modalSx,
}) => {
	const { isOpened, modalName } = useSelector(selectModal);

	const isOpen = isOpened && modalName === name;

	if (!isOpen) return null;

	return (
		<MuiModal open onClose={onClose} slotProps={modalSlotProps} sx={modalSx}>
			<ModalCard sx={cardSx}>{children}</ModalCard>
		</MuiModal>
	);
};

export default Modal;
