import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { notAllowedQuestionnaireMessage } from 'src/constants/questionnaire';
import { fittingsAndContents } from 'src/constants/surveyJson/fittingsAndContents';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { QuestionnaireStatus, QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { selectPropertyQuestionnaire } from 'src/redux/slices/propertyQuestionnaire';
import { selectRooms } from 'src/redux/slices/rooms';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { useQuestionnaire } from '../useQuestionnaire';
import { modalSx } from './styled';

export const QuestionnaireFittingsAndContents = () => {
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const propertyQuestionnaire = useSelector(selectPropertyQuestionnaire);
	const rooms = useSelector(selectRooms);
	const { onPageChanged, onSubmit, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnaireTransactionEnum.fitting_contents,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	if (!rooms.data || isHidden) return null;

	const completedFittings = fillQuestionnaireJsonWithCompletedData(
		fittingsAndContents(propertyQuestionnaire, rooms.data),
		fullQuestionnaire?.fitting_contents,
	);

	const isDisabled = [QuestionnaireStatus.IN_PROGRESS, QuestionnaireStatus.NOT_STARTED].includes(
		propertyQuestionnaire?.property_details?.status || QuestionnaireStatus.NOT_STARTED,
	);

	const disabledMessage =
		propertyQuestionnaire?.property_details?.status !== QuestionnaireStatus.DONE
			? 'You must complete the Property Details card first'
			: notAllowedQuestionnaireMessage;

	return (
		<>
			<QuestionnarieModal
				initialCurrentPage={fullQuestionnaire?.fitting_contents?.currentPage}
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				questionnaireKey={QuestionnaireTransactionEnum.fitting_contents}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				surveyJson={completedFittings}
				title='FITTINGS, FIXTURES, CONTENTS'
				status={fullQuestionnaire?.fitting_contents?.status ?? QuestionnaireStatus.NOT_STARTED}
				modalSx={modalSx}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title='Fixtures, Fittings & Contents'
				type={QuestionnaireTransactionEnum.fitting_contents}
				notAllowed={isDisabled || isNotAllowed}
				notAllowedMessage={disabledMessage || notAllowedMessage}
			/>
		</>
	);
};
