import * as Yup from 'yup';
import { transactionSellerRelationship } from 'src/pages/transactions/constants';
import { requiredString } from 'src/utils/schemas';

export const propertyProprietorshipVerificationIssueValidationSchema = Yup.object().shape({
	relationship: requiredString.oneOf([
		transactionSellerRelationship.attorney.key,
		transactionSellerRelationship.executor.key,
		'other',
	]),
});
