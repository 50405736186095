import { getDoc } from 'firebase/firestore';
import { Branch, Business } from 'src/types';

export const getBranches = async (chosenFirm: Business) => {
	const listOfBranches: Branch[] = [];

	const listOfBranchesSnapshots = await Promise.allSettled(chosenFirm.branches.map((branchRef) => getDoc(branchRef)));

	listOfBranchesSnapshots.forEach((settledBranch) => {
		if (settledBranch.status === 'fulfilled' && settledBranch.value.exists()) {
			const branchSnapshot = settledBranch.value;
			const branchData = branchSnapshot.data();

			if (!!chosenFirm.authorisationType || branchData.services.includes('sales')) {
				listOfBranches.push({ ...branchData, id: branchSnapshot.id });
			}
		}
	});

	return listOfBranches;
};
