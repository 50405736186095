/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getQuestionnaireById } from 'src/api/questionnaire';
import { questionnaireNotFoundError } from 'src/constants/questionnaire';
import { IExtendedQuestionnaireProperty, IQuestionnaireProperty, QuestionnaireTypeEnum } from 'src/types';
import { RootState } from '../store';
import {
	GetQuestionnaireThunkPayload,
	GetQuestionnaireThunkResponse,
	QuestionnaireState,
	QuestionnaireThunkOptions,
	UpdateQuestionnaireThunkPayload,
} from '../types/questionnaire';
import { getNativeErrorMessage } from '../utils/common/error-handler';
import { clearDoneQuestionnaire } from '../utils/questionnaire/clear-done';
import { updateQuestionnaire } from '../utils/questionnaire/update';

const initialState: QuestionnaireState<IExtendedQuestionnaireProperty> = {
	fullQuestionnaire: null,
	errorMessage: '',
	loading: true,
};

export const selectPropertyQuestionnaireSlice = (state: RootState) => state.propertyQuestionnaire;
export const selectPropertyQuestionnaire = (state: RootState) => state.propertyQuestionnaire.fullQuestionnaire;

export const getPropertyQuestionnaire = createAsyncThunk<
	GetQuestionnaireThunkResponse<QuestionnaireTypeEnum.PROPERTY>,
	GetQuestionnaireThunkPayload,
	QuestionnaireThunkOptions
>('propertyQuestionnaire/get', async ({ id }, { rejectWithValue }) => {
	try {
		const questionnaire = await getQuestionnaireById(id, QuestionnaireTypeEnum.PROPERTY);

		return { questionnaire };
	} catch (error) {
		console.log('ERRR', error);
		return rejectWithValue(getNativeErrorMessage(error) || questionnaireNotFoundError);
	}
});

export const refreshPropertyQuestionnaire = createAsyncThunk<
	IExtendedQuestionnaireProperty,
	void,
	QuestionnaireThunkOptions
>('propertyQuestionnaire/refresh', async (_, { rejectWithValue, getState }) => {
	try {
		const fullQuestionnaire = selectPropertyQuestionnaire(getState() as RootState);
		const questionnaire = await getQuestionnaireById(
			fullQuestionnaire?.property.id ?? '',
			QuestionnaireTypeEnum.PROPERTY,
		);

		return {
			...questionnaire.data(),
			id: questionnaire.id,
		};
	} catch (error) {
		return rejectWithValue(getNativeErrorMessage(error) || questionnaireNotFoundError);
	}
});

export const updatePropertyQuestionnaire = createAsyncThunk<
	void,
	UpdateQuestionnaireThunkPayload,
	QuestionnaireThunkOptions
>('propertyQuestionnaire/update', async ({ type, data }, { getState, rejectWithValue }) => {
	try {
		const fullQuestionnaire = selectPropertyQuestionnaire(getState() as RootState);

		return await updateQuestionnaire({
			questionnaireType: QuestionnaireTypeEnum.PROPERTY,
			type,
			data,
			fullQuestionnaire,
		});
	} catch (error) {
		return rejectWithValue(getNativeErrorMessage(error));
	}
});

export const clearDonePropertyQuestionnaire = createAsyncThunk<
	void,
	keyof IQuestionnaireProperty,
	QuestionnaireThunkOptions
>('propertyQuestionnaire/clear', async (type, { getState, rejectWithValue }) => {
	try {
		const fullQuestionnaire = selectPropertyQuestionnaire(getState() as RootState);

		return await clearDoneQuestionnaire({ questionnaireType: QuestionnaireTypeEnum.PROPERTY, type, fullQuestionnaire });
	} catch (error) {
		return rejectWithValue(getNativeErrorMessage(error));
	}
});

const slice = createSlice({
	name: 'questionnaire',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getPropertyQuestionnaire.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getPropertyQuestionnaire.fulfilled, (state, { payload: { questionnaire } }) => {
			if (questionnaire.exists()) state.fullQuestionnaire = { id: questionnaire.id, ...questionnaire.data() };
			state.loading = false;
		});
		builder.addCase(getPropertyQuestionnaire.rejected, (state, { payload }) => {
			state.errorMessage = payload as ValidationErrors;
			state.loading = false;
		});
		builder.addCase(updatePropertyQuestionnaire.rejected, (state, { payload = '' }) => {
			state.errorMessage = payload;
		});
		builder.addCase(clearDonePropertyQuestionnaire.rejected, (state, { payload = '' }) => {
			state.errorMessage = payload;
		});
		builder.addCase(refreshPropertyQuestionnaire.fulfilled, (state, { payload }) => {
			state.fullQuestionnaire = payload;
		});
	},
});

export default slice.reducer;
