import moment from 'moment-timezone';
import { renderToStaticMarkup } from 'react-dom/server';
import { usernameOrEmailRegExp } from 'src/constants';
import { VAT } from 'src/constants/common';
import { transactionUserRelationship } from 'src/pages/transactions/constants';
import { ITransactionSummaryUser } from 'src/redux/types';
import { formatBidNumber, formatBidValue } from 'src/utils/formatBid';

export const calculatePriceWithVAT = (price: number, vat = VAT) => parseFloat((price * vat).toFixed(2));
export const calculatePriceWithoutVAT = (price: number, vat = VAT) => parseFloat((price / vat).toFixed(2));

export const formatDate = (date: string | null, format = 'YYYY-MM-DD') =>
	date ? moment(date).tz('UTC').format(format) : 'N/A';

export const formatUsers = (users: ITransactionSummaryUser[]) =>
	users
		.map(({ givenNames, lastNames, tradingName, relationship }) => {
			let name = `${givenNames} ${lastNames}`;

			if (relationship) name += ` (${transactionUserRelationship[relationship]?.name ?? relationship})`;
			if (tradingName) name += ` (${tradingName})`;

			return name;
		})
		.join(', ');

export const formatPrice = (price: string | number | null | undefined) => {
	if (price === null || price === undefined) return 'N/A';

	return `£ ${formatBidNumber(+price)}`;
};

export const formatDaysDuration = (days: null | number) => {
	if (days === null) return 'N/A';

	return `${days} day${days >= 2 || days === 0 ? 's' : ''}`;
};

export const formatPercent = (percent: string | number | null | undefined) => {
	if (!percent) return 'N/A';

	return `${formatBidValue(percent)} %`;
};

export const convertNextActionToHtml = (nextAction: string) =>
	// eslint-disable-next-line react/no-danger
	renderToStaticMarkup(<div dangerouslySetInnerHTML={{ __html: nextAction }} />).replaceAll(
		usernameOrEmailRegExp,
		'<span style="color: #2065D1;">$1$2</span>',
	);
