import { ReactNode } from 'react';
import { VerificationRequiredModalType } from './types';

export const verificationRequiredModalTypes = {
	transactionInvitation: 'transactionInvitation',
} as const;

export const verificationRequiredModalContentByType: Record<VerificationRequiredModalType, ReactNode> = {
	transactionInvitation: (
		<>
			We must verify your identity before you can invite any participants to this transaction. <br /> <br />
			Please check your participant card.
		</>
	),
};
