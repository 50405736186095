import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum } from 'src/types';
import useLoading from 'src/hooks/useLoading';
import {
	askTitleSelectionHelp,
	selectTransactionOverview,
	updateTransactionOnboarding,
} from 'src/redux/slices/transaction';
import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPropertyTitles, selectProperty, selectPropertyTitles } from 'src/redux/slices/property';
import { Box, Typography } from '@mui/material';
import Progress from 'src/components/Progress';
import { groupPropertyTitles } from 'src/utils/propertyHelpers';
import { RHFRadioGroup } from 'src/components/hook-form';
import { LoadingButton } from '@mui/lab';
import { EmailLink } from 'src/components/common';
import { helpEmail } from 'src/constants/common';
import { useSnackbar } from 'notistack';
import { noop } from 'lodash';
import { ConfirmationModal } from '../../common';
import { SelectTransactionTitleForm } from './types';
import { validationSchema } from './validation';

const Content = () => {
	const form = useForm<SelectTransactionTitleForm>({ resolver: yupResolver(validationSchema) });
	const property = useSelector(selectProperty);
	const transaction = useSelector(selectTransactionOverview);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const { data: titles, isInitialized } = useSelector(selectPropertyTitles);

	useEffect(() => {
		dispatch(getPropertyTitles({ id: property.id }));
	}, [property.id]);

	const handleSubmit = async (data: SelectTransactionTitleForm) => {
		const selectedTitle = titles.find((t) => t.number === data.title);

		if (!selectedTitle) return;

		setIsLoading(true);

		await dispatch(
			updateTransactionOnboarding({
				title: OnboardingStepStatusEnum.PASSED,
				titleHelp: OnboardingStepStatusEnum.PASSED,
				titleError: OnboardingStepStatusEnum.PASSED,
				selectedTitle,
			}),
		);
		setIsLoading(false);
	};

	const handleAskForHelp = async () => {
		try {
			setIsLoading(true);

			await dispatch(askTitleSelectionHelp()).unwrap();
			await dispatch(
				updateTransactionOnboarding({
					title: OnboardingStepStatusEnum.PASSED,
					titleError: OnboardingStepStatusEnum.PASSED,
				}),
			);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const groupedTitles = groupPropertyTitles(titles);

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleSubmit)}>
				<Box>
					{!isInitialized ? (
						<Box py='50px'>
							<Progress />
						</Box>
					) : (
						<div>
							<Box display='flex' flexDirection='column' gap='10px'>
								{groupedTitles.length ? (
									<Box pb='15px'>
										<Typography mb='10px'>
											Below are all titles associated with this property. Which one are you looking to{' '}
											{transaction.isInstrument ? 'remortgage' : transaction.isBuySide ? 'buy' : 'sell'}?
										</Typography>
										{groupedTitles.map((group) => (
											<Box key={group.address}>
												<Typography fontWeight={600} fontSize='15px' color='black'>
													{group.address}
												</Typography>
												<RHFRadioGroup name='title' row={false} sx={{ width: '100%' }} options={group.titles} />
											</Box>
										))}
									</Box>
								) : (
									<Typography>
										We couldn't find any titles for this address.
										<br /> Please contact us at <EmailLink email={helpEmail} />
									</Typography>
								)}
							</Box>
							<Box display='flex' gap='15px' justifyContent='flex-end'>
								<LoadingButton
									onClick={handleAskForHelp}
									variant='outlined'
									size='large'
									loading={isLoading}
									sx={{ textTransform: 'unset' }}
								>
									Ask for help
								</LoadingButton>
								<LoadingButton
									variant='contained'
									size='large'
									type='submit'
									loading={isLoading}
									sx={{ textTransform: 'unset' }}
								>
									Select
								</LoadingButton>
							</Box>
						</div>
					)}
				</Box>
			</form>
		</FormProvider>
	);
};

const TransactionTitleModal = () => (
	<ConfirmationModal
		sx={{ maxWidth: '650px' }}
		modalName={transactionOnboardingSteps.title}
		title='SELECT TITLE'
		withButtons={false}
		handleClose={noop}
		description={<Content />}
	/>
);

export default TransactionTitleModal;
