import Page from 'src/components/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { dispatch } from 'src/redux/store';
import { selectUser, updateUser, updateUserOnboarding } from 'src/redux/slices/auth';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { PROFESSION_ESTATE_AGENT } from 'src/constants';
import useStatic from 'src/hooks/useStatic';
import { getLawHierarchy, getRealtyHierarchy } from 'src/utils/firebase';
import { Business } from 'src/types';
import { AuthPage, CompanyAutocomplete, CompanyBrandCard, ContinueButton, EmailTextInput } from '../styles';
import { OnboardingCompanyFormType } from './types';
import { onboardingCompanyValidationSchema } from './validation';
import { getDefaultFormValues, getUserBranch, getUserBusiness } from './utils';
import OnboardingFooter from '../OnboardingFooter';

const OnboardingCompany = () => {
	const user = useSelector(selectUser);
	const [isCustomBusiness, setIsCustomBusiness] = useState(!user.isEstateAgent && !user.isSolicitorConveyancer);
	const { firms, fetchFirms, branches, fetchBranches, firmsLoading, branchesLoading } = useStatic();
	const form = useForm<OnboardingCompanyFormType>({
		resolver: yupResolver(onboardingCompanyValidationSchema),
		defaultValues: getDefaultFormValues(user),
	});

	const business = form.watch('business');

	const handleSubmit = async (data: OnboardingCompanyFormType) => {
		await Promise.all([
			dispatch(updateUserOnboarding({ company: true })),
			dispatch(
				updateUser({
					business: getUserBusiness(isCustomBusiness, user, data.business),
					branch: getUserBranch(isCustomBusiness, user, data.branch),
				}),
			),
		]);
	};

	useEffect(() => {
		fetchFirms({ id: user.professions[0]?.id ?? PROFESSION_ESTATE_AGENT.id });
	}, [user.professions]);

	useEffect(() => {
		const setup = async () => {
			if ((user.isEstateAgent || user.isSolicitorConveyancer) && business) {
				const { data } = user.isEstateAgent ? await getRealtyHierarchy(business) : await getLawHierarchy(business);

				fetchBranches(data as Business);
			}
		};

		setup();
	}, [business, user.isEstateAgent, user.isSolicitorConveyancer]);

	const handleCantFindFirm = () => {
		form.setValue('business', '');
		form.setValue('branch', '');
		setIsCustomBusiness(true);
	};

	return (
		<FormProvider {...form}>
			<Page title='Login'>
				<AuthPage>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<CompanyBrandCard title='I work at...'>
							{!isCustomBusiness ? (
								<>
									<CompanyAutocomplete name='business' label='Firm' options={firms} loading={firmsLoading} />
									<CompanyAutocomplete
										name='branch'
										label='Branch'
										options={branches}
										loading={branchesLoading}
										disabled={!business}
										sx={{ mt: '5px' }}
									/>
								</>
							) : (
								<>
									<EmailTextInput name='business' placeholder='Enter your firm' />
									<EmailTextInput name='branch' placeholder='Enter your branch' />
								</>
							)}
							<ContinueButton variant='contained' fullWidth type='submit' loading={form.formState.isSubmitting}>
								Continue
							</ContinueButton>
							<OnboardingFooter withFirmNotFound={!isCustomBusiness} handleFirmNotFoundClick={handleCantFindFirm} />
						</CompanyBrandCard>
					</form>
				</AuthPage>
			</Page>
		</FormProvider>
	);
};

export default OnboardingCompany;
