import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { getLatestPropertyListing, selectPropertySlice } from 'src/redux/slices/property';
import { useSelector } from 'react-redux';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { PropertyGeneralAddressCard, TypographyAddressTitle } from '../../styled';
import PhotosCarousel from '../PhotosCarousel';

const PhotosSection = () => {
	const { property } = useSelector(selectPropertySlice);

	useEffect(() => {
		dispatch(getLatestPropertyListing());
	}, []);

	if (!property?.latestListing?.photos.length) return null;

	const handleClick = () => {
		dispatch(
			openModal({
				name: modals.photosCarousel,
				payload: { title: 'Photos', photos: property?.latestListing?.photos.map(({ url }) => url) ?? [] },
			}),
		);
	};

	return (
		<PropertyGeneralAddressCard>
			<TypographyAddressTitle mb='20px'>PHOTOS</TypographyAddressTitle>
			<PhotosCarousel photos={property.latestListing.photos} onPhotoClick={handleClick} />
		</PropertyGeneralAddressCard>
	);
};

export default PhotosSection;
