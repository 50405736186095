import { Typography } from '@mui/material';
import { FC } from 'react';
import { JobLogItem } from 'src/types';

const JobLogs: FC<{ logs: JobLogItem[] }> = ({ logs }) => (
	<div>
		{logs.map((l) => (
			<Typography key={l.message} color={l.severity === 'ERROR' ? 'red' : 'inherit'}>
				{l.message}
			</Typography>
		))}
	</div>
);

export default JobLogs;
