import { useSnackbar } from 'notistack';
import { useEffect, useState, type FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { QuestionnaireStatus, QuestionnaireTypeEnum } from 'src/types';
import { handleOpenModal } from 'src/utils/propertyHelpers';
import { selectQuestionnairePermissions } from 'src/redux/slices/permissions';
import { selectUser } from 'src/redux/slices/auth';
import Iconify from 'src/components/Iconify';
import { Box, Switch } from '@mui/material';
import { noop } from 'lodash';
import { statusColors } from './localConsts';
import { QuestionnaireCardWrapper, QuestionnaireIcon, TitleRow, TypographyStatus, TypographyTitle } from './styles';
import { IQuestionnaireCard } from './types';
import { isQuestionnaireEditable, isQuestionnaireOpenable } from '../utils';
import { useQuestionnaire } from '../useQuestionnaire';
import { UseQuestionnaireParams } from '../types';

const QuestionnaireCard: FC<IQuestionnaireCard> = ({
	questionnaireType,
	title,
	type,
	children,
	notAllowed,
	notAllowedMessage,
	isGiftorFinance,
}) => {
	const user = useSelector(selectUser);
	const { updateData } = useQuestionnaire({ type, questionnaireType } as UseQuestionnaireParams);
	const questionnairePermissions = useSelector(selectQuestionnairePermissions);
	const { fullQuestionnaire } = useSelector((state: RootState) => {
		if (questionnaireType === QuestionnaireTypeEnum.PROPERTY) return state.propertyQuestionnaire;

		return state.transactionQuestionnaire;
	});
	const { enqueueSnackbar } = useSnackbar();
	const isSubmitted = !!(fullQuestionnaire && fullQuestionnaire[type]?.isSubmitted);

	const questionnaireStatus = fullQuestionnaire?.[type]?.status || QuestionnaireStatus.NOT_STARTED;

	const [status, setStatus] = useState(questionnaireStatus);
	const [isLocked, setIsLocked] = useState(status === QuestionnaireStatus.DONE);

	useEffect(() => {
		setStatus(questionnaireStatus);
	}, [questionnaireStatus]);

	const isStarted = questionnaireStatus !== QuestionnaireStatus.NOT_STARTED;

	const handleIsLockedChange = async () => {
		const newStatus = isLocked ? QuestionnaireStatus.IN_PROGRESS : QuestionnaireStatus.DONE;

		setIsLocked(!isLocked);
		setStatus(newStatus);

		await updateData({ status: newStatus });
	};
	const handleClick = () => {
		if (notAllowed) enqueueSnackbar(notAllowedMessage || '', { variant: 'error' });
		else handleOpenModal(type);
	};

	const questionnairePermission = isGiftorFinance
		? questionnairePermissions.transaction.buyer_questions
		: questionnaireType === QuestionnaireTypeEnum.PROPERTY
		? questionnairePermissions.property[type]
		: questionnairePermissions.transaction[type];

	if (!questionnairePermission) return null;

	const isOpenable = isQuestionnaireOpenable(questionnairePermission);
	const isEditable = isQuestionnaireEditable(questionnairePermission);

	return (
		<QuestionnaireCardWrapper>
			<TitleRow>
				<TypographyTitle>{title}</TypographyTitle>
				<TypographyStatus
					mr='7px'
					sx={{ color: statusColors[status]?.color || '', backgroundColor: statusColors[status]?.bgColor || '' }}
				>
					{status}
				</TypographyStatus>
				{isSubmitted && isLocked && !user.isAdmin && (
					<Iconify icon='mingcute:lock-line' fontSize={22} color='grey.600' />
				)}
				{isOpenable ? (
					isEditable ? (
						<QuestionnaireIcon onClick={handleClick} icon='ph:arrow-circle-right-fill' color='#2065d1' fontSize={45} />
					) : (
						<QuestionnaireIcon
							onClick={isStarted ? handleClick : noop}
							icon='ph:eye'
							color={isStarted ? '#2065d1' : 'grey'}
							fontSize={40}
							disabled={!isStarted}
						/>
					)
				) : null}
			</TitleRow>
			{children && children({ details: fullQuestionnaire ? fullQuestionnaire[type] : null })}
			{isSubmitted && user.isAdmin && (
				<Box display='flex' alignItems='center' position='absolute' bottom='15px' right='15px'>
					<Iconify icon='mingcute:unlock-line' fontSize={22} color='grey.600' />
					<Switch
						checked={isLocked}
						style={{ ...(!isLocked && { color: 'lightgrey' }) }}
						onChange={handleIsLockedChange}
					/>
					<Iconify icon='mingcute:lock-line' fontSize={22} color='grey.600' />
				</Box>
			)}
		</QuestionnaireCardWrapper>
	);
};

export default QuestionnaireCard;
