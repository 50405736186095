import { UserData } from 'src/api/auth/@user-data';

const trackerStep = { seller: 'seller', property: 'property', buyer: 'buyer', legals: 'legals' };

export const getTabs = (user: UserData) => [
	...(user.isAdmin
		? [
				{ value: trackerStep.seller, label: 'Sellers' },
				{ value: trackerStep.property, label: 'Property' },
				{ value: trackerStep.buyer, label: 'Buyers' },
		  ]
		: []),
	{ value: trackerStep.legals, label: 'Legals' },
	{ value: 'deleted', label: 'Deleted' },
];
