import { ChangeEvent, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from 'src/components/customModal';
import { dispatch, useSelector } from 'src/redux/store';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { selectTransactionOverview, selectTransactionParticipants } from 'src/redux/slices/transaction';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/Iconify';
import { extractBidValue, formatBidValue } from 'src/utils/formatBid';
import modals from 'src/constants/modals';
import { confirmPriceSchema } from './validationSchemas';

const ConfirmPriceModalContent = () => {
	const { payload } = useSelector(selectModal<{ userId: string }>);
	const transaction = useSelector(selectTransactionOverview);
	const { participants } = useSelector(selectTransactionParticipants);

	const viewer = useMemo(
		() => participants.viewers.find(({ uid: id }) => id === payload.userId),
		[payload.userId, participants.viewers],
	);
	const form = useForm({
		resolver: yupResolver(confirmPriceSchema),
		values: { price: viewer?.offer?.price ? formatBidValue(`${viewer.offer.price}`) : '' },
	});

	const handleConfirm = (data) => {
		const price = extractBidValue(data.price);

		dispatch(openModal({ name: modals.saleConfirmation, payload: { price, userId: payload.userId } }));
	};
	const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
		const formattedPrice = formatBidValue(e.target.value);

		if (formattedPrice !== null) form.setValue('price', formattedPrice, { shouldValidate: true });
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleConfirm)}>
				<Typography sx={styledMainTitle} pt={0}>
					CONFIRM PRICE
				</Typography>
				<Typography fontSize='17px' fontWeight={500} mb={'15px'}>
					Please confirm the agreed price for <b>{transaction.name} </b>
				</Typography>
				<RHFTextField
					onChange={handlePriceChange}
					name='price'
					InputProps={{ startAdornment: <Iconify icon='mdi:sterling' width={17} height={24} color='#697885' /> }}
				/>
				<ActionButtons mt={'20px'}>
					<Button variant='contained' size='large' fullWidth onClick={handleCloseModal}>
						Reject
					</Button>
					<Button variant='contained' size='large' fullWidth type='submit'>
						Confirm
					</Button>
				</ActionButtons>
			</form>
		</FormProvider>
	);
};

const ConfirmPriceModal = () => (
	<Modal name={modals.confirmPrice} cardSx={{ maxWidth: '550px', padding: '32px 27px' }}>
		<ConfirmPriceModalContent />
	</Modal>
);

export default ConfirmPriceModal;
