import moment from 'moment-timezone';
import { Log, LogTypeEnum, TableColumn } from 'src/types';
import { labelByLogEvent, labelByLogStatus } from '../TransactionSummary/constants';
import JobLogs from './components/JobLogs';

export const columns: TableColumn<Log<LogTypeEnum.JOB>>[] = [
	{
		key: 'timestamp',
		label: 'Timestamp',
		sortable: true,
		width: 150,
		getContent: (log) => moment(log.createdAt).utc().format('YYYY-MM-DD hh:mmA z'),
	},
	{
		key: 'event',
		label: 'Event',
		width: 150,
		sortable: true,
		getContent: (log) => labelByLogEvent[log.event] ?? log.event,
	},
	{
		key: 'status',
		label: 'Status',
		width: 100,
		sortable: true,
		getContent: (log) => labelByLogStatus[log.status],
	},
	{
		key: 'logs',
		label: 'Logs',
		width: 400,
		getContent: (log) => (log.metadata.logs ? <JobLogs logs={log.metadata.logs} /> : null),
	},
	{
		key: 'finishedAt',
		label: 'Finished At',
		width: 150,
		sortable: true,
		getContent: (log) => (log.finishedAt ? moment(log.finishedAt).utc().format('YYYY-MM-DD hh:mmA z') : ''),
	},
];
