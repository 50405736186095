import { TrackerStepStatus } from 'src/types';

export const convertTrackerStepStatusToText = (status: TrackerStepStatus) => {
	switch (status) {
		case 'notStarted':
			return 'Not Started';
		case 'inProgress':
			return 'In Progress';
		case 'pending':
			return 'Pending';
		case 'completed':
			return 'Completed';
		case 'error':
			return 'Error';
		default:
			return 'N/A';
	}
};
