import { getBytes, getStorage, ref } from 'firebase/storage';
import { firebaseApp } from 'src/firebaseConfig';

export const getPhotoUrlFromBucket = async (photo: IProfilePhoto) => {
	const storage = getStorage(firebaseApp, `gs://${process.env.REACT_APP_PUBLIC_ASSETS_BUCKET}`);
	const fileRef = ref(storage, photo.file);
	const bytes = await getBytes(fileRef);
	const blob = new Blob([bytes], { type: photo.mimeType });
	return URL.createObjectURL(blob);
};
