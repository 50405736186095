import type { FC, PropsWithChildren } from 'react';
import { m } from 'framer-motion';
// @mui
import { Box } from '@mui/material';
//
import { varContainer } from './variants';
import type { IMotionContainer } from './types';

// ----------------------------------------------------------------------

const MotionContainer: FC<PropsWithChildren<IMotionContainer>> = ({ animate, action = false, children, ...other }) => (
	<Box
		component={m.div}
		initial={action ? false : 'initial'}
		animate={animate || action ? 'animate' : 'exit'}
		variants={varContainer()}
		{...other}
	>
		{children}
	</Box>
);

export default MotionContainer;
