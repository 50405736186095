import { FC, SyntheticEvent, useState } from 'react';
import { ImageWithMagnifierProps } from './types';
import { StyledGlassMagnifier } from './styled';

const ImageWithMagnifier: FC<ImageWithMagnifierProps> = ({ src }) => {
	const [largeImageDimensions, setLargeImageDimensions] = useState({ width: 0, height: 0 });

	const handleImageLoad = (e: SyntheticEvent) => {
		setLargeImageDimensions({
			width: (e.target as HTMLImageElement).offsetWidth * 2,
			height: (e.target as HTMLImageElement).offsetHeight * 2,
		});
	};

	return (
		<StyledGlassMagnifier
			className='image-magnify'
			imageSrc={src}
			allowOverflow
			magnifierSize='30%'
			onImageLoad={handleImageLoad}
			largeHeight={largeImageDimensions.height}
			largeWidth={largeImageDimensions.width}
		/>
	);
};

export default ImageWithMagnifier;
