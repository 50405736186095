export enum BuildingsWorkEnum {
	'alterations_use' = 'Commercial conversion',
	'alterations_windows' = 'Glazed windows (after 2002)',
	'alterations_electrical' = 'Electrical installation (after 2005)',
	'alterations_extension' = 'Extension added',
	'alterations_conservatory' = 'Conservatory added',
	'alterations_loft' = 'Loft conversion',
	'alterations_garage' = 'Garage conversion',
	'alterations_walls' = 'Removal of internal walls',
	'alterations_other' = 'Other works',
	'alterations' = 'None',
}
