import { FC } from 'react';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import palette from 'src/theme/palette';
import { Document } from 'src/redux/types/documents';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import { downloadFile, downloadMultipleFiles } from './utils';

const DownloadButton: FC<Document> = (document) => {
	const { name, type, bucket, path } = document;
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();

	const handleClick = async () => {
		try {
			setIsLoading(true);

			if (type === 'file') {
				await downloadFile(bucket, path, name);

				return;
			}

			await downloadMultipleFiles(document);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	if (isLoading) {
		return (
			<IconButton color='info' disabled disableRipple sx={{ padding: '3px' }}>
				<Iconify icon='line-md:loading-loop' fontSize={25} color={palette.light.grey[500]} />
			</IconButton>
		);
	}

	return (
		<IconButton color='info' disabled={isLoading} onClick={handleClick} disableRipple sx={{ padding: '3px' }}>
			<Iconify icon='material-symbols:download' fontSize={25} color={palette.light.primary.main} />
		</IconButton>
	);
};

export default DownloadButton;
