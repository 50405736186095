import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import acceptTransactionInvitation from 'src/api/invitation/accept-transaction-invitation';

import LoadingScreen from 'src/components/LoadingScreen';
import { auth } from 'src/firebaseConfig';
import { PATH_AUTH } from 'src/routes/paths';
import { getTransactionInvitation, getUser } from 'src/utils/firebase';
import { logout } from 'src/api';
import { TransactionSide } from './transactions/types';

export default function Invitation() {
	const [params] = useSearchParams();
	const navigate = useNavigate();

	const transactionInvitationId = params.get('transactionInvitationId');

	useEffect(() => {
		(async () => {
			try {
				if (!transactionInvitationId) {
					navigate(PATH_AUTH.login);
					return;
				}

				const currentUser = await new Promise<firebase.default.User | null>((resolve, reject) => {
					const unsubscribe = auth.onAuthStateChanged((user) => {
						unsubscribe();
						resolve(user);
					}, reject);
				});

				const { data: transactionInvitation } = await getTransactionInvitation(transactionInvitationId);

				if (transactionInvitation.user.id !== currentUser?.uid) await logout();

				const {
					data: { email },
				} = await getUser(transactionInvitation.user.id);

				const userSignInMethods = await fetchSignInMethodsForEmail(auth, email);

				await acceptTransactionInvitation({
					userId: transactionInvitation.user.id,
					transactionInvitationId,
					transactionId: transactionInvitation.transaction.id,
					role: transactionInvitation.invitationRole,
					side: transactionInvitation.side as TransactionSide,
				}).catch(() => null);

				sessionStorage.setItem(
					'redirectTo',
					`/${transactionInvitation.isInstrument ? 'instruments' : 'transactions'}/${
						transactionInvitation.transaction.id
					}`,
				);

				if (!userSignInMethods.length) {
					navigate(PATH_AUTH.register, { state: { transactionInvitationId, email } });

					return;
				}

				navigate(PATH_AUTH.login, { state: { transactionInvitationId, email } });
			} catch (e) {
				console.error(e);

				navigate(PATH_AUTH.login);
			}
		})();
	}, [transactionInvitationId]);

	return <LoadingScreen />;
}
