import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { selectProperty, updatePropertyOnboarding } from 'src/redux/slices/property';
import { dispatch, useSelector } from 'src/redux/store';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { requestPayment } from 'src/api';
import { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import useLoading from 'src/hooks/useLoading';
import Progress from 'src/components/Progress';
import { getPaymentRef } from 'src/utils/firebase';
import { onSnapshot } from 'firebase/firestore';
import { PaymentStatusEnum, OnboardingStepStatusEnum } from 'src/types';
import { noop } from 'lodash';
import { useSnackbar } from 'notistack';
import { EmailLink } from 'src/components/common';
import { helpEmail } from 'src/constants/common';
import Iconify from 'src/components/Iconify';
import { ConfirmationModal } from '../../common';
import { Iframe } from './styled';

const Content = () => {
	const { isLoading, setIsLoading } = useLoading(true);
	const { isLoading: isIframeLoading, setIsLoading: setIsIframeLoading } = useLoading(true);
	const property = useSelector(selectProperty);
	const [paymentStatus, setPaymentStatus] = useState(PaymentStatusEnum.PENDING);
	const { enqueueSnackbar } = useSnackbar();

	const [paymentUrl, setPaymentUrl] = useState<null | string>(null);

	useEffect(() => {
		let unsubscribe = noop;

		const setup = async () => {
			setIsLoading(true);

			const response = await requestPayment(property.id);

			setPaymentUrl(response.url);
			setIsLoading(false);

			const paymentRef = getPaymentRef(response.id);

			unsubscribe = onSnapshot(paymentRef, (snapshot) => {
				if (!snapshot.exists()) return;

				const payment = snapshot.data();

				setPaymentStatus(payment.status);
			});
		};

		setup();

		return () => unsubscribe();
	}, []);

	useEffect(() => {
		if (paymentStatus === PaymentStatusEnum.FAILED) {
			enqueueSnackbar(
				<div>
					We encountered an issue processing your payment. Please try again. <br />
					If the error persists, please contact our support team at{' '}
					<EmailLink email={helpEmail} sx={{ display: 'inline' }} />.
				</div>,
				{ variant: 'error' },
			);
		}

		if (paymentStatus === PaymentStatusEnum.PAID) {
			enqueueSnackbar(<>Payment successfully processed, thank you!</>);
			dispatch(
				updatePropertyOnboarding({
					payload: { payment: OnboardingStepStatusEnum.PASSED },
					moveToNextModal: true,
				}),
			);
		}
	}, [paymentStatus]);

	const handleLoad = () => setIsIframeLoading(false);

	return (
		<Box minHeight='750px' position='relative' bgcolor='white'>
			{!isLoading && paymentUrl && (
				<Box height='100%' overflow='hidden'>
					<Iframe title='Payment' src={paymentUrl} width='100%' height='815px' onLoad={handleLoad} />
				</Box>
			)}
			{isIframeLoading && (
				<Box
					bgcolor='white'
					height='700px'
					width='100%'
					display='flex'
					justifyContent='center'
					alignItems='center'
					position='absolute'
					top={40}
					zIndex={999}
				>
					<Progress zoom={1.5} />
				</Box>
			)}
			{!isLoading && !isIframeLoading && (
				<Box
					position='absolute'
					width='100%'
					display='flex'
					justifyContent='flex-end'
					gap='10px'
					pt='10px'
					top='-35px'
					right='-15px'
				>
					<IconButton onClick={handleCloseModal}>
						<Iconify icon='icon-park-solid:close-one' fontSize='25px' />
					</IconButton>
				</Box>
			)}
		</Box>
	);
};

const PropertyPaymentModal = () => (
	<ConfirmationModal
		sx={{ maxWidth: '600px', pb: '0' }}
		modalName={propertyOnboardingSteps.payment}
		title=''
		handleClose={noop}
		withButtons={false}
		handleConfirm={handleCloseModal}
		description={<Content />}
	/>
);

export default PropertyPaymentModal;
