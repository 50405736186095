import { DocumentSnapshot, getDoc, QueryDocumentSnapshot } from 'firebase/firestore';
import { RowData } from 'src/sections/@dashboard/table/types';
import { Transaction, TransactionInvitation, User } from 'src/types';
import { AuthErrorMessages } from '../@/err-messages';
import { Firestore } from './@firestore';
import { getMyTransactions } from './get-my-transactions';
import { getTransactionInvitations } from './get-transaction-invitations';

export const getDashboardTable = async (
	firestore: Firestore,
	user,
	isProspective: boolean,
	onlyVisible: boolean,
	roles: string[],
) => {
	try {
		if (!user) throw new Error(AuthErrorMessages.noLogin);
		const userRef = user.ref;

		const transactions = await getMyTransactions(firestore);
		const transactionInvDocs = await getTransactionInvitations(
			firestore,
			isProspective || false,
			transactions.map(({ ref }) => ref),
			onlyVisible,
		);

		const allRows: Promise<RowData | undefined>[] = transactionInvDocs.reduce(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(acc, transactionInvitation: QueryDocumentSnapshot<any>) => {
				const data = transactionInvitation.data() as TransactionInvitation;
				const res: Promise<RowData | undefined>[] = [
					...acc,
					Promise.all([getDoc(data.user), getDoc(data.invitingUser), getDoc(data.transaction)]).then(
						async ([userSnap, invitingUser, transaction]: [
							DocumentSnapshot<User>,
							DocumentSnapshot<User>,
							DocumentSnapshot<Transaction>,
						]) => {
							if (isProspective && invitingUser.id !== userRef.id) return;

							const userBusiness = invitingUser.data()?.business;
							const property = transaction.exists() ? await getDoc(transaction.data().property) : null;
							const business = userBusiness ? await getDoc(userBusiness) : null;

							// eslint-disable-next-line consistent-return
							return {
								user: userSnap,
								transactionInvitation,
								businessName: business ? business.data()?.tradingName : '',
								invitingUser,
								transaction,
								property,
								// transaction.exists() ? transaction.data().prices.agreedPrice?.price : null
								purchasePrice: null,
								invitationRole: data.invitationRole,
								isProspective: data.isProspective,
								id: transactionInvitation.id,
								createdAt: data.createdAt,
							} as RowData;
						},
					),
				];
				return res;
			},
			[] as Promise<RowData | undefined>[],
		);
		const filteredList = (await Promise.all(allRows)).filter(
			(item) => item && roles.includes(item.invitationRole) && (isProspective || item.user.id === user.uid),
		);
		return filteredList as RowData[];
	} catch (error) {
		console.warn(error);
		return null;
	}
};
