import { FC, PropsWithChildren, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated, selectIsInitialized, selectUser } from 'src/redux/slices/auth';
import { logout } from 'src/api';
import { PATH_DASHBOARD } from '../routes/paths';
import LoadingScreen from '../components/LoadingScreen';

const GuestGuard: FC<PropsWithChildren<{ forceLogout?: boolean }>> = ({ children, forceLogout }) => {
	const isAuthenticated = useSelector(selectIsAuthenticated);
	const user = useSelector(selectUser);
	const isInitialized = useSelector(selectIsInitialized);
	const [logoutDone, setLogoutDone] = useState(!forceLogout || !isAuthenticated);

	if (!isInitialized || !logoutDone) return <LoadingScreen />;
	if (!forceLogout && isAuthenticated && user) {
		const redirectTo = sessionStorage.getItem('redirectTo');

		return <Navigate to={redirectTo ?? user.onboarding?.currentPage ?? PATH_DASHBOARD.root} />;
	}
	if (forceLogout && isAuthenticated) logout().then(() => setLogoutDone(true));

	return <>{children}</>;
};

export default GuestGuard;
