module.exports = {
	/** @type {'ACCEPTED'} */
	accepted: 'ACCEPTED',
	/** @type {'PENDING'} */
	pending: 'PENDING',
	/** @type {'REQUEST'} */
	request: 'REQUEST',
	/** @type {'REJECTED'} */
	rejected: 'REJECTED',
	/** @type {'OFFER_SENT'} */
	offerSent: 'OFFER_SENT',
};
