import { SVGProps } from 'react';

const PendingIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 100 100' x='0px' y='0px' {...props}>
		<path
			d='M41.409,79.292A31.879,31.879,0,1,1,79.291,41.41a3.751,3.751,0,0,0,7.336-1.572A39.38,39.38,0,1,0,39.838,86.625a3.75,3.75,0,1,0,1.571-7.333Z'
			fill={props.color}
		/>
		<path
			d='M44.375,25.625v18.75H33.125a3.75,3.75,0,0,0,0,7.5h15a3.75,3.75,0,0,0,3.75-3.75v-22.5a3.75,3.75,0,0,0-7.5,0Z'
			fill={props.color}
		/>
		<path
			d='M70.625,46.25A24.375,24.375,0,1,0,95,70.625,24.4,24.4,0,0,0,70.625,46.25Zm0,41.25A16.875,16.875,0,1,1,87.5,70.625,16.895,16.895,0,0,1,70.625,87.5Z'
			fill={props.color}
		/>
		<path
			d='M70.625,57.5a3.75,3.75,0,0,0-3.75,3.75v9.375a3.75,3.75,0,0,0,7.5,0V61.25A3.75,3.75,0,0,0,70.625,57.5Z'
			fill={props.color}
		/>
		<circle cx='70.625' cy='80' r='3.75' fill={props.color} />
	</svg>
);

export default PendingIcon;
