import { PropertyTitleTenure } from 'src/types';

export const labelByPropertyTitleTenure: Record<PropertyTitleTenure, string> = {
	other: 'Other',
	freehold: 'Freehold',
	leasehold: 'Leasehold',
	commonhold: 'Commonhold',
	feuhold: 'Feuhold',
	mixed: 'Mixed',
	unknown: 'Unknown',
	unavailable: 'Unavailable',
	cautionAgainstFirstRegistration: 'Caution Against First Registration',
	rentCharge: 'Rent Charge',
	franchise: 'Franchise',
	profitAPrendreInGross: 'Profit A Prendre In Gross',
	manor: 'Manor',
};
