/* eslint-disable @typescript-eslint/no-explicit-any */
import { updateDoc } from 'firebase/firestore';
import { NavigateFunction } from 'react-router';
import Iconify from 'src/components/Iconify';
import { setAnchorEl } from 'src/redux/slices/headerSlice';
import { closeModal, openModal } from 'src/redux/slices/modal';
import {
	getAdminNav,
	getPrincipalNav,
	getEstateNav,
	getMortgageBrokerNav,
	getSolicitorNav,
	getSurveyorNav,
	getTradesPersonNav,
} from 'src/redux/slices/navSlice';
import { RootState, dispatch, getState } from 'src/redux/store';
import { PATH_INSTRUMENTS, PATH_TRANSACTIONS, ROOTS_DASHBOARD } from 'src/routes/paths';
import { FParties, FRoles, PropertyOnboarding, OnboardingStepStatusEnum, PropertyTitle } from 'src/types';
import { sellProperty } from 'src/api/property';
import { INewProperty, PropertyOnboardingStep } from 'src/pages/properties/types';
import addProperty, { Payload as AddPropertyPayload } from 'src/api/property/add';
import { propertyOnboardingSteps, propertyOnboardingStepsKeys } from 'src/pages/properties/constants';
import { TransactionRole, TransactionSide } from 'src/pages/transactions/types';
import { IFindAddressByPostcodeResponse } from 'src/api/epc/epc-types';
import { GroupedTitle } from 'src/sections/property/types';
import { capitalize } from 'lodash';
import { inactiveTransactionStates } from 'src/components/modals/transaction/UpdateTransactionState/constants';
import { getProperty, getTransactionRef } from './firebase';
import { notAdminTransactionsFilter } from './transaction';

export const docsToNav = async (
	docs,
	path,
	icon = 'codicon:key',
	filter: (data) => boolean = notAdminTransactionsFilter,
) => {
	const areTransactionDocs = path === PATH_TRANSACTIONS;
	const areInstrumentsDocs = path === PATH_INSTRUMENTS;

	const extractedDocuments = docs
		.map((d) => ({ ...d.data(), id: d.id }))
		.sort((a, b) => {
			const numA = a.name.match(/^(\d+)/);
			const numB = b.name.match(/^(\d+)/);

			const valA = numA ? parseInt(numA[1], 10) : Infinity;
			const valB = numB ? parseInt(numB[1], 10) : Infinity;

			return valA - valB;
		});

	const items: any[] = [];

	for await (const data of extractedDocuments) {
		if (data.isInstrument && areTransactionDocs) continue;
		if (!filter(data)) continue;

		let name = !(areTransactionDocs || areInstrumentsDocs) ? data.address.displayAddress : '';

		if (areTransactionDocs || areInstrumentsDocs) {
			if (inactiveTransactionStates.includes(data.state)) continue;

			const property = await getProperty(data.property?.id);

			name = property.data.address.displayAddress;
		}

		items.push({
			id: data.id,
			title: name,
			property: data.property,
			path: path.view(data.id),
			icon: <Iconify icon={icon} />,
		});
	}

	return items;
};

export const extractNewPropertyAddress = (address: IFindAddressByPostcodeResponse): AddPropertyPayload['address'] => ({
	postcode: address.postcode,
	townCity: address.town_city,
	county: address.county,
	address: address.address,
	displayAddress: address.display_address,
	district: address.district,
	street: address.street,
	locality: address.locality,
	latitude: address.latitude,
	longitude: address.longitude,
	paon: address.paon,
	saon: address.saon,
});

export const addTransaction = async (
	data: INewProperty & { uid: string; isInstrument?: boolean; role?: TransactionRole; side?: TransactionSide },
) => {
	// .catch for case when property exists
	const propertyRef = await addProperty({
		...data,
		address: extractNewPropertyAddress(data.address),
	}).catch(() => ({ uprn: data.uprn }));

	return sellProperty(
		{ propertyId: propertyRef.uprn, role: data.role, side: data.side, isInstrument: data.isInstrument },
		'A transaction for this property already exists. Not expecting this message? Check your navigation bar on the left or ask your introducer for an invite.',
	);
};

export const fromRoleToPartie = (role: FRoles) =>
	({
		[FRoles.BUYER]: FParties.BUYER,
		[FRoles.SELLER]: FParties.SELLER,
		[FRoles.ESTATE_AGENT]: FParties.ESTATE_AGENT,
		[FRoles.SELL_SIDE_SOLICITOR]: FParties.SELL_SIDE_SOLICITOR,
		[FRoles.BUY_SIDE_SOLICITOR]: FParties.BUY_SIDE_SOLICITOR,
	}[role]);

export const abandonUserFunc = (id: string, navigate: NavigateFunction) => {
	const docRef = getTransactionRef(id);
	const abandon = async (role: FRoles) => {
		await updateDoc<unknown>(docRef, {
			[`parties.${role}`]: {
				userRef: null,
				approved: false,
			},
		});
		await getNav();
		navigate(ROOTS_DASHBOARD);
	};

	return abandon;
};

export const handleOpenModal = (modal: string) => {
	dispatch(setAnchorEl(null));
	dispatch(openModal({ name: modal }));
};

export const handleCloseModal = () => dispatch(closeModal());

export async function getNav() {
	const {
		auth: { user },
	}: RootState = getState();

	if (!user) return;

	if (user.isAdmin) await dispatch(getAdminNav());
	if (user.isEstateAgent) await dispatch(getEstateNav());
	if (user.isTradesPerson) await dispatch(getTradesPersonNav());
	if (user.isMortgageBroker) await dispatch(getMortgageBrokerNav());
	if (user.isSurveyor) await dispatch(getSurveyorNav());
	if (user.isSolicitorConveyancer) await dispatch(getSolicitorNav());
	if (user.isPrincipal) await dispatch(getPrincipalNav());
}

export const calculatePropertyRatingPixels = (value: number) => {
	if (value <= 20) return 31;
	if (value <= 38) return 81;
	if (value <= 54) return 131;
	if (value <= 68) return 181;
	if (value <= 81) return 231;
	if (value <= 91) return 281;

	return 331;
};

export const sortPropertyOnboardingSteps = (steps: PropertyOnboardingStep[]) =>
	steps.sort((a, b) => propertyOnboardingStepsKeys.indexOf(a) - propertyOnboardingStepsKeys.indexOf(b));

export const getPropertyOnboardingLatestStep = (onboarding: PropertyOnboarding): PropertyOnboardingStep | undefined => {
	const onboardingSideKeys = Object.keys(onboarding) as PropertyOnboardingStep[];

	return sortPropertyOnboardingSteps(onboardingSideKeys).find(
		(step) => onboarding[step] === OnboardingStepStatusEnum.PENDING,
	);
};

export const getPropertyOnboardingModalName = (step: PropertyOnboardingStep) => propertyOnboardingSteps[step];

export const groupPropertyTitles = (titles: PropertyTitle[]) =>
	titles.reduce<GroupedTitle[]>((acc, item) => {
		const existingTitleIndex = acc.findIndex((t) => t.address === item.address);
		const formattedItem = {
			name: `${item.number} (${capitalize(item.tenure)})`,
			key: item.number,
		};

		if (existingTitleIndex !== -1) {
			acc[existingTitleIndex].titles.push(formattedItem);

			return acc;
		}

		acc.push({ address: item.address, titles: [formattedItem] });

		return acc;
	}, []);
