import { getStorage } from 'firebase/storage';
import { firebaseApp } from 'src/firebaseConfig';

function storageLink(storage: string) {
	return `gs://${process.env[`REACT_APP_${storage.toUpperCase()}_BUCKET`]}`;
}

export default {
	properties: getStorage(firebaseApp, storageLink('properties')),
	transactions: getStorage(firebaseApp, storageLink('transactions')),
	publicAssets: getStorage(firebaseApp, storageLink('public_assets')),
};
