import { FC } from 'react';
import { Payment, PaymentStatusEnum } from 'src/types';

const PaymentField: FC<Payment> = ({ status, paymentUrl }) => (
	<div>
		{status === PaymentStatusEnum.PAID ? '🟢' : '🟡'}{' '}
		<a href={paymentUrl} target='_blank' rel='noreferrer'>
			Link
		</a>
	</div>
);

export default PaymentField;
