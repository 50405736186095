import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SecondTitle = styled(Typography)({
	marginTop: '24px',
	fontFamily: 'Public Sans',
	fontStyle: 'normal',
	fontWeight: '700',
	fontSize: '16px',
});

export const DescriptionBlock = styled(Box)({
	fontFamily: 'Public Sans',
	fontStyle: 'normal',
	fontWeight: '400',
	fontSize: '14px',
	color: '#697885',
	span: {
		'&:not(:first-of-type)': {
			marginLeft: '30px',
		},
	},
});

export const StyledGridUnit = styled('span')({
	textTransform: 'lowercase',
});

export const StyledGrid = styled(Grid)({
	display: 'flex',
	flexDirection: 'column',
	fontFamily: 'Public Sans',
	fontStyle: 'normal',
	fontWeight: '400',
	fontSize: '14px',
	color: '#697885',

	b: {
		fontFamily: 'Roboto',
		fontWeight: '600',
		textTransform: 'capitalize',
	},
});
