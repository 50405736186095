import type { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormHelperText, Box } from '@mui/material';
import OTPInput from 'react-otp-input';
import type { IRHFOtpInput } from './types';
import { StyledOtpInput } from './styles';

const RHFOtpInput: FC<IRHFOtpInput> = ({ name }) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<Box width='100%' display='flex' flexDirection='column' alignItems='center'>
					<OTPInput
						{...field}
						shouldAutoFocus
						placeholder='------'
						renderInput={(props) => <StyledOtpInput inputProps={props} />}
						numInputs={6}
						containerStyle={{ display: 'flex', gap: '7px' }}
					/>
					<FormHelperText error sx={{ px: 2 }}>
						{!!error && error.message}
					</FormHelperText>
				</Box>
			)}
		/>
	);
};

export default RHFOtpInput;
