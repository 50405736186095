import { Box, LinearProgress, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Iconify from 'src/components/Iconify';
import { propertyDataExtractionJobsCount } from 'src/constants/common';
import { selectPropertySlice } from 'src/redux/slices/property';
import PropertyProgressItem from './components/PropertyProgressItem';

const PropertyProgress = () => {
	const { property } = useSelector(selectPropertySlice);

	if (!property?.dataExtraction) return null;

	const progress = Math.round((property.dataExtraction.completedJobs / propertyDataExtractionJobsCount) * 100);

	if (!property?.dataExtraction || progress === 100) return null;

	return (
		<Box pt='10px' pr='50px' pl='30px' width='100%' display='flex' flexDirection='column' gap='7px'>
			<LinearProgress variant='determinate' value={progress} sx={{ width: '100%' }} />
			<Typography
				variant='subtitle1'
				display='flex'
				justifyContent='center'
				alignItems='center'
				gap='5px'
				color='grey'
				fontSize='14px'
			>
				Loading property data ({property.dataExtraction.completedJobs}/{propertyDataExtractionJobsCount}){' '}
				<Tooltip
					title={
						<Box display='flex' flexDirection='column' gap='7px' p='5px'>
							<PropertyProgressItem stage='epc' isCompleted={!!property.dataExtraction.stages?.epc} />
							<PropertyProgressItem stage='council' isCompleted={!!property.dataExtraction.stages?.council} />
							<PropertyProgressItem stage='utilities' isCompleted={!!property.dataExtraction.stages?.utilities} />
							<PropertyProgressItem
								stage='leases'
								isCompleted={!!(property.dataExtraction.stages?.leases && property.dataExtraction.stages?.covenants)}
							/>
							<PropertyProgressItem stage='listings' isCompleted={!!property.dataExtraction.stages?.listings} />
						</Box>
					}
					arrow
				>
					<Box lineHeight={0}>
						<Iconify icon='material-symbols:info-outline' sx={{ cursor: 'pointer' }} color='grey' fontSize={14} />
					</Box>
				</Tooltip>
			</Typography>
		</Box>
	);
};

export default PropertyProgress;
