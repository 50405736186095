import type { DocumentReference } from 'firebase/firestore';
import findCommonElements from 'src/utils/findCommonElements';
import { collections, importFirestore, invitationStatuses, type Firestore } from './@firestore';
import { getMyRef } from './get-my-ref';

// for some unknown (yet?) reason, `orderBy` prevents transaction invitations from reading
// only on real env, not on test one, so filter & sorting should be done on the client side

export async function getTransactionInvitations(
	firestore: Firestore,
	isProspective: boolean,
	transactionRefs: DocumentReference[],
	onlyVisible = true,
) {
	// condition to avoid firebase empty query 'in' value error
	if (!transactionRefs.length) return [];
	const { collection, getDocs, query, where } = importFirestore();
	const myRef = getMyRef(firestore);
	const { docs } = await getDocs(
		query(
			collection(firestore, collections.transactionInvitations),
			where('status', 'in', [
				invitationStatuses.pending,
				...(isProspective ? [invitationStatuses.request, invitationStatuses.offerSent] : []),
			]),
			where('isVisible', 'in', onlyVisible ? [true] : [true, false]),
			where(isProspective ? 'invitingUser' : 'user', '==', myRef),
			// where('transaction', 'in', transactionRefs),
		),
	);
	// to avoid the error "Too many disjunctions after normalization" the following code is used.
	// instead of " where('transaction', 'in', transactionRefs)," we implement the filter ourselves.
	const transRefPath = transactionRefs.map((el) => el.path);
	const docsRefPath = docs.map((el) => el.data().transaction.path);
	const filtredElems = findCommonElements(transRefPath, docsRefPath);
	const filtredDocs = docs.filter((el) => filtredElems.includes(el.data().transaction.path));

	return filtredDocs
		.filter((v) => v.data().createdAt)
		.sort((a, b) => Number(new Date(b.data().createdAt)) - Number(new Date(a.data().createdAt)));
}
