import { dispatch } from 'src/redux/store';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { OnboardingStepStatusEnum } from 'src/types';
import { updatePropertyOnboarding } from 'src/redux/slices/property';
import useLoading from 'src/hooks/useLoading';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { ConfirmationModal } from '../../common';
import { DocumentsList } from './styles';

const PropertyVerificationRequestModal = () => {
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(
			updatePropertyOnboarding({
				moveToNextModal: true,
				payload: {
					verificationRequest: OnboardingStepStatusEnum.PASSED,
					verificationIntroduction: OnboardingStepStatusEnum.PASSED,
				},
			}),
		);

		setIsLoading(false);
	};

	const handleCancel = async () => dispatch(openModal({ name: modals.cancelPropertyOnboarding }));

	return (
		<ConfirmationModal
			sx={{ maxWidth: '650px' }}
			modalName={propertyOnboardingSteps.verificationRequest}
			isLoading={isLoading}
			title='GET VERIFIED'
			cancelText='Cancel'
			confirmText='Continue'
			handleConfirm={handleConfirm}
			handleCancel={handleCancel}
			description={
				<>
					For this step, you will need:
					<DocumentsList>
						<li>your mobile phone</li>
						<li>a valid passport or driving licence</li>
						<li>a utility bill, council tax bill or bank statement issued in the last 3 months</li>
					</DocumentsList>
					<br />
					Once your identity is confirmed, you will be able to move on to the property register checks.
				</>
			}
		/>
	);
};

export default PropertyVerificationRequestModal;
