import { useEffect } from 'react';
import VerificationModal from 'src/components/modals/common/VerificationModal';
import {
	AbandonTransactionModal,
	TransactionTitleModal,
	TransactionVerificationFailModal,
	TransactionVerificationInProgressModal,
	TransactionVerificationIntroductionModal,
} from 'src/components/modals';
import {
	processTransactionOnboarding,
	selectTransactionOnboarding,
	selectTransactionOverviewField,
} from 'src/redux/slices/transaction';
import { dispatch, useSelector } from 'src/redux/store';
import { selectPropertySlice } from 'src/redux/slices/property';
import { noop } from 'lodash';
import { getTransactionOnboardingRef } from 'src/utils/firebase';
import { onSnapshot } from 'firebase/firestore';
import { openModal, selectModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import TransactionTitleHelpModal from 'src/components/modals/transaction/TransactionTitleHelp';
import TransactionCompletionModal from 'src/components/modals/transaction/TransactionCompletion';
import TransactionTitleConfirmationModal from 'src/components/modals/transaction/TransactionTitleConfirmation';
import TransactionTitleErrorModal from 'src/components/modals/transaction/TransactionTitleError';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import VerificationNameConfirmationModal from 'src/components/modals/common/VerificationNameConfirmation';
import VerificationAddressConfirmationModal from 'src/components/modals/common/VerificationAddressConfirmation';
import VerificationIssueModal from 'src/components/modals/common/VerificationIssueModal';
import VerificationAddressIssueModal from 'src/components/modals/common/VerificationAddressIssue';
import VerificationNameIssueModal from 'src/components/modals/common/VerificationNameIssue';
import { transactionOnboardingSteps } from '../../constants';

const TransactionOnboardingFlow = () => {
	const { data } = useSelector(selectTransactionOverviewField);
	const transactionOnboarding = useSelector(selectTransactionOnboarding);
	const { property } = useSelector(selectPropertySlice);
	const { modalName } = useSelector(selectModal);

	useEffect(() => {
		let unsubscribe = noop;

		if (!data?.id) return unsubscribe;

		const setup = async () => {
			const onboarding = await dispatch(processTransactionOnboarding({ transactionId: data.id })).unwrap();

			if (!onboarding) return;

			const onboardingRef = getTransactionOnboardingRef(onboarding.id);

			unsubscribe = onSnapshot(onboardingRef, () => {
				dispatch(processTransactionOnboarding({ transactionId: data.id }));
			});
		};

		setup();

		return unsubscribe;
	}, [data?.id]);

	if (!data || !property) return null;

	const handleCancel = () => {
		if (transactionOnboarding?.type === 'verification') {
			handleCloseModal();

			return;
		}

		dispatch(openModal({ name: modals.abandonTransaction, payload: { previousModal: modalName } }));
	};

	return (
		<>
			<TransactionVerificationIntroductionModal handleCancel={handleCancel} />
			<VerificationModal name={transactionOnboardingSteps.verification} handleClose={noop} />
			<TransactionVerificationInProgressModal />
			<TransactionVerificationFailModal />
			<VerificationNameConfirmationModal />
			<VerificationNameIssueModal />
			<VerificationAddressConfirmationModal />
			<VerificationAddressIssueModal />
			<VerificationIssueModal />
			<TransactionTitleModal />
			<TransactionTitleConfirmationModal />
			<TransactionTitleHelpModal />
			<TransactionTitleErrorModal />
			<TransactionCompletionModal />
			<AbandonTransactionModal />
		</>
	);
};

export default TransactionOnboardingFlow;
