import { TransactionTenure } from 'src/redux/types';
import { IQuestionnaireProperty } from 'src/types';

export const ownership = (
	tenure: TransactionTenure | undefined,
	propertyQuestionnaire: IQuestionnaireProperty | null,
) => {
	const isLeasehold = tenure === 'leasehold';
	const isShareOfFreehold = propertyQuestionnaire?.property_details.dwelling?.share_of_freehold_yn === true;
	const isNotShareOfFreehold = propertyQuestionnaire?.property_details.dwelling?.share_of_freehold_yn === false;

	return {
		logoWidth: '100px',
		logoHeight: '100px',
		logoPosition: 'right',
		completedHtml: "<h3>That's it! Thank you for completing the Ownership card! 🎉</h3>",
		completedBeforeHtml: '<h3>It looks like you have already answered all questions in this section 🧐.</h3>',
		loadingHtml: '<h3>Loading questions...</h3>',
		pages: [
			{
				name: 'management',
				elements: [
					...(isShareOfFreehold
						? [
								{
									type: 'boolean',
									name: 'freehold_management_company_yn',
									title: 'Have the freeholders formed a freehold management company? *',
									titleLocation: 'left',
									hideNumber: true,
									isRequired: true,
								},
						  ]
						: []),
					{
						type: 'radiogroup',
						name: 'freeholders_company_dissolved',
						visibleIf: '{freeholders_company_yn} = true',
						title:
							'Has any management company formed by the freeholders ever been dissolved or struck off Companies House? *',
						hideNumber: true,
						requiredIf: '{freeholders_company_yn} = true',
						choices: [
							{
								value: 'yes',
								text: 'Yes',
							},
							{
								value: 'no',
								text: 'No',
							},
							{
								value: 'not_known',
								text: 'Not known',
							},
						],
						colCount: 3,
					},
					{
						type: 'boolean',
						name: 'management_company_yn',
						...(isShareOfFreehold && { visibleIf: '{freeholders_company_yn} = false' }),
						title: 'Is the building managed by a management company? *',
						titleLocation: 'left',
						description: 'Their role could be as simple as collecting rent.',
						...(isNotShareOfFreehold && { visible: isLeasehold }),
						hideNumber: true,
						isRequired: true,
					},
					...(isNotShareOfFreehold
						? [
								{
									type: 'radiogroup',
									name: 'management_company_contracted_by',
									visibleIf: '{management_company_yn} = true',
									title: 'Who contracts the management company? *',
									hideNumber: true,
									isRequired: true,
									choices: [
										{
											value: 'landlord',
											text: 'Landlord',
										},
										{
											value: 'residents',
											text: 'Residents',
										},
									],
									colCount: 2,
								},
						  ]
						: []),
					...(isLeasehold && isNotShareOfFreehold
						? [
								{
									type: 'multipletext',
									name: 'landlord',
									title: 'Landlord',
									description: "Please provide the landlord's contact details",
									hideNumber: true,
									isRequired: true,
									items: [
										{
											name: 'name',
											isRequired: true,
											title: 'Name *',
										},
										{
											name: 'address',
											isRequired: true,
											title: 'Address *',
										},
										{
											name: 'telephone',
											title: 'Phone',
										},
										{
											name: 'email',
											title: 'Email',
										},
									],
								},
						  ]
						: []),
					{
						type: 'multipletext',
						name: 'management_company',
						visibleIf: '{freeholders_company_yn} = true or {management_company_yn} = true',
						title: 'Management company',
						description: "Please provide the management company's contact details",
						hideNumber: true,
						requiredIf: '{freeholders_company_yn} = true or {management_company_yn} = true',
						items: [
							{
								name: 'name',
								isRequired: true,
								title: 'Name *',
							},
							{
								name: 'address',
								isRequired: true,
								title: 'Address *',
							},
							{
								name: 'telephone',
								isRequired: true,
								title: 'Phone *',
							},
							{
								name: 'email',
								isRequired: true,
								title: 'Email *',
							},
						],
					},
				],
				visible: isLeasehold || isShareOfFreehold,
				...(!isLeasehold && { visible: isShareOfFreehold }),
				title: 'Management',
			},
			{
				name: 'charge',
				elements: [
					{
						type: 'boolean',
						name: 'rent_yn',
						title: 'Do you pay ground rent?',
						titleLocation: 'left',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'text',
						name: 'rent_amount',
						visibleIf: '{rent_yn} = true',
						title: 'How much is the current yearly ground rent (in £)? *',
						titleLocation: 'left',
						hideNumber: true,
						requiredIf: '{rent_yn} = true',
						inputType: 'number',
						min: 0.01,
						step: 0.01,
					},
					{
						type: 'dropdown',
						name: 'rent_frequency',
						visibleIf: '{rent_yn} = true',
						title: 'How often do you pay ground rent? *',
						titleLocation: 'left',
						hideNumber: true,
						requiredIf: '{rent_yn} = true',
						choices: [
							{
								value: 'weekly',
								text: 'Weekly',
							},
							{
								value: 'fortnightly',
								text: 'Fortnightly',
							},
							{
								value: 'monthly',
								text: 'Monthly',
							},
							{
								value: 'quarterly',
								text: 'Quarterly',
							},
							{
								value: 'yearly',
								text: 'Yearly',
							},
						],
						showOtherItem: true,
					},
					{
						type: 'boolean',
						name: 'building_maintenance_seller',
						title: 'Do you contribute to the cost of maintaining the building? *',
						titleLocation: 'left',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'radiogroup',
						name: 'building_maintenance_arrangements',
						visibleIf: '{building_maintenance_seller} = true',
						title:
							'Do you pay a regular charge (e.g. service charge) or there is an informal arrangement to cover costs on a need-be basis? *',
						hideNumber: true,
						requiredIf: '{building_maintenance_seller} = true',
						choices: [
							{
								value: 'regular_charge',
								text: 'Regular charge',
							},
							{
								value: 'informal_arrangement',
								text: 'Informal arrangement',
							},
						],
						colCount: 2,
					},
					{
						type: 'text',
						name: 'service_charge_amount',
						visibleIf: "{building_maintenance_arrangements} = 'regular_charge'",
						title: 'How much is the service charge (in £)? *',
						titleLocation: 'left',
						hideNumber: true,
						requiredIf: "{building_maintenance_arrangements} = 'regular_charge'",
						inputType: 'number',
						min: 0.01,
						step: 0.01,
					},
					{
						type: 'dropdown',
						name: 'service_charge_frequency',
						visibleIf: "{building_maintenance_arrangements} = 'regular_charge'",
						title: 'How often do you pay service charge? *',
						titleLocation: 'left',
						hideNumber: true,
						requiredIf: "{building_maintenance_arrangements} = 'regular_charge'",
						choices: [
							{
								value: 'weekly',
								text: 'Weekly',
							},
							{
								value: 'fortnightly',
								text: 'Fortnightly',
							},
							{
								value: 'monthly',
								text: 'Monthly',
							},
							{
								value: 'quarterly',
								text: 'Quarterly',
							},
							{
								value: 'half-yearly',
								text: 'Half-yearly',
							},
							{
								value: 'yearly',
								text: 'Yearly',
							},
						],
						showOtherItem: true,
					},
					{
						type: 'boolean',
						name: 'other_charges_yn',
						visible: false,
						title: 'Do you pay any other charges relating to the property? *',
						description:
							'Excluding payments such as council tax and utility bills. For example, payments to a management company or for the use of a private drainage system.',
						hideNumber: true,
						isRequired: true,
					},
					{
						type: 'comment',
						name: 'other_charges_desc',
						visibleIf: '{other_charges_yn} = true',
						title: 'Tell us more',
						hideNumber: true,
						isRequired: true,
						requiredIf: '{other_charges_yn} = true',
					},
					{
						type: 'text',
						name: 'decoration_facade_year',
						width: '66%',
						title: 'In what year was the outside of the building last decorated?',
						titleLocation: 'left',
						description: 'Leave empty if not known.',
						descriptionLocation: 'underInput',
						hideNumber: true,
						inputType: 'number',
						min: 1900,
						step: 1,
					},
					{
						type: 'text',
						name: 'decoration_communal_year',
						width: '66%',
						title: 'In what year were any internal communal parts last decorated?',
						titleLocation: 'left',
						description: 'Leave empty if not known.',
						descriptionLocation: 'underInput',
						hideNumber: true,
						inputType: 'number',
						min: 1900,
					},
				],
				visible: isLeasehold || isShareOfFreehold,
				title: 'Charges',
			},
		],
		showCompletedPage: false,
		showProgressBar: 'top',
		completeText: 'Submit',
		widthMode: 'static',
	};
};
