import { type Firestore, collections, importFirestore } from './@firestore';
import { getMyRef } from './get-my-ref';

export async function getMyTransactions(firestore: Firestore) {
	const { collection, getDocs, query, where, orderBy } = importFirestore();
	const me = getMyRef(firestore);
	const [
		{ docs: buyerDocs },
		{ docs: eaDocs },
		{ docs: sellerDocs },
		{ docs: sbDocs },
		{ docs: ssDocs },
		{ docs: pbDocs },
	] = await Promise.all([
		...['buyer', 'estate_agent', 'seller', 'solicitor_buyside', 'solicitor_sellside'].map((role) =>
			getDocs(query(collection(firestore, collections.transactions), where(`parties.${role}.userRef`, '==', me))),
		),
		getDocs(query(collection(firestore, collections.transactions), orderBy(`parties.prospective_buyers.${me.id}`))),
	]);
	return [...buyerDocs, ...eaDocs, ...sellerDocs, ...sbDocs, ...ssDocs, ...pbDocs].sort((a, b) =>
		b.id > a.id ? 1 : a.id > b.id ? -1 : 0,
	);
}
