import { SxProps } from '@mui/material';

export const modalSx: SxProps = {
	'div[data-key="account0"]': {
		'.sd-panel__content': {
			'.sd-description': { paddingBottom: 0 },
			'.sv-string-viewer': { fontSize: '15px' },
			'.sd-clearfix': { marginTop: '15px', marginBottom: 0 },
		},
	},
};
