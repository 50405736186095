import { Box, CircularProgress, Typography } from '@mui/material';
import { FC } from 'react';
import Iconify from 'src/components/Iconify';
import { propertyDataExtractionStagesNames } from 'src/pages/properties/constants';
import palette from 'src/theme/palette';
import { PropertyProgressItemProps } from './types';

const PropertyProgressItem: FC<PropertyProgressItemProps> = ({ stage, isCompleted }) => (
	<Box display='flex' justifyContent='space-between' alignItems='center' gap='5px' key={stage}>
		<Typography textAlign='center' width='100%' fontSize='13px' fontWeight={500} color='lightgrey'>
			{propertyDataExtractionStagesNames[stage]}
		</Typography>
		{isCompleted ? (
			<Iconify icon='mdi:done-outline' color={palette.light.success.main} fontSize={18} />
		) : (
			<CircularProgress size='15px' />
		)}
	</Box>
);

export default PropertyProgressItem;
