import { noop } from 'lodash';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

export default function useAppBarHeight() {
	const location = useLocation();
	const [appBarHeight, setAppBarHeight] = useState(0);

	useEffect(() => {
		const bar = document.querySelector('header.MuiAppBar-root');

		if (!bar) return noop;

		const resizeObserver = new ResizeObserver((entries) => {
			setAppBarHeight(entries[0].contentRect.height);
		});

		resizeObserver.observe(bar);

		return () => resizeObserver.disconnect();
	}, [location.pathname]);

	return appBarHeight;
}
