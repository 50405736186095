export default function findCommonElements(arr1: string[], arr2: string[]): string[] {
	const set = new Set(arr1);
	const commonElements: string[] = [];
	const duplicates: Set<string> = new Set();

	for (const element of arr2) {
		if (set.has(element) && !duplicates.has(element)) {
			commonElements.push(element);
			duplicates.add(element);
		}
	}

	return commonElements;
}
