import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Stack, Typography } from '@mui/material';
import { FONT_ROBOTO } from 'src/theme/typography';
import { ILogo } from './types';

// ----------------------------------------------------------------------

const Logo: FC<ILogo> = ({ disabledLink = false, sx, full = false, zoom = 1 }) => {
	// OR
	// -------------------------------------------------------
	const logo = full ? (
		<Stack direction='row' alignItems='center' sx={{ zoom }}>
			<Box component='img' src='/logo/logo_single.svg' sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }} />
			<Typography
				sx={{
					marginLeft: '10px',
					fontFamily: FONT_ROBOTO,
					fontSize: 28,
					fontWeight: 600,
					color: 'black',
				}}
			>
				conveyo
			</Typography>
		</Stack>
	) : (
		<Box component='img' src='/logo/logo_single.svg' sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }} />
	);

	if (disabledLink) {
		return <>{logo}</>;
	}

	return (
		<RouterLink to='/dashboard' style={{ textDecoration: 'none' }}>
			{logo}
		</RouterLink>
	);
};

export default Logo;
