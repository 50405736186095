import { Box, Card, IconButton, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import palette from 'src/theme/palette';
import { FONT_ROBOTO } from 'src/theme/typography';
import Image from '../Image';
import { StatusBarProps } from './types';
import Label from '../Label';

export const TypoBranchName = styled(Typography)<{ hasLogo: boolean }>(({ theme, hasLogo }) => ({
	fontWeight: 400,
	color: theme.palette.grey[800],
	marginTop: '10px',
	...(hasLogo && {
		width: '100%',
		whiteSpace: 'nowrap',
		maxWidth: '200px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
}));

export const TypoCompanyName = styled(Typography)<{ hasLogo: boolean }>(({ hasLogo }) => ({
	fontSize: 16,
	lineHeight: '19px',
	fontWeight: 600,
	...(hasLogo && {
		width: '100%',
		whiteSpace: 'nowrap',
		maxWidth: '200px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
}));

export const InviteLogo = styled(Image)(() => ({
	maxHeight: '56px',
	objectFit: 'contain',
}));

export const GeneralBoxWrapper = styled(Box)(() => ({
	display: 'flex',
	rowGap: '10px',
	flexDirection: 'column',
	margin: '20px 15px',
}));

export const FieldWrapper = styled(Box)({
	display: 'flex',
	columnGap: '5px',
});

export const IconWrapper = styled(Box)(({ theme }) => ({
	minWidth: '28px',
	height: '28px',
	borderRadius: '50%',
	backgroundColor: theme.palette.primary.light,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const ValueWrapper = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '100%',
	backgroundColor: theme.palette.grey[0],
}));

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#FFFFFF',
		boxShadow: '0px 4px 8px #CACACA',
		fontFamily: "'Roboto', sans-serif",
		color: theme.palette.custom.bidyText,
		fontWeight: 400,
		fontSize: '16px',
		maxWidth: 'none',
	},
}));

export const CardStyled = styled(Card)<{ isActive: boolean; isCurrentUser?: boolean; isExistingUser?: boolean }>(
	({ isActive, isCurrentUser, isExistingUser, theme }) => ({
		margin: '25px 22px',
		paddingTop: isExistingUser ? '34px' : '18px',
		boxShadow: `0px 1px 1px #F1F1F1, 0px 4px 16px ${isCurrentUser ? theme.palette.warning.lighter : '#E9E9E9'}`,
		width: 'calc(33% - 44px)',
		maxWidth: 400,
		minWidth: 370,
		height: 240,
		color: isActive ? 'grey.800' : '#929292',
		backgroundColor: isActive ? 'white' : '#E7E7E7',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	}),
);

export const ContentContainer = styled('div')({
	padding: '0 20px',
});

export const PurchaseConfirmationLabelStyle = {
	position: 'absolute',
	right: 45,
	height: '24px',
	fontSize: '14px',
	fontWeight: 700,
	top: 95,
};

export const Bid = styled(Label)({
	padding: '7px 5px',
	flexDirection: 'column',
	height: 'fit-content',
	width: '150px',
	alignItems: 'center',
	textAlign: 'center',
	fontSize: '13px',
});

export const BidPrice = styled('span')({
	width: '90%',
	wordWrap: 'break-word',
	tableLayout: 'fixed',
	display: 'inline-table',
	whiteSpace: 'pre-wrap',
	'::before': {
		content: '"£"',
		paddingRight: '3px',
	},
});

export const TextUserName = styled(Typography)({
	maxWidth: '80%',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

export const UserNameStyled = styled(Typography)<{ editable: boolean }>(({ editable }) => ({
	textTransform: 'uppercase',
	lineHeight: '20px',
	display: 'flex',
	alignItems: 'center',
	columnGap: '10px',
	...(editable && { paddingRight: '25px' }),
	'& span': {
		cursor: 'pointer',
	},
}));

export const UserRoleStyled = styled(Typography)(() => ({
	color: palette.light.custom.bidyText,
	marginTop: '7px',
}));

export const CompanyStyled = styled(Typography)(() => ({
	display: 'inline-flex',
	alignItems: 'center',
	columnGap: '10px',
	span: {
		cursor: 'pointer',
		height: '19px',
	},
}));

export const MenuPopoverStyle = {
	width: 'fit-content',
};

export const EditIconBtnStyled = styled(IconButton)(() => ({
	position: 'absolute',
	top: 34,
	right: 18,
	width: 24,
	height: 24,
	padding: '4px',
}));

export const CardHintStyled = styled(Typography)(() => ({
	marginBottom: '30px',
	color: 'custom.bidyText',
	fontFamily: FONT_ROBOTO,
}));

export const Offers = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: '20px',
});

export const StatusBar = styled('div')<StatusBarProps>(({ color, textColor = palette.light.grey[800] }) => ({
	position: 'absolute',
	width: '100%',
	top: 0,
	background: color,
	padding: '3px 0',
	display: 'flex',
	justifyContent: 'center',
	fontWeight: 500,
	color: textColor,
}));

export const CompanyInfo = styled('div')(({ theme }) => ({
	padding: '20px',
	gap: '15px',
	display: 'flex',
	justifyContent: 'space-between',
	background: theme.palette.grey[200],
}));

export const GetVerified = styled('div')<{ color?: string }>(({ color = palette.light.info.dark }) => ({
	color,
	fontWeight: 600,
	'> button': { all: 'unset', textDecoration: 'underline', cursor: 'pointer' },
}));
