import type { FC } from 'react';
// @mui
import { Box } from '@mui/material';
// hooks
import useLocales from 'src/hooks/useLocales';
//
import { ListSubheaderStyle, ListStyled } from './styles';
import NavList from './NavList';
import type { INav } from './types';

// ----------------------------------------------------------------------

const NavSectionVertical: FC<INav> = ({ navConfig, isCollapse, ...other }) => {
	const { translate } = useLocales();

	return (
		<Box {...other}>
			{navConfig.map((group) => {
				if (!group.items.length) return null;

				return (
					<ListStyled key={group?.subheader} disablePadding sx={{ px: 2 }}>
						<ListSubheaderStyle
							sx={{
								...(isCollapse && {
									opacity: 0,
								}),
							}}
						>
							{translate(group?.subheader, {})}
						</ListSubheaderStyle>

						{group?.items.map(({ depth = 1, ...list }) => (
							<NavList
								key={list.title + list.path}
								data={list}
								depth={depth}
								hasChildren={!!list.children}
								isCollapse={isCollapse}
							/>
						))}
					</ListStyled>
				);
			})}
		</Box>
	);
};

export default NavSectionVertical;
