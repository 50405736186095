import { updateQuestionnaire as updateDocQuestionnaire } from 'src/api';
import { QuestionnaireTypeEnum } from 'src/types';
import { getPropertyQuestionnaireRef, getTransactionQuestionnaireRef } from 'src/utils/firebase';
import { UpdateDoneQuestionnaireParams } from './types';

export const updateQuestionnaire = async ({
	questionnaireType,
	type,
	fullQuestionnaire,
	data,
}: UpdateDoneQuestionnaireParams) => {
	if (fullQuestionnaire) {
		const isProperty = questionnaireType === QuestionnaireTypeEnum.PROPERTY;

		const questionnaireRef = isProperty
			? getPropertyQuestionnaireRef(fullQuestionnaire.id)
			: getTransactionQuestionnaireRef(fullQuestionnaire.id);

		return await updateDocQuestionnaire(questionnaireRef, type, data);
	}

	throw new Error("Questionnaire doesn't exist");
};
