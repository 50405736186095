import { useSelector } from 'react-redux';
import {
	PropertyOwnershipModal,
	PropertyPaymentModal,
	PropertyProprietorshipModal,
	PropertyProprietorshipConfirmationModal,
	PropertyRelationshipModal,
	PropertyVerificationIntroductionModal,
	PropertyVerificationRequestModal,
	PropertyProprietorshipVerificationModal,
	PropertyProprietorshipVerificationIssueModal,
	VerificationModal,
	PropertyVerificationInProgressModal,
	PropertyVerificationFailModal,
} from 'src/components/modals';
import { selectPropertySlice } from 'src/redux/slices/property';
import PropertySaleConfirmationModal from 'src/components/modals/property/PropertySaleConfirmation';
import PropertyPurchaseConfirmationModal from 'src/components/modals/property/PropertyPurchaseConfirmation';
import { noop } from 'lodash';
import PropertyTitleHelpModal from 'src/components/modals/property/PropertyTitleHelp';
import PropertyTitleConfirmationModal from 'src/components/modals/property/PropertyTitleConfirmation';
import PropertyCompletionModal from 'src/components/modals/property/PropertyCompletion';
import PropertyIntroductionModal from 'src/components/modals/property/PropertyIntroduction';
import PropertyCancelationModal from 'src/components/modals/property/PropertyCancelation';
import PropertyRemortgageConfirmationModal from 'src/components/modals/property/PropertyRemortgageConfirmation';
import VerificationNameIssueModal from 'src/components/modals/common/VerificationNameIssue';
import VerificationAddressIssueModal from 'src/components/modals/common/VerificationAddressIssue';
import VerificationIssueModal from 'src/components/modals/common/VerificationIssueModal';
import VerificationAddressConfirmationModal from 'src/components/modals/common/VerificationAddressConfirmation';
import VerificationNameConfirmationModal from 'src/components/modals/common/VerificationNameConfirmation';
import { propertyOnboardingSteps } from '../../constants';

const PropertyOnboardingFlow = () => {
	const { property } = useSelector(selectPropertySlice);

	if (!property) return null;

	return (
		<>
			<PropertyIntroductionModal />
			<PropertySaleConfirmationModal />
			<PropertyPurchaseConfirmationModal />
			<PropertyRemortgageConfirmationModal />
			<PropertyOwnershipModal />
			<PropertyPaymentModal />
			<PropertyVerificationIntroductionModal />
			<PropertyVerificationRequestModal />
			<VerificationModal name={propertyOnboardingSteps.verification} handleClose={noop} />
			<PropertyVerificationInProgressModal />
			<PropertyVerificationFailModal />
			<VerificationNameConfirmationModal isProperty />
			<VerificationNameIssueModal isProperty />
			<VerificationAddressConfirmationModal isProperty />
			<VerificationAddressIssueModal isProperty />
			<PropertyVerificationInProgressModal />
			<VerificationIssueModal isProperty />
			<PropertyRelationshipModal />
			<PropertyProprietorshipModal />
			<PropertyTitleHelpModal />
			<PropertyTitleConfirmationModal />
			<PropertyProprietorshipConfirmationModal />
			<PropertyProprietorshipVerificationModal />
			<PropertyProprietorshipVerificationIssueModal />
			<PropertyCompletionModal />
			<PropertyCancelationModal />
		</>
	);
};

export default PropertyOnboardingFlow;
