import { useEffect, createContext, ChangeEvent, FC, PropsWithChildren } from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// utils
import getColorPresets, { colorPresets } from '../utils/getColorPresets';
// config
import { defaultSettings } from '../config';
import { SettingsContextProps } from './types';
import { initialDataSettings } from './initialData';

const SettingsContext = createContext<SettingsContextProps>(initialDataSettings);

// ----------------------------------------------------------------------

const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
	const [settings, setSettings] = useLocalStorage('settings', {
		themeMode: defaultSettings.themeMode,
		themeLayout: defaultSettings.themeLayout,
		themeStretch: defaultSettings.themeStretch,
		themeContrast: defaultSettings.themeContrast,
		themeDirection: defaultSettings.themeDirection,
		themeColorPresets: defaultSettings.themeColorPresets,
	});

	const isArabic = localStorage.getItem('i18nextLng') === 'ar';

	const onChangeDirectionByLang = (lang: string) => {
		setSettings({
			...settings,
			themeDirection: lang === 'ar' ? 'rtl' : 'ltr',
		});
	};

	useEffect(() => {
		if (isArabic) {
			onChangeDirectionByLang('ar');
		}
	}, [isArabic]);

	// Mode

	const onToggleMode = () => {
		setSettings({
			...settings,
			themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
		});
	};

	const onChangeMode = (event: ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeMode: event.target.value,
		});
	};

	// Direction

	const onToggleDirection = () => {
		setSettings({
			...settings,
			themeDirection: settings.themeDirection === 'rtl' ? 'ltr' : 'rtl',
		});
	};

	const onChangeDirection = (event: ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeDirection: event.target.value,
		});
	};

	// Layout

	const onToggleLayout = () => {
		setSettings({
			...settings,
			themeLayout: settings.themeLayout === 'vertical' ? 'horizontal' : 'vertical',
		});
	};

	const onChangeLayout = (event: ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeLayout: event.target.value,
		});
	};

	// Contrast

	const onToggleContrast = () => {
		setSettings({
			...settings,
			themeContrast: settings.themeContrast === 'default' ? 'bold' : 'default',
		});
	};

	const onChangeContrast = (event: ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeContrast: event.target.value,
		});
	};

	// Color

	const onChangeColor = (event: ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeColorPresets: event.target.value,
		});
	};

	// Stretch

	const onToggleStretch = () => {
		setSettings({
			...settings,
			themeStretch: !settings.themeStretch,
		});
	};

	// Reset

	const onResetSetting = () => {
		setSettings({
			themeMode: defaultSettings.themeMode,
			themeLayout: defaultSettings.themeLayout,
			themeStretch: defaultSettings.themeStretch,
			themeContrast: defaultSettings.themeContrast,
			themeDirection: defaultSettings.themeDirection,
			themeColorPresets: defaultSettings.themeColorPresets,
		});
	};

	return (
		<SettingsContext.Provider
			value={{
				...settings,

				// Mode
				onToggleMode,
				onChangeMode,

				// Direction
				onToggleDirection,
				onChangeDirection,
				onChangeDirectionByLang,

				// Layout
				onToggleLayout,
				onChangeLayout,

				// Contrast
				onChangeContrast,
				onToggleContrast,

				// Stretch
				onToggleStretch,

				// Color
				onChangeColor,
				setColor: getColorPresets(settings.themeColorPresets),
				colorOption: colorPresets.map((color) => ({
					name: color.name,
					value: color.main,
				})),

				// Reset
				onResetSetting,
			}}
		>
			{children}
		</SettingsContext.Provider>
	);
};

export { SettingsProvider, SettingsContext };
