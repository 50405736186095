import { Box } from '@mui/material';
import { capitalize } from 'lodash';
import Iconify from 'src/components/Iconify';
import { PropertyDocument, PropertyDocumentAvailabilityCode } from 'src/types';

const getAvailabilityIcon = (availability: PropertyDocumentAvailabilityCode) => {
	switch (availability) {
		case 'IMMEDIATE':
			return <Iconify icon='openmoji:green-circle' fontSize={12} mt='2px' />;

		case 'MANUAL':
			return <Iconify icon='openmoji:yellow-circle' fontSize={12} mt='2px' />;

		default:
			return null;
	}
};

const getEntryNumbers = (document: PropertyDocument) => {
	if (!document.entry_numbers?.length) return '';

	return ` (${document.entry_numbers.join(', ')})`;
};

export const getDocumentName = (document: PropertyDocument) => {
	if (!document.date) {
		return (
			<Box display='flex' alignItems='center' gap='5px'>
				{capitalize(document.type)}
				{getEntryNumbers(document)}
				{getAvailabilityIcon(document.availability_code)}
			</Box>
		);
	}

	return (
		<Box display='flex' alignItems='center' gap='5px'>
			{document.date}
			{getEntryNumbers(document)}
			{getAvailabilityIcon(document.availability_code)}
		</Box>
	);
};
