import Modal from 'src/components/customModal';
import modals from 'src/constants/modals';
import { useState } from 'react';
import { ModalErrorAlert } from 'src/sections/property/components/styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { InitialAdress } from 'src/components/GoogleAddressAutoComplete/GooglePlaceAutocomlete';
import UprnForm from 'src/components/UprnForm';
import { FormProvider, RHFRadioGroup } from 'src/components/hook-form';
import { Box, Stack, Typography } from '@mui/material';
import { LoadingButtonStyled } from 'src/pages/properties/style';
import { addTransaction, getNav, handleCloseModal } from 'src/utils/propertyHelpers';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
import { newPropertyDataSchema } from 'src/sections/property/components/validationSchema';
import { PATH_TRANSACTIONS } from 'src/routes/paths';
import { newTransactionSchema } from './validationSchemas';
import { SubtitleStyled, TitleStyled } from './style';
import { transactionPartieSides, transactionRoles } from '../constants';

const Content = () => {
	const [isSelected, setIsSelected] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const user = useSelector(selectUser);

	const navigate = useNavigate();

	const methods = useForm({
		resolver: yupResolver(user.isAdmin ? newPropertyDataSchema : newTransactionSchema),
		defaultValues: InitialAdress,
		mode: 'onChange',
	});

	const {
		handleSubmit,
		watch,
		formState: { isSubmitting },
	} = methods;

	const onSubmit = async (data) => {
		setError(null);

		try {
			const id = await addTransaction({
				...data,
				uid: user.uid,
				...(!user.isAdmin && { role: user.transactionRole, side: data.side }),
			});

			handleCloseModal();
			await getNav();

			navigate(PATH_TRANSACTIONS.view(id));
		} catch (e) {
			if (e instanceof Error) {
				setError(e.message);
			}
		}
	};

	const [postcodeWatcher, uprn] = watch(['address.postcode', 'uprn']);

	return (
		<>
			<TitleStyled>Add transaction</TitleStyled>
			<SubtitleStyled>Enter the property postcode and choose an address.</SubtitleStyled>
			{!!error && (
				<ModalErrorAlert variant='filled' severity='error'>
					{error}
				</ModalErrorAlert>
			)}
			<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
				<UprnForm setError={setError} isLoading={isLoading} setIsLoading={setIsLoading} setIsSelected={setIsSelected} />
				{isSelected && !user.isAdmin && (
					<Box mt='15px' display='flex' alignItems='center' flexDirection='column'>
						<Typography variant='subtitle1'>Who are you acting for?</Typography>
						<RHFRadioGroup
							sx={{ gap: '40px' }}
							name='side'
							options={[
								{
									name: transactionRoles.seller.name,
									key: transactionPartieSides.sell.key,
								},
								{
									name: transactionRoles.buyer.name,
									key: transactionPartieSides.buy.key,
								},
							]}
						/>
					</Box>
				)}

				<Stack>
					<LoadingButtonStyled
						type='submit'
						variant='contained'
						loading={isSubmitting || isLoading}
						size='large'
						disabled={!uprn || !postcodeWatcher || !!error}
					>
						Add Transaction
					</LoadingButtonStyled>
				</Stack>
			</FormProvider>
		</>
	);
};

const AddTransactionModal = () => (
	<Modal name={modals.addTransaction} cardSx={{ maxWidth: '630px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default AddTransactionModal;
