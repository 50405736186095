import { labelByPropertyTitleTenure } from 'src/constants/title';
import { FormTitleDocumentCategory } from '../../types';

export const getDocumentsCategoryName = (document: FormTitleDocumentCategory) => (
	<b>
		{document.title}
		{document.status && ` (${document.status})`}
		{document.tenure && ` (${labelByPropertyTitleTenure[document.tenure]})`}
		{document.address && ` - ${document.address}`}
	</b>
);
