import { QuestionnaireStatus } from 'src/types';

export const QUESTIONNAIRE_PROPERTY = {
	property: null,
	environment: {
		common: null,
		status: QuestionnaireStatus.NOT_STARTED,
		flooding: {
			flooded: null,
			floods_history: {
				flooded_date: null,
				flooded_desc: null,
			},
			flood_type: {
				ground: null,
				sewer: null,
				surface: null,
				coastal: null,
				river: null,
				other: null,
			},
			flood_type_other_desc: null,
			flood_report: null,
		},
		radon: {
			radon_test: null,
			radon_test_safe: null,
			radon_test_remedies: null,
			radon_remedies: null,
		},
		japanese_knotweed: {
			knotweed: null,
			knotweed_plan: null,
			knotweed_plan_note: `
        After the questionnaire, we'll ask you to upload
        a copy with any insurance cover linked to the plan.`,
		},
	},
	property_details: {
		common: {
			tenure: null,
			floor_height: null,
			construction_age: null,
		},
		status: QuestionnaireStatus.NOT_STARTED,
		property_rooms_count: null,
		property_details: {
			property_type: null,
			built_form: null,
			property_rooms: null,
			total_floor_area: null,
		},
		protected_buildings: {
			listed_building: null,
			listed_building_note: `
        After the questionnaire, we'll ask you to upload copies of
        the relevant documents (e.g. notice of listing, letter from local authority confirming listing, etc.).`,
			conservation_area: null,
		},
		protected_trees: {
			protected_trees: null,
			protected_trees_note: `
        After the questionnaire, we'll ask you
         to upload a copy of the Tree Preservation Order.`,
			protected_trees_complied: null,
		},
		parking: {
			parking_arrangements: null,
			parking_control: null,
		},
	},
	building_works: {
		common: {
			alterations_windows: null,
			alterations_extension: null,
		},
		status: QuestionnaireStatus.NOT_STARTED,
		green_deal_page: {
			green_deal_yn: null,
			green_deal_desc: null,
			question1: null,
		},
		alterations_works: {
			alterations: null,
			alterations_use_desc: null,
			alterations_use_completed_yn: null,
			alterations_use_completed_date: null,
			alterations_use_incomplete_desc: null,
			alterations_use_consent_yn: null,
			alterations_use_consent_note: `
        After the questionnaire, we'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions,
            Building Regulations approvals, Completion Certificates). </br>\n\nIf you are unsure, please ask us.`,
			alterations_use_consent_desc: null,
			alterations_use_landlords_consent: null,
			alterations_windows_desc: null,
			alterations_windows_completed_yn: null,
			alterations_windows_completed_date: null,
			alterations_windows_incomplete_desc: null,
			alterations_windows_consent_yn: null,
			alterations_windows_consent_note: `
        After the questionnaire, we'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions,
        Building Regulations approvals, Completion Certificates). </br>\n\nIf you are unsure, please ask us.`,
			alterations_windows_consent_desc: null,
			alterations_windows_landlords_consent: null,
			alterations_extension_desc: null,
			alterations_extension_completed_yn: null,
			alterations_extension_completed_date: null,
			alterations_extension_incomplete_desc: null,
			alterations_extension_consent_yn: null,
			alterations_extension_consent_note: `
        After the questionnaire, we'll ask you to upload copies of any planning documents and consents granted (e.g. planning permissions,
        Building Regulations approvals, Completion Certificates). </br>\n\nIf you are unsure, please ask us.`,
			alterations_extension_consent_desc: null,
			alterations_extension_landlords_consent: null,
			alterations_conservatory_desc: null,
			alterations_conservatory_completed_yn: null,
			alterations_conservatory_completed_date: null,
			alterations_conservatory_incomplete_desc: null,
			alterations_conservatory_consent_yn: null,
			alterations_conservatory_consent_note: `
        After the questionnaire, we'll ask you to upload copies of any planning documents and consents granted (e.g.
        planning permissions, Building Regulations approvals, Completion Certificates). </br>\n\nIf you are unsure, please ask us.`,
			alterations_conservatory_consent_desc: null,
			alterations_conservatory_landlords_consent: null,
			alterations_loft_desc: null,
			alterations_loft_completed_yn: null,
			alterations_loft_completed_date: null,
			alterations_loft_incomplete_desc: null,
			alterations_loft_consent_yn: null,
			alterations_loft_consent_note: `
        After the questionnaire, we'll ask you to upload copies of any planning documents and consents granted (e.g.
        planning permissions, Building Regulations approvals, Completion Certificates). </br>\n\nIf you are unsure, please ask us.`,
			alterations_loft_consent_desc: null,
			alterations_loft_landlords_consent: null,
			alterations_garage_desc: null,
			alterations_garage_completed_yn: null,
			alterations_garage_completed_date: null,
			alterations_garage_incomplete_desc: null,
			alterations_garage_consent_yn: null,
			alterations_garage_consent_note: `
        After the questionnaire, we'll ask you to upload copies of any planning documents and consents granted (e.g.
        planning permissions, Building Regulations approvals, Completion Certificates). </br>\n\nIf you are unsure, please ask us.`,
			alterations_garage_consent_desc: null,
			alterations_garage_landlords_consent: null,
			alterations_walls_desc: null,
			alterations_walls_completed_yn: null,
			alterations_walls_date: null,
			alterations_walls_incomplete_desc: null,
			alterations_walls_consent_yn: null,
			alterations_walls_consent_note: `
        After the questionnaire, we'll ask you to upload copies of any planning documents and consents granted (e.g.
        planning permissions, Building Regulations approvals, Completion Certificates). </br>\n\nIf you are unsure, please ask us.`,
			alterations_walls_consent_desc: null,
			alterations_walls_landlords_consent: null,
			alterations_other_desc: null,
			alterations_other_completed_yn: null,
			alterations_other_completed_date: null,
			alterations_other_incomplete_desc: null,
			alterations_other_consent_yn: null,
			alterations_other_consent_note: `
        After the questionnaire, we'll ask you to upload copies of any planning documents and consents granted
        (e.g. planning permissions, Building Regulations approvals, Completion Certificates). </br>\n\nIf you are unsure, please ask us.
        `,
			alterations_other_consent_desc: null,
			alterations_other_landlords_consent: null,
		},
	},
	rights_arrangements: {
		common: null,
		status: QuestionnaireStatus.NOT_STARTED,
		rights_arrangements: {
			joint_services: null,
			joint_services_desc: null,
			rights_nearby: null,
			rights_nearby_desc: null,
			access_right_challenged: null,
			access_right_challenged_desc: null,
			rights: {
				light: null,
				support: null,
				customary: null,
			},
			arrangements: {
				mining: null,
				chancel: null,
				things: null,
			},
			arrangements_desc: null,
			access_right: null,
			access_right_desc: null,
			other_rights: null,
			other_rights_desc: null,
		},
		drains_and_pipes: {
			shared_infra_active: null,
			shared_infra_passive: null,
			shared_infra_agreement: null,
			shared_infra_agreement_desc: null,
		},
	},
	guarantees_insurance: {
		common: null,
		status: QuestionnaireStatus.NOT_STARTED,
		guarantees: {
			guarantees_warranties_property: null,
			guarantees_warranties_note: `
        After the questionnaire, we'll ask you to upload a copy of the guarantees or warranties selected above.`,
			guarantees_warranties_claims: null,
			guarantees_warranties_claims_desc: null,
		},
		insurance_page: {
			home_insurance: null,
			home_insurance_desc: null,
			buildings_insurance: null,
			buildings_insurance_issues: {
				abnormal_premium_rise: null,
				high_excesses: null,
				unusual_conditions: null,
				insurance_refused: null,
			},
			buildings_insurance_issues_desc: null,
			buildings_insurance_claims: null,
			buildings_insurance_claims_desc: null,
		},
	},
	services: {
		common: null,
		status: QuestionnaireStatus.NOT_STARTED,
		services_page: {
			services: null,
			electricity_certificate_yn: null,
			electricity_certificate_yes_note: `
          After the questionnaire, we'll ask you to upload a copy of the Electrical Safety Certificate.`,
			electricity_certificate_no_note: `
          No worries! We'll get a qualified and registered electrician booked
          in for you. You'll be able to give your availability after the questionnaire.
          `,
			electricity_provider: null,
			electricity_meter_location: null,
			gas_certificate_yn: null,
			gas_certificate_yes_note: `
          After the questionnaire,
           we'll ask you to upload a copy of the Gas Safe Register.`,
			gas_certificate_no_note: `
          No worries! We'll get a qualified and registered boiler surveyor
          booked in for you. You'll be able to give your availability after the questionnaire.`,
			gas_provider: null,
			gas_meter_location: null,
			water_provider: null,
			water_stopcock_location: null,
			water_meter_location: null,

			landline_provider: null,

			broadband_provider: null,

			cable_provider: null,
		},
		heating_page: {
			heating_system: {
				central: null,
				direct: null,
			},
			central_heating_type: null,
			furnace_install_date: null,
			furnace_working: null,
			furnace_service_date: null,
			furnace_fuel: null,
			boiler_install_date: null,
			boiler_working: null,
			boiler_service_date: null,
			boiler_fuel: null,
			heatpump_install_date: null,
			heatpump_working: null,
			heatpump_service_date: null,
			heatpump_fuel: null,
			othercentralheating_install_date: null,
			othercentralheating_working: null,
			othercentralheating_service_date: null,
			othercentralheating_fuel: null,
			central_heating_means: null,
			direct_heating_type: null,
		},
		drainage_page: {
			drainage: {},
		},
		sewerage_page: {
			sewerage_type: {
				septic_tank: null,
				sewage_plant: null,
				cesspool: null,
			},
			septic_tank_date: null,
			sewerage_shared: null,
			sewerage_shared_count: null,
			sewerage_emptied_date: null,
			sewage_plant_servicing_date: null,
			sewerage_install_date: null,
			sewerage_outside: null,
			sewerage_outside_note: `
        After the questionnaire, we'll ask you to upload a copy of
        any plans showing the location of the system and how access is obtained.`,
		},
		solarpanels: {
			solar_panels_yn: null,
			solar_panels_year: null,
			solar_panels_owned: null,
			solar_panels_lease: null,
			solar_panels_lease_note: `
        After the questionnaire, we'll ask you to upload copies of the relevant documents, such as copies of electricity
        bills for feed-in tariffs (e.g. payments made for supplying electricity to the main grid).  </br>\n\nIf you are unsure, please ask us.
        `,
		},
	},
	ownership: {
		common: null,
		status: QuestionnaireStatus.NOT_STARTED,
		ownership_page: {
			tenure_type: null,
			freehold_type: null,
			leasehold_type: null,
		},
		finance: {
			ownership_type: null,
		},
		leasehold_page: {
			rent_yn: null,
			rent_amount: null,
			rent_frequency: null,
			building_maintenance_seller: null,
			other_charges_yn: null,
			other_charges_desc: null,
		},
		management_page: {
			landlord_managing_agent_yn: null,
			tenants_company_dissolved: null,
			managing_agent_daytoday_yn: null,
			decoration_facade_year: null,
			decoration_communal_year: null,
			leasehold_contact_details: {
				landlords_name: null,
				landlords_address: null,
				landlords_telephone: null,
				landlords_email: null,
			},
			landlords_managing_agent_details: {
				landlords_agent_name: null,
				landlords_agent_address: null,
				landlords_agent_telephone: null,
				landlords_agent_email: null,
			},
			tenants_managing_agent_details: {
				tenants_agent_name: null,
				tenants_agent_address: null,
				tenants_agent_telephone: null,
				tenants_agent_email: null,
			},
		},
	},
};
