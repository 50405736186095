import { Box, Fade, IconButton, MenuItem, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { MouseEvent, useMemo } from 'react';
import Iconify from 'src/components/Iconify';
import { setAnchorEl } from 'src/redux/slices/headerSlice';
import { dispatch, useSelector } from 'src/redux/store';
import MenuPopover from 'src/components/MenuPopover';
import { FONT_ROBOTO } from 'src/theme/typography';
import { logout, selectUser } from 'src/redux/slices/auth';
import useResponsive from 'src/hooks/useResponsive';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import { logout as logoutApi } from 'src/api';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { RootStyle, ToolbarStyle } from './styles';
import PurchaseActionButtons from './actions/PurchaseActionButtons';
import SaleActionButtons from './actions/SaleActionButtons';
import DashboardActionButtons from './actions/DashboardActionButtons';
import AdminPropertyActionButtons from './actions/AdminPropertyActionButtons';
import AdminTransactionActionButtons from './actions/AdminTransactionActionButtons';
import CollapseButton from './navbar/CollapseButton';
import MainProgress from './MainProgress';
import PropertyActionButtons from './actions/PropertyActionButtons';

const MainActionButtons = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const isDesktop = useResponsive('up', 'lg');
	const user = useSelector(selectUser);
	const { anchorEl } = useSelector((state) => state.actionMenu);
	const transaction = useSelector(selectTransactionOverview);
	const { onToggleHideNavar, hideNavbar } = useCollapseDrawer();

	const open = !!anchorEl;

	const actions = useMemo(() => {
		const isPropertyPage = location.pathname.startsWith('/properties');
		const isTransactionsPage = location.pathname.startsWith('/transactions');
		const isInstrumentsPage = location.pathname.startsWith('/instruments');
		const isDashboardPage = location.pathname.startsWith('/dashboard');

		if (isDashboardPage) return <DashboardActionButtons />;
		if (user.isAdmin && isPropertyPage) return <AdminPropertyActionButtons />;
		if (isPropertyPage) return <PropertyActionButtons />;

		if ((isTransactionsPage || isInstrumentsPage) && transaction) {
			if (user.isAdmin) return <AdminTransactionActionButtons />;
			if (transaction.isBuyer || transaction.isBuyerViewer) return <PurchaseActionButtons />;

			return <SaleActionButtons />;
		}

		return null;
	}, [location.pathname, transaction]);

	const handleLogout = async () => {
		await logoutApi();
		sessionStorage.removeItem('redirectTo');
		dispatch(logout());
		dispatch(setAnchorEl(null));
		navigate('/auth/login');
	};
	const handleSettingsClick = (event: MouseEvent<HTMLButtonElement>) => dispatch(setAnchorEl(event.currentTarget));
	const handleOptionClick = (cb: () => unknown) => () => {
		dispatch(setAnchorEl(null));
		cb();
	};

	const settingsMenuOptions = useMemo(
		() => [
			{
				title: 'Edit profile',
				onClick: () => navigate('/account-settings'),
			},
			{
				title: 'Log out',
				onClick: () => handleLogout(),
			},
		],
		[handleLogout, navigate],
	);

	return (
		<RootStyle>
			<ToolbarStyle
				disableGutters
				sx={{
					pr: '25px',
				}}
			>
				<Box width='100%'>
					<MainProgress />
					<Box display='flex' justifyContent='space-between' width='100%' alignItems='center'>
						<Box display='flex' alignItems='center' justifyContent='space-between' p='7px 40px 7px 32px'>
							{!isDesktop && <CollapseButton onToggleCollapse={onToggleHideNavar} collapseClick={!hideNavbar} />}
							{actions}
						</Box>
						<Stack flexDirection='row' display='inline-flex' alignItems='center' gap='30px' ml='auto'>
							<IconButton
								id='settings'
								aria-controls={open ? 'fade-menu' : undefined}
								aria-haspopup='true'
								aria-expanded={open ? 'true' : undefined}
								onClick={handleSettingsClick}
							>
								<Iconify icon='carbon:settings' width={22} height={22} sx={{ color: 'body.bidyText2' }} />
							</IconButton>
						</Stack>
					</Box>
				</Box>
			</ToolbarStyle>

			<MenuPopover
				id='fade-menu'
				anchorEl={anchorEl}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				open={open}
				onClose={() => dispatch(setAnchorEl(null))}
				TransitionComponent={Fade}
			>
				{settingsMenuOptions.map((option) => (
					<MenuItem
						onClick={handleOptionClick(option.onClick)}
						key={option.title}
						sx={{ borderRadius: '10px', fontFamily: FONT_ROBOTO, color: 'custom.bidyText' }}
					>
						{option.title}
					</MenuItem>
				))}
			</MenuPopover>
		</RootStyle>
	);
};

export default MainActionButtons;
