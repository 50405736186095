import type { FC } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText } from '@mui/material';
//
import Editor from '../editor';
import type { IRHFEditor } from './types';

// ----------------------------------------------------------------------

const RHFEditor: FC<IRHFEditor> = ({ name, ...other }) => {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<Editor
					value={field.value}
					onChange={field.onChange}
					error={!!error}
					helperText={
						<FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
							{error?.message}
						</FormHelperText>
					}
					{...other}
				/>
			)}
		/>
	);
};

export default RHFEditor;
