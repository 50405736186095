import { useLocation } from 'react-router';
import { useMemo } from 'react';
import PropertyProgress from './progress/PropertyProgress';

const MainProgress = () => {
	const location = useLocation();

	const progress = useMemo(() => {
		const isPropertyPage = location.pathname.startsWith('/properties');
		const isTransactionPage = location.pathname.startsWith('/transactions');

		if (isPropertyPage || isTransactionPage) return <PropertyProgress />;

		return null;
	}, [location.pathname]);

	return progress;
};

export default MainProgress;
