import { useMemo } from 'react';
import { Button, Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import { dispatch, useSelector } from 'src/redux/store';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { getUserName } from 'src/components/InviteCard/utils';
import modals from 'src/constants/modals';
import { selectTransactionOverview, selectTransactionParticipants } from 'src/redux/slices/transaction';

const PromoteBuyerModalContent = () => {
	const { payload } = useSelector(selectModal<{ userId: string }>);
	const transaction = useSelector(selectTransactionOverview);
	const { participants } = useSelector(selectTransactionParticipants);

	const viewer = useMemo(
		() => participants.viewers.find(({ uid: id }) => id === payload.userId),
		[payload.userId, participants.viewers],
	);

	if (!viewer) return null;

	const handleConfirm = async () => {
		if (transaction.agreedOffer) {
			dispatch(
				openModal({
					name: modals.saleConfirmation,
					payload: { userId: payload.userId, price: transaction.agreedOffer.price },
				}),
			);

			return;
		}

		dispatch(
			openModal({
				name: modals.saleConfirmation,
				payload: { userId: payload.userId, price: viewer?.offer?.price ?? transaction.askPrice?.price },
			}),
		);
	};

	return (
		<>
			<Typography sx={styledMainTitle} pt={0}>
				CONFIRM BUYER
			</Typography>
			<Typography fontSize='17px' fontWeight={500} mb={'20px'}>
				Are you sure you want to make <b>{getUserName(viewer)}</b> a Buyer?
			</Typography>
			<ActionButtons>
				<Button variant='contained' size='large' fullWidth onClick={handleCloseModal}>
					Reject
				</Button>
				<Button variant='contained' size='large' fullWidth onClick={handleConfirm}>
					Confirm
				</Button>
			</ActionButtons>
		</>
	);
};

const PromoteBuyerModal = () => (
	<Modal name={modals.promoteBuyer} cardSx={{ maxWidth: '550px', padding: '32px 27px' }}>
		<PromoteBuyerModalContent />
	</Modal>
);

export default PromoteBuyerModal;
