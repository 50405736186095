import { CircularProgress } from '@mui/material';
import { FC } from 'react';
import { CircularLoaderWrapper } from './styles';
import { ICircularLoader } from './types';

const CircularLoader: FC<ICircularLoader> = ({ size }) => (
	<CircularLoaderWrapper>
		<CircularProgress size={size} />
	</CircularLoaderWrapper>
);

export default CircularLoader;
