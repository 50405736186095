import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from 'src/components/customModal';
import { RHFTextField } from 'src/components/hook-form';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import { selectModal } from 'src/redux/slices/modal';
import { dispatch, useSelector } from 'src/redux/store';
import { Document } from 'src/redux/types/documents';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { LoadingButton } from '@mui/lab';
import { getDocumentsOverviewThunk, updateDocumentsFolderThunk } from 'src/redux/slices/documents';
import useCurrentDocumentsLocation from 'src/hooks/useCurrentDocumentsLocation';
import { updateFolderValidationSchema } from './validation';
import { UpdateFolderForm } from './types';
import PermissionsPicker from '../../components/PermissionsPicker';
import { getDefaultActionPermissions } from '../AddFolder/utils';
import { extractSelectedPermissions } from '../../components/PermissionsPicker/utils';

const Content = () => {
	const { payload } = useSelector(selectModal<Document>);
	const location = useCurrentDocumentsLocation();
	const form = useForm<UpdateFolderForm>({
		defaultValues: {
			name: payload.name,
			permissions: {
				delete: getDefaultActionPermissions(payload.permissions.delete),
				download: getDefaultActionPermissions(payload.permissions.download),
				upload: getDefaultActionPermissions(payload.permissions.upload),
				see: getDefaultActionPermissions(payload.permissions.see),
			},
		},
		resolver: yupResolver(updateFolderValidationSchema),
	});

	const handleUpdate = async (data: UpdateFolderForm) => {
		await dispatch(
			updateDocumentsFolderThunk({
				...data,
				folderId: payload.id,
				location,
				permissions: extractSelectedPermissions(data.permissions),
			}),
		);
		dispatch(getDocumentsOverviewThunk({ withLoading: true, location }));

		handleCloseModal();
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleUpdate)}>
				<Typography sx={styledMainTitle} display='flex' alignItems='center'>
					EDIT {payload.type.toUpperCase()}
				</Typography>
				<Box display='flex' flexDirection='column' gap='20px'>
					<RHFTextField name='name' placeholder={`Enter ${payload.type} name`} />
					<PermissionsPicker areSeeSettingsDisabled={payload.path === '/'} />
					<Box display='flex' justifyContent='flex-end' gap='15px' alignItems='center'>
						<LoadingButton variant='contained' onClick={handleCloseModal} loading={form.formState.isSubmitting}>
							CANCEL
						</LoadingButton>
						<LoadingButton variant='contained' type='submit' loading={form.formState.isSubmitting}>
							SAVE
						</LoadingButton>
					</Box>
				</Box>
			</form>
		</FormProvider>
	);
};

const UpdateFolderModal = () => (
	<Modal name={modals.updateDocumentsFolder} cardSx={{ maxWidth: '500px' }}>
		<Content />
	</Modal>
);

export default UpdateFolderModal;
