import { styled, Typography } from '@mui/material';

export const Breadcrumb = styled(Typography)<{ isCurrentPath?: boolean }>(({ isCurrentPath, theme }) => ({
	color: theme.palette.grey[600],
	fontSize: '14px',
	cursor: 'pointer',
	textDecoration: isCurrentPath ? 'underline' : 'none',
	'&:hover': { textDecoration: 'underline' },
}));

export const BreadcrumbSeparator = styled(Typography)(({ theme }) => ({
	fontSize: '12px',
	color: theme.palette.grey[400],
}));
