import { Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { SubtitleStyled, TitleStyled } from 'src/pages/transactions/modals/style';
import { LoadingButton } from '@mui/lab';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import Modal from '../../../customModal';
import { ConfirmationModalProps } from './types';

const Content: FC<ConfirmationModalProps> = ({
	title,
	description,
	confirmText = 'Yes',
	cancelText = 'No',
	isLoading,
	withCancelButton = true,
	withContinueButton = true,
	withButtons = true,
	confirmDisabled = false,
	setup,
	handleConfirm,
	handleCancel = handleCloseModal,
}) => {
	useEffect(() => {
		setup?.();
	}, [setup]);

	return (
		<Box>
			<TitleStyled textTransform='uppercase' fontWeight={700}>
				{title}
			</TitleStyled>
			<SubtitleStyled mt='10px' sx={{ mb: '15px' }}>
				{description}
			</SubtitleStyled>
			{withButtons && (
				<Box display='flex' gap='15px' justifyContent='flex-end'>
					{withCancelButton && (
						<LoadingButton
							onClick={handleCancel}
							variant='outlined'
							size='large'
							loading={isLoading}
							sx={{ textTransform: 'unset' }}
						>
							{cancelText}
						</LoadingButton>
					)}
					{withContinueButton && (
						<LoadingButton
							onClick={handleConfirm}
							variant='contained'
							size='large'
							loading={isLoading}
							sx={{ textTransform: 'unset' }}
							disabled={confirmDisabled}
						>
							{confirmText}
						</LoadingButton>
					)}
				</Box>
			)}
		</Box>
	);
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({ sx, handleClose = handleCloseModal, ...props }) => (
	<Modal name={props.modalName} cardSx={{ maxWidth: '500px', paddingTop: '25px', ...sx }} onClose={handleClose}>
		<Content {...props} />
	</Modal>
);

export default ConfirmationModal;
