import modals from 'src/constants/modals';
import { useSelector } from 'src/redux/store';
import { selectModal } from 'src/redux/slices/modal';
import ConfirmationModal from '../ConfirmationModal';
import { VerificationRequiredModalType } from './types';
import { verificationRequiredModalContentByType } from './constants';

const VerificationRequiredModal = () => {
	const {
		payload: { type = 'transactionInvitation' },
	} = useSelector(selectModal<{ type?: VerificationRequiredModalType }>);

	return (
		<ConfirmationModal
			modalName={modals.verificationRequired}
			withButtons={false}
			title='IDENTITY VERIFICATION REQUIRED'
			description={verificationRequiredModalContentByType[type]}
		/>
	);
};

export default VerificationRequiredModal;
