import { ChangeEvent, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { formatBidValue } from 'src/utils/formatBid';
import { RHFTextField } from '../hook-form';
import { IRHFTextField } from '../hook-form/types';
import Iconify from '../Iconify';

const PriceField: FC<IRHFTextField> = ({ InputProps, ...other }) => {
	const { setValue } = useFormContext();

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const formattedPrice = formatBidValue(e.target.value);

		if (formattedPrice !== null) {
			setValue(other.name, formattedPrice, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
		}
	};

	return (
		<RHFTextField
			onChange={handleChange}
			InputProps={{
				startAdornment: <Iconify icon='mdi:sterling' width={17} height={24} mr='5px' color='#697885' />,
				...InputProps,
			}}
			{...other}
		/>
	);
};

export default PriceField;
