import { useState, useEffect } from 'react';

// ----------------------------------------------------------------------

// eslint-disable-next-line
export default function useLocalStorage(key: string, defaultValue?: any) {
	const [value, setValue] = useState(() => {
		const storedValue = localStorage.getItem(key);

		return storedValue === null ? defaultValue : JSON.parse(storedValue);
	});

	useEffect(() => {
		const listener = (e: StorageEvent) => {
			if (e.storageArea === localStorage && e.key === key) {
				setValue(e.newValue && JSON.parse(e.newValue));
			}
		};
		window.addEventListener('storage', listener);

		return () => {
			window.removeEventListener('storage', listener);
		};
	}, [key, defaultValue]);

	// eslint-disable-next-line
	const setValueInLocalStorage = (newValue: string | ((currentVal: string) => any)) => {
		setValue((currentValue: string) => {
			const result = typeof newValue === 'function' ? newValue(currentValue) : newValue;

			localStorage.setItem(key, JSON.stringify(result));

			return result;
		});
	};

	return [value, setValueInLocalStorage];
}
