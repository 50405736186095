import { ChangeEvent, FC } from 'react';
import { Accordion } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FolderPermissionsAction } from 'src/redux/types/documents';
import { ActionPermissionsPickerProps } from './types';
import { PermissionsAccordionDetails, PermissionsAccordionSummary, PermissionsCheckbox } from '../../styled';
import SidePermissionsPicker from '../SidePermissionsPicker';
import { PermissionsType } from '../../types';

const ActionPermissionsPicker: FC<ActionPermissionsPickerProps> = ({ name, label, disabled }) => {
	const { watch, setValue } = useFormContext();

	const permissions: PermissionsType[FolderPermissionsAction] = watch(name);
	const areAllSidesAndRolesSelected = Object.values(permissions).every((s) =>
		Object.values(s).every((r) => r.selected),
	);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setValue(
			name,
			Object.entries(permissions).reduce((acc, [side, roles]) => {
				acc[side] = Object.entries(roles).reduce((nestedAcc, [role, value]) => {
					// eslint-disable-next-line no-param-reassign
					nestedAcc[role] = { ...value, selected: e.target.checked };

					return nestedAcc;
				}, {});

				return acc;
			}, {}),
		);
	};

	return (
		<Accordion disabled={disabled}>
			<PermissionsAccordionSummary>
				<PermissionsCheckbox
					name=''
					onChange={handleChange}
					label={label}
					checked={areAllSidesAndRolesSelected}
					disabled={disabled}
				/>
			</PermissionsAccordionSummary>
			<PermissionsAccordionDetails>
				<SidePermissionsPicker side='sell' action={name} disabled={disabled} />
				<SidePermissionsPicker side='buy' action={name} disabled={disabled} />
				<SidePermissionsPicker side='view' action={name} disabled={disabled} />
			</PermissionsAccordionDetails>
		</Accordion>
	);
};

export default ActionPermissionsPicker;
