import { noop } from 'lodash';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import {
	cancelPropertyOnboardingThunk,
	getProperty,
	selectProperty,
	updatePropertyOnboarding,
} from 'src/redux/slices/property';
import useLoading from 'src/hooks/useLoading';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import modals from 'src/constants/modals';
import { ConfirmationModal } from '../../common';

const PropertyCancelationModal = () => {
	const property = useSelector(selectProperty);
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(cancelPropertyOnboardingThunk());
		await dispatch(getProperty({ id: property.id, withLoading: false }));

		handleCloseModal();
		setIsLoading(false);
	};

	const handleCancel = async () => {
		// go back to previous modal
		await dispatch(updatePropertyOnboarding({ moveToNextModal: true }));
	};

	const action = property.onboarding?.activeSide === 'sell' ? 'sale' : 'purchase';

	return (
		<ConfirmationModal
			modalName={modals.cancelPropertyOnboarding}
			title={`CANCEL ${action.toUpperCase()}`}
			confirmText='Yes'
			cancelText='No'
			handleCancel={handleCancel}
			handleConfirm={handleConfirm}
			handleClose={noop}
			isLoading={isLoading}
			description={
				<>
					Are you sure you want to cancel the {action} <b>{property.name}</b>?
				</>
			}
		/>
	);
};

export default PropertyCancelationModal;
