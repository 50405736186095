import { useNavigate } from 'react-router';
import { Typography } from '@mui/material';

import Modal from 'src/components/customModal';
import { useSelector } from 'src/redux/store';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { selectPropertySlice } from 'src/redux/slices/property';
import { selectTransactionOverview } from 'src/redux/slices/transaction';
import { ROOTS_DASHBOARD } from 'src/routes/paths';
import modals from 'src/constants/modals';
import { selectUser } from 'src/redux/slices/auth';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { arrayUnion, updateDoc } from 'firebase/firestore';
import { getTransactionRef } from 'src/utils/firebase';

const Content = () => {
	const [isLoading, setIsLoading] = useState(false);

	const transaction = useSelector(selectTransactionOverview);
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const { property } = useSelector(selectPropertySlice);

	const handleDelete = async () => {
		setIsLoading(true);

		await updateDoc<unknown>(user.ref, { hiddenTransactions: arrayUnion(getTransactionRef(transaction.id)) });

		handleCloseModal();
		navigate(ROOTS_DASHBOARD);
		setIsLoading(false);
	};

	return (
		<>
			<Typography sx={styledMainTitle} pt={0} textTransform='uppercase'>
				DELETE TRANSACTION
			</Typography>
			<Typography fontSize='17px' fontWeight={500} mb={'20px'}>
				Are you sure you want to delete <b>{property?.name}</b>?
			</Typography>
			<ActionButtons>
				<LoadingButton variant='outlined' size='large' fullWidth onClick={handleCloseModal} loading={isLoading}>
					Cancel
				</LoadingButton>
				<LoadingButton
					variant='contained'
					size='large'
					fullWidth
					loading={isLoading}
					onClick={handleDelete}
					sx={{ textTransform: 'capitalize' }}
				>
					Delete
				</LoadingButton>
			</ActionButtons>
		</>
	);
};

const DeleteTransactionModal = () => (
	<Modal name={modals.deleteTransaction} cardSx={{ maxWidth: '550px', padding: '32px 27px' }}>
		<Content />
	</Modal>
);

export default DeleteTransactionModal;
