import { Box, styled } from '@mui/material';

export const GreetingsWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	backgroundColor: theme.palette.primary.bgColor,
	borderRadius: '16px',
	width: '100%',
	maxWidth: '595px',
	padding: '17px 23px',
	transform: 'translate(-22px, 0px)',
}));

export const LogoWrapper = styled(Box)({
	position: 'absolute',
	bottom: '0',
	right: '0',
});

export const CloseWrapper = styled(Box)({
	position: 'absolute',
	top: '20px',
	right: '35px',
	cursor: 'pointer',
});
