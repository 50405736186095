import { Alert } from '@mui/material';
import { styled } from '@mui/system';

export const StyledAlert = styled(Alert)({
	fontSize: '13px',
	borderRadius: '2px',
	padding: '3px 7px',
	width: '100%',
	alignItems: 'center',
});
