import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { getLatestPropertyListing, selectPropertySlice } from 'src/redux/slices/property';
import { useSelector } from 'react-redux';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { PropertyGeneralAddressCard, TypographyAddressTitle } from '../../styled';
import PhotosCarousel from '../PhotosCarousel';

const FloorPlansSection = () => {
	const { property } = useSelector(selectPropertySlice);

	useEffect(() => {
		dispatch(getLatestPropertyListing());
	}, []);

	if (!property?.latestListing?.floorPlans.length) return null;

	const handleClick = () => {
		dispatch(
			openModal({
				name: modals.photosCarousel,
				payload: {
					title: 'Floor Plans',
					photos: property?.latestListing?.floorPlans.map(({ url }) => url) ?? [],
					withMagnifier: true,
				},
			}),
		);
	};

	return (
		<PropertyGeneralAddressCard>
			<TypographyAddressTitle mb='20px'>FLOOR PLANS</TypographyAddressTitle>
			<PhotosCarousel photos={property.latestListing.floorPlans} onPhotoClick={handleClick} />
		</PropertyGeneralAddressCard>
	);
};

export default FloorPlansSection;
