import MuiCarousel from 'react-material-ui-carousel';
import { styled } from '@mui/material';

export const Carousel = styled(MuiCarousel)({
	height: '95%',
	overflow: 'visible',
	'> div': {
		height: '100% !important',
	},
	'> div, div:has(> .MuiBox-root)': {
		height: '100% !important',
	},
	'div:has(> .image-magnify)': {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
	},
});
