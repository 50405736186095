import type { FC, PropsWithChildren } from 'react';
import { LazyMotion } from 'framer-motion';

// ----------------------------------------------------------------------

// eslint-disable-next-line import/extensions
const loadFeatures = () => import('./features.js').then((res) => res.default);

const MotionLazyContainer: FC<PropsWithChildren> = ({ children }) => (
	<LazyMotion strict features={loadFeatures}>
		{children}
	</LazyMotion>
);

export default MotionLazyContainer;
