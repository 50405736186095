/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import noop from 'src/utils/noop';
import { HeaderSliceState } from '../types/headerSlice';

// ----------------------------------------------------------------------

const initialState: HeaderSliceState = {
	actionMenu: [],
	anchorEl: null,
	loading: false,
};

const headerSlice = createSlice({
	name: 'header',
	initialState,
	reducers: {
		setActionMenu: (state, action) => {
			state.actionMenu = action.payload;
		},
		setAnchorEl: (state, action) => {
			state.anchorEl = action.payload;
		},
		setLoading: (state) => {
			state.loading = true;
		},
		unsetLoading: (state) => {
			state.loading = false;
		},
	},
	extraReducers: noop<[]>,
});

// Actions
export const { setActionMenu, setAnchorEl, setLoading, unsetLoading } = headerSlice.actions;

export const selectMenuLoading = (state) => state.actionMenu.loading;

// Reducer
export default headerSlice.reducer;
