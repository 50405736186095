import { PropertyOnboardingStep } from '../types';

export const propertyOnboardingSteps: Record<PropertyOnboardingStep, string> = {
	introduction: 'propertyIntroduction',
	ownership: 'propertyOwnership',
	payment: 'propertyPayment',
	verificationIntroduction: 'propertyVerificationIntroduction',
	verificationRequest: 'propertyVerificationRequest',
	verification: 'propertyVerification',
	verificationInProgress: 'propertyVerificationInProgress',
	verificationFail: 'propertyVerificationFail',
	nameConfirmation: 'propertyNameConfirmation',
	nameIssue: 'propertyNameIssue',
	addressConfirmation: 'propertyAddressConfirmation',
	addressIssue: 'propertyAddressIssue',
	verificationIssue: 'propertyVerificationIssue',
	titleIntroduction: 'propertyTitleIntroduction',
	titleSelection: 'propertyTitleSelection',
	titleHelp: 'propertyTitleHelp',
	titleConfirmation: 'propertyTitleConfirmation',
	proprietorshipMatch: 'propertyProprietorshipMatch',
	proprietorshipMatchIssue: 'propertyProprietorshipMatchIssue',
	relationship: 'propertyRelationship',
	completion: 'propertyCompletion',
} as const;

export const propertyOnboardingStepsKeys = Object.keys(propertyOnboardingSteps) as PropertyOnboardingStep[];

export const propertyDataExtractionStagesNames: Record<PropertyDataExtractionStage, string> = {
	epc: 'Fetching EPC data...',
	council: 'Fetching council data...',
	utilities: 'Fetching utilities data...',
	covenants: 'Fetching HMLR data...',
	leases: 'Fetching HMLR data...',
	listings: 'Fetching listings data...',
};
