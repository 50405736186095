import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import modals from 'src/constants/modals';
import { ActionButtons } from 'src/layouts/main/styles';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { BrandModal } from 'src/components/common';
import { dispatch } from 'src/redux/store';
import { getPropertyTitles, selectProperty, selectPropertyTitles } from 'src/redux/slices/property';
import Progress from 'src/components/Progress';
import { RHFCheckboxGroup } from 'src/components/hook-form';
import { openModal } from 'src/redux/slices/modal';
import Modal from 'src/components/customModal';
import { labelByPropertyTitleTenure } from 'src/constants/title';
import { getOfficialCopiesValidationSchema } from './validation';
import { GetOfficialCopiesFormType } from './types';

const Content = () => {
	const titles = useSelector(selectPropertyTitles);
	const property = useSelector(selectProperty);
	const form = useForm<GetOfficialCopiesFormType>({
		resolver: yupResolver(getOfficialCopiesValidationSchema),
		defaultValues: { titles: [] },
	});

	const handleSubmit = async (data: GetOfficialCopiesFormType) => {
		const selectedTitles = data.titles.reduce<Array<{ title: string; tenure: string; address: string }>>(
			(acc, item) => {
				if (item.value) {
					const [title, tenure, address] = item.key.split('-');

					acc.push({ title, tenure, address });
				}

				return acc;
			},
			[],
		);

		dispatch(openModal({ name: modals.availableDocuments, payload: { titles: selectedTitles } }));
	};

	useEffect(() => {
		dispatch(getPropertyTitles({ id: property.id }));
	}, [property.id]);

	useEffect(() => {
		form.reset({
			titles: titles.data.map((title) => ({ key: `${title.number}-${title.tenure}-${title.address}`, value: false })),
		});
	}, [titles.data]);

	const handleSearch = () => dispatch(openModal({ name: modals.searchTitle, payload: { areOfficialCopies: true } }));

	return (
		<FormProvider {...form}>
			<form
				onSubmit={(e) => {
					e.stopPropagation();
					form.handleSubmit(handleSubmit)(e);
				}}
			>
				<BrandModal.Title title='GET OFFICIAL COPIES' />
				<Box py='15px'>
					{titles.isInitialized ? (
						<>
							{titles.data.length ? (
								<RHFCheckboxGroup
									name='titles'
									options={titles.data.map(({ number, tenure, address }) => ({
										value: `${number}-${tenure}`,
										label: `${number} (${labelByPropertyTitleTenure[tenure]}) - ${address}`,
									}))}
								/>
							) : (
								<Typography mb='20px' mt='10px'>
									We couldn't find any titles for this address.{' '}
									<Typography
										sx={{ textDecoration: 'none', cursor: 'pointer' }}
										display='inline'
										color='primary'
										onClick={handleSearch}
									>
										Search title manually
									</Typography>
								</Typography>
							)}
							<ActionButtons mt='10px'>
								<Button variant='contained' fullWidth onClick={handleCloseModal}>
									Cancel
								</Button>
								<LoadingButton variant='contained' fullWidth type='submit'>
									Continue
								</LoadingButton>
							</ActionButtons>
						</>
					) : (
						<Box height='100px'>
							<Progress zoom={0.5} />
						</Box>
					)}
				</Box>
			</form>
		</FormProvider>
	);
};

const GetOfficialCopiesModal = () => (
	<Modal name={modals.getOfficialCopies} cardSx={{ maxWidth: '600px' }}>
		<Content />
	</Modal>
);

export default GetOfficialCopiesModal;
