import { postCodeRegExp } from 'src/constants';
import { requiredNumber, requiredString } from 'src/utils/schemas';
import * as Yup from 'yup';

export const newPropertyDataSchema = Yup.object().shape({
	address: Yup.object().shape({
		postcode: requiredString.matches(postCodeRegExp, 'Post code is not valid'),
		address: requiredString,
		paon: requiredString,
		saon: Yup.string().nullable().optional(),
		street: requiredString,
		locality: Yup.string().nullable().optional(),
		town_city: requiredString,
		district: Yup.string().nullable().optional(),
		county: requiredString,
		latitude: requiredNumber,
		longitude: requiredNumber,
		display_address: requiredString,
		name: Yup.string().nullable().optional(),
	}),
});
