import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { noop } from 'lodash';
import { useSelector } from 'react-redux';
import { selectTransactionOnboarding, updateTransactionOnboarding } from 'src/redux/slices/transaction';
import useLoading from 'src/hooks/useLoading';
import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum } from 'src/types';
import { Typography } from '@mui/material';
import { ConfirmationModal } from '../../common';

const TransactionTitleErrorModal = () => {
	const { isLoading, setIsLoading } = useLoading();
	const onboarding = useSelector(selectTransactionOnboarding);

	const handleContinue = async () => {
		setIsLoading(true);

		await dispatch(
			updateTransactionOnboarding({
				titleHelp: OnboardingStepStatusEnum.PASSED,
				titleConfirmation: OnboardingStepStatusEnum.PASSED,
				titleError: OnboardingStepStatusEnum.PASSED,
				completion: OnboardingStepStatusEnum.PASSED,
			}),
		);

		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			modalName={transactionOnboardingSteps.titleError}
			title='SELECT TITLE'
			description={
				<>
					<Typography color='error'>{onboarding?.titlesExtractionError ?? 'Unknown error occurred'}</Typography>
					<br />
					We apologise for the inconvenience. You can continue creating your transaction, and a notification has been
					sent to the Conveyo team for assistance. Help is on the way!
				</>
			}
			withCancelButton={false}
			confirmText='Continue'
			handleConfirm={handleContinue}
			isLoading={isLoading}
			handleClose={noop}
			sx={{ maxWidth: '600px' }}
		/>
	);
};

export default TransactionTitleErrorModal;
