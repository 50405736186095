/* eslint-disable @typescript-eslint/no-var-requires */
const collections = require('./collections');
const roles = require('./roles');
const invitationStatuses = require('./invitation-statuses');

module.exports = {
	collections,
	roles,
	invitationStatuses,
};
