import type { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';
import { makeTextInputStyles } from './styles';

const RHFPhoneNumberInput: FC<MuiTelInputProps & { name: string }> = ({ name, ...other }) => {
	const { control } = useFormContext();
	const classes = makeTextInputStyles({ disabled: other.disabled ?? false });

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { ref, ...field }, fieldState: { error } }) => (
				<MuiTelInput
					defaultCountry='GB'
					{...field}
					inputRef={ref}
					fullWidth
					classes={classes}
					error={!!error}
					helperText={error?.message}
					{...other}
				/>
			)}
		/>
	);
};

export default RHFPhoneNumberInput;
