import { call } from '../@';

export type Payload = {
	id: string;
	transactionId: string;
};

export default async function removeTransactionPlaceholder(payload: Payload) {
	return await call('transaction-removePlaceholder', payload);
}
