import { Link, Typography } from '@mui/material';
import Modal from 'src/components/customModal';
import { fullVersionEmail } from 'src/constants/common';
import modals from 'src/constants/modals';

const Content = () => (
	<>
		<Typography textAlign='center' variant='h4'>
			Unlock unlimited transactions today!
		</Typography>
		<Typography textAlign='center' mt='10px' variant='subtitle1'>
			Contact <Link href={`mailto:${fullVersionEmail}`}>{fullVersionEmail}</Link>.
		</Typography>
	</>
);

const FullVersionModal = () => (
	<Modal name={modals.fullVersion} cardSx={{ maxWidth: '630px', overflow: 'visible' }}>
		<Content />
	</Modal>
);

export default FullVersionModal;
