import { DefaultValues } from 'react-hook-form';
import { UserData } from 'src/api/auth/@user-data';
import { getBranchRef, getLawHierarchyRef, getRealtyHierarchyRef } from 'src/utils/firebase';
import { OnboardingCompanyFormType } from './types';

export const getDefaultFormValues = (user: UserData): DefaultValues<OnboardingCompanyFormType> => ({
	branch: typeof user.branch === 'string' ? user.branch : user.branch?.id,
	business: typeof user.business === 'string' ? user.business : user.business?.id,
});

export const getUserBusiness = (isCustomBusiness: boolean, user: UserData, business: string) => {
	if (isCustomBusiness) return business;
	if (user.isEstateAgent) return getRealtyHierarchyRef(business);
	if (user.isSolicitorConveyancer) return getLawHierarchyRef(business);

	return business;
};

export const getUserBranch = (isCustomBusiness: boolean, user: UserData, branch: string) => {
	if (isCustomBusiness) return branch;
	if (user.isEstateAgent || user.isSolicitorConveyancer) return getBranchRef(branch);

	return branch;
};
