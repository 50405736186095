import { call } from '../@';

export type Payload = {
	id: string;
	city?: string;
	postcode?: string;
	street?: string;
	paon?: string;
	saon?: string;
};

const searchPropertyTitles = (payload: Payload) => call('hmlr-searchPropertyTitles', payload);

export default searchPropertyTitles;
