import { useSelector } from 'react-redux';
import { getTransactionOverviewThunk, selectTransactionOverview } from 'src/redux/slices/transaction';
import { Typography, Box } from '@mui/material';
import palette from 'src/theme/palette';
import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { PropertyGeneralAddressCard, TypographyAddressTitle } from '../../styled';
import Task from './components/Task';
import { TasksWrapper } from './styled';

const TasksSection = () => {
	const transaction = useSelector(selectTransactionOverview);

	useEffect(() => {
		dispatch(getTransactionOverviewThunk({ id: transaction.id, withLoading: false }));
	}, []);

	return (
		<PropertyGeneralAddressCard
			sx={{ maxHeight: '620px', overflowY: 'auto', pb: 0, border: `1px solid ${palette.light.info.light}` }}
		>
			<TypographyAddressTitle mb='20px'>YOUR TASKS</TypographyAddressTitle>
			<TasksWrapper>
				<Box width='100%' mb='25px'>
					{transaction.tasks.length ? (
						<Box>
							<Box display='flex' flexDirection='column' gap='10px'>
								{transaction.tasks.map(({ user, tasks: userTasks }) => (
									<div key={user.id}>
										<Typography sx={{ cursor: 'default' }} variant='link'>
											@{user.knownAs ?? user.givenNames}
										</Typography>
										:
										<Box pl='35px'>
											<Box
												component='ol'
												display='flex'
												flexDirection='column'
												gap='5px'
												sx={{ listStylePosition: 'outside' }}
											>
												{userTasks.map((task) => (
													<li key={task.id}>
														<Task {...task} user={user} />
													</li>
												))}
											</Box>
										</Box>
									</div>
								))}
							</Box>
						</Box>
					) : (
						<b>There are currently no pending tasks.</b>
					)}
				</Box>
			</TasksWrapper>
		</PropertyGeneralAddressCard>
	);
};

export default TasksSection;
