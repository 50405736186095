import { useSelector } from 'react-redux';
import { selectUser, updateUser } from 'src/redux/slices/auth';
import { openModal } from 'src/redux/slices/modal';
import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum, VerificationSessionStatus } from 'src/types';
import useLoading from 'src/hooks/useLoading';
import { updateTransactionOnboarding } from 'src/redux/slices/transaction';
import { noop } from 'lodash';
import { getNameVerificationDocument } from 'src/utils/verification';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { FC, useEffect } from 'react';
import useError from 'src/hooks/useError';
import { DocumentReference, updateDoc } from 'firebase/firestore';
import { extractKnownAsFromGivenNames } from 'src/utils/users';
import { useSnackbar } from 'notistack';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { updatePropertyOnboarding } from 'src/redux/slices/property';
import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { ConfirmationModal } from '..';
import { VerificationConfirmationModalProps, VerificationNameConfirmationForm } from './types';
import { verificationNameConfirmationValidationSchema } from './validation';
import { transformName } from './utils';

const VerificationNameConfirmationModal: FC<VerificationConfirmationModalProps> = ({ isProperty }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { isLoading, setIsLoading } = useLoading();
	const user = useSelector(selectUser);
	const { error, setError } = useError();
	const methods = useForm<VerificationNameConfirmationForm>({
		resolver: yupResolver(verificationNameConfirmationValidationSchema),
	});

	const document = user.verificationSession ? getNameVerificationDocument(user.verificationSession) : null;

	useEffect(() => {
		if (!document) return;

		methods.reset({
			fullName: transformName(document.full_name ?? ''),
			givenNames: transformName(document.given_names ?? ''),
			familyNames: transformName(document.family_name ?? ''),
		});
	}, [document]);

	if (!user.verificationSession) return null;

	const handleAccept = async (data: VerificationNameConfirmationForm) => {
		try {
			setIsLoading(true);

			const constructedFullName = `${data.givenNames} ${data.familyNames}`;

			if (data.fullName !== constructedFullName) {
				setError('Your given name(s) and family name(s) don`t match your full name. Please check again.');

				return;
			}

			const areCustomGivenNames = !document?.given_names;
			const areCustomFamilyNames = !document?.family_name;

			if (areCustomGivenNames || areCustomFamilyNames) {
				const verificationSessionUpdate = {
					...(areCustomGivenNames && { requestedGivenNames: data.givenNames }),
					...(areCustomFamilyNames && { requestedFamilyNames: data.familyNames }),
				};

				await updateDoc(user.verificationSessionRef as DocumentReference, verificationSessionUpdate);
			}

			if (isProperty) {
				await dispatch(
					updatePropertyOnboarding({
						payload: {
							nameConfirmation: OnboardingStepStatusEnum.PASSED,
							nameIssue: OnboardingStepStatusEnum.PASSED,
						},
						moveToNextModal: true,
					}),
				).unwrap();
			} else {
				await dispatch(
					updateTransactionOnboarding({
						nameConfirmation: OnboardingStepStatusEnum.PASSED,
						nameIssue: OnboardingStepStatusEnum.PASSED,
					}),
				).unwrap();
			}

			dispatch(
				updateUser({
					isNameLocked: true,
					givenNames: data.givenNames,
					lastNames: data.familyNames,
					knownAs: extractKnownAsFromGivenNames(data.givenNames),
				}),
			);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message);
		} finally {
			setIsLoading(false);
		}
	};
	const handleReject = () => {
		if (isProperty) {
			dispatch(
				updatePropertyOnboarding({
					payload: {
						nameConfirmation: OnboardingStepStatusEnum.PASSED,
						verificationIssue: OnboardingStepStatusEnum.PENDING,
					},
					moveToNextModal: true,
				}),
			);
		} else {
			dispatch(
				updateTransactionOnboarding({
					nameConfirmation: OnboardingStepStatusEnum.PASSED,
					verificationIssue: OnboardingStepStatusEnum.PENDING,
				}),
			);
		}

		dispatch(updateUser({ verificationStatus: VerificationSessionStatus.NAME_MISMATCH }));

		dispatch(
			openModal({ name: isProperty ? propertyOnboardingSteps.nameIssue : transactionOnboardingSteps.nameIssue }),
		);
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '600px' }}
			modalName={isProperty ? propertyOnboardingSteps.nameConfirmation : transactionOnboardingSteps.nameConfirmation}
			isLoading={isLoading}
			title='NAME CONFIRMATION'
			description={
				<FormProvider methods={methods} onSubmit={methods.handleSubmit(handleAccept)}>
					{!!error && <Box mb='15px'>{error}</Box>}
					<Box display='flex' flexDirection='column' gap='15px'>
						<RHFTextField name='fullName' label='Full Name' placeholder='Please enter your full name' disabled />
						<RHFTextField
							name='givenNames'
							label='Given Name(s)'
							placeholder='Please enter your given name(s)'
							disabled={!!document?.given_names}
						/>
						<RHFTextField
							name='familyNames'
							label='Family Name(s)'
							placeholder='Please enter your family name(s)'
							disabled={!!document?.family_name}
						/>
					</Box>
					<br />
					<Box display='flex' gap='15px' justifyContent='flex-end' mt='15px'>
						<LoadingButton onClick={handleReject} variant='outlined' size='large' loading={isLoading}>
							Reject
						</LoadingButton>
						<LoadingButton variant='contained' size='large' loading={isLoading} type='submit'>
							Accept
						</LoadingButton>
					</Box>
				</FormProvider>
			}
			confirmText='Yes'
			cancelText='No'
			handleClose={noop}
			withButtons={false}
		/>
	);
};

export default VerificationNameConfirmationModal;
