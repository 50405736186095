export enum FinancingAttributesEnum {
	'gifted_deposit' = 'Gifted Deposit',
	'first_time_buyer' = 'First-time Buyer',
	'htb_isa' = 'Help-to-Buy ISA',
	'htb_loan' = 'Help-to-Buy Equity Loan',
	'lifetime_isa' = 'Lifetime ISA',
}

export enum FinancingSourcesEnum {
	mortgage = 'Mortgage',
	savings = 'Savings',
	gift = 'Gift',
	inheritance = 'Inheritance',
	property_sale = 'Sale of a property',
	pension = 'Pension',
	gambling = 'Gambling winnings',
	remortgage = 'Remortgage',
	equity_release = 'Equity release',
	compensation = 'Compensation',
}
