import { noop } from 'lodash';
import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum } from 'src/types';
import useLoading from 'src/hooks/useLoading';
import { useSnackbar } from 'notistack';
import Progress from 'src/components/Progress';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import {
	confirmPropertyTitleSelectionThunk,
	selectProperty,
	updatePropertyOnboarding,
} from 'src/redux/slices/property';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { labelByPropertyTitleTenure } from 'src/constants/title';
import { ConfirmationModal } from '../../common';

const PropertyTitleConfirmationModal = () => {
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();
	const property = useSelector(selectProperty);

	const handleConfirm = async () => {
		try {
			setIsLoading(true);

			await dispatch(confirmPropertyTitleSelectionThunk());

			await dispatch(
				updatePropertyOnboarding({
					moveToNextModal: true,
					payload: { titleConfirmation: OnboardingStepStatusEnum.PASSED },
				}),
			);
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const handleBack = async () => {
		setIsLoading(true);

		await dispatch(
			updatePropertyOnboarding({
				moveToNextModal: true,
				payload: {
					titleSelection: OnboardingStepStatusEnum.PENDING,
					titleConfirmation: OnboardingStepStatusEnum.PENDING,
				},
			}),
		);

		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			modalName={propertyOnboardingSteps.titleConfirmation}
			title='CONFIRM TITLE'
			withButtons={false}
			handleClose={noop}
			isLoading={isLoading}
			sx={{ maxWidth: '550px' }}
			description={
				property.onboarding?.selectedTitle ? (
					<>
						You have selected{' '}
						<b>
							{property.onboarding.selectedTitle.number} (
							{labelByPropertyTitleTenure[property.onboarding.selectedTitle.tenure]})
						</b>
						. If you made a mistake, don't worry! A member of our team can help you change your selection later.
						<Box display='flex' gap='15px' justifyContent='flex-end' mt='15px' mb='-10px'>
							<LoadingButton onClick={handleBack} variant='outlined' size='large' loading={isLoading}>
								Back
							</LoadingButton>
							<LoadingButton variant='contained' size='large' loading={isLoading} onClick={handleConfirm}>
								Confirm
							</LoadingButton>
						</Box>
					</>
				) : (
					<Box py='30px'>
						<Progress zoom={0.8} />
					</Box>
				)
			}
		/>
	);
};

export default PropertyTitleConfirmationModal;
