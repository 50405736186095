export enum RightsEnum {
	'joint_services' = 'Joint services',
	'rights_nearby' = 'Rights over neighbouring properties',
	'light' = 'Right of light',
	'support' = 'Right of support',
	'customary' = 'Customary rights',
	'other_rights' = 'Other rights',
}

export enum ArrangementsEnum {
	'chancel' = 'Chancel repair',
	'mining' = 'Rights to things under the land',
	'things' = 'Rights to things from the land',
	'access_right' = 'Right of others to enter your property',
	'other_rights' = 'Other arrangements',
}
