import palette from 'src/theme/palette';

export const styledMainTitle = {
	color: palette.light.text.primary,
	pb: '15px',
	fontWeight: 700,
	fontSize: '18px',
};

export const styledAutocompleteJob = {
	color: palette.light.custom.bidyText,
	marginTop: '5px',
	fontSize: '14px',
};

export const styledAutocompleteWrapper = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'start !important',
	'&.Mui-focused': {
		backgroundColor: '#D1E9FC !important',
	},
};

export const styledInviteBtn = {
	boxShadow: 'none',
	marginLeft: 'auto',
	display: 'block',
	minWidth: '171px',
	height: '48px',
	marginTop: '28px',
	fontSize: '16px',
};
