import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'src/firebaseConfig';
import { until } from '../@';
import logout from './logout';
import getCurrentUser from './user';

export default async function login(email: string, password: string) {
	let user = await getCurrentUser();
	if (user) await logout();
	// now "user" is null
	await signInWithEmailAndPassword(auth, email, password);
	// "user" is still null, we're loading data from the DB. Should wait until it's fulfilled
	// eslint-disable-next-line no-return-assign
	await until(async () => Boolean((user = await getCurrentUser())));
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return user!;
}
