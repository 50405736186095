import type { FirebaseFirestore } from '@firebase/firestore-types';

export type Firestore = FirebaseFirestore;

export { collections, invitationStatuses, roles } from '../../../constants';

export const uidFieldName = Symbol('UID field');

export const firestoreImportsField = Symbol('Firestore field');

type ImportedFields = 'doc' | 'collection' | 'getDocs' | 'query' | 'where' | 'orderBy';

export function importFirestore(): { [x in ImportedFields]: typeof import('firebase/firestore')[x] } {
	return globalThis[firestoreImportsField];
}
