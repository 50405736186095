import { ConfirmationModal } from 'src/components/modals';
import modals from 'src/constants/modals';
import { dispatch } from 'src/redux/store';
import { setCurrentTransactionTab } from 'src/redux/slices/transaction';
import { noop } from 'lodash';
import { handleCloseModal } from 'src/utils/propertyHelpers';

const CompleteYourPersonalDetailsModal = () => {
	const handleConfirm = async () => {
		dispatch(setCurrentTransactionTab('participants'));
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			sx={{ minWidth: '600px' }}
			modalName={modals.completePersonalDetails}
			handleClose={noop}
			withCancelButton={false}
			handleConfirm={handleConfirm}
			confirmText='Continue'
			title='ACTION REQUIRED: COMPLETE YOUR PERSONAL DETAILS'
			description={
				<>
					You must confirm your personal details before you can fill out the questions.
					<br />
					<br />
					Please head to the <b>Participants</b> tab and check the header on your card.
				</>
			}
		/>
	);
};

export default CompleteYourPersonalDetailsModal;
