import { Timestamp, updateDoc } from 'firebase/firestore';

import { TransactionOffer, TransactionOfferStatusEnum } from 'src/types';
import { IStateTransactionOffer } from 'src/redux/types';
import { getTransaction, getTransactionOffer, getTransactionOfferRef } from './firebase';
import { sortArrayByTimestampField } from './firestore';

type CreateTransactionOfferParams = Partial<
	Pick<TransactionOffer, 'timestamp' | 'agreedBy' | 'viewedBy' | 'toUser' | 'status'>
> &
	Pick<TransactionOffer, 'toOffer' | 'price' | 'fromUser' | 'transaction'>;

export const createTransactionOffer = (params: CreateTransactionOfferParams): TransactionOffer => ({
	timestamp: Timestamp.now(),
	agreedBy: [],
	viewedBy: [],
	status: TransactionOfferStatusEnum.ACTIVE,
	...params,
});

export const makeTransactionOffersFrozenByUserId = async (offers: IStateTransactionOffer[], userId: string) => {
	const frozenOfferIds = offers.reduce<string[]>((acc, offer) => {
		if (offer.toUserId === userId || offer.fromUserId === userId) {
			acc.push(offer.id);
		}

		return acc;
	}, []);

	await Promise.all(
		frozenOfferIds.map((id) => updateDoc(getTransactionOfferRef(id), { status: TransactionOfferStatusEnum.FROZEN })),
	);
};

export const getTransactionOffers = async (id: string) => {
	const transaction = await getTransaction(id);

	const offers = await Promise.all(
		transaction.data.offers.map((offer) => getTransactionOffer(offer.id).then(({ data }) => data)),
	);
	const agreedOffer = transaction.data.agreedOffer
		? await getTransactionOffer(transaction.data.agreedOffer?.id)
		: undefined;

	return { offers: sortArrayByTimestampField(offers, 'timestamp'), agreedOffer };
};
