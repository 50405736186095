import { Suspense, lazy, FC, PropsWithChildren } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// pages
import AuthGuard from 'src/guards/AuthGuard';
import Invitation from 'src/pages/Invitation';
import ConfirmEmailChange from 'src/pages/ConfirmEmailChange';
import { MyTransaction } from 'src/pages/transactions/MyTransaction';
import PropertiesViewPage from 'src/pages/properties/PropertiesView';
import TransactionLayout from 'src/layouts/transaction';
import OnboardingRole from 'src/pages/auth/OnboardingRole';
import OnboardingGuard from 'src/guards/OnboardingGuard';
import OnboardingProfession from 'src/pages/auth/OnboardingProfession';
import OnboardingCompany from 'src/pages/auth/OnboardingCompany';
import OnboardingContacts from 'src/pages/auth/OnboardingInfo';
import OnboardingVerification from 'src/pages/auth/OnboardingVerification';
import ConfirmResetPassword from 'src/pages/auth/ConfirmResetPassword';
import TransactionQrCode from 'src/pages/TransactionQrCode';
import TransactionParticipants from 'src/pages/transactions/participants';
import Logout from 'src/pages/auth/Logout';
import { GeneralTab } from 'src/components/GeneralTab';
import TransactionQuestions from 'src/sections/transaction/Questions';
import { Documents } from 'src/components/Documents';
import TransactionSummary from 'src/components/TransactionSummary';
import TransactionEmailTrail from 'src/components/TransactionTrail';
import TransactionJobs from 'src/components/TransactionJobs';
import Bin from 'src/pages/Bin';
import Archive from 'src/pages/Archive';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import MainLayout from '../layouts/main';
// guards
import GuestGuard from '../guards/GuestGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------
// eslint-disable-next-line
const Loadable = (Component: FC<PropsWithChildren>) => (props: any) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { pathname } = useLocation();

	return (
		<Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
			<Component {...props} />
		</Suspense>
	);
};

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

// USER
const UserAccount = Loadable(lazy(() => import('../pages/account-settings/UserAccount')));

// MAIN
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Main')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export default function Router() {
	return useRoutes([
		{
			path: '/',
			element: <Navigate to='/dashboard' />,
		},
		{
			path: 'invitation',
			element: <Invitation />,
		},
		{
			path: 'confirm-email-change',
			element: <ConfirmEmailChange />,
		},
		{
			path: 'transaction-qr-code/:id',
			element: <TransactionQrCode />,
		},
		{
			path: 'logout',
			element: <Logout />,
		},
		{
			path: 'onboarding',
			children: [
				{
					path: 'verification',
					element: (
						<OnboardingGuard>
							<OnboardingVerification />
						</OnboardingGuard>
					),
				},
				{
					path: 'select-role',
					element: (
						<OnboardingGuard>
							<OnboardingRole />
						</OnboardingGuard>
					),
				},
				{
					path: 'select-profession',
					element: (
						<OnboardingGuard>
							<OnboardingProfession />
						</OnboardingGuard>
					),
				},
				{
					path: 'select-company',
					element: (
						<OnboardingGuard>
							<OnboardingCompany />
						</OnboardingGuard>
					),
				},
				{
					path: 'fill-info',
					element: (
						<OnboardingGuard>
							<OnboardingContacts />
						</OnboardingGuard>
					),
				},
			],
		},
		{
			path: 'auth',
			children: [
				{
					path: 'login',
					element: (
						<GuestGuard>
							<Login />
						</GuestGuard>
					),
				},
				{
					path: 'register',
					element: (
						<GuestGuard>
							<Register />
						</GuestGuard>
					),
				},
				{ path: 'reset-password', element: <ResetPassword /> },
				{ path: 'confirm-reset-password', element: <ConfirmResetPassword /> },
			],
		},
		{
			path: 'properties',
			element: (
				<AuthGuard>
					<MainLayout withHeader={false} withActions />
				</AuthGuard>
			),
			children: [
				{
					path: ':id',
					element: <PropertiesViewPage />,
				},
			],
		},
		{
			path: 'archive',
			element: (
				<AuthGuard>
					<MainLayout withHeader={false} withActions={false} />
				</AuthGuard>
			),
			children: [{ path: '', element: <Archive /> }],
		},
		{
			path: 'bin',
			element: (
				<AuthGuard>
					<MainLayout withHeader={false} withActions={false} />
				</AuthGuard>
			),
			children: [{ path: '', element: <Bin /> }],
		},
		{
			path: 'transactions',
			element: (
				<AuthGuard>
					<TransactionLayout>
						<MainLayout withHeader={false} withActions />
					</TransactionLayout>
				</AuthGuard>
			),
			children: [
				{
					path: ':id',
					element: <MyTransaction />,
					children: [
						{ path: 'overview', Component: GeneralTab },
						{ path: 'participants', Component: TransactionParticipants },
						{ path: 'questions', Component: TransactionQuestions },
						{ path: 'documents', Component: Documents },
						{ path: 'summary', Component: TransactionSummary },
						{ path: 'trail', Component: TransactionEmailTrail },
						{ path: 'jobs', Component: TransactionJobs },
						{ path: '*', element: <Navigate to='/dashboard' /> },
					],
				},
			],
		},
		{
			path: 'instruments',
			element: (
				<AuthGuard>
					<TransactionLayout>
						<MainLayout withHeader={false} withActions />
					</TransactionLayout>
				</AuthGuard>
			),
			children: [{ path: ':id', element: <MyTransaction /> }],
		},

		// Dashboard Routes
		{
			path: '/dashboard',
			element: (
				<AuthGuard>
					<MainLayout withHeader={false} withActions />
				</AuthGuard>
			),
			children: [{ element: <Dashboard />, index: true }],
		},

		// Main Routes
		{
			path: '*',
			element: <LogoOnlyLayout />,
			children: [
				{ path: 'maintenance', element: <Maintenance /> },
				{ path: '500', element: <Page500 /> },
				{ path: '404', element: <Page404 /> },
				{ path: '403', element: <Page403 /> },
				{ path: '*', element: <Navigate to='/404' replace /> },
			],
		},
		{
			path: '/',
			element: (
				<AuthGuard>
					<MainLayout />
				</AuthGuard>
			),
			children: [{ path: 'account-settings', element: <UserAccount /> }],
		},
		{ path: '*', element: <Navigate to='/404' replace /> },
	]);
}
