import { useSelector } from 'react-redux';
import { selectTransactionTracker } from 'src/redux/slices/transaction';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import Progress from 'src/components/Progress';
import { useState } from 'react';
import { TrackerStepName } from 'src/types';
import { selectUser } from 'src/redux/slices/auth';
import { TransactionSummaryTable } from '../../styles';
import TrackerSection from './components/TrackerSection';
import { getTabs } from './constants';
import Task from './components/Task';
import SummaryField from '../SummaryField';

const Tracker = () => {
	const user = useSelector(selectUser);
	const [tab, setTab] = useState(user.isAdmin ? 'seller' : 'legals');
	const tracker = useSelector(selectTransactionTracker);

	const handleTabChange = (_, t) => setTab(t);

	const tabs = getTabs(user);

	return (
		<TransactionSummaryTable title='Tasks' minWidth='1200px' sx={{ position: 'relative' }}>
			{tracker.error ? (
				<Typography variant='subtitle1' color='red' textAlign='center' fontSize='20px'>
					{tracker.error.message}
				</Typography>
			) : (
				<>
					<Box pt='10px'>
						<Box pl='10px'>
							<Tabs
								allowScrollButtonsMobile
								variant='scrollable'
								scrollButtons='auto'
								value={tab}
								onChange={handleTabChange}
							>
								{tabs.map((t) => (
									<Tab disableRipple key={t.value} label={t.label} value={t.value} />
								))}
							</Tabs>
						</Box>
					</Box>
					<Box height='100%' width='100%'>
						{tab === 'deleted' && (
							<>
								<SummaryField name={'DELETED'} sx={{ background: '#DCDCDC' }} />
								{tracker.data?.deleted.map((t) => (
									<Task key={t.id} {...t} />
								))}
							</>
						)}
						{tabs.map(({ value }) => {
							const isMatched = value === tab;

							if (tab === 'deleted') return null;

							return (
								isMatched && (
									<Box key={value} sx={{ height: '100%' }}>
										<TrackerSection step={value as TrackerStepName} />
									</Box>
								)
							);
						})}
					</Box>
				</>
			)}
			{!tracker.isInitialized && (
				<Box py='75px' position='absolute' height='100%' width='100%' bgcolor='white' sx={{ opacity: 0.6 }}>
					<Progress />
				</Box>
			)}
		</TransactionSummaryTable>
	);
};

export default Tracker;
