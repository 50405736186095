export const defaultCountryId = 'DEGExQlFtQfygl3jFfNJ';

export const COUNTIES_LIST = [
	{
		name: 'Greater London',
	},
	{
		name: 'England',
	},
	{
		name: 'Northern Ireland',
	},
	{
		name: 'Scotland',
	},
	{
		name: 'Wales',
	},
];
