import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ButtonGroup(theme: Theme) {
	return {
		MuiButtonGroup: {
			variants: [
				{
					props: { variant: 'contained', color: 'inherit' },
					style: { boxShadow: theme.customShadows.z8 },
				},
				{
					props: { disabled: true },
					style: {
						boxShadow: 'none',
						'& .MuiButtonGroup-grouped.Mui-disabled': {
							color: theme.palette.action.disabled,
							borderColor: `${theme.palette.action.disabledBackground} !important`,
							'&.MuiButton-contained': {
								backgroundColor: theme.palette.action.disabledBackground,
							},
						},
					},
				},
			],

			styleOverrides: {
				root: {
					'&:hover': {
						boxShadow: 'none',
					},
				},
			},
		},
	};
}
