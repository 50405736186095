import { requiredString } from 'src/utils/schemas';
import * as Yup from 'yup';

export const getOfficialCopiesValidationSchema = Yup.object().shape({
	titles: Yup.array(Yup.object().shape({ key: requiredString, value: Yup.boolean() })).test(
		'isTitleSelected',
		'At least one title must be selected',
		(value) => !!value?.filter((t) => t.value).length,
	),
});
