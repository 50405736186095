import type { FC } from 'react';
import { usePhotoRef } from 'src/hooks/usePhotoRef';
import Image from '../Image';
import { ProfilePicWrapper, TypoWithoutPhoto } from './styles';
import type { IUserProfilePhoto } from './types';

export const ProfilePhoto: FC<IUserProfilePhoto> = ({ photo, givenNames }) => {
	const link = usePhotoRef(photo);

	return (
		<ProfilePicWrapper>
			{photo && link ? (
				<Image src={link} sx={{ width: '100%', height: '100%' }} />
			) : (
				<TypoWithoutPhoto>{givenNames.split('')[0]}</TypoWithoutPhoto>
			)}
		</ProfilePicWrapper>
	);
};
