import { FC } from 'react';
import { Accordion, AccordionDetails, Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import {
	labelByPropertyTitleDocumentsCategories,
	propertyTitleDocumentsCategories,
} from 'src/components/TransactionSummary/constants';
import { DocumentsCategoryProps } from './types';
import { DocumentCheckboxesAccordionSummary } from '../../styled';
import { FormTitleDocumentCategory } from '../../types';
import { getDocumentsCategoryName } from './utils';
import DocumentsSubcategory from '../DocumentsSubcategory';

const DocumentsCategory: FC<DocumentsCategoryProps> = ({ documentsCategory, index }) => {
	const { watch } = useFormContext();

	const titleDocuments: FormTitleDocumentCategory | undefined = watch(`titlesDocuments.${index}`);
	// const areAllDocumentsSelected = titleDocuments ? checkAreAllTitleDocumentsSelected(titleDocuments) : false;

	if (!titleDocuments) return null;

	// const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
	// 	e.stopPropagation();
	// 	setValue(`titlesDocuments.${index}`, updateNestedTitleDocumentsSelection(titleDocuments, e.target.checked));
	// };

	return (
		<Box mb='7px'>
			<Accordion>
				<DocumentCheckboxesAccordionSummary>
					{getDocumentsCategoryName(documentsCategory)}
					{/* <DocumentCheckbox
						name=''
						onChange={handleChange}
						checked={areAllDocumentsSelected}
						disableRipple
						label={getDocumentsCategoryName(documentsCategory)}
						labelControlProps={{ labelPlacement: 'end', onClick: stopPropagation }}
					/> */}
				</DocumentCheckboxesAccordionSummary>
				<AccordionDetails>
					<Box pl='15px' display='flex' flexDirection='column' gap='5px'>
						{Object.keys(propertyTitleDocumentsCategories).map((k) => (
							<DocumentsSubcategory
								key={k}
								categoryIndex={index}
								documents={documentsCategory[k]}
								title={labelByPropertyTitleDocumentsCategories[k] ?? k}
								name={k}
							/>
						))}
					</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};

export default DocumentsCategory;
