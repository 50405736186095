import { FC } from 'react';

import { TaskProps } from './types';
import { useTask } from './hooks/useTask';

const Task: FC<TaskProps> = (task) => {
	const { statement } = useTask(task, task.user);

	return <>{statement}</>;
};

export default Task;
