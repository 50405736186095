import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import Modal from 'src/components/customModal';
import { styledMainTitle } from 'src/components/InviteModal/styles';
import modals from 'src/constants/modals';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import { dispatch } from 'src/redux/store';
import { addDocumentsFolderThunk, getDocumentsOverviewThunk, selectDocuments } from 'src/redux/slices/documents';
import { useSelector } from 'react-redux';
import useCurrentDocumentsLocation from 'src/hooks/useCurrentDocumentsLocation';
import { AddFolderForm } from './types';
import { addFolderValidationSchema } from './validation';
import FolderLocationPicker from '../../components/FolderLocationPicker';
import PermissionsPicker from '../../components/PermissionsPicker';
import { getDefaultActionPermissions } from './utils';
import { extractSelectedPermissions } from '../../components/PermissionsPicker/utils';

const Content = () => {
	const { tree } = useSelector(selectDocuments);
	const location = useCurrentDocumentsLocation();
	const form = useForm<AddFolderForm>({
		defaultValues: {
			isSection: false,
			folderId: tree.id,
			permissions: {
				upload: getDefaultActionPermissions([], true),
				delete: getDefaultActionPermissions([], true),
				download: getDefaultActionPermissions([], true),
				see: getDefaultActionPermissions([], true),
			},
		},
		resolver: yupResolver(addFolderValidationSchema),
	});

	const handleCreate = async (data: AddFolderForm) => {
		await dispatch(
			addDocumentsFolderThunk({
				...data,
				location,
				type: data.isSection ? 'section' : 'folder',
				permissions: extractSelectedPermissions(data.permissions),
			}),
		);
		dispatch(getDocumentsOverviewThunk({ location, withLoading: true }));

		handleCloseModal();
	};

	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(handleCreate)}>
				<Box display='flex' flexDirection='column' gap='15px'>
					<Typography sx={{ ...styledMainTitle, pb: 0 }}>ADD FOLDER</Typography>
					<FolderLocationPicker name='folderId' />
					<Box>
						<RHFTextField name='name' placeholder='Enter name' />
						<RHFCheckbox
							name='isSection'
							label='Show as section'
							labelControlProps={{ labelPlacement: 'end', sx: { padding: 0 } }}
						/>
					</Box>
					<PermissionsPicker />
					<Box display='flex' justifyContent='flex-end' gap='15px' alignItems='center'>
						<LoadingButton variant='contained' onClick={handleCloseModal} loading={form.formState.isSubmitting}>
							CANCEL
						</LoadingButton>
						<LoadingButton variant='contained' type='submit' loading={form.formState.isSubmitting}>
							SAVE
						</LoadingButton>
					</Box>
				</Box>
			</form>
		</FormProvider>
	);
};

const AddFolderModal = () => (
	<Modal name={modals.addDocumentsFolder}>
		<Content />
	</Modal>
);

export default AddFolderModal;
