import { Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from 'src/components/Iconify';

export const QuestionnaireCardWrapper = styled(Card)`
	width: 400px;
	height: 280px;
	padding: 20px 25px;
	box-shadow: 0px 1px 1px #f1f1f1, 0px 4px 16px #e9e9e9;
	position: relative;
`;

export const TitleRow = styled('div')({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	height: '45px',
});

export const TypographyTitle = styled(Typography)({
	fontFamily: 'Public Sans',
	fontStyle: 'normal',
	fontWeight: '700',
	fontSize: '16px',
});

export const QuestionnaireIcon = styled(Iconify)<{ disabled?: boolean }>(({ disabled }) => ({
	marginLeft: 'auto',
	cursor: disabled ? 'default' : 'pointer',
}));

export const TypographyStatus = styled(Typography)(() => ({
	marginLeft: '9px',
	padding: '4px 8px',
	borderRadius: '8px',
	fontFamily: 'Public Sans',
	fontStyle: 'normal',
	fontWeight: '700',
	fontSize: '14px',
	textTransform: 'capitalize',
	whiteSpace: 'nowrap',
}));
