import { Box } from '@mui/material';
import { ChangeEvent, FC, useRef } from 'react';
import {
	getTransactionParticipantsThunk,
	selectTransactionOverview,
	uploadPlaceholderVerificationDocument,
} from 'src/redux/slices/transaction';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { IInviteCardMenuOptionProps } from 'src/components/InviteCard/types';
import { useSnackbar } from 'notistack';
import { UploadProofOfIdOptionProps } from './types';

const UploadProofOfIdOption: FC<UploadProofOfIdOptionProps & IInviteCardMenuOptionProps> = ({
	placeholderId,
	handleClose,
}) => {
	const { enqueueSnackbar } = useSnackbar();
	const transaction = useSelector(selectTransactionOverview);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
		try {
			const { files } = event.target;

			if (!files) return;

			handleClose();
			await dispatch(
				uploadPlaceholderVerificationDocument({ file: files[0], placeholderId, transactionId: transaction.id }),
			).unwrap();
			dispatch(getTransactionParticipantsThunk({ id: transaction.id, withLoading: true }));
		} catch (e) {
			if (e instanceof Error) {
				enqueueSnackbar(e.message, { variant: 'error' });
			}
		}
	};

	const handleClick = () => inputRef.current?.click();

	return (
		<Box onClick={handleClick}>
			Upload Proof of ID
			<input ref={inputRef} onChange={handleChange} type='file' hidden />
		</Box>
	);
};

export default UploadProofOfIdOption;
