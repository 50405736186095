import { ChangeEvent, FC, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { dispatch } from 'src/redux/store';
import { uploadDocumentThunk } from 'src/redux/slices/documents';
import Iconify from 'src/components/Iconify';
import { useSnackbar } from 'notistack';
import { validateUploadingFiles } from 'src/utils/documents';
import { documentsInputAcceptTypes } from 'src/constants/documents';
import { removeTask } from 'src/redux/slices/transaction';
import { useSelector } from 'react-redux';
import { selectUser } from 'src/redux/slices/auth';
import { UploadButtonProps } from './types';

const UploadButton: FC<UploadButtonProps> = ({ disabled, task }) => {
	const user = useSelector(selectUser);
	const [isLoading, setIsLoading] = useState(false);
	const ref = useRef<HTMLInputElement>(null);
	const { enqueueSnackbar } = useSnackbar();

	const handleClick = () => ref.current?.click();
	const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
		try {
			if (!event.target.files) return;

			const filesArray = Array.from(event.target.files);

			const isValid = validateUploadingFiles(filesArray, enqueueSnackbar);

			if (!isValid) return;

			setIsLoading(true);

			await Promise.all(
				filesArray.map(async (file) => {
					await dispatch(
						uploadDocumentThunk({
							folderId: task?.metadata.folderId,
							location: 'transaction',
							file,
							taskType: task.type,
						}),
					).unwrap();
				}),
			);

			dispatch(removeTask({ taskId: task.id, userId: user.uid }));
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			// eslint-disable-next-line no-param-reassign
			event.target.value = '';

			setIsLoading(false);
		}
	};

	return (
		<Typography
			variant='link'
			onClick={handleClick}
			sx={{
				pointerEvents: disabled || isLoading ? 'none' : 'auto',
				display: 'inline-flex',
				alignItems: 'center',
				gap: '3px',
			}}
			{...((disabled || isLoading) && { color: 'gray' })}
		>
			[Upload]
			<input
				ref={ref}
				hidden
				type='file'
				onChange={handleChange}
				disabled={disabled}
				multiple
				accept={documentsInputAcceptTypes}
			/>
			{isLoading && <Iconify icon='line-md:loading-loop' />}
		</Typography>
	);
};

export default UploadButton;
