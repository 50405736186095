import { FC, useEffect } from 'react';
import Modal from 'src/components/customModal';
import { useYotiVerificationSession } from 'src/hooks/useYotiVerificationSession';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { Box } from '@mui/material';
import Progress from 'src/components/Progress';
import { dispatch, useSelector } from 'src/redux/store';
import { updateAllPropertyOnboardings } from 'src/redux/slices/property';
import { OnboardingStepStatusEnum, VerificationSessionStatus } from 'src/types';
import { openModal, selectModal } from 'src/redux/slices/modal';
import useLoading from 'src/hooks/useLoading';
import { VerificationSessionSide } from 'src/hooks/types';
import { updateUser } from 'src/redux/slices/auth';
import { updateAllTransactionOnboardings } from 'src/redux/slices/transaction';
import { handleCloseModal } from 'src/utils/propertyHelpers';
import { VerificationModalProps } from './types';

const Content: FC<VerificationModalProps> = ({ isPropertyOnboarding }) => {
	const {
		payload: { side },
	} = useSelector(selectModal<{ side: VerificationSessionSide }>);
	const { session } = useYotiVerificationSession(side);
	const { isLoading, setIsLoading } = useLoading(true);

	const handleVerificationMessageEvent = async (event: MessageEvent) => {
		if (event.data.eventType === 'SUCCESS') {
			setIsLoading(true);

			await Promise.all([
				isPropertyOnboarding
					? dispatch(
							updateAllPropertyOnboardings({
								payload: {
									verificationIntroduction: OnboardingStepStatusEnum.PASSED,
									verification: OnboardingStepStatusEnum.PASSED,
								},
							}),
					  )
					: dispatch(
							updateAllTransactionOnboardings({
								verificationIntroduction: OnboardingStepStatusEnum.PASSED,
								verification: OnboardingStepStatusEnum.PASSED,
							}),
					  ),
				dispatch(updateUser({ verificationStatus: VerificationSessionStatus.IN_PROGRESS })),
			]);
			if (isPropertyOnboarding) dispatch(openModal({ name: propertyOnboardingSteps.verificationInProgress }));

			setIsLoading(false);
		}
	};

	useEffect(() => {
		window.addEventListener('message', handleVerificationMessageEvent);

		return () => {
			window.removeEventListener('message', handleVerificationMessageEvent);
		};
	}, []);

	const handleLoad = () => setIsLoading(false);

	return (
		<Box position='relative' height='750px'>
			{isLoading && (
				<Box
					height='100%'
					width='100%'
					display='flex'
					alignItems='center'
					justifyContent='center'
					position='absolute'
					bgcolor='white'
				>
					<Progress />
				</Box>
			)}
			{session && (
				<iframe
					onLoad={handleLoad}
					title='Identity Verification'
					style={{ border: 'none' }}
					width='100%'
					height='100%'
					allow='camera'
					allowFullScreen
					src={`${process.env.REACT_APP_YOTI_IDV_IFRAME_URL}?sessionID=${session.sessionId}&sessionToken=${session.clientSessionToken}`}
				/>
			)}
		</Box>
	);
};

const VerificationModal: FC<VerificationModalProps> = ({
	name,
	isPropertyOnboarding,
	handleClose = handleCloseModal,
}) => (
	<Modal name={name} cardSx={{ maxWidth: '500px' }} onClose={handleClose}>
		<Content name={name} isPropertyOnboarding={isPropertyOnboarding} handleClose={handleClose} />
	</Modal>
);

export default VerificationModal;
