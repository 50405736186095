import Page from 'src/components/Page';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BrandCard, RHFOtpInput } from 'src/components';
import { confirmation } from 'src/api';
import { selectUser, updateUserOnboarding } from 'src/redux/slices/auth';
import { dispatch } from 'src/redux/store';
import useError from 'src/hooks/useError';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import resendVerificationCode from 'src/api/auth/resend-verification-code';
import { useSnackbar } from 'notistack';
import useLoading from 'src/hooks/useLoading';
import { Typography } from '@mui/material';
import { AuthPage, ContinueButton } from '../styles';
import { onboardingVerificationValidationSchema } from './validation';
import { OnboardingVerificationFormType } from './types';
import OnboardingFooter from '../OnboardingFooter';
import { GoBackButton, GoBackButtonProgress } from '../OnboardingFooter/styled';

const OnboardingVerification = () => {
	const { error, setError } = useError();
	const { enqueueSnackbar } = useSnackbar();
	const { isLoading, setIsLoading } = useLoading();
	const user = useSelector(selectUser);
	const form = useForm<OnboardingVerificationFormType>({
		resolver: yupResolver(onboardingVerificationValidationSchema),
	});

	const code = form.watch('code');

	const handleSubmit = async (data: OnboardingVerificationFormType) => {
		try {
			setError(null);

			await confirmation(data.code);
			await dispatch(updateUserOnboarding({ verification: true }));
		} catch (e) {
			console.error(e);

			if (e instanceof Error) setError(e.message);
		}
	};

	const handleResend = async () => {
		try {
			setIsLoading(true);
			await resendVerificationCode(user.email);

			setIsLoading(false);

			enqueueSnackbar('Verification code resent!');
		} catch (e) {
			console.error(e);

			if (e instanceof Error) setError(e.message);
		}
	};

	useEffect(() => {
		setError(null);
	}, [code]);

	return (
		<FormProvider {...form}>
			<Page title='Login'>
				<AuthPage>
					<form onSubmit={form.handleSubmit(handleSubmit)}>
						<BrandCard title='Activate your account'>
							{error}
							<RHFOtpInput name='code' />
							<ContinueButton variant='contained' fullWidth type='submit' loading={form.formState.isSubmitting}>
								Continue
							</ContinueButton>
							<GoBackButton disableRipple disabled={isLoading} onClick={handleResend}>
								Resend code{isLoading && <GoBackButtonProgress size='11px' />}
							</GoBackButton>
							<OnboardingFooter />
							<Typography variant='caption' fontSize='10px' textAlign='center'>
								Can't find the activation email? <br />
								Don't forget to check your spam folder
							</Typography>{' '}
						</BrandCard>
					</form>
				</AuthPage>
			</Page>
		</FormProvider>
	);
};

export default OnboardingVerification;
