import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string);

const firebaseURL = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL;

// Use this to initialize the firebase App
// eslint-disable-next-line
let firebaseApp: ReturnType<typeof firebase.initializeApp>;
try {
	firebaseApp = firebase.initializeApp(firebaseConfig);
	// we skip the "already exists" message which is
	// not an actual error when we're hot-reloading
	// eslint-disable-next-line
} catch (err: any) {
	if (!/already exists/.test(err.message)) {
		console.error('Firebase initialization error', err.stack);
	}
}

// Use these for db & auth
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const db = firebaseApp.firestore();
const auth = firebase.auth();

db.settings({ ignoreUndefinedProperties: true, experimentalForceLongPolling: true });

export { auth, db, firebaseURL, firebaseApp };
