import { handleCloseModal } from 'src/utils/propertyHelpers';
import { dispatch, useSelector } from 'src/redux/store';
import {
	acceptOffer,
	getTransactionOverviewThunk,
	getTransactionParticipantsThunk,
	selectTransactionOverview,
} from 'src/redux/slices/transaction';
import { selectModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { ConfirmationModal } from 'src/components/modals';
import { IStateTransactionOffer } from 'src/redux/types';
import useLoading from 'src/hooks/useLoading';
import { LocalUser } from 'src/components/InviteCard/types';
import { NoBreak } from 'src/styled';
import { formatBidValue } from 'src/utils/formatBid';
import { useSnackbar } from 'notistack';

const Content = () => {
	const { payload } = useSelector(selectModal<{ offer: IStateTransactionOffer; user: LocalUser }>);

	return (
		<>
			By accepting this offer, you agree to make{' '}
			<b>
				{payload.user.givenNames} {payload.user.lastNames}
			</b>{' '}
			a confirmed buyer in this transaction for an agreed price of{' '}
			<NoBreak as='b'>£{formatBidValue(`${payload.offer.price}`)}</NoBreak>.
		</>
	);
};

export const AcceptOfferModal = () => {
	const { payload } = useSelector(selectModal<{ offer: IStateTransactionOffer }>);
	const transaction = useSelector(selectTransactionOverview);
	const { isLoading, setIsLoading } = useLoading();
	const { enqueueSnackbar } = useSnackbar();

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(acceptOffer({ offerId: payload.offer.id }));

		enqueueSnackbar('Offer accepted!');
		dispatch(getTransactionOverviewThunk({ id: transaction.id }));
		dispatch(getTransactionParticipantsThunk({ id: transaction.id }));
		setIsLoading(false);
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			sx={{ minWidth: '550px' }}
			modalName={modals.acceptOffer}
			title='ACCEPT OFFER'
			description={<Content />}
			confirmText='Confirm'
			handleConfirm={handleConfirm}
			cancelText='Cancel'
			isLoading={isLoading}
		/>
	);
};
