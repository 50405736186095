import { Box, styled, Typography } from '@mui/material';
import palette from 'src/theme/palette';

export const TypoTitle = styled(Typography)`
	font-weight: 700;
	font-size: 18px;
`;

export const ProgressBarWrapper = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '3px',
	backgroundColor: theme.palette.custom.disabledText,
	marginTop: '10px',
	marginBottom: '15px',
}));

export const ProgressBar = styled(Box)<{ currentPage: number; pageCount: number }>(({ currentPage, pageCount }) => ({
	transition: 'width .2s ease-in-out',
	width: `${(currentPage / pageCount) * 100}%`,
	backgroundColor: '#2065D1',
	height: '100%',
}));

export const Step = styled(Box)<{ current?: boolean }>(({ current }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '40px',
	height: '40px',
	borderRadius: '50%',
	backgroundColor: current ? palette.light.primary.main : palette.light.primary.lighter,
	fontWeight: 700,
	fontSize: '14px',
	lineHeight: '16px',
	color: current ? '#FFFFFF' : palette.light.custom.bidyText,
}));
