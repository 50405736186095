import { Box, Card, styled, Typography } from '@mui/material';

// RatingCard

export const RatingCardWrapper = styled(Card)`
	padding: 40px 44px;
	width: 100%;
	box-shadow: 0px 1px 1px #f1f1f1, 0px 4px 16px #d5d5d5;
`;

export const EmptyBox = styled(Box)(({ theme }) => ({
	fontWeight: 700,
	color: theme.palette.grey[800],
	textTransform: 'uppercase',
	width: '100%',
	height: '400px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
	paddingBottom: '10px',
	fontWeight: 700,
	color: theme.palette.grey[800],
	textTransform: 'uppercase',
}));

export const RangesInfoWrapper = styled(Box)`
	display: flex;
	justify-content: space-between;
`;

export const MaxValueTitleTypo = styled(Typography)(({ theme }) => ({
	padding: '30px 0 10px',
	fontSize: '12px',
	color: theme.palette.custom.bidyText,
}));

export const RateRangesItem = styled(Box)<{ color: string; index: number }>(({ theme, color, index }) => ({
	marginBottom: '10px',
	color: theme.palette.grey[0],
	borderRadius: '2px 4px 4px 2px',
	padding: '5px 10px',
	backgroundColor: color,
	width: `${100 + index * 40}px`,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
}));

export const RateRangesTypo = styled(Typography)`
	font-size: 12px;
	font-weight: 500;
`;

export const RateRangesName = styled(Typography)`
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
`;

export const MinValueTitleTypo = styled(Typography)(({ theme }) => ({
	padding: '10px 0',
	fontSize: '12px',
	color: theme.palette.custom.bidyText,
}));

export const GraphBox = styled(Box)`
	display: flex;
	justify-content: space-between;
	column-gap: 40px;
`;

export const GraphName = styled(Typography)`
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	padding-bottom: 15px;
`;

export const GraphContainer = styled(Box)(({ theme }) => ({
	height: '386px',
	backgroundColor: theme.palette.primary.bgColor,
	position: 'relative',
	display: 'flex',
	justifyContent: 'center',
}));

export const RatingLabelWrapper = styled(Box)<{ val: number }>(({ val }) => ({
	width: '90%',
	height: '32px',
	position: 'absolute',
	bottom: `${val}px`,
}));

// RatingLabel

export const RatingLabelContainer = styled(Box)`
	position: relative;
	display: flex;
	height: 100%;
	justify-content: flex-end;
`;

export const RatingLabelBefore = styled(Box)<{ color: string }>(({ color }) => ({
	width: '43%',
	transform: 'rotate(45deg)',
	backgroundColor: color,
	borderRadius: '4px',
	zIndex: 1,
	left: '7px',
	height: '75%',
	top: '4px',
	position: 'absolute',
}));

export const RatingLabelValue = styled(Box)<{ color: string }>(({ theme, color }) => ({
	borderRadius: '4px',
	backgroundColor: color,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '70%',
	zIndex: 2,
	fontSize: '18px',
	fontWeight: 700,
	color: theme.palette.grey[0],
}));
