function path(root: string, sublink: string) {
	return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
export const ROOTS_DASHBOARD = '/dashboard';
export const PATH_ACCOUNT_SETTINGS = '/account-settings';

export const PATH_AUTH = {
	root: ROOTS_AUTH,
	login: path(ROOTS_AUTH, '/login'),
	register: path(ROOTS_AUTH, '/register'),
	verify: path(ROOTS_AUTH, '/verify'),
	resetPassword: path(ROOTS_AUTH, '/reset-password'),
	newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PROPERTIES = {
	view: (id: string | number) => `/properties/${id}`,
};

export const PATH_TRANSACTIONS = {
	view: (id: string) => `/transactions/${id}`,
};

export const PATH_INSTRUMENTS = {
	view: (id: string) => `/instruments/${id}`,
};

export const PATH_DASHBOARD = {
	root: ROOTS_DASHBOARD,
};
