import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { selectTransactionQuestionnaireSlice } from 'src/redux/slices/transactionQuestionnaire';
import { useSelector } from 'src/redux/store';
import { QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnaireTransactionEnum } from 'src/utils/types';
import { movingIn } from 'src/constants/surveyJson/movingIn';
import { selectTransactionParticipants } from 'src/redux/slices/transaction';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { useQuestionnaire } from '../useQuestionnaire';

const QuestionnaireMovingInCard = () => {
	const { fullQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const participants = useSelector(selectTransactionParticipants);
	const { onSubmit, onPageChanged, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnaireTransactionEnum.moving_in,
		questionnaireType: QuestionnaireTypeEnum.TRANSACTION,
	});

	if (isHidden) return null;

	const completedTransactionMovingIn = fillQuestionnaireJsonWithCompletedData(
		movingIn(participants.participants),
		fullQuestionnaire?.moving_in,
	);

	return (
		<>
			<QuestionnarieModal
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				initialCurrentPage={fullQuestionnaire?.moving_in?.currentPage}
				questionnaireKey={QuestionnaireTransactionEnum.moving_in}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				surveyJson={completedTransactionMovingIn}
				status={fullQuestionnaire?.moving_in?.status}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.TRANSACTION}
				title='Moving In'
				type={QuestionnaireTransactionEnum.moving_in}
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			/>
		</>
	);
};

export default QuestionnaireMovingInCard;
