export enum GuaranteesWarrantiesEnum {
	'new_home_warranty' = 'New home warranty',
	'damp_proofing' = 'Damp proofing',
	'timber_treatment' = 'Timber treatment',
	'windows' = 'Windows, roof lights, roof windows or glazed doors',
	'electrical_work' = 'Electrical work',
	'roofing' = 'Roofing',
	'central_heating' = 'Central heating',
	'underpinning' = 'Underpinning',
	'none' = 'None',
}
