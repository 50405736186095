import { TransactionRelationship } from 'src/pages/transactions/types';
import { InvitationAuthorityOn } from 'src/pages/transactions/modals/types';
import { call } from '../@';

type Payload = {
	transactionId: string;
	relationship?: TransactionRelationship;
	placeholderId?: string;
	email: string;
	role: string;
	quote?: number | null;
	authorityOn?: InvitationAuthorityOn[] | null;
};

export default async function transactionInvitation(payload: Payload) {
	return await call('transactionInvitation-invite', payload);
}
