import { DocumentSnapshot, getDoc, updateDoc } from 'firebase/firestore';
import { DEFAULT_QUESTIONNAIRE_DATA } from 'src/constants';
import { DOC_TEMPLATE } from 'src/docTemplates';
import { QuestionnairePropertyEnum } from 'src/utils/types';

const clearQuestionnaire = async (
	// eslint-disable-next-line
	questionnaireRef: any,
	type: string,
	cleanAll?: boolean,
) => {
	// eslint-disable-next-line
	const questionnaire = (await getDoc(questionnaireRef)) as DocumentSnapshot<any>;
	if (questionnaire.exists()) {
		const data = questionnaire.data();
		const preData =
			type in QuestionnairePropertyEnum ? DOC_TEMPLATE.questionnaireProperty : DOC_TEMPLATE.questionnaireTransaction;
		await updateDoc(
			questionnaireRef,
			cleanAll
				? {
						...DEFAULT_QUESTIONNAIRE_DATA,
				  }
				: {
						[type]: {
							...preData[type],
							...DEFAULT_QUESTIONNAIRE_DATA[type],
							common: data[type]?.common,
							status: data[type]?.status,
						},
				  },
		);
	}
};

export default clearQuestionnaire;
