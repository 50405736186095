import { type FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { allDigitsAfterDot, moneyRegExp } from 'src/constants';
import RHFTextField from './RHFTextField';
import { IRHFTextField } from './types';

const RHFTextFieldNumber: FC<IRHFTextField> = (props) => {
	const { watch, setValue } = useFormContext();
	const textNumber = watch(props.name);
	useEffect(() => {
		if (textNumber && !moneyRegExp.test(textNumber)) {
			let textSecNumber = textNumber.replaceAll('-', '');
			if (allDigitsAfterDot.test(textNumber)) {
				const [notDigit, digits] = textSecNumber.split('.');
				textSecNumber = [notDigit, `${digits.split('')[0]}${digits.split('')[1] || ''}`].join('.');
			}
			setValue(props.name, textSecNumber);
		}
	}, [textNumber]);
	return <RHFTextField type='number' step='0.01' min='0' {...props} />;
};

export default RHFTextFieldNumber;
