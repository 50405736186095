import { SVGProps } from 'react';

const InProgressIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg enableBackground='new 0 0 24 24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
		<path
			d='m11.7 2c-.1 0-.1 0-.2 0 0 0 0 0-.1 0-.2 0-.3 0-.5 0l.2 2h1.3c4 .3 7.3 3.5 7.5 7.6.2 4.4-3.2 8.2-7.6 8.4 0 0-.1 0-.2 0-.3 0-.7 0-1 0l-.1 2h1.3.4c5.4-.4 9.5-5 9.3-10.4-.2-5.1-4.3-9.1-9.3-9.5-.2 0-.3 0-.5 0-.2-.1-.3-.1-.5-.1zm-3.5.7c-.5.3-1 .5-1.5.8l1.1 1.7c.3-.2.7-.4 1.1-.6zm-3.7 2.7c-.4.4-.7.9-1 1.3l1.7 1c.2-.3.5-.6.8-1zm10.9 3-4.6 5.2-2.7-2.1-1.1 1.7 4.2 3.2 5.8-6.6zm-13 .6c-.2.5-.3 1.1-.3 1.6l2 .3c.1-.4.1-.9.3-1.3zm1.7 4-2 .2v.3c.1.4.2.9.3 1.3l1.9-.6c-.1-.3-.2-.7-.2-1.1zm1.1 3.2-1.7 1.1c.3.5.6.9 1 1.3l1.5-1.3c-.3-.4-.6-.7-.8-1.1zm2.6 2.6-1.1 1.7c.5.3 1 .5 1.5.8l.8-1.8c-.5-.3-.9-.5-1.2-.7z'
			fill={props.color}
		/>
		<path d='m0 0h24v24h-24z' fill='none' />
	</svg>
);

export default InProgressIcon;
