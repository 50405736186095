import { Box } from '@mui/material';
import { TitleStyled } from 'src/pages/transactions/modals/style';
import modals from 'src/constants/modals';
import { useSelector } from 'src/redux/store';
import { selectModal } from 'src/redux/slices/modal';
import Image from 'src/components/Image';
import Modal from '../../../customModal';
import { Carousel } from './styled';
import ImageWithMagnifier from './components/ImageWithMagnifier';

const Content = () => {
	const { payload } = useSelector(selectModal<{ photos: string[]; title: string; withMagnifier?: boolean }>);

	const showControls = payload.photos.length > 1;

	return (
		<Box height='100%'>
			<TitleStyled textTransform='uppercase' fontWeight={700}>
				{payload.title}
			</TitleStyled>
			<Carousel
				autoPlay={false}
				animation='slide'
				navButtonsAlwaysVisible={showControls}
				navButtonsAlwaysInvisible={!showControls}
				indicators={showControls}
				indicatorContainerProps={{ style: { position: 'absolute', zIndex: 999, overflow: 'visible' } }}
			>
				{payload.photos.map((photo) =>
					payload.withMagnifier ? (
						<ImageWithMagnifier key={photo} src={photo} />
					) : (
						<Image key={photo} src={photo} sx={{ display: 'unset' }} objectFit='contain' />
					),
				)}
			</Carousel>
		</Box>
	);
};

const PhotosCarouselModal = () => (
	<Modal name={modals.photosCarousel} cardSx={{ maxWidth: '75%', height: '75%', pb: '50px' }}>
		<Content />
	</Modal>
);

export default PhotosCarouselModal;
