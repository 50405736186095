import { Box, styled } from '@mui/material';

export const SummaryTableWrapper = styled(Box)(({ theme }) => ({
	border: `2px solid ${theme.palette.grey[400]}`,
}));

export const SummaryTableContent = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
}));
