export const convertSquareMetersToSquareFeet = (meters: number) => Math.round(meters * 10.764);

export const convertMetersToFeetAndInches = (meters: number) => {
	const totalInches = meters * 39.3701;

	const feet = Math.floor(totalInches / 12);
	const inches = Math.round(totalInches % 12);

	return `${feet}' ${inches}"`;
};
