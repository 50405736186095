import modals, { TERMS_OF_USE } from 'src/constants/modals';
import { BrandModal } from 'src/components/common';
import { LegalModal } from '../styled';

const TermsAndConditionsModal = () => (
	<LegalModal name={modals.termsAndConditions} width='80%'>
		<BrandModal.Title title='TERMS OF USE' />
		<BrandModal.SubTitle subTitle='THESE TERMS APPLY TO ALL USERS. PLEASE READ CAREFULLY BEFORE USING CONVEYO' />
		<BrandModal.Note note='Date last revised: January 2023' />
		<p>
			CONVEYO LTD <b>(we/us/our)</b> operates the digital platform located at{' '}
			<a href='https://www.app.conveyo.io'>app.conveyo.io</a> <b>(Platform)</b>. We are committed to respecting your
			privacy and protecting your personal data. We recognise that your personal data is your property and that you have
			loaned it to us for specific purposes. As such, you will be able to choose the information that is displayed in
			your profile and who can see it.
			<br />
			Unless otherwise required by law, the Information Commissioner’s Office (ICO) guidance or best practice, or in
			order to perform our contract with you, we will only process your personal data in the way we tell you or in the
			way you ask us to, and we will give it back to you at any time.
			<br />
			<ol>
				<li>
					<b>This policy</b>
					<ol type='a'>
						<li>
							This is the privacy policy referred to in section 2.2.2 of our Terms of Use (available on our Platform at{' '}
							<a
								href={`http://www.app.conveyo.io/auth/register#${TERMS_OF_USE}`}
							>{`www.app.conveyo.io/auth/register#${TERMS_OF_USE}`}</a>{' '}
							). It sets out how we will process the personal data we take from you during your use of conveyo, and it
							is one of the documents that governs your use of our Platform .
						</li>
						<li>
							This policy therefore applies to our contract with you and you are advised to read it carefully. Terms
							used within it shall have the meaning(s) given in the Data Protection Act 2018 (<b>Act</b>) and/or the
							General Data Protection Regulation (<b>Regulation</b>), as applicable.
						</li>
						<li>
							By visiting our Platform, registering to use conveyo, or by otherwise providing your personal data to us,
							you understand, accept and consent to the practices described in this policy.
						</li>
						<li>
							Any changes we make to this policy will be posted on this page. You are advised to check back frequently
							as, unless your consent is required, any changes will be binding on you when you continue to use the
							Platform or work with us after the date of the relevant change.
						</li>
						<li>For more information relating to your rights under this policy, please see section 10.</li>
						<li>
							If you have any queries relating to this policy, please contact us at{' '}
							<a href='mailto:hello@conveyo.io'>hello@conveyo.io</a> in the first instance.
						</li>
					</ol>
				</li>
				<li>
					<b> Who we are</b>
					<ol type='a'>
						<li>
							For the purposes of the Act, the data controller is CONVEYO LTD. We are a UK registered, private limited
							company (number 14345945) and our registered office is at 6 Bentinck Street, London W1U 2EQ, England,
							United Kingdom.
						</li>
						<li>
							We are registered with the ICO to process the categories of your personal data in accordance with this
							policy and our registration number is ZB406420.
						</li>
						<li>
							Your personal data will be held and stored by us in our databases, on servers held by Google Cloud located
							in the United Kingdom and the European Union.
						</li>
						<li>
							All data stored within our databases will be accessible by all of our staff, who are based in the UK.
							Staff access is granted by secure, unique login details only to those staff who receive appropriate
							training on the platforms we use to operate conveyo.
						</li>
					</ol>
				</li>
				<li>
					<b> Your consent </b>
					<ol type='a'>
						<li>
							We do not ordinarily rely on your consent to process your non-sensitive (or non-special category) personal
							data (as defined in the Regulation). This is because we process your personal data primarily to enable you
							to use conveyo and the Regulation permits us to process that data on this basis.
						</li>
						<li>
							We do rely on your express consent to process the special category data (listed in section 4 and contained
							on our registration form). This consent is given by you on registration, and refreshed at regular
							intervals thereafter.
						</li>
						<li>
							We consider the personal data we obtain from you on our registration form at the start of your use of
							conveyo is reasonable and necessary for these purposes. However, we review this intermittently and remove
							any inaccurate or obsolete data.
						</li>
						<li>
							By using conveyo, we ask you to accept this privacy policy on registration. This is to be sure that you
							are aware of, and expressly consent to:
							<ol type='1'>
								<li>
									how we process your personal data (including your sensitive or “special category” personal data);{' '}
								</li>
								<li>who we transfer your personal data to, and for what reasons; </li>
								<li>how we keep your data secure.</li>
							</ol>
						</li>
						<li>
							You may exercise your rights under section 10 at any time. However, where you wish to object to how we
							process your personal data, this may prevent us from providing you with access to conveyo (in which case,
							we may need to disable or close your account).
						</li>
					</ol>
				</li>
				<li>
					<b> What we collect </b>
					<ol type='a'>
						<li>
							On registration, we will collect basic contact details from you in order to create an account. These
							include your full name and email address. As a second onboarding step, we are legally required to verify
							your identity. This includes providing your full name, date of birth, home addresses and a valid
							identification form, such as passport or driving licence. All information we request is made clear to you
							in our identity verification form.
						</li>
						<li>
							Estate Agents, Solicitors and Conveyancers are required by HMRC under the Money Laundering Regulations
							2017 to prove the identity of clients selling property prior to the commencement of marketing. Two forms
							of identity are required; A document identifying the person(s): A current full passport: Resident permit
							issued by Home Office: Current UK driving Licence*; State Pension or Benefit's book*; Inland Revenue tax
							notifications.
						</li>
						<li>
							And an address linking document: A utility bill; Local authority rent book or tenancy agreement: Recent
							mortgage statement: Bank/Building Society statement; Current UK Driving licence*; State Pension book*;
							Council Tax bill. Documents marked with a* can be used to confirm name or address but not both.
							<li />
							By agreeing to use conveyo, you understand that we will undertake a search with Credas, Yoti, Thirdfort,
							Experian or another similar provider for the purposes of verifying your identity. To do so, the Provider
							may check the details you supply against any database (public or otherwise) to which they have access.
							They may also use your details in the future to assist other companies for verification purposes. A record
							of the search will be retained.
						</li>
						<li>
							Where you are a current, potential or former employee, worker or other member of our staff, we may collect
							additional categories of your personal data for the purposes of providing you with the necessary benefits
							under our contract with you. In those circumstances, a separate privacy notice applies and a copy is
							available on request.
						</li>
						<li>
							Any bank or credit or debit card details that you provide to us in order to make payment for any services
							through conveyo will be retained by our authorised payment processor, Stripe Inc., and not by us (other
							than the expiry date and last 4 digits of the relevant card). This is in line with PCI DSS standards.
						</li>
					</ol>
				</li>
				<li>
					<b>How we collect your data</b>
					<br />
					The categories of personal data listed in section 4 are collected in the following ways:
					<ol type='a'>
						<li>
							<b>When you provide it to us</b>
							{/* @ts-expect-error UL type */}
							<ul type='disc'>
								<li>
									When you register to use conveyo, we will require your full name and email contact details. This
									allows us to create a personal account for you and to correspond with you when using our Platform.
								</li>
								<li>
									When we verify your identity, we will require your full name, date of birth, home address, a valid
									form of identification, and may require you to provide further information, such as bank statements or
									utility bills that prove your address. We will store this information for 18 months after the end of
									your last involvement with any one property transaction of the Platform.
								</li>
								<li>
									Whenever you correspond with us, any personal data contained in that correspondence (including your IP
									address and location) will be retained by us;
								</li>
								<li>
									Where you buy any products or services from us, you will provide us with the personal data requested
									on checkout or confirmation
								</li>
							</ul>
						</li>
						<li>
							<b>When we collect it from you</b>
							{/* @ts-expect-error UL type */}
							<ul type='disc'>
								<li>
									When you use our Platform , we will automatically collect technical information about the device you
									use to visit, including your IP address, browser type/version and related settings.
								</li>
								<li>
									We also monitor your use of our Platform. This includes the full URLs, your clickstreams through our
									Platform , the pages you view and how you interact with them and how you leave the Platform.
								</li>
								<li>
									During your use of conveyo, each user will generate metadata from interacting with content such as
									questionnaires, widgets and dashboards. We will collect, aggregate and analyse this data (which has
									been manifestly released to us by the user through agreeing to use conveyo) for monitoring purposes.
								</li>
							</ul>
						</li>
						<li>
							<p>
								The following third parties provide your personal data to us, or we disclose it to them for the reasons
								listed:
							</p>
							<p>
								<b>Cloud SQL</b> and Firebase are storage solutions hosted by <b>Google LLC (Google)</b> who are based
								in the USA (California). Google hosts our Platform, where your emails, account details and personal
								information are stored, in locations across the United Kingdom and the European Union. More information
								about the security and privacy of your data held by Google is available at{' '}
								<a href='https://policies.google.com/privacy'>https://policies.google.com/privacy</a>
							</p>
						</li>
						<li>
							We will use Google Analytics, a web analytics service provided by Google, which uses “cookies”
							(alpha-numeric text files placed on your computer’s hard drive) to help analyse how you use the Platform .
							The information generated by the cookie about your use of the Platform (including your IP address) will be
							transmitted to and stored by Google on servers in the USA. Google will use this information for the
							purpose of evaluating your use of the Platform, compiling reports on your activity for us and providing
							other services relating to website activity and internet usage. Google may also transfer this information
							to third parties where required to do so by law, or where such third parties process the information on
							Google's behalf. Google will not associate your IP address with any other data held by Google. You may
							refuse the use of cookies by selecting the appropriate settings on your browser, however please note that
							if you do this your ability to use the Platform may be restricted. By using the Platform, you consent to
							the processing of data about you by Google in the manner and for the purposes set out above
						</li>
					</ol>
				</li>
				<li>
					<b>Our use of cookies</b>
					<ol type='a'>
						<li>
							The Platform uses cookies to distinguish you from other users. This helps us provide you with a good
							experience whilst browsing, and allows us to improve it. By continuing to use our Platform, you agree to
							our use of cookies.
						</li>
						<li>
							We use the following cookies on conveyo:
							<ol type='1'>
								<li>
									<b>Targeting cookies</b> - these cookies record your visit to our Platform, the pages you have visited
									and the links you have followed. We will use this information to make it, and the advertising
									displayed on it, more relevant to your interests.
								</li>
								<li>
									<b>Functionality cookies</b> - these are used to recognise you when you return to the Platform. This
									enables us to personalise its content for you, greet you by name and remember your preferences (for
									example, your choice of language or region).
								</li>
								<li>
									<b>Analytical/performance cookies</b> - they allow us to recognise and count the number of visitors
									and to see how visitors move around the Platform when they are using it. This helps us to improve the
									way the Platform works (for example, by ensuring that users are finding what they are looking for
									easily);
								</li>
								<li>
									<b>Strictly necessary cookies</b> - these are cookies that are required for the operation of the
									Platform. They include, for example, cookies that enable you to log into secure areas, use a shopping
									cart or make use of e-billing services.
								</li>
							</ol>
						</li>
						<li>
							You block cookies by activating the setting on your browser that allows you to refuse the setting of all
							or some cookies. However, if you use your browser settings to block all cookies (including essential
							cookies) you may not be able to access all or parts of our Platform.
						</li>
					</ol>
				</li>
				<li>
					<b>What we use your data for</b>
					<ol type='a'>
						<li>
							Your personal data is primarily required to enable us to verify who you are for the purposes of using
							conveyo. It is also to provide support you have requested from us, and to contact you in relation to any
							enquiries or requests you raise with us.
						</li>
						<li>
							Special category data is used to make conveyo more responsive, and appropriately filtered, to your
							interests and preferences. This is to allow you to connect with people who have similar interests to
							yourself.
						</li>
						<li>
							Technical information we collect about your visit to conveyo is used to enable us to:
							<ol type='1'>
								<li>personalise and improve its functionality and security (to keep it safe and secure);</li>
								<li>
									administer and monitor traffic and behaviours on our Platform for analysis, testing, research,
									statistical and survey purposes;
								</li>
								<li>
									ensure that we can offer you the most effective and efficient browsing experience, and make
									improvements where necessary.
								</li>
							</ol>
						</li>
						<li>
							Once collected, your personal data will be retained by us for as long as is necessary for you to use
							conveyo. After your account is closed or disabled, the data will be retained for up to 12 months, when it
							will be securely deleted and we will not contact you unless you ask us to.
						</li>
						<li>
							To ensure the data we hold about you is accurate, we will ask you to update your details and preferences
							at regular intervals. This is done when you login at specified intervals, with access to conveyo
							temporarily suspended until such time as your details have been updated.
						</li>
					</ol>
				</li>
				<li>
					<b>How we keep your data safe</b>
					<p>
						Our databases held with Google are ISO27001 accredited (the highest level of international accreditation
						available). All data held within that database is encrypted at rest.
					</p>
				</li>
				<li>
					<b> How and why we disclose your data</b>
					<ol type='a'>
						<li>
							The only third parties to whom we disclose personal data are those listed in section 5.3, for the purposes
							listed in that section.
						</li>
						<li>
							Any websites which are linked from the Platform are outside of our control and not covered by this policy.
							If you access those websites using the links provided, the website operators may collect information from
							you which will be used by them in accordance with their own privacy policies (if any). These policies may
							differ from ours, and we cannot accept any responsibility or liability in respect of these.
						</li>
					</ol>
				</li>
				<li>
					<b>Your rights </b>
					<ol type='a'>
						<li>
							Any In relation to all of your personal data, you have the following rights (in addition to any rights you
							may have under the Act or the Regulation) to ask us:
							<ol type='1'>
								<li>not to process your personal data for marketing purposes;</li>
								<li>
									to clarify what data we hold about you, how it was obtained, to whom it has been disclosed and for how
									long it will be stored;
								</li>
								<li>to amend any inaccurate data we hold about you;</li>
								<li>
									to delete any of your data (where you no longer think we need to hold it, or you think we have
									obtained or processed it without your consent at any time);
								</li>
								<li>to only process your personal data in limited circumstances, for limited purposes.</li>
							</ol>
						</li>
						<li>
							We have the capacity to extract your personal data from our databases and provide it to you in a
							structured, commonly-used way (typically by .csv file).
						</li>
						<li>
							If you wish to exercise any of your rights at any time, please contact us on the details contained at the
							beginning of this policy in the first instance. We will require you to verify your identity to us before
							we provide any personal data, and reserve the right to ask you to specify the types of personal data to
							which your request relates.
						</li>
						<li>
							Where you wish to exercise any of your rights, they may be subject to payment of a nominal administration
							fee (to cover our costs incurred in processing your request) and any clarification we may reasonably
							require in relation to your request. Such fees may be charged where we consider (acting reasonably) that
							your request is excessive, unfounded or repetitive.
						</li>
					</ol>
				</li>
			</ol>
		</p>
	</LegalModal>
);

export default TermsAndConditionsModal;
