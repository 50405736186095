import {
	AccordionDetails,
	AccordionDetailsProps,
	AccordionSummary,
	AccordionSummaryProps,
	styled,
} from '@mui/material';
import { RHFCheckbox } from 'src/components/hook-form';
import { IRHFCheckbox } from 'src/components/hook-form/types';
import Iconify from 'src/components/Iconify';
import { stopPropagation } from 'src/utils/common';

export const PermissionsAccordionSummary = styled((props: AccordionSummaryProps) => (
	<AccordionSummary expandIcon={<Iconify icon='material-symbols-light:play-arrow' fontSize={18} />} {...props} />
))({
	background: 'transparent',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotate(90deg)' },
});

export const PermissionsAccordionDetails = styled((props: AccordionDetailsProps) => <AccordionDetails {...props} />)({
	paddingLeft: '37px',
});

export const PermissionsCheckbox = styled((props: IRHFCheckbox) => (
	<RHFCheckbox labelControlProps={{ labelPlacement: 'end', sx: { ml: '-7px' }, onClick: stopPropagation }} {...props} />
))({});
