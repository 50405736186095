import { Box, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { goToBreadcrumb, selectDocuments, selectDocumentsFolderId } from 'src/redux/slices/documents';
import { dispatch } from 'src/redux/store';
import Iconify from 'src/components/Iconify';
import palette from 'src/theme/palette';
import { Breadcrumb, BreadcrumbSeparator } from './styled';

const DocumentsBreadcrumbs = () => {
	const { breadcrumbs } = useSelector(selectDocuments);
	const currentFolderId = useSelector(selectDocumentsFolderId);

	const handleGoUp = () => {
		const parentBreadcrumb = breadcrumbs.at(-2);

		if (!parentBreadcrumb) return;

		dispatch(goToBreadcrumb(parentBreadcrumb.folderId));
	};
	const handleClick = (id: string) => {
		if (id === currentFolderId) return;

		dispatch(goToBreadcrumb(id));
	};

	const isUpButtonDisabled = breadcrumbs.length <= 1;

	return (
		<Box display='flex' gap='7px' mt='15px'>
			<IconButton color='info' onClick={handleGoUp} disabled={isUpButtonDisabled}>
				<Iconify
					icon='lets-icons:up'
					fontSize={18}
					color={isUpButtonDisabled ? palette.light.grey[500] : palette.light.primary.main}
				/>
			</IconButton>
			<Box display='flex' gap='5px' alignItems='center'>
				{breadcrumbs.map((b, i) => (
					<Box display='flex' alignItems='center' gap='5px' key={b.folderId}>
						<Breadcrumb onClick={() => handleClick(b.folderId)} isCurrentPath={i + 1 === breadcrumbs.length}>
							{b.name}
						</Breadcrumb>
						<BreadcrumbSeparator>/</BreadcrumbSeparator>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default DocumentsBreadcrumbs;
