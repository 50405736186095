import { handleCloseModal } from 'src/utils/propertyHelpers';
import { dispatch, useSelector } from 'src/redux/store';
import { confirmRejectedOffer } from 'src/redux/slices/transaction';
import { selectModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import { ConfirmationModal } from 'src/components/modals';
import { IStateTransactionOffer } from 'src/redux/types';
import { noop } from 'lodash';
import useLoading from 'src/hooks/useLoading';
import { LocalUser } from 'src/components/InviteCard/types';

export const ViewerOfferRejectedModal = () => {
	const { payload } = useSelector(selectModal<{ offer?: IStateTransactionOffer; user?: LocalUser }>);
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		if (!payload.offer) return;

		setIsLoading(true);

		await dispatch(confirmRejectedOffer({ offerId: payload.offer.id }));

		setIsLoading(false);
		handleCloseModal();
	};

	return (
		<ConfirmationModal
			handleClose={noop}
			sx={{ minWidth: '600px' }}
			modalName={modals.viewerOfferRejected}
			title='OFFER REJECTED'
			description={
				<>
					Unfortunately,{' '}
					<b>
						{payload.user?.givenNames} {payload.user?.lastNames}
					</b>{' '}
					did not accept your counteroffer.
					<br /> <br />
					Don't be discouraged - consider negotiating further or exploring other incentives! Good luck!
				</>
			}
			withCancelButton={false}
			confirmText='Continue'
			handleConfirm={handleConfirm}
			isLoading={isLoading}
		/>
	);
};
