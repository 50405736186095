import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(({ theme }) => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backdropFilter: 'blur(7px)',
	backgroundColor: theme.palette.grey[100],
}));
