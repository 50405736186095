import useLoading from 'src/hooks/useLoading';
import modals from 'src/constants/modals';
import { openModal, selectModal } from 'src/redux/slices/modal';
import { dispatch, useSelector } from 'src/redux/store';
import { abandonTransaction } from 'src/redux/slices/transaction';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { getNav, handleCloseModal } from 'src/utils/propertyHelpers';
import { noop } from 'lodash';
import { ConfirmationModal } from '../../common';

const AbandonTransactionModal = () => {
	const { isLoading, setIsLoading } = useLoading();
	const { payload } = useSelector(selectModal);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const handleConfirm = async () => {
		try {
			setIsLoading(true);

			await dispatch(abandonTransaction()).unwrap();

			getNav();
			handleCloseModal();
			navigate('/dashboard');
		} catch (e) {
			if (e instanceof Error) enqueueSnackbar(e.message, { variant: 'error' });
		} finally {
			setIsLoading(false);
		}
	};

	const handleCancel = async () => dispatch(openModal({ name: payload.previousModal }));

	return (
		<ConfirmationModal
			sx={{ maxWidth: '550px' }}
			modalName={modals.abandonTransaction}
			isLoading={isLoading}
			title='CANCEL TRANSACTION'
			handleConfirm={handleConfirm}
			handleCancel={handleCancel}
			handleClose={noop}
			description={<>Are you sure you want to cancel this transaction? Exiting now will delete all your progress.</>}
		/>
	);
};

export default AbandonTransactionModal;
