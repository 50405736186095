enum HTTPMethod {
	get = 'GET',
	post = 'POST',
	put = 'PUT',
	patch = 'PATCH',
	delete = 'DELETE',
	head = 'HEAD',
	options = 'OPTIONS',
}

export default HTTPMethod;
