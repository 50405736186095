import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum } from 'src/types';
import useLoading from 'src/hooks/useLoading';
import { updateTransactionOnboarding } from 'src/redux/slices/transaction';
import { transactionOnboardingSteps } from 'src/pages/transactions/constants';
import { FC } from 'react';
import { TransactionOnboardingModalProps } from 'src/pages/transactions/components/TransactionOnboardingFlow/types';
import { noop } from 'lodash';
import { ConfirmationModal } from '../../common';

const TransactionVerificationIntroductionModal: FC<TransactionOnboardingModalProps> = ({ handleCancel }) => {
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		setIsLoading(true);

		await dispatch(updateTransactionOnboarding({ verificationIntroduction: OnboardingStepStatusEnum.PASSED }));

		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '550px' }}
			modalName={transactionOnboardingSteps.verificationIntroduction}
			isLoading={isLoading}
			title='GET VERIFIED'
			confirmText='Continue'
			cancelText='Cancel'
			handleConfirm={handleConfirm}
			handleCancel={handleCancel}
			handleClose={noop}
			description={
				<>
					We need to verify your identity before you can do business on Conveyo. <br /> <br />
					Please have your mobile phone ready, and a valid passport or driving licence.
				</>
			}
		/>
	);
};

export default TransactionVerificationIntroductionModal;
