import { UserData } from 'src/api/auth/@user-data';
import { Documents } from 'src/components/Documents';
import { GeneralTab } from 'src/components/GeneralTab';
import Iconify from 'src/components/Iconify';
import { IExtendedProperty } from 'src/redux/slices/property';
import { PropertyQuestionnaires } from 'src/sections/property';

export const getPropertyTabs = (user: UserData, property: IExtendedProperty | null) => [
	{
		value: 'general',
		label: 'Overview',
		icon: <Iconify icon={'ph:house-fill'} width={20} height={20} />,
		component: <GeneralTab isProperty />,
	},
	{
		value: 'questionnaries',
		label: 'Facts',
		icon: <Iconify icon={'carbon:floorplan'} width={20} height={20} />,
		component: <PropertyQuestionnaires />,
	},
	...(property?.isProprietor || user.isAdmin
		? [
				{
					value: 'documents',
					label: 'Documents',
					icon: <Iconify icon={'ic:insert-drive-file'} width={20} height={20} />,
					component: <Documents />,
				},
		  ]
		: []),
];
