/* eslint-disable no-param-reassign */
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { QuestionnarieModal } from 'src/components/QuestionnarieModal';
import { propertyDetails } from 'src/constants/surveyJson/propertyDetails';
import { selectPropertyQuestionnaireSlice } from 'src/redux/slices/propertyQuestionnaire';
import { QuestionnaireStatus, QuestionnaireTypeEnum } from 'src/types';
import { fillQuestionnaireJsonWithCompletedData } from 'src/utils/questionnaire';
import { QuestionnairePropertyEnum, QuestionnaireTransactionEnum } from 'src/utils/types';
import { selectRooms } from 'src/redux/slices/rooms';
import { QuestionnaireSubmittingError } from 'src/components/QuestionnarieModal/types';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import {
	refreshTransactionQuestionnaire,
	selectTransactionQuestionnaireSlice,
	updateTransactionQuestionnaire,
} from 'src/redux/slices/transactionQuestionnaire';
import { dispatch } from 'src/redux/store';
import QuestionnaireCard from '../QuestionnaireCard/QuestionnaireCard';
import { DescriptionBlock, StyledGrid, StyledGridUnit } from '../styles';
import { useQuestionnaire } from '../useQuestionnaire';
import { modalSx } from './styled';

const QuestionnairePropertyCard = () => {
	const { fullQuestionnaire } = useSelector(selectPropertyQuestionnaireSlice);
	const { fullQuestionnaire: transactionQuestionnaire } = useSelector(selectTransactionQuestionnaireSlice);
	const { data: rooms } = useSelector(selectRooms);
	const location = useLocation();
	const isTransactionPage = location.pathname.startsWith('/transactions');

	const handleUpdate = async (data) => {
		if (!rooms?.types || !isTransactionPage || !transactionQuestionnaire) return;

		const fittingsQuestionnaireRoomsCount = Object.keys(rooms.types).reduce((acc, type) => {
			acc += transactionQuestionnaire?.fitting_contents?.[type]?.[`${type}_rooms_count`] ?? 0;

			return acc;
		}, 0);
		const propertyDetailsRoomsCount = Object.keys(rooms.types).reduce((acc, type) => {
			data?.rooms?.[type]?.forEach(() => {
				acc += 1;
			});

			return acc;
		}, 0);

		if (fittingsQuestionnaireRoomsCount !== propertyDetailsRoomsCount) {
			await dispatch(
				updateTransactionQuestionnaire({
					type: QuestionnaireTransactionEnum.fitting_contents,
					data: {
						...transactionQuestionnaire.fitting_contents,
						status: QuestionnaireStatus.IN_PROGRESS,
						currentPage: 0,
					},
				}),
			);
			dispatch(refreshTransactionQuestionnaire());
		}
	};

	const { onPageChanged, onSubmit, onComplete, isHidden, isNotAllowed, notAllowedMessage } = useQuestionnaire({
		type: QuestionnairePropertyEnum.property_details,
		questionnaireType: QuestionnaireTypeEnum.PROPERTY,
		onUpdate: handleUpdate,
	});

	const submittingError: QuestionnaireSubmittingError | null = useMemo(() => {
		if (!rooms?.types) return null;

		const generalRoomsCount = fullQuestionnaire?.property_details?.dwelling?.room_count ?? 0;
		const calculatedRoomsCount = Object.keys(rooms.types).reduce((acc, type) => {
			fullQuestionnaire?.property_details?.rooms?.[type]?.forEach(() => {
				acc += 1;
			});

			return acc;
		}, 0);

		if (calculatedRoomsCount < generalRoomsCount) {
			return {
				title: 'ROOM COUNT MISMATCH',
				children: (
					<>
						You have entered fewer than {generalRoomsCount} rooms. <br />
						Please adjust your selection to match the total number of rooms.
					</>
				),
				handleContinueClick: ({ setCurrentPage, setIsSubmitting }) => {
					setIsSubmitting(false);
					setCurrentPage(1);
				},
			};
		}

		return null;
	}, [fullQuestionnaire?.property_details, rooms?.types]);

	if (!rooms || isHidden) return null;

	const completedPropertyDetails = fillQuestionnaireJsonWithCompletedData(
		propertyDetails(rooms),
		fullQuestionnaire?.property_details,
	);

	return (
		<>
			<QuestionnarieModal
				modalSx={modalSx}
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				initialCurrentPage={fullQuestionnaire?.property_details.currentPage}
				questionnaireKey={QuestionnairePropertyEnum.property_details}
				surveyJson={completedPropertyDetails}
				onSubmit={onSubmit}
				onComplete={onComplete}
				onPageChanged={onPageChanged}
				status={fullQuestionnaire?.property_details.status}
				submittingError={submittingError}
			/>
			<QuestionnaireCard
				questionnaireType={QuestionnaireTypeEnum.PROPERTY}
				title='Property Details'
				type={QuestionnairePropertyEnum.property_details}
				notAllowed={isNotAllowed}
				notAllowedMessage={notAllowedMessage}
			>
				{({ details }) => {
					const isTypeVisible = details?.building?.dwelling?.built_form || details?.dwelling?.property_type;

					return (
						<>
							{details?.status ? (
								<>
									<Grid container spacing={1} display={'block'} rowSpacing={2}>
										<StyledGrid gap={'7px'} item>
											<StyledGrid gap={'4px'} item>
												{isTypeVisible ? (
													<DescriptionBlock>
														Type:{' '}
														<b>
															{details.dwelling.built_form} {details.dwelling.property_type}
														</b>
													</DescriptionBlock>
												) : null}
												{/* {details?.property_details?.construction_age && (
													<DescriptionBlock>
														Age: <b>{details?.property_details?.construction_age}</b>
													</DescriptionBlock>
												)} */}
											</StyledGrid>
											<StyledGrid gap={'4px'} item m='15px 0'>
												<DescriptionBlock>
													{/* {details?.property_details?.property_rooms ? (
														<span>
															Rooms: <b>{details.property_details.property_rooms.length}</b>
														</span>
													) : null} */}
													{details?.dwelling?.total_floor_area && (
														<span>
															Area:{' '}
															<b>
																{details.dwelling.total_floor_area} <StyledGridUnit>sqft</StyledGridUnit>
															</b>
														</span>
													)}
													{details?.dwelling?.ceiling_height && (
														<span>
															Ceilings: <b>{details.dwelling.ceiling_height}</b>
														</span>
													)}
												</DescriptionBlock>
												<DescriptionBlock>
													{details?.dwelling?.level_count ? (
														<span>
															Floor: <b>{details.dwelling.level_count}</b>
														</span>
													) : null}
												</DescriptionBlock>
											</StyledGrid>
										</StyledGrid>
									</Grid>
								</>
							) : null}
						</>
					);
				}}
			</QuestionnaireCard>
		</>
	);
};

export default QuestionnairePropertyCard;
