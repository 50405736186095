import type { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel } from '@mui/material';
import type { IRHFCheckbox } from './types';

// ----------------------------------------------------------------------

export const RHFCheckbox: FC<IRHFCheckbox> = ({ name, label, labelControlProps, ...other }) => {
	const { control } = useFormContext();

	return (
		<FormControlLabel
			sx={{ width: 'fit-content', marginLeft: 0 }}
			label={label}
			labelPlacement='start'
			control={
				<Controller
					name={name}
					control={control}
					render={({ field }) => <Checkbox {...field} checked={field.value ?? false} {...other} />}
				/>
			}
			{...labelControlProps}
		/>
	);
};
