import Iconify from 'src/components/Iconify';
import { useDispatch } from 'src/redux/store';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { selectTransactionOverview, selectTransactionSlice } from 'src/redux/slices/transaction';
import { OfferModal } from 'src/pages/transactions/modals/OfferModal';
import { openModal } from 'src/redux/slices/modal';
import modals from 'src/constants/modals';
import InvitationModal from 'src/pages/transactions/modals/InvitationModal';
import AcceptAskPriceModal from 'src/pages/transactions/modals/AcceptAskPriceModal';
import { Typography } from '@mui/material';
import { ActionButtonItem, ActionButtons } from '../styles';

const PurchaseActionButtons = () => {
	const { id = '' } = useParams();
	const dispatch = useDispatch();
	const { currentTab } = useSelector(selectTransactionSlice);
	const transaction = useSelector(selectTransactionOverview);

	const isParticipants = currentTab === 'participants';

	if (!transaction || transaction.state === 'ARCHIVED') {
		return (
			<Typography color='grey.600' fontWeight={500}>
				This transaction has been archived. No actions available.
			</Typography>
		);
	}

	const handleMakeAnOffer = () => dispatch(openModal({ name: modals.offerModal }));
	const handleQrCode = () => dispatch(openModal({ name: modals.transactionQrCode }));
	const handleModifyOffer = () =>
		dispatch(openModal({ name: modals.offerModal, payload: { price: transaction.myOffer?.price } }));
	const handleAddParticipant = () => dispatch(openModal({ name: modals.invitation }));
	const handleDelete = () =>
		dispatch(openModal({ name: modals.dropProperty, payload: { transactionId: id, delist: true } }));
	const handleExit = () =>
		dispatch(openModal({ name: modals.dropProperty, payload: { transactionId: id, abandon: true } }));
	const handleAcceptOffer = () => dispatch(openModal({ name: modals.acceptAskPrice }));

	return (
		<ActionButtons>
			{!transaction.isPendingParticipant && (
				<>
					{transaction.isBuyerViewer && transaction.askPrice && !transaction.myOffer && (
						<>
							<ActionButtonItem onClick={handleAcceptOffer}>
								<Iconify icon='mdi:tick-circle-outline' fontSize={20} />
								Accept Offer
							</ActionButtonItem>
							{(transaction.hasSeller || transaction.hasSellSideAgent) && (
								<ActionButtonItem onClick={handleMakeAnOffer}>
									<Iconify icon='material-symbols:person-raised-hand' fontSize={18} />
									Make Counteroffer
								</ActionButtonItem>
							)}
						</>
					)}
					{transaction.isBuyerViewer && transaction.myOffer && transaction.myOffer.isActive && (
						<ActionButtonItem onClick={handleModifyOffer}>
							<Iconify icon='mdi:receipt-text-edit' fontSize={20} />
							Modify offer
						</ActionButtonItem>
					)}
					{transaction.isBuyerViewer && !transaction.askPrice && !transaction.myOffer && (
						<ActionButtonItem onClick={handleMakeAnOffer}>
							<Iconify icon='material-symbols:person-raised-hand' fontSize={18} />
							Make an offer
						</ActionButtonItem>
					)}
				</>
			)}
			{isParticipants && !transaction.isPendingParticipant && (
				<ActionButtonItem onClick={handleAddParticipant}>
					<Iconify icon='zondicons:add-outline' fontSize={20} />
					Add Participant
				</ActionButtonItem>
			)}
			{(transaction.isSellSideAgent || transaction.isBuySideAgent) &&
				isParticipants &&
				!transaction.isPendingParticipant && (
					<ActionButtonItem onClick={handleQrCode}>
						<Iconify icon='bx:qr' fontSize={18} />
						Create QR code
					</ActionButtonItem>
				)}
			{transaction.isPropertyOwner ? (
				<ActionButtonItem onClick={handleDelete}>
					<Iconify icon='gravity-ui:trash-bin' fontSize={20} />
					Delist
				</ActionButtonItem>
			) : (
				<ActionButtonItem onClick={handleExit}>
					<Iconify icon='mingcute:exit-line' fontSize={20} />
					Abandon
				</ActionButtonItem>
			)}
			<OfferModal />
			<InvitationModal />
			<AcceptAskPriceModal />
		</ActionButtons>
	);
};

export default PurchaseActionButtons;
