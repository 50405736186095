/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { arrayRemove, arrayUnion, DocumentReference, updateDoc } from 'firebase/firestore';
import {
	Epc,
	IQuestionnaireProperty,
	Property,
	PropertyListing,
	PropertyOnboarding,
	PropertyTitle,
	PropertyTitleDocuments,
} from 'src/types';
import { getPropertyRef, getProperty as getPropertyUtil, getTransaction } from 'src/utils/firebase';
import { findAllPropertyOnboardings, findPropertyOnboarding } from 'src/utils/firebase/property-onboarding';
import { PropertyOnboardingNotificationAction, PropertyOnboardingStep } from 'src/pages/properties/types';
import { getPropertyOnboardingLatestStep, getPropertyOnboardingModalName } from 'src/utils/propertyHelpers';
import { propertyOnboardingNotification as propertyOnboardingNotificationApi } from 'src/api/property';
import { Payload as GetPropertyAvailableDocumentsPayload } from 'src/api/property/get-property-available-documents';
import { extractLatestPropertyListing } from 'src/utils/firebase/property-listing';
import { extractPropertyPrices } from 'src/utils/firebase/property-prices';
import { extractPropertyOverview } from 'src/utils/firebase/property';
import {
	getPropertyTitles as getPropertyTitlesApi,
	getPropertyAvailableDocuments as getPropertyAvailableDocumentsApi,
} from 'src/api';
import confirmPropertyTitleSelection from 'src/api/property/confirm-property-title-selection';
import cancelPropertyOnboarding from 'src/api/property-onboarding/cancel';
import { dispatch, RootState } from '../store';
import { UpdateAllPropertyOnboardingsPayload, UpdatePropertyOnboardingPayload } from '../types/property';
import { openModal } from './modal';
import { InitializedStateField } from '../types/common';
import { selectTransactionOnboarding } from './transaction';

export type ExtractedPropertyPrice = {
	date: Date;
	price: number;
};

export type ExtractedPropertyPrices = {
	ask: ExtractedPropertyPrice[];
	paid: ExtractedPropertyPrice[];
};

export type ExtractedPropertyListing = {
	photos: PropertyListingImage[];
	floorPlans: PropertyListingFloorPlan[];
};

export interface IExtendedProperty extends Property {
	id: string;
	isProprietor: boolean;
	latestOnboardingStep?: PropertyOnboardingStep;
	latestOnboardingStepModalName?: string;
	onboarding?: PropertyOnboarding;
	onboardingRef?: DocumentReference<PropertyOnboarding>;
	latestListing: null | ExtractedPropertyListing;
	prices: ExtractedPropertyPrices | null;
	overview: {
		latestListing: PropertyListing[string] | null;
		epc: Epc | null;
		propertyQuestionnaire: IQuestionnaireProperty | null;
	};
}

interface PropertyState {
	property: null | IExtendedProperty;
	currentTab: string;
	titles: InitializedStateField<PropertyTitle[]>;
	titlesDocuments: InitializedStateField<PropertyTitleDocuments[]>;
	newProperties: string[];
	isLoading: boolean;
	errorMessage: null | string | ValidationErrors;
}

const initialState: PropertyState = {
	currentTab: 'general',
	property: null,
	isLoading: true,
	newProperties: [],
	titles: { data: [], isInitialized: false },
	titlesDocuments: { data: [], isInitialized: false },
	errorMessage: null,
};

export const selectPropertySlice = (state: RootState) => state.property;
export const selectProperty = (state: RootState) => state.property.property as IExtendedProperty;
export const selectPropertyTitles = (state: RootState) => state.property.titles;
export const selectPropertyTitlesDocuments = (state: RootState) => state.property.titlesDocuments;

export const getProperty = createAsyncThunk<
	IExtendedProperty,
	{ id: string; withLoading?: boolean },
	{ rejectValue: ValidationErrors | string }
>('property/get', async ({ id }, { rejectWithValue, getState }) => {
	const {
		auth: { user },
	} = getState() as RootState;

	if (!user) return rejectWithValue('Unauthorized');

	try {
		const [{ data }, onboardingSnapshot] = await Promise.all([
			getPropertyUtil(id),
			findPropertyOnboarding(user.uid, id),
		]);

		const onboarding = onboardingSnapshot?.data();
		const onboardingRef = onboardingSnapshot?.ref;

		const [latestListing, prices, overview] = await Promise.all([
			extractLatestPropertyListing(data.uprn.toString()),
			extractPropertyPrices(data.uprn.toString()),
			extractPropertyOverview(id),
		]);

		return {
			...data,
			id,
			isProprietor: data.proprietors?.some((proprietor) => proprietor.id === user?.uid),
			onboarding,
			onboardingRef,
			latestListing,
			prices,
			...(onboarding && {
				latestOnboardingStep: getPropertyOnboardingLatestStep(onboarding),
			}),
			overview,
		};
	} catch (error) {
		const e = error as AxiosError<ValidationErrors>;
		return rejectWithValue(e?.response?.data || '');
	}
});

export const getLatestPropertyListing = createAsyncThunk<ExtractedPropertyListing | null>(
	'property/get-latest-listing',
	async (_, { getState }) => {
		const {
			property: { property },
		} = getState() as RootState;

		if (!property) return null;

		return extractLatestPropertyListing(property.uprn.toString());
	},
);

export const getPropertyPrices = createAsyncThunk<ExtractedPropertyPrices | null>(
	'property/get-prices',
	async (_, { getState }) => {
		const {
			property: { property },
		} = getState() as RootState;

		if (!property) return null;

		return extractPropertyPrices(property.uprn.toString());
	},
);

export const getPropertyByTransactionId = createAsyncThunk<
	IExtendedProperty,
	{ id: string; withLoading?: boolean },
	{ rejectValue: ValidationErrors | string }
>('property/get-by-transaction', async ({ id }, { rejectWithValue, getState }) => {
	const {
		auth: { user },
	} = getState() as RootState;

	if (!user) return rejectWithValue('Unauthorized');

	try {
		const transaction = await getTransaction(id);
		const [{ data }, onboardingSnapshot] = await Promise.all([
			getPropertyUtil(transaction.data.property.id),
			findPropertyOnboarding(user.uid, transaction.data.property.id),
		]);

		const onboarding = onboardingSnapshot?.data();
		const onboardingRef = onboardingSnapshot?.ref;

		const [latestListing, prices, overview] = await Promise.all([
			extractLatestPropertyListing(data.uprn.toString()),
			extractPropertyPrices(data.uprn.toString()),
			extractPropertyOverview(transaction.data.property.id),
		]);

		return {
			...data,
			id: transaction.data.property.id,
			isProprietor: data.proprietors?.some((proprietor) => proprietor.id === user?.uid),
			onboarding,
			onboardingRef,
			latestListing,
			prices,
			...(onboarding && {
				latestOnboardingStep: getPropertyOnboardingLatestStep(onboarding),
			}),
			overview,
		};
	} catch (error) {
		const e = error as AxiosError<ValidationErrors>;
		return rejectWithValue(e?.response?.data || (e?.name as unknown as ValidationErrors) || '');
	}
});

export const pinProperty = createAsyncThunk<
	void,
	{ id: string; isPinned: boolean },
	{ rejectValue: ValidationErrors | string }
>('property/pin-unpin', async ({ id, isPinned }, { getState }) => {
	const {
		auth: { user },
	} = getState() as RootState;

	if (!user) return;

	const transaction = getPropertyRef(id);

	await updateDoc<unknown>(user.ref, {
		'settings.pinnedProperties': isPinned ? arrayRemove(transaction) : arrayUnion(transaction),
	});
});

export const updatePropertyOnboarding = createAsyncThunk<
	Pick<IExtendedProperty, 'onboarding' | 'latestOnboardingStep' | 'latestOnboardingStepModalName'>,
	UpdatePropertyOnboardingPayload,
	{ rejectValue: ValidationErrors | string }
>(
	'property-onboarding/update',
	async ({ moveToNextModal = false, activeSide, payload }, { getState, rejectWithValue }) => {
		const {
			auth: { user },
			property,
		} = getState() as RootState;

		if (!user || !property.property) return rejectWithValue('Unauthorized');

		const onboardingSnapshot = await findPropertyOnboarding(user.uid, property.property.id);

		if (!onboardingSnapshot || !onboardingSnapshot.exists()) return rejectWithValue('Unauthorized');

		const onboardingRef = onboardingSnapshot.ref;
		const onboarding = onboardingSnapshot.data();
		const onboardingSide = activeSide ?? onboarding.activeSide ?? 'sell';

		if (!onboardingRef || !onboarding) return rejectWithValue('Onboarding not found');

		const updatedOnboarding: PropertyOnboarding = {
			...onboarding,
			...payload,
			activeSide: onboardingSide,
		};

		await updateDoc(onboardingRef, updatedOnboarding);

		const latestOnboardingStep = getPropertyOnboardingLatestStep(updatedOnboarding);
		const latestOnboardingStepModalName = latestOnboardingStep && getPropertyOnboardingModalName(latestOnboardingStep);

		if (moveToNextModal && latestOnboardingStepModalName) {
			dispatch(
				openModal({
					name: latestOnboardingStepModalName,
					payload: { side: onboarding.activeSide ?? 'sell' },
				}),
			);
		}

		return { onboarding: updatedOnboarding, latestOnboardingStep, latestOnboardingStepModalName };
	},
);

export const updateAllPropertyOnboardings = createAsyncThunk<
	Array<(PropertyOnboarding & { id: string }) | null>,
	UpdateAllPropertyOnboardingsPayload,
	{ rejectValue: ValidationErrors | string }
>('property-onboardings/update-all', async ({ payload }, { getState }) => {
	const {
		auth: { user },
	} = getState() as RootState;

	if (!user) return [];

	const allOnboardings = await findAllPropertyOnboardings(user.uid);

	return await Promise.all(
		allOnboardings.map(async (onboardingSnapshot) => {
			if (!onboardingSnapshot.exists()) return null;

			const onboarding = onboardingSnapshot.data();

			const updatedOnboarding: PropertyOnboarding & { id: string } = {
				id: onboardingSnapshot.id,
				...onboarding,
				...payload,
			};
			await updateDoc(onboardingSnapshot.ref, updatedOnboarding);

			return updatedOnboarding;
		}),
	);
});

export const propertyOnboardingNotification = createAsyncThunk<
	{ message: string } | undefined,
	{ id: string; action: PropertyOnboardingNotificationAction },
	{ rejectValue: ValidationErrors | string }
>(
	'property-onboarding-notification/send',
	async ({ id, action }) => await propertyOnboardingNotificationApi(id, action),
);

export const cancelPropertyOnboardingThunk = createAsyncThunk<void>(
	'property-onboarding/cancel',
	async (_, { getState }) => {
		const state = getState() as RootState;

		const property = selectProperty(state);

		await cancelPropertyOnboarding(property.id);
	},
);

export const getPropertyTitles = createAsyncThunk<PropertyTitle[], { id: string }>(
	'property/get-titles',
	async ({ id }, { getState }) => {
		const state = getState() as RootState;
		const transactionOnboarding = selectTransactionOnboarding(state);
		const property = selectProperty(state);

		const { titles } = await getPropertyTitlesApi({
			id,
			transactionOnboardingId: transactionOnboarding?.id,
			propertyOnboardingId: property.onboardingRef?.id,
		});

		return titles;
	},
);

export const getPropertyAvailableDocuments = createAsyncThunk<
	PropertyTitleDocuments[],
	GetPropertyAvailableDocumentsPayload
>('property/get-available-documents', async (payload) => (await getPropertyAvailableDocumentsApi(payload)).titles);

export const confirmPropertyTitleSelectionThunk = createAsyncThunk<void>(
	'property/get-available-documents',
	async (_, { getState }) => {
		const state = getState() as RootState;

		const property = selectProperty(state);

		await confirmPropertyTitleSelection(property.id);
	},
);

const propertySlice = createSlice({
	name: 'property',
	initialState,
	reducers: {
		changeProperty: (state, { payload }) => {
			state.property = payload;
		},
		clearProperty: (state) => {
			state.property = null;
			state.isLoading = true;
			state.titles = { data: [], isInitialized: false, error: null };
			state.titlesDocuments = { data: [], isInitialized: false, error: null };
		},
		addNewProperty: (state, { payload }) => {
			state.newProperties = [...state.newProperties, payload];
		},

		removeNewProperty: (state, { payload }) => {
			const allNewProps = [...state.newProperties];
			allNewProps.splice(
				state.newProperties.findIndex((prop) => prop === payload),
				1,
			);
			state.newProperties = allNewProps;
		},
		clearErrors: (state) => {
			state.errorMessage = null;
		},
		setCurrentTab: (state, { payload }: PayloadAction<string>) => {
			state.currentTab = payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getProperty.pending, (state, { meta }) => {
			if (!meta.arg.withLoading) return state;

			state.property = null;
			state.isLoading = true;

			return state;
		});
		builder.addCase(getProperty.fulfilled, (state, { payload }) => {
			state.property = payload;
			state.isLoading = false;

			if (payload.latestOnboardingStep) {
				state.property.latestOnboardingStepModalName = getPropertyOnboardingModalName(payload.latestOnboardingStep);
			}
		});
		builder.addCase(getProperty.rejected, (state, { payload }) => {
			state.errorMessage = payload || null;
			state.isLoading = false;
		});

		builder.addCase(updatePropertyOnboarding.fulfilled, (state, { payload }) => {
			if (!state.property) return state;

			state.property.onboarding = payload.onboarding;
			state.property.latestOnboardingStep = payload.latestOnboardingStep;

			if (payload.latestOnboardingStepModalName) {
				state.property.latestOnboardingStepModalName = payload.latestOnboardingStepModalName;
			}

			return state;
		});

		builder.addCase(updateAllPropertyOnboardings.fulfilled, (state, { payload }) => {
			if (!state.property) return state;

			const currentOnboarding = payload.find(
				(onboarding) => onboarding && onboarding.id === state.property?.onboardingRef?.id,
			);

			if (!currentOnboarding) return state;

			state.property.onboarding = currentOnboarding;
			state.property.latestOnboardingStep = getPropertyOnboardingLatestStep(currentOnboarding);

			if (state.property.latestOnboardingStep) {
				state.property.latestOnboardingStepModalName = getPropertyOnboardingModalName(
					state.property.latestOnboardingStep,
				);
			}

			return state;
		});

		builder.addCase(getLatestPropertyListing.fulfilled, (state, { payload }) => {
			if (!state.property) return state;

			state.property.latestListing = payload;

			return state;
		});

		builder.addCase(getPropertyPrices.fulfilled, (state, { payload }) => {
			if (!state.property) return state;

			state.property.prices = payload;

			return state;
		});

		builder.addCase(getPropertyTitles.pending, (state) => {
			state.titles.data = [];
			state.titles.isInitialized = false;
		});
		builder.addCase(getPropertyTitles.fulfilled, (state, { payload }) => {
			state.titles.data = payload;
			state.titles.isInitialized = true;
			state.titles.error = null;
		});
		builder.addCase(getPropertyTitles.rejected, (state, { error }) => {
			state.titles.data = [];
			state.titles.error = error;
			state.titles.isInitialized = true;
		});

		builder.addCase(getPropertyAvailableDocuments.pending, (state) => {
			state.titlesDocuments.data = [];
			state.titlesDocuments.isInitialized = false;
		});
		builder.addCase(getPropertyAvailableDocuments.fulfilled, (state, { payload }) => {
			state.titlesDocuments.data = payload;
			state.titlesDocuments.isInitialized = true;
		});
		builder.addCase(getPropertyAvailableDocuments.rejected, (state, { error }) => {
			state.titlesDocuments.data = [];
			state.titlesDocuments.error = error;
			state.titlesDocuments.isInitialized = true;
		});

		builder.addCase(getPropertyByTransactionId.pending, (state, { meta }) => {
			if (meta.arg.withLoading) state.isLoading = true;
		});
		builder.addCase(getPropertyByTransactionId.fulfilled, (state, { payload }) => {
			state.property = payload;
			state.isLoading = false;
		});
		builder.addCase(getPropertyByTransactionId.rejected, (state, { payload }) => {
			state.errorMessage = payload || null;
			state.isLoading = false;
		});
	},
});

// Reducer
export default propertySlice.reducer;

// Actions
export const { clearErrors, addNewProperty, removeNewProperty, changeProperty, clearProperty, setCurrentTab } =
	propertySlice.actions;
