import {
	transactionBuyerRelationship,
	transactionRoles,
	transactionSellerRelationship,
	transactionSolicitorRelationship,
} from 'src/pages/transactions/constants';
import { TransactionInvitationRoleItem } from 'src/pages/transactions/types';

export const getTransactionInvitationRoleItemLabel = ({ relationship, role, side }: TransactionInvitationRoleItem) => {
	if (relationship) {
		if (side === 'buy' && relationship in transactionBuyerRelationship) {
			return transactionBuyerRelationship[relationship].name;
		}
		if (side === 'sell' && relationship in transactionSellerRelationship) {
			return transactionSellerRelationship[relationship].name;
		}

		return transactionSolicitorRelationship[relationship].name;
	}

	return transactionRoles[role].name;
};
