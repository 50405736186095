import { createContext, useState, useEffect, FC, PropsWithChildren } from 'react';
// hooks
import useResponsive from '../hooks/useResponsive';
import { initialDataCollapseDrawer } from './initialData';
import type { CollapseDrawerContextProps } from './types';

const CollapseDrawerContext = createContext<CollapseDrawerContextProps>(initialDataCollapseDrawer);

// ----------------------------------------------------------------------

const CollapseDrawerProvider: FC<PropsWithChildren> = ({ children }) => {
	const isDesktop = useResponsive('up', 'lg');

	const [collapse, setCollapse] = useState({
		click: false,
		hover: false,
	});

	const [hideNavbar, setHideNavbar] = useState(false);

	useEffect(() => {
		if (!isDesktop) {
			setCollapse({
				click: false,
				hover: false,
			});
		}
	}, [isDesktop]);

	const handleToggleCollapse = () => {
		setCollapse({ ...collapse, click: !collapse.click });
	};

	const handleToggleHideNavbar = () => {
		setHideNavbar((prev) => !prev);
	};

	const handleHoverEnter = () => {
		if (collapse.click) {
			setCollapse({ ...collapse, hover: true });
		}
	};

	const handleHoverLeave = () => {
		setCollapse({ ...collapse, hover: false });
	};

	return (
		<CollapseDrawerContext.Provider
			value={{
				isCollapse: collapse.click && !collapse.hover,
				collapseClick: collapse.click,
				collapseHover: collapse.hover,
				hideNavbar,
				onToggleCollapse: handleToggleCollapse,
				onHoverEnter: handleHoverEnter,
				onHoverLeave: handleHoverLeave,
				onToggleHideNavar: handleToggleHideNavbar,
			}}
		>
			{children}
		</CollapseDrawerContext.Provider>
	);
};

export { CollapseDrawerProvider, CollapseDrawerContext };
