import { FC } from 'react';
import { useTheme } from '@mui/material';
import { IIconWithLoading } from './types';
import Iconify from '../Iconify';

const IconWithLoading: FC<IIconWithLoading> = ({ isLoading, children }) => {
	const theme = useTheme();

	if (isLoading) {
		return (
			<Iconify icon='line-md:loading-loop' fontSize={18} color={theme.palette.primary.main} sx={{ opacity: 0.6 }} />
		);
	}

	return <>{children}</>;
};

export default IconWithLoading;
