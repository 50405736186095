import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { sellProperty } from 'src/api/property';
import { useSelector } from 'react-redux';
import { selectProperty, updatePropertyOnboarding } from 'src/redux/slices/property';
import { RHFRadioGroup } from 'src/components/hook-form';
import { transactionBuyerRelationship } from 'src/pages/transactions/constants';
import { dispatch } from 'src/redux/store';
import { OnboardingStepStatusEnum } from 'src/types';
import { useSnackbar } from 'notistack';
import { getTransactionRef } from 'src/utils/firebase';
import { ConfirmationModal } from '../../common';
import { propertyRelationshipValidationSchema } from './validation';
import { PropertyRelationshipForm } from './types';

const PropertyRelationshipModal = () => {
	const { enqueueSnackbar } = useSnackbar();
	const property = useSelector(selectProperty);
	const form = useForm<PropertyRelationshipForm>({
		resolver: yupResolver(propertyRelationshipValidationSchema),
	});

	const handleConfirm = async (data: PropertyRelationshipForm) => {
		try {
			const transactionId = await sellProperty({
				propertyId: property.id,
				role: 'buyer',
				side: 'buy',
				relationship: data.relationship,
			});

			await dispatch(
				updatePropertyOnboarding({
					moveToNextModal: true,
					payload: {
						transactionRef: getTransactionRef(transactionId),
						proprietorshipMatchIssue: OnboardingStepStatusEnum.PASSED,
						relationship: OnboardingStepStatusEnum.PASSED,
					},
				}),
			);
		} catch (e) {
			if (e instanceof Error) {
				enqueueSnackbar(e.message, { variant: 'error' });
			}
		}
	};

	return (
		<FormProvider {...form}>
			<ConfirmationModal
				sx={{ maxWidth: '600px' }}
				modalName={propertyOnboardingSteps.relationship}
				isLoading={form.formState.isSubmitting}
				title='ROLE'
				cancelText='Cancel'
				confirmText='Confirm'
				description={
					<>
						What is your role in the purchase of <b>{property.name}</b>?
						<br /> <br />
						<RHFRadioGroup
							name='relationship'
							options={[
								{
									key: transactionBuyerRelationship.buyer.key,
									name: (
										<>
											<b>Buyer</b> - a future owner of this property
										</>
									),
								},
								{
									key: transactionBuyerRelationship.proxy.key,
									name: (
										<>
											<b>Helper</b> - family and friends, just here to help
										</>
									),
								},
							]}
						/>
					</>
				}
				handleConfirm={form.handleSubmit(handleConfirm)}
			/>
		</FormProvider>
	);
};

export default PropertyRelationshipModal;
