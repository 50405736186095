import { dispatch } from 'src/redux/store';
import { selectProperty, updatePropertyOnboarding } from 'src/redux/slices/property';
import useLoading from 'src/hooks/useLoading';
import { useSelector } from 'react-redux';
import modals from 'src/constants/modals';
import { syncPropertyOnboarding } from 'src/api/property-onboarding';
import { ConfirmationModal } from '../../common';

const PropertySaleConfirmationModal = () => {
	const property = useSelector(selectProperty);
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		setIsLoading(true);

		await syncPropertyOnboarding(property.id, 'sell');
		await dispatch(updatePropertyOnboarding({ activeSide: 'sell', moveToNextModal: true }));

		setIsLoading(false);
	};

	const currentAction = property.onboarding?.activeSide === 'buy' ? 'purchase' : 'remortgage';

	return (
		<ConfirmationModal
			modalName={modals.onboardingSaleConfirmation}
			isLoading={isLoading}
			title={`Cancel ${currentAction}`}
			cancelText='No'
			confirmText='Yes'
			handleConfirm={handleConfirm}
			description={
				<>
					Are you sure you want to cancel the {currentAction} and proceed with selling <b>{property.name}</b>?
				</>
			}
		/>
	);
};

export default PropertySaleConfirmationModal;
