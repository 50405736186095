import { UserOnboardingStep } from 'src/types';

// order matters
export const userOnboardingSteps: UserOnboardingStep[] = ['verification', 'role', 'profession', 'company', 'info'];

export const routesByUserOnboardingSteps: Record<UserOnboardingStep, string> = {
	verification: '/onboarding/verification',
	role: '/onboarding/select-role',
	profession: '/onboarding/select-profession',
	company: '/onboarding/select-company',
	info: '/onboarding/fill-info',
};
