import { PermissionsType } from './types';

export const extractSelectedPermissions = (permissions: PermissionsType) =>
	Object.entries(permissions).reduce(
		(acc, [action, sides]) => {
			acc[action] = [];

			const isEverySideAndRoleSelected = Object.values(sides).every((roles) =>
				Object.values(roles).every((role) => role.selected),
			);

			if (isEverySideAndRoleSelected) {
				acc[action] = ['*'];

				return acc;
			}

			Object.entries(sides).forEach(([side, roles]) => {
				Object.entries(roles).forEach(([role, { selected }]) => {
					if (selected) acc[action].push(`${side}-${role}`);
				});
			});

			return acc;
		},
		{ delete: [], upload: [], download: [], see: [] },
	);
