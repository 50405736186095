// @mui
import { styled } from '@mui/material/styles';
import { ListItemText, ListItemIcon, ListSubheader, ListItemButton, List } from '@mui/material';
// utils
import { FONT_ROBOTO } from 'src/theme/typography';
import cssStyles from 'src/utils/cssStyles';
// config
import { ICON, NAVBAR } from 'src/config';

// ----------------------------------------------------------------------

export const ListStyled = styled(List)(({ theme }) => ({
	overflow: 'auto',
	'&::-webkit-scrollbar': {
		width: '4px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: 'transparent',
		border: 0,
	},
	'&::-webkit-scrollbar-thumb': {
		borderRadius: '2px',
		backgroundColor: theme.palette.grey[500],
		cursor: 'pointer',
	},
}));

export const ListItemStyle = styled(ListItemButton, {
	shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean; depth?: number }>(({ active, depth, theme }) => ({
	position: 'relative',
	textTransform: 'capitalize',
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(1.5),
	marginBottom: theme.spacing(0.5),
	color: theme.palette.text.primary,
	borderRadius: theme.shape.borderRadius,
	minHeight: 44,
	fontWeight: 400,
	// Active item
	...(active && {
		color: theme.palette.primary.dark,
		backgroundColor: theme.palette.custom.bgColor,
		span: {
			color: theme.palette.primary.dark,
		},
	}),
	// Active item
	...(active &&
		depth !== 1 && {
			color: theme.palette.text.primary,
			backgroundColor: 'transparent',
		}),
	// Sub item
	...(depth && {
		...(depth > 1 && {
			height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
		}),
		...(depth > 2 && {
			paddingLeft: theme.spacing(depth),
		}),
	}),
}));

// ----------------------------------------------------------------------

export const ListItemTextStyle = styled(ListItemText, {
	shouldForwardProp: (prop) => prop !== 'isCollapse',
})<{ isCollapse?: boolean }>(({ isCollapse, theme }) => ({
	fontSize: '1rem',
	transition: theme.transitions.create(['width', 'opacity'], {
		duration: theme.transitions.duration.shorter,
	}),
	...(isCollapse && {
		width: 0,
		opacity: 0,
	}),
	span: {
		display: 'flex',
		alignItems: 'center',
		minHeight: 44,
		whiteSpace: 'break-spaces',
		overflowWrap: 'auto',
		fontSize: '1rem',
		fontFamily: FONT_ROBOTO,
		fontWeight: 400,
		color: theme.palette.custom.bidyText,
	},
}));

// ----------------------------------------------------------------------

export const ListItemIconStyle = styled(ListItemIcon)({
	width: ICON.NAVBAR_ITEM,
	height: ICON.NAVBAR_ITEM,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	'& svg': { width: '100%', height: '100%' },
});

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled(ListSubheader)(({ theme }) => ({
	...theme.typography.overline,
	borderRadius: theme.shape.borderRadius,
	paddingTop: theme.spacing(3),
	paddingLeft: theme.spacing(2),
	paddingBottom: theme.spacing(1),
	color: theme.palette.text.primary,
	fontSize: '1rem',
	fontFamily: FONT_ROBOTO,
	transition: theme.transitions.create('opacity', {
		duration: theme.transitions.duration.shorter,
	}),
	...cssStyles(theme).bgBlur(),
}));
