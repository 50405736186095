import { dispatch } from 'src/redux/store';
import { propertyOnboardingSteps } from 'src/pages/properties/constants';
import { updatePropertyOnboarding } from 'src/redux/slices/property';
import { OnboardingStepStatusEnum } from 'src/types';
import useLoading from 'src/hooks/useLoading';
import { ConfirmationModal } from '../../common';

const PropertyVerificationIntroductionModal = () => {
	const { isLoading, setIsLoading } = useLoading();

	const handleConfirm = async () => {
		setIsLoading(true);

		await Promise.all([
			dispatch(
				updatePropertyOnboarding({
					moveToNextModal: true,
					payload: { verificationIntroduction: OnboardingStepStatusEnum.PASSED },
				}),
			),
		]);

		setIsLoading(false);
	};

	return (
		<ConfirmationModal
			sx={{ maxWidth: '550px' }}
			modalName={propertyOnboardingSteps.verificationIntroduction}
			isLoading={isLoading}
			title='Buy Property'
			handleConfirm={handleConfirm}
			description={
				<>
					To get things started, we quickly need to verify your identity. Don't worry! Your ID check will remain valid
					even if you decide to buy a different property. <br /> <br />
					Are you happy to proceed?
				</>
			}
		/>
	);
};

export default PropertyVerificationIntroductionModal;
