import { MonthlyReport } from 'src/redux/types';
import { call } from '../@';

export type GetMonthlyReportsResponse = {
	rows: MonthlyReport[];
	total: MonthlyReport;
};

export default async function getMonthlyReports(): Promise<GetMonthlyReportsResponse> {
	return await call('reports-monthly', {});
}
