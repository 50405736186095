import { IExtendedProperty } from 'src/redux/slices/property';
import { getExtendedPropertyListing } from '../property-listing';
import { getEpc, getProperty, getPropertyQuestionnaire } from '..';

export const extractPropertyOverview = async (propertyId: string): Promise<IExtendedProperty['overview']> => {
	const property = await getProperty(propertyId);
	const [listing, propertyQuestionnaire, epc] = await Promise.all([
		getExtendedPropertyListing(propertyId),
		property.data.questionnaireProperty ? getPropertyQuestionnaire(property.data.questionnaireProperty.id) : null,
		property.data.epcReference ? getEpc(property.data.epcReference.id) : null,
	]);

	return {
		latestListing: listing?.latest ?? null,
		epc: epc?.data ?? null,
		propertyQuestionnaire: propertyQuestionnaire?.data ?? null,
	};
};
