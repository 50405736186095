import type { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { m } from 'framer-motion';
import type { IAnimateWrap } from './types';

const AnimateWrap: FC<PropsWithChildren<IAnimateWrap>> = ({ size, children, varSmall, varLarge, varMedium, sx }) => {
	const isSmall = size === 'small';
	const isLarge = size === 'large';

	return (
		<Box
			component={m.div}
			whileTap='tap'
			whileHover='hover'
			variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
			sx={{
				display: 'inline-flex',
				...(sx || {}),
			}}
		>
			{children}
		</Box>
	);
};

export default AnimateWrap;
