import { TableSortLabel } from '@mui/material';
import { useSelector } from 'react-redux';
import { TableCell, TableHead } from 'src/pages/dashboard/styles';
import { dispatch } from 'src/redux/store';
import { selectTransactionTrail, sortTransactionTrail } from 'src/redux/slices/transaction';
import { columns } from '../../constants';

const Head = () => {
	const {
		data: { orderBy, order },
	} = useSelector(selectTransactionTrail);

	const handleSort = (key: string) => () => {
		const isAsc = orderBy === key && order === 'asc';

		dispatch(sortTransactionTrail({ order: isAsc ? 'desc' : 'asc', orderBy: key }));
	};

	return (
		<TableHead>
			{columns.map((column) => (
				<TableCell minWidth={column.width} maxWidth={column.width} key={column.key} sx={{ pt: 0 }}>
					<TableSortLabel
						disabled={!column.sortable}
						active={orderBy === column.key}
						direction={orderBy === column.key ? order : 'asc'}
						onClick={handleSort(column.key)}
					>
						{column.label}
					</TableSortLabel>
				</TableCell>
			))}
		</TableHead>
	);
};

export default Head;
