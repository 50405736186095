import { useSelector } from 'react-redux';
import { selectProperty } from 'src/redux/slices/property';
import { Box } from '@mui/material';
import { wrapOptionalValue } from 'src/utils/common';
import { convertPriceToPricePerPeriod } from 'src/utils/strings';
import { PropertyGeneralAddressCard, TypographyAddressTitle } from '../../styled';
import { formatPropertyWaterProviders } from './utils';

const LocalAuthoritySection = () => {
	const property = useSelector(selectProperty);

	const parts = [
		{
			label: 'Council tax band:',
			value: wrapOptionalValue(property.councilTax, (council) => (
				<a target='_blank' href={council.sourceUrl} rel='noreferrer'>
					Band {council.councilTaxBand}
				</a>
			)),
		},
		{
			label: 'Council tax charge:',
			value: wrapOptionalValue(property.councilTax?.councilTaxBandCharge, convertPriceToPricePerPeriod),
		},
		{
			label: 'Local authority:',
			value: !!property.councilTax && (
				<a target='_blank' href={property.councilTax.localAuthorityUrl} rel='noreferrer'>
					{property.councilTax.localAuthority}
				</a>
			),
		},
		{
			label: 'Water provider:',
			value: wrapOptionalValue(property.utilities?.water, formatPropertyWaterProviders),
		},
		{
			label: 'Wastewater provider:',
			value: wrapOptionalValue(property.utilities?.sewerage, formatPropertyWaterProviders),
		},
	];

	return (
		<PropertyGeneralAddressCard sx={{ maxWidth: '630px' }}>
			<TypographyAddressTitle mb='20px'>LOCAL AUTHORITY</TypographyAddressTitle>
			<Box display='grid' gridTemplateColumns='1fr' columnGap='40px' rowGap='10px'>
				{parts.map(({ label, value }) => (
					<Box key={label}>
						<b>{label}</b> {value}
					</Box>
				))}
			</Box>
		</PropertyGeneralAddressCard>
	);
};

export default LocalAuthoritySection;
