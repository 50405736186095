import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';

export const ParticipantsCategories = styled(Box)({
	position: 'relative',
	padding: 20,
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	gap: 15,
});

export const ParticipantsCategory = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	gap: 15,
	paddingLeft: '10px',
});

export const ParticipantsCategoryList = styled(Box)({
	display: 'flex',
	flexWrap: 'wrap',
});

export const ParticipantsCategoryTitle = styled(Typography)({
	fontWeight: 700,
	fontSize: '16px',
	lineHeight: '19px',
	color: '#BDBDBD',
});

export const AddParticipant = styled(Button)({
	fontSize: 17,
	display: 'flex',
	alignSelf: 'flex-start',
	gap: 10,
});
